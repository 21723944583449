import PropTypes from "prop-types";
const Container = ({ children, className = "" }) => {
  return (
    <div className={`w-[100%] sm:w-[100%] ${className}`}>{children}</div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Container;
