// import React from 'react';
import Container from '../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
// import * as Yup from 'yup';
import TimelineForm from "../../../components/forms/postForms/timelineForm/TimelineForm";
// import TimelineForm from '../../../components/forms/postForms/timelineForm/TImelineForm';

const AddTimeline = () => {
  return (
    <Container
      breadcumbMenu="Timelines"
      breadcumbSubMenu="new Timeline"
      title="Timelines"
      btnTitle="back to timeline"
      path="/admin/timelines"
    >
      <FormContainer heading="Add Timeline">
        <TimelineForm />
      </FormContainer>
    </Container>
  );
};

export default AddTimeline;
