import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import CustomInputField from "../../customInputField/CustomInputField";
import CustomButton from "../../button/CustomButton";
// import RTE from "../../editor/RTE";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAddData } from "../../../../hooks/useAddData/useAddData";
import CustomLink from "../../customLink/CustomLink";
import { useEditData } from "../../../../hooks/useEdit/useEdit";

const ScopeForm = ({ defaultData }) => {
  const navigate = useNavigate();
  const { handleSubmit, register, control } = useForm({
    defaultValues: {
      title: defaultData?.title || "",
      description: defaultData?.description || "",
    },
  });

  const { addData, isLoading: isAdding, error: addError } = useAddData();
  const { editData, isLoading: isEditing, error: editError } = useEditData();

  const onSubmit = async (data) => {
    try {
      if (defaultData) {
        // console.log("this isupdate");
        // Editing existing data
        await editData(data, "scope", defaultData._id);
        toast.success("Data updated successfully!");
      } else {
        // Adding new data
        await addData(data, "scope");
        toast.success("Data added successfully!");
      }
      navigate("/scope");
    } catch (error) {
      console.error("Error adding ScopeForm Me:", error);
      toast.error("Failed to add ScopeForm Me");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomInputField
        label="title:"
        type="text"
        name="title"
        {...register("title")}
      />
      <CustomInputField
        label="Scope Description:"
        name="description"
        type="text"
        control={control}
        {...register("description")}
        // error={errors?.description?.message}
        // defaultValue={getValues("description")}
      />
      {/* <div className="grid grid-cols-2 gap-5">
        <CustomInputField
          label="established Date:"
          type="date"
          name="establishedDate"
          {...register("establishedDate")}
        />
        <CustomInputField
          label="impact:"
          type="Number"
          name="impacted"
          {...register("impacted")}
        />
      </div> */}
      <div className="grid grid-cols-2  gap-5 mt-5">
        <CustomLink
          path="/scope"
          btnTitle="Back"
          className="w-full text-center "
        />
        <CustomButton type="submit" disabled={isAdding || isEditing}>
          {isAdding || isEditing ? "Processing..." : "Submit"}
        </CustomButton>
      </div>
      {(addError || editError) && (
        <p className="text-red-500 mt-2">{addError || editError}</p>
      )}
    </form>
  );
};

ScopeForm.propTypes = {
  defaultData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    // establishedDate: PropTypes.instanceOf(Date),
    // impacted: PropTypes.number,
    _id: PropTypes.string,
  }),
};

export default ScopeForm;
