import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import AdvertisementForm from "../../../components/forms/postForms/advertisementForm/AdvertisementForm";
// import ColorForm from "../../../components/forms/postForms/colorForm/ColorForm";

const AddAdvertisement = () => {
  return (
    <Container
      breadcumbMenu='Advertisement'
      breadcumbSubMenu='new Advertisement'
      title='New Colors'
      btnTitle='back to Advertisement'
      path='/admin/advertisements'
    >
      <FormContainer heading='Add Advertisement Data'>
        <AdvertisementForm />
      </FormContainer>
    </Container>
  );
};

export default AddAdvertisement;
