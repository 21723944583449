import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import ProjectForm from "../../../components/forms/postForms/projectForm/ProjectForm";

const AddProject = () => {
  return (
    <Container
      breadcumbMenu='involvements'
      breadcumbSubMenu='new involvements'
      title='New involvements'
      btnTitle='back to involvements'
      path='/admin/involvements'
    >
      <FormContainer heading='Add involvements'>
        <ProjectForm />
      </FormContainer>
    </Container>
  );
};

export default AddProject;
