import Container from '../../components/container/Container';
import DataNotFound from '../errorPage/DataNotFound';
import TlppdInitiativeTable from '../../components/forms/tables/TlppdInitiativeTable';
import Loader from '../../components/loader/Loader';
import DataFetchingError from '../errorPage/DataFetchingError';
import { useFetchData } from '../../hooks/useFetchData/useFetchData';

const Initiatives = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: 'initiatives',
  });
  return (
    <Container
      breadcumbMenu="Initiatives"
      title="Initiatives"
      btnTitle="Add Initiatives"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refetchData={refetchData} />
      ) : data.length > 0 ? (
        <TlppdInitiativeTable data={data} refetchData={refetchData} />
        // <InitiativesTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Initiatives;
