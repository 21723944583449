import { useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import CustomInputField from "../../customInputField/CustomInputField";
import CustomButton from "../../button/CustomButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAddData } from "../../../../hooks/useAddData/useAddData";
import CustomLink from "../../customLink/CustomLink";
import { useEditData } from "../../../../hooks/useEdit/useEdit";
import { zodResolver } from "@hookform/resolvers/zod";
import { contactInfoSchema } from "../../../../zodSchema/contactSchema/contactSchema.js";

const ContactInfoForm = ({ defaultData }) => {
  const navigate = useNavigate();

  const { handleSubmit, register, formState: { errors }, reset } = useForm({
    defaultValues: {
      name: defaultData?.name || "",
      email: defaultData?.email || "",
      subject: defaultData?.subject || "",
      message: defaultData?.message || "",
      phoneNumber: defaultData?.phoneNumber || "",
    },
    resolver: zodResolver(contactInfoSchema),
  });

  useEffect(() => {
    reset({
      name: defaultData?.name || "",
      email: defaultData?.email || "",
      subject: defaultData?.subject || "",
      message: defaultData?.message || "",
      phoneNumber: defaultData?.phoneNumber || "",
    });
  }, [defaultData, reset]);

  const { addData, isLoading: isAdding, error: addError } = useAddData();
  const { editData, isLoading: isEditing, error: editError } = useEditData();

  const onSubmit = async (data) => {
    try {
      if (defaultData?._id) {
        await editData(data, "contacts", defaultData._id);
        toast.success("Data updated successfully!");
      } else {
        const res = await addData(data, "contacts");
        if (res.data.statusCode >= 200 && res.data.statusCode < 300) {
          toast.success(`Data '${res.data.data.name}' added successfully!`);
        } else {
          toast.error(res.data.message);
        }
      }
      navigate("/admin/contacts");
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Failed to ${defaultData?._id ? "update" : "add"} data`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomInputField
        label="Full name:"
        type="text"
        name="name"
        error={errors?.name?.message}
        {...register("name")}
      />
      <div className="grid grid-cols-3 gap-5">
        <CustomInputField
          label="Email:"
          type="email"
          name="email"
          error={errors?.email?.message}
          {...register("email")}
        />
        <CustomInputField
          label="Subject:"
          type="text"
          name="subject"
          error={errors?.subject?.message}
          {...register("subject")}
        />
        <CustomInputField
          label="Phone number:"
          type="text"
          name="phoneNumber"
          error={errors?.phoneNumber?.message}
          {...register("phoneNumber")}
        />
      </div>

      <div className="grid grid-cols-1 gap-5">
        <CustomInputField
          label="Message:"
          type="text"
          name="message"
          error={errors?.message?.message}
          {...register("message")}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
        <CustomLink
          path="/admin/contacts"
          btnTitle="Back"
          className="w-full text-center"
        />
        <CustomButton type="submit" disabled={isAdding || isEditing}>
          {isAdding || isEditing ? "Processing..." : "Submit"}
        </CustomButton>
      </div>
      {(addError || editError) && (
        <p className="text-red-500 mt-2">{addError || editError}</p>
      )}
    </form>
  );
};

ContactInfoForm.propTypes = {
  defaultData: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    subject: PropTypes.string,
    message: PropTypes.string,
    phoneNumber: PropTypes.string,
    _id: PropTypes.string,
  }),
};

export default ContactInfoForm;





















// import { useForm } from "react-hook-form";
// import PropTypes from "prop-types";
// import CustomInputField from "../../customInputField/CustomInputField";
// import CustomButton from "../../button/CustomButton";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { useAddData } from "../../../../hooks/useAddData/useAddData";
// import CustomLink from "../../customLink/CustomLink";
// import { useEditData } from "../../../../hooks/useEdit/useEdit";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { contactInfoSchema } from "../../../../zodSchema/contactSchema/contactSchema.js";

// const ContactInfoForm = ({ defaultData }) => {
//   const navigate = useNavigate();

//   const {
//     handleSubmit,register,formState: { errors },
//   } = useForm({
//     defaultValues: {
//       name: defaultData?.name || "",
//       email: defaultData?.email || "",
//       subject: defaultData?.subject || "",
//       message: defaultData?.message || "",
//       phoneNumber: defaultData?.phoneNumber || "",
//     },
//     resolver: zodResolver(contactInfoSchema),
//   });

//   const { addData, isLoading: isAdding, error: addError } = useAddData();
//   const { editData, isLoading: isEditing, error: editError } = useEditData();

//   const onSubmit = async (data) => {
//     // const formData = new FormData();


//     // formData.append('name', data?.name);
//     // formData.append('email', data?.email);
//     // formData.append('subject', data?.subject);
//     // formData.append('message', data?.message);
//     // formData.append('phoneNumber', data?.phoneNumber);
//     try {
//       if (defaultData) {
//         await editData(data, "contacts", defaultData._id);
//         toast.success("Data updated successfully!");
//         navigate("/admin/contacts");
//       } else {
//         const res = await addData(data, "contacts");
//         if (res.data.statusCode >= 200 && res.data.statusCode < 300) {
//           toast.success(`Data '${res.data.data.name}' added successfully!`);
//           navigate("/admin/contacts");
//         } else if (res.data.statusCode >= 400 && res.data.statusCode < 500) {
//           toast.error(res.data.message);
//         } else {
//           toast.error(res.data.message);
//         }
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <CustomInputField
//         label="Full name:"
//         type="text"
//         name="name"
//         error={errors?.name?.message}
//         {...register("name")}
//       />
//       <div className="grid grid-cols-3 gap-5">
//       <CustomInputField
//           label="email:"
//           type="email"
//           name="email"
//           error={errors?.email?.message}
//           {...register("email")}
//         />
//       <CustomInputField
//         label="subject:"
//         type="text"
//         name="subject"
//         error={errors?.subject?.message}
//         {...register("subject")}
//       />
//       <CustomInputField
//           label="phone number:"
//           type="text"
//           name="phoneNumber"
//           error={errors?.phoneNumber?.message}
//           {...register("phoneNumber")}
//         />
//         </div>

//       <div className="grid grid-cols-1 gap-5">
//         <CustomInputField
//           label="message:"
//           type="text"
//           name="message"
//           error={errors?.message?.message}
//           {...register("message")}
//         />
        
        
//       </div>

//       <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
//         <CustomLink
//           path="/admin/contacts"
//           btnTitle="Back"
//           className="w-full text-center "
//         />
//         <CustomButton type="submit" disabled={isAdding || isEditing}>
//           {isAdding || isEditing ? "Processing..." : "Submit"}
//         </CustomButton>
//       </div>
//       {(addError || editError) && (
//         <p className="text-red-500 mt-2">{addError || editError}</p>
//       )}
//     </form>
//   );
// };

// ContactInfoForm.propTypes = {
//   defaultData: PropTypes.shape({
//     name: PropTypes.string,
//     email: PropTypes.string,
//     subject: PropTypes.string,
//     message: PropTypes.string,
//     phoneNumber: PropTypes.string,
//     _id: PropTypes.string,
//   }),
// };
// export default ContactInfoForm;
