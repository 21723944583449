import Container from '../../components/container/Container';
import DonationTable from '../../components/forms/tables/DonationTable';
import Loader from '../../components/loader/Loader';
import { useFetchData } from '../../hooks/useFetchData/useFetchData';
import DataFetchingError from '../errorPage/DataFetchingError';
import DataNotFound from '../errorPage/DataNotFound';

const Donation = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: 'donations',
  });

  return (
    <Container
      breadcumbMenu="Donation"
      title="Donation"
      btnTitle="Add Donation"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refetchData={refetchData} />
      ) : data.length > 0 ? (
        <DonationTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Donation;
