import PropTypes from 'prop-types';
import { formatDate } from "../../utility/dateFormates";
import { Link } from "react-router-dom";

const InvolvementCard = ({ detail, index, navigateToDetailsPage }) => {
  return (
    <div
      key={index}
      className={`w-full cursor-pointer flex flex-col-reverse my-24 ${
        index % 2 !== 0
          ? "md:flex-row-reverse lg:flex-row-reverse"
          : "md:flex-row lg:flex-row "
      }`}
      onClick={() => navigateToDetailsPage(detail._id)}
    >
      <div className={`md:w-1/2 ${index % 2 !== 0 ? "ml-8" : "mr-8"}`}>
        <div className="flex justify-center items-center flex-col h-fit">
          {detail?.logo && (
            <img
              src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
                detail.logo
              }`}
              alt={detail.logo}
              className="h-[88px] w-[88px] mt-4 md:mt-0"
            />
          )}
          <h1 className="text-primary-headingColor py-4 capitalize font-semibold text-xl">
            {detail?.position}
          </h1>
          <h1 className="font-mulish font-bold text-sm">
            {formatDate(detail?.startingDate ?? "")} -{" "}
            {formatDate(detail?.endDate ?? "")}
          </h1>
        </div>
        <h1
          className="mt-3 py-4 font-mulish text-base font-bold capitalize"
          style={{
            lineHeight: "26px",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {detail?.title}
        </h1>
        <p
          className="font-mulish text-justify"
          style={{
            lineHeight: "29px",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 5,
          }}
        >
          {detail?.content}
        </p>
        <p className="text-primary-btnColor font-mulish font-bold text-sm cursor-pointer my-6">
          <Link to={`/involvement/details/${detail._id}`}>Read More</Link>
        </p>
      </div>
      <div className="md:w-1/2 h-[65vh]">
        {detail?.image && (
          <img
            src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${detail.image}`}
            alt={detail?.image}
            className="w-full h-full object-cover object-center rounded-tr-[80px] rounded-bl-[80px]"
          />
        )}
      </div>
    </div>
  );
};
InvolvementCard.propTypes = {
  detail: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    startingDate: PropTypes.string, // Add this line
    endDate: PropTypes.string,
    logo: PropTypes.string,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  navigateToDetailsPage: PropTypes.func.isRequired,
};
export default InvolvementCard;
