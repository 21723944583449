/* eslint-disable no-unused-vars */
// import Container from 'postcss/lib/container';
// import { Container } from 'postcss/lib/container';
import React from 'react';
import Container from './../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
import DonationForm from '../../../components/forms/postForms/donationForm/DonationForm';
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';

const AddDonation = () => {
  const { data: initiativesData } = useFetchData({
    endpoint: 'initiatives',
  });
  const { data: donorsData } = useFetchData({
    endpoint: 'donors',
  });
  return (
    <Container
      breadcumbMenu="Donations"
      breadcumbSubMenu="new Donations"
      title="New Donations"
      btnTitle="back to Donations"
      path="/admin/donations"
    >
      <FormContainer heading="Add Donations Detail">
        <DonationForm
          donorsData={donorsData}
          initiativesData={initiativesData}
        />
        {/* <h1>this</h1> */}
      </FormContainer>
    </Container>
  );
};

export default AddDonation;
