/* eslint-disable no-unused-vars */
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import PublicationForm from "../../../components/forms/postForms/publicationForm/PublicationForm";
import { useParams } from "react-router-dom";
import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";
import DataFetchingError from "../../errorPage/DataFetchingError";
import Loader from "../../../components/loader/Loader";
import ProductCatForm from "../../../components/forms/postForms/productCatForm/ProductCatForm";

const EditProductCat = () => {
  const { id } = useParams();
  //   custom hook for fetching data detaails from api with 2 parameters (endpoint and id)
  const { dataDetails, loading, error } = useFetchDataDetails(
    "product-categories",
    id
  );

  return (
    <Container
      breadcumbMenu='Product Category'
      breadcumbSubMenu='edit Product Category'
      title='edit Product Category'
      btnTitle='back to Product Category'
      path='/admin/product-categories'
    >
      <FormContainer heading='edit Product Category'>
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <ProductCatForm defaultData={dataDetails} isEdit='true ' />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditProductCat;
