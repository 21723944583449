// import React from 'react';
import Container from '../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
import PaperPresentForm from '../../../components/forms/postForms/paperPresent/PaperPresentForm';

const AddPaperPresent = () => {
  return (
    <Container
      breadcumbMenu="Paper Present"
      breadcumbSubMenu="new Paper Present"
      title="New Paper Present"
      btnTitle="back to Paper Present"
      path="/admin/paper/presents"
    >
      <FormContainer heading="Add Paper Present">
        <PaperPresentForm />
      </FormContainer>
    </Container>
  );
};

export default AddPaperPresent;
