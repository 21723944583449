import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import MerchandiseForm from "../../../components/forms/postForms/merchandise/MerchandiseForm";

const AddMerchandise = () => {
  return (
    <Container
      breadcumbMenu="Merchandise"
      breadcumbSubMenu="New Merchandise"
      title="New Merchandise"
      btnTitle="Back to Merchandise"
      path="/admin/merchandise"
    >
      <FormContainer heading="Add Merchandise Detail">
        <MerchandiseForm />
      </FormContainer>
    </Container>
  );
};

export default AddMerchandise;
