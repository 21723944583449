/* eslint-disable no-unused-vars */
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import { useParams } from "react-router-dom";
import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";
import DataFetchingError from "../../errorPage/DataFetchingError";
import Loader from "../../../components/loader/Loader";
import ProductForm from "../../../components/forms/postForms/productForm/ProductForm";
import ProjectForm from "../../../components/forms/postForms/projectForm/ProjectForm";

const EditProject = () => {
  const { id } = useParams();

  const { dataDetails, loading, error, refetchData } = useFetchDataDetails(
    "involvements",
    id
  );

  return (
    <Container
      breadcumbMenu="involvements"
      breadcumbSubMenu="edit involvements"
      title="edit involvements"
      btnTitle="back to involvements"
      path="/admin/involvements"
    >
      <FormContainer heading="edit involvements">
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <ProjectForm defaultData={dataDetails} refetchData={refetchData} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditProject;
