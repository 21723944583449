
// import PropTypes from "prop-types";
// import { useForm } from "react-hook-form";
// import CustomInputField from "../../customInputField/CustomInputField";
// import CustomButton from "../../button/CustomButton";
// import CustomLink from "../../customLink/CustomLink";
// import { toast } from "react-toastify";
// import { Link, useNavigate } from "react-router-dom";
// import { useAddData } from "../../../../hooks/useAddData/useAddData";
// import { useEditData } from "../../../../hooks/useEdit/useEdit";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { involvementBannerSchema } from "../../../../zodSchema/homeSchema/homeSchema";

// const InvolvementBannersForm = ({ defaultData }) => {


//   const {
//     register,
//     handleSubmit,
//     control,
//     setValue,
//     formState: { errors },
//   } = useForm({
//     defaultValues: {
//       title: defaultData?.title || "",
//       description: defaultData?.description || "",
//       image: defaultData?.image || "",
//     },
//     resolver: zodResolver(involvementBannerSchema),
//   });

//   const navigate = useNavigate();
//   // eslint-disable-next-line no-unused-vars
//   const { addData, isLoading: isAdding, error: addError } = useAddData();
//   // eslint-disable-next-line no-unused-vars
//   const { editData, isLoading: isEditing, error: editError } = useEditData();

//   // const { addData, isLoading: isAdding, error: addError } = useAddData();
//   // const { editData, isLoading: isEditing, error: editError } = useEditData();

//   const onSubmit = async (data) => {
//     const formData = new FormData();
//     formData.append("title", data.title);
//     formData.append("description", data.description);
//     formData.append("image", data.image[0]);


//     try {
//       if (defaultData) {
//         await editData(formData, "involvements/banners", defaultData._id);
//         toast.success("Data updated successfully!");
//       } else {
//         await addData(formData, "involvements/banners");
//         toast.success("Data added successfully!");
//       }
//       navigate("/admin/involvements/banners");
//     } catch (error) {
//       toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <CustomInputField
//         label="Title:"
//         type="text"
//         name="title"
//         error={errors?.title?.message}
//         {...register("title")}
//       />

//       <CustomInputField
//         label="Description:"
//         type="textarea"
//         name="description"
//         error={errors?.description?.message}
//         {...register("description")}
//       />

//       <div className="flex items-center gap-10">

//         <CustomInputField
//           label="Image:"
//           type="file"
//           name="image"
//           error={errors?.image?.message}
//           control={control}
//           onChange={(e) => setValue("image", e.target.files)}
//         />
//       </div>
//       <div className="grid grid-cols-3 gap-5 items-center">
        
//         {defaultData?.image && (
//           <div className="w-fit mb-5">
//             <Link
//               to={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${defaultData?.image}`}
//               target="image"
//             >
//               <div className="bg-yellow-600 text-xl text-center text-white p-2">
//                 Images
//               </div>
//             </Link>
//           </div>
//         )}
        
//       </div>
      
//       <div className="grid grid-cols-2  gap-5 mt-5">
//          <CustomLink
//           path="/admin/involvement/banners"
//           btnTitle="Back"
//           className="w-full text-center "
//         />
//         <CustomButton type="submit" disabled={isAdding || isEditing}>
//           {isAdding || isEditing ? "Processing..." : "Submit"}
//         </CustomButton>
//       </div>
//       {(addError || editError) && (
//         <p className="text-red-500 mt-2">{addError || editError}</p>
//       )}

//       {/* <div className="flex items-center gap-10 justify-center">
//         <CustomButton type="submit" text={`${defaultData ? "Update" : "Add"} Involvement`} />
//         <CustomLink
//           path="/admin/involvements"
//           text="Cancel"
//           className="bg-red-900 px-6 py-3 text-lg text-white rounded"
//         />
//       </div> */}
//     </form>
//   );
// };

// InvolvementBannersForm.propTypes = {
//   defaultData: PropTypes.object,
// };

// export default InvolvementBannersForm;

















import PropTypes from "prop-types";
import CustomInputField from "../../customInputField/CustomInputField";
import CustomButton from "../../button/CustomButton";
import CustomLink from "../../customLink/CustomLink";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAddData } from "../../../../hooks/useAddData/useAddData";
import { useEditData } from "../../../../hooks/useEdit/useEdit";
import { zodResolver } from "@hookform/resolvers/zod";
// import { involvementBannerSchema } from "../../../../zodSchema/involvementSchema/involvementSchema"; // Updated schema import
import { involvementBannerSchema } from "../../../../zodSchema/homeSchema/homeSchema";
const InvolvementBannersForm = ({ defaultData }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: defaultData?.title || "",
      description: defaultData?.description || "",
      image: defaultData?.image || "",
    },
    resolver: zodResolver(involvementBannerSchema),
  });

  const navigate = useNavigate();

  const { addData, isLoading: isAdding, error: addError } = useAddData();
  const { editData, isLoading: isEditing, error: editError } = useEditData();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("image", data?.image[0]);
    formData.append("title", data?.title);
    formData.append("description", data?.description);

    const header = {
      "Content-Type": "multipart/form-data", // Add the Content-Type header
    };

    try {
      if (defaultData) {
        // Editing existing data
        await editData(formData, "involvement-banners", defaultData._id);
        toast.success("Data updated successfully!");
      } else {
        // Adding new data
        await addData(formData, "involvement-banners", header);
        toast.success("Data added successfully!");
      }
      navigate("/admin/involvement-banners");
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomInputField
        label="Title:"
        type="text"
        name="title"
        {...register("title")}
        error={errors?.title?.message}
      />
      <CustomInputField
        label="Description:"
        type="text"
        name="description"
        {...register("description")}
        error={errors?.description?.message}
      />

      <CustomInputField
        label="Image:"
        type="file"
        name="image"
        control={control}
        onChange={(e) => setValue("image", e.target.files)}
        error={errors?.image?.message}
      />

      {defaultData?.image && (
        <div className="w-40 mb-5">
          <img
            className="w-full h-full object-cover"
            src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${defaultData?.image}`}
            alt="Images"
          />
        </div>
      )}

      <div className="grid grid-cols-2 gap-5 mt-5">
        <CustomLink
          path="/admin/involvement-banners"
          btnTitle="Back"
          className="w-full text-center"
        />
        <CustomButton type="submit" disabled={isAdding || isEditing}>
          {isAdding || isEditing ? "Processing..." : "Submit"}
        </CustomButton>
      </div>
      {(addError || editError) && (
        <p className="text-red-500 mt-2">{addError || editError}</p>
      )}
    </form>
  );
};

// Define prop types
InvolvementBannersForm.propTypes = {
  defaultData: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([
      PropTypes.string, // If image is a URL string
      PropTypes.instanceOf(File), // If image is a File object
    ]),
    // Add other prop types as needed
  }),
};

export default InvolvementBannersForm;






























// import PropTypes from "prop-types";
// import CustomInputField from "../../customInputField/CustomInputField";
// import CustomButton from "../../button/CustomButton";
// import CustomLink from "../../customLink/CustomLink";
// import { useForm } from "react-hook-form";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { useAddData } from "../../../../hooks/useAddData/useAddData";
// import { useEditData } from "../../../../hooks/useEdit/useEdit";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { achievmentsSchema } from "../../../../zodSchema/homeSchema/homeSchema";

// const  InvolvementBannersForm = ({ defaultData }) => {
//   const {
//     register,
//     handleSubmit,
//     control,
//     setValue,

//     formState: { errors },
//   } = useForm({
//     defaultValues: {
//       title: defaultData?.title || "",
//       description: defaultData?.description || "",
//       image: defaultData?.image || "",
//     },
//     resolver: zodResolver(achievmentsSchema),
//   });

//   const navigate = useNavigate();

//   const { addData, isLoading: isAdding, error: addError } = useAddData();
//   const { editData, isLoading: isEditing, error: editError } = useEditData();

//   const onSubmit = async (data) => {
//     const formData = new FormData();
//     console.log(data, "this is image file");

//     formData.append("image", data?.image[0]);
//     formData.append("title", data?.title);
//     formData.append("description", data?.description);

//     const header = {
//       "Content-Type": "multipart/form-data", // Add the Content-Type header
//     };

//     try {
//       if (defaultData) {
//         // Editing existing data
//         await editData(formData, "achievements", defaultData._id);
//         toast.success("Data updated successfully!");
//       } else {
//         // Adding new data
//         await addData(formData, "achievements", header);
//         toast.success("Data added successfully!");
//       }
//       navigate("/admin/achievements");
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <CustomInputField
//         label="Title:"
//         type="text"
//         name="title"
//         {...register("title")}
//         error={errors?.title?.message}
//       />
//       <CustomInputField
//         label="Description:"
//         type="text"
//         name="description"
//         {...register("description")}
//         error={errors?.description?.message}
//       />

//       <CustomInputField
//         label=" Image:"
//         type="file"
//         name="image"
//         control={control}
//         onChange={(e) => setValue("image", e.target.files)}
//         error={errors?.image?.message}
//       />

//       {defaultData?.image && (
//         <div className="w-40 mb-5">
//           <img
//             className="w-full h-full object-cover"
//             src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/images/${
//               defaultData?.image
//             }`}
//             alt="Images"
//           />
//         </div>
//       )}

//       <div className="grid grid-cols-2  gap-5 mt-5">
//         <CustomLink
//           path="/admin/achievements"
//           btnTitle="Back"
//           className="w-full text-center "
//         />
//         <CustomButton type="submit" disabled={isAdding || isEditing}>
//           {isAdding || isEditing ? "Processing..." : "Submit"}
//         </CustomButton>
//       </div>
//       {(addError || editError) && (
//         <p className="text-red-500 mt-2">{addError || editError}</p>
//       )}
//     </form>
//   );
// };

// // Define prop types
// InvolvementBannersForm.propTypes = {
//   defaultData: PropTypes.shape({
//     _id: PropTypes.string,
//     title: PropTypes.string,
//     description: PropTypes.string,
//     image: PropTypes.oneOfType([
//       PropTypes.string, // If image is a URL string
//       PropTypes.instanceOf(File), // If image is a File object
//     ]),
//     // Add other prop types as needed
//   }),
// };

// export default InvolvementBannersForm;
