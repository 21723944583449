/* eslint-disable react/prop-types */
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ManageUserForm = ({ user, refetchData }) => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const apiUrl = `${import.meta.env.VITE_APP_API_URL}/access/users`;
  const [formData, setFormData] = useState({
    fullName: user?.fullName || "",
    email: user?.email || "",
    password: "",
    role: user?.role || "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const token = JSON.parse(localStorage.getItem("amrityonjan")).accessToken;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (user?._id) {
        // Update existing user
        await axios.put(`${apiUrl}/${user._id}`, formData, config);
        toast.success("User updated successfully!");
      } else {
        // Add new user
        await axios.post(apiUrl, formData, config);
        toast.success("User added successfully!");
      }

      refetchData();
      navigate("/admin/access/users");
    } catch (error) {
      toast.error("Error saving user data. Please try again.");
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            required
          />
        </div>
        {!user?._id && ( // Only show password field for new user addition
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 px-3 py-2"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>
        )}
        {/* {!user?._id && ( // Only show password field for new user addition
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              // type={showPassword ? "text" : "password"}
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              required
            />
          </div>
        )} */}

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Role
          </label>
          <select
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            required
          >
            <option value="" disabled>
              Select role
            </option>
            <option value="admin">Admin</option>
            <option value="staff">Staff</option>
          </select>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            {user ? "Update User" : "Add User"}
          </button>
          <button
            type="button"
            onClick={() => navigate("/admin/access/users")}
            className="bg-gray-500 text-white px-4 py-2 rounded-md"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ManageUserForm;















// /* eslint-disable react/prop-types */
// import axios from "axios";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

// const ManageUserForm = ({ user, refetchData }) => {
//   const navigate = useNavigate();
//   const apiUrl = `${import.meta.env.VITE_APP_API_URL}/access/users`;
//   const [formData, setFormData] = useState({
//     fullName: user?.fullName || "",
//     email: user?.email || "",
//     password: user?.password || "",
//     role: user?.role || "",
//   });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };
//   const token = JSON.parse(localStorage.getItem("amrityonjan")).accessToken;

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const config = {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       };

//       if (user?._id) {
//         await axios.put(`${apiUrl}/${user._id}`, formData, config);
//         toast.success("User updated successfully!");
//       } else {
//         await axios.post(apiUrl, formData, config);
//         toast.success("User added successfully!");
//       }

//       refetchData();
//       navigate("/admin/access/users");
//     } catch (error) {
//       toast.error("Error saving user data. Please try again.");
//     }
//   };

//   return (
//     <div className="max-w-md mx-auto mt-10">
//       <form onSubmit={handleSubmit}>
//         <div className="mb-4">
//           <label className="block text-sm font-medium text-gray-700">
//             Name
//           </label>
//           <input
//             type="text"
//             name="name"
//             value={formData.fullName}
//             onChange={handleChange}
//             className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-sm font-medium text-gray-700">
//             Email
//           </label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-sm font-medium text-gray-700">
//             Password
//           </label>
//           <input
//             type="password"
//             name="password"
//             value={formData.password}
//             onChange={handleChange}
//             className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
//             required
//             disabled={!!user?._id}
//           />
//         </div>

//         <div className="mb-4">
//           <label className="block text-sm font-medium text-gray-700">
//             Role
//           </label>
//           <select
//             name="role"
//             value={formData.role}
//             onChange={handleChange}
//             className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
//             required
//           >
//             <option value="" disabled>
//               Select role
//             </option>
//             <option value="admin">Admin</option>
//             <option value="staff">Staff</option>
//           </select>
//         </div>
//         <div className="flex items-center justify-between">
//           <button
//             type="submit"
//             className="bg-blue-500 text-white px-4 py-2 rounded-md"
//           >
//             {user ? "Update User" : "Add User"}
//           </button>
//           <button
//             type="button"
//             onClick={() => navigate("/admin/access/users")}
//             className="bg-gray-500 text-white px-4 py-2 rounded-md"
//           >
//             Cancel
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default ManageUserForm;
















// /* eslint-disable react/prop-types */
// import { useState } from "react";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// const ManageUsersForm = ({ user, refetchData }) => {
//   const navigate = useNavigate();
//   const apiUrl = `${import.meta.env.VITE_APP_API_URL}/access/users`;
//   const [formData, setFormData] = useState({
//     name: user?.name || "",
//     email: user?.email || "",
//     role: user?.role || "",
//     // phone: user?.phone || "",
//     password: user?.password || "",
//   });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };
//   const token = JSON.parse(localStorage.getItem("amrityonjan")).accessToken;


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const config = {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       };
//       if (user?._id) {
//         await axios.put(`${apiUrl}/${user._id}`, formData, config);
//         toast.success("User updated successfully!");
//       } else {
//         await axios.post(apiUrl, formData, config);
//         toast.success("User added successfully!");
//       }
//       refetchData();
//       navigate("/admin/access/users");
//     } catch (error) {
//       toast.error("Error saving user data. Please try again.");
//     }
//   };

//   return (
//     <div className="max-w-md mx-auto mt-10">
//       <h1 className="text-xl font-bold mb-5">{user ? "Edit User" : "Add User"}</h1>
//       <form onSubmit={handleSubmit}>
//         <div className="mb-4">
//           <label className="block text-sm font-medium text-gray-700">
//             Name
//           </label>
//           <input
//             type="text"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-sm font-medium text-gray-700">
//             Email
//           </label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-sm font-medium text-gray-700">
//             Password
//           </label>
//           <input
//             type="password"
//             name="password"
//             value={formData.password}
//             onChange={handleChange}
//             className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
//             required
//           />
//         </div>

//         <div className="mb-4">
//           <label className="block text-sm font-medium text-gray-700">
//             Role
//           </label>
//           <select
//             name="role"
//             value={formData.role}
//             onChange={handleChange}
//             className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
//             required
//           >
//             <option value="" disabled>
//               Select role
//             </option>
//             <option value="admin">Admin</option>
//             <option value="staff">Staff</option>
//           </select>
//         </div>
//         <div className="flex items-center justify-between">
//           <button
//             type="submit"
//             className="bg-blue-500 text-white px-4 py-2 rounded-md"
//           >
//             {user ? "Update User" : "Add User"}
//           </button>
//           <button
//             type="button"
//             onClick={() => navigate("/admin/access/users")}
//             className="bg-gray-500 text-white px-4 py-2 rounded-md"
//           >
//             Cancel
//           </button>
//         </div>
//       </form>
      
//     </div>
//   );
// };

// export default ManageUsersForm;











// // import PropTypes from "prop-types";
// // import { useForm } from "react-hook-form";
// // import { toast } from "react-toastify";
// // import { useNavigate } from "react-router-dom";
// // import { useEffect } from "react";
// // import { yupResolver } from "@hookform/resolvers/yup";
// // import * as yup from "yup";
// // // import Button from "../../../button/Button";
// // import { getFormFieldClassName } from "../../../../utility/functions";
// // import CustomInputField from "../../../../components/forms/customInputField/CustomInputField";

// // // import CustomInputField from "../../inputFields/InputField";
// // import TextAreaField from "../../../../components/forms/customInputField/TextAreaField";
// // // import CustomLink from "../../customLink/CustomLink";
// // import CustomButton from "../../button/CustomButton";

// // const schema = yup.object().shape({
// //   name: yup.string().required("Name is required"),
// //   description: yup.string().required("Description is required"),
// //   email: yup.string().email("Invalid email").required("Email is required"),
// //   phone: yup.string().required("Phone is required"),
// //   facebook: yup.string().url("Invalid URL"),
// //   linkedin: yup.string().url("Invalid URL"),
// //   image: yup.mixed().nullable(),
// // });

// // const ManageUsersForm = ({ defaultData }) => {
// //   const {
// //     register,
// //     handleSubmit,
// //     setValue,
// //     formState: { errors },
// //   } = useForm({
// //     resolver: yupResolver(schema),
// //     defaultValues: defaultData,
// //   });

// //   const navigate = useNavigate();

// //   useEffect(() => {
// //     if (defaultData) {
// //       for (const [key, value] of Object.entries(defaultData)) {
// //         setValue(key, value);
// //       }
// //     }
// //   }, [defaultData, setValue]);

// //   const onSubmit = async (data) => {
// //     try {
// //       const formData = new FormData();

// //       for (const [key, value] of Object.entries(data)) {
// //         formData.append(key, value);
// //       }

// //       const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/access/users/${defaultData?._id || ""}`, {
// //         method: defaultData ? "PUT" : "POST",
// //         body: formData,
// //       });

// //       if (!response.ok) {
// //         throw new Error("Something went wrong");
// //       }

// //       const result = await response.json();
// //       toast.success(result.message);
// //       navigate("/admin/access/users");
// //     } catch (error) {
// //       toast.error(error.message);
// //     }
// //   };

// //   return (
// //     <form onSubmit={handleSubmit(onSubmit)}>
// //       <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
// //         <CustomInputField
// //           name="name"
// //           label="Name"
// //           type="text"
// //           register={register}
// //           error={errors.name?.message}
// //           className={getFormFieldClassName(errors.name?.message)}
// //         />
// //         <CustomInputField
// //           name="email"
// //           label="Email"
// //           type="email"
// //           register={register}
// //           error={errors.email?.message}
// //           className={getFormFieldClassName(errors.email?.message)}
// //         />
// //         <CustomInputField
// //           name="phone"
// //           label="Phone"
// //           type="text"
// //           register={register}
// //           error={errors.phone?.message}
// //           className={getFormFieldClassName(errors.phone?.message)}
// //         />
// //         <CustomInputField
// //           name="facebook"
// //           label="Facebook"
// //           type="url"
// //           register={register}
// //           error={errors.facebook?.message}
// //           className={getFormFieldClassName(errors.facebook?.message)}
// //         />
// //         <CustomInputField
// //           name="linkedin"
// //           label="LinkedIn"
// //           type="url"
// //           register={register}
// //           error={errors.linkedin?.message}
// //           className={getFormFieldClassName(errors.linkedin?.message)}
// //         />
// //         <CustomInputField
// //           name="image"
// //           label="Image"
// //           type="file"
// //           register={register}
// //           error={errors.image?.message}
// //           className={getFormFieldClassName(errors.image?.message)}
// //         />
// //         <TextAreaField
// //           name="description"
// //           label="Description"
// //           register={register}
// //           error={errors.description?.message}
// //           className={getFormFieldClassName(errors.description?.message)}
// //         />
// //       </div>
// //       <div className="mt-6">
// //         <CustomButton type="submit" text="Save" />
// //       </div>
// //     </form>
// //   );
// // };

// // ManageUsersForm.propTypes = {
// //   defaultData: PropTypes.object,
// // };

// // export default ManageUsersForm;






// // // import PropTypes from "prop-types";
// // // import { useForm } from "react-hook-form";
// // // import { toast } from "react-toastify";
// // // import { useNavigate } from "react-router-dom";
// // // import { useEditData } from "../../../../hooks/useEdit/useEdit";
// // // import { useAddData } from "../../../../hooks/useAddData/useAddData";
// // // import CustomLink from "../../customLink/CustomLink";
// // // import { zodResolver } from "@hookform/resolvers/zod";
// // // import CustomInputField from "../../customInputField/CustomInputField";
// // // import CustomButton from "../../button/CustomButton";
// // // import UsersSchema from "../../../../zodSchema/memberSchema/UsersSchema";

// // // const ManageUsersForm = ({ defaultData }) => {
// // //   const navigate = useNavigate();

// // //   const {
// // //     handleSubmit,
// // //     register,
// // //     setValue,
// // //     control,
// // //     formState: { errors },
// // //   } = useForm({
// // //     defaultValues: {
// // //       name: defaultData?.name || "",
// // //       description: defaultData?.description || "",
// // //       email: defaultData?.email || "",
// // //       facebook: defaultData?.facebook || "",
// // //       linkedin: defaultData?.linkedin || "",
// // //       phone: defaultData?.phone || "",
// // //       image: defaultData?.image || "",
// // //     },
// // //     resolver: zodResolver(UsersSchema),
// // //   });

// // //   const { addData, isLoading: isAdding, error: addError } = useAddData();
// // //   const { editData, isLoading: isEditing, error: editError } = useEditData();

// // //   const onSubmit = async (data) => {
// // //     const formData = new FormData();

// // //     formData.append("name", data?.name);
// // //     formData.append("address", data?.description);
// // //     formData.append("email", data?.email);
// // //     formData.append("phone", data?.phone);
// // //     formData.append("facebook", data?.facebook);
// // //     formData.append("linkedin", data?.linkedin);
// // //     formData.append("image", data?.image[0]);

// // //     try {
// // //       if (defaultData) {
// // //         await editData(formData, "access/users", defaultData._id);
// // //         toast.success("Data updated successfully!");
// // //       } else {
// // //         await addData(formData, "access/users");
// // //         toast.success("Data added successfully!");
// // //       }
// // //       navigate("/admin/access/users");
// // //     } catch (error) {
// // //       console.error("Error while adding data:", error);
// // //       toast.error(`Failed to ${defaultData ? "update" : "add"} data`);

// // //       // toast.error("Failed to add data");
// // //     }
// // //   };

// // //   return (
// // //     <form onSubmit={handleSubmit(onSubmit)}>
// // //       <CustomInputField
// // //         label="Member name:"
// // //         type="text"
// // //         name="name"
// // //         error={errors?.name?.message}
// // //         {...register("name")}
// // //       />
// // //       <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
// // //         <CustomInputField
// // //           label="Email:"
// // //           type="email"
// // //           name="email"
// // //           error={errors?.email?.message}
// // //           {...register("email")}
// // //         />
// // //         <CustomInputField
// // //           label="Phone number:"
// // //           type="number"
// // //           name="phone"
// // //           error={errors?.phone?.message}
// // //           {...register("phone")}
// // //         />
// // //       </div>

// // //       <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
// // //         <CustomInputField
// // //           label="Facebook:"
// // //           type="text"
// // //           name="facebook"
// // //           error={errors?.facebook?.message}
// // //           {...register("facebook")}
// // //         />
// // //         <CustomInputField
// // //           label="LinkedIn:"
// // //           type="text"
// // //           name="linkedin"
// // //           error={errors?.linkedin?.message}
// // //           {...register("linkedin")}
// // //         />
// // //         <div className="flex gap-2">
// // //           {" "}
// // //           <CustomInputField
// // //             label="Featured Image:"
// // //             type="file"
// // //             name="image"
// // //             control={control}
// // //             onChange={(e) => setValue("image", e.target.files)}
// // //             error={errors?.image?.message}
// // //           />
// // //           {defaultData?.image && (
// // //             <div className="w-40 mb-5">
// // //               <img
// // //                 className="w-full h-full object-cover"
// // //                 src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/images/${
// // //                   defaultData?.image
// // //                 }`}
// // //                 alt=""
// // //               />
// // //             </div>
// // //           )}
// // //         </div>
// // //       </div>
// // //       {/* <div className='grid grid-cols-2 gap-5'> */}
// // //       <CustomInputField
// // //         label="Description:"
// // //         type="text-area"
// // //         name="description"
// // //         error={errors?.description?.message}
// // //         {...register("description")}
// // //       />

// // //       {/* </div> */}
// // //       <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
// // //         <CustomLink
// // //           path="/admin/access/users"
// // //           btnTitle="Back"
// // //           className="w-full text-center "
// // //         />
// // //         <CustomButton type="submit" disabled={isAdding || isEditing}>
// // //           {isAdding || isEditing ? "Processing..." : "Submit"}
// // //         </CustomButton>
// // //       </div>
// // //       {(addError || editError) && (
// // //         <p className="text-red-500 mt-2">{addError || editError}</p>
// // //       )}
// // //     </form>
// // //   );
// // // };

// // // ManageUsersForm.propTypes = {
// // //   defaultData: PropTypes.shape({
// // //     _id: PropTypes.string.isRequired,
// // //     name: PropTypes.string,
// // //     description: PropTypes.string,
// // //     email: PropTypes.string,
// // //     facebook: PropTypes.string,
// // //     linkedin: PropTypes.string,
// // //     phone: PropTypes.string,
// // //     image: PropTypes.string,
// // //     report: PropTypes.string,
// // //   }),
// // // };

// // // export default ManageUsersForm;
