import { z } from "zod";

const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
];
const ACCEPTED_DOC_TYPES = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

// export const ProductCatSchema = z.object({
//   name: z.string().min(1, { message: " Category Name field is required" }),
// });
// export const ColorSchema = z.object({
//   name: z.string().min(1, { message: "Color Name field is required" }),
// });

export const ProjectSchema = z.object({
  title: z.string().min(1, { message: "title field is required" }),
  description: z.string().min(1, { message: "description field is required" }),
  status: z.string().min(1, { message: "status field is required" }),
  startingDate: z.string(),
  endDate: z.string(),
  budget: z.union([z.string(), z.number()]),
  image: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    )
    .optional(),
  proposal: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_DOC_TYPES.includes(file?.type));
      },
      {
        message: "Accepted documents types only PDF, DOC, DOCX...",
      }
    )
    .optional(),
});



export const InvolvementSchema = z.object({
  title: z.string().min(1, { message: "Title field is required" }),
  position: z.string().optional(),
  organization: z.string().optional(),
  content: z.string().min(1, { message: "Content field is required" }),
  location: z.string().min(1, { message: "Location field is required" }),
  yourPost: z.string().min(1, { message: "Your Post field is required" }),
  keyResponsibility: z.string().optional(),
  category: z.enum(["communityDevelopment", "languageLogstic", "teaching"]),
  startingDate: z.string().min(1, { message: "Starting date field is required" }),
  endDate: z.string().min(1, { message: "End date field is required" }),
  logo: z
    .any()
    .refine(
      (files) => !Array.isArray(files) || files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type)),
      { message: "Accepted image types: JPG, JPEG, PNG, GIF" }
    )
    .optional(),
  image: z
    .any()
    .refine(
      (files) => !Array.isArray(files) || files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type)),
      { message: "Accepted image types: JPG, JPEG, PNG, GIF" }
    )
    .optional(),
  subImages: z
    .any()
    .refine(
      (files) => !Array.isArray(files) || files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type)),
      { message: "Accepted image types: JPG, JPEG, PNG, GIF" }
    )
    .optional(),
});

