import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import { useParams } from "react-router-dom";
import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";
import Loader from "../../../components/loader/Loader";
import DataFetchingError from "../../errorPage/DataFetchingError";
import InvolvementResponsibilitiesForm from "../../../components/forms/postForms/InvolvementResponsibilitiesForm/InvolvementResponsibilitiesForm";
import { useFetchData } from "../../../hooks/useFetchData/useFetchData";


const EditinvolvementResponsibilities = () => {
  const { id } = useParams();
  const { dataDetails, loading, error } = useFetchDataDetails("involvementResponsibilities", id);

  const { data } = useFetchData({
    endpoint: 'involvements',
  });

  
  return (
    <Container
      breadcumbMenu='involvement-Responsibilities'
      breadcumbSubMenu='edit involvement-Responsibilities'
      title='edit involvement-Responsibilities'
      btnTitle='back to Responsibilities'
      path='/admin/involvementResponsibilities'
    >
      <FormContainer heading='edit Key Responsibilities'>
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
          ) : (
            <InvolvementResponsibilitiesForm defaultData={dataDetails} involvementData={data} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditinvolvementResponsibilities;
