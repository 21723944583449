import { lazy, Suspense } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthProvider } from "./layouts/AuthContext";
// import Loader from "./components/loader/Loader";
import ProtectedRoute from "./components/ProtectedRoute";

const DashboardLayout = lazy(() => import("./layouts/DashboardLayout"));
const AuthLayout = lazy(() => import("./layouts/AuthLayout")); // Make sure you have this component
const Dashboard = lazy(() => import("./pages/dashbaord/Dashboard"));
const Member = lazy(() => import("./pages/member/Member"));
const AddMember = lazy(() => import("./pages/member/addMember/AddMember"));
const EditMember = lazy(() => import("./pages/member/editMember/EditMember"));
const Login = lazy(() => import("./pages/auth/Login"));
const PageNotFound = lazy(() => import("./pages/errorPage/PageNotFound"));


// AboutMe
const AboutMe = lazy(() => import("./pages/aboutme/AboutMe"));
const AddAboutMe = lazy(() => import("./pages/aboutme/addAboutMe/AddAboutMe"));
const EditAboutMe = lazy(() => import("./pages/aboutme/editAboutMe/EditAboutMe"));


// Affiliation
const Affiliation = lazy(() => import("./pages/affiliation/Affiliation"));
const AddAffiliation = lazy(() => import("./pages/affiliation/addAffiliation/AddAffiliation"));
const EditAffiliation = lazy(() => import("./pages/affiliation/editAffiliation/EditAffiliation"));


// BannerAds
const BannerAds = lazy(() => import("./pages/bannerAds/BannerAds"));
const AddBannerAds = lazy(() => import("./pages/bannerAds/addBannerAds/AddBannerAds"));
const EditBannerAds = lazy(() => import("./pages/bannerAds/editBannerAds/EditBannerAds"));


// Scope
import Scope from "./pages/scope/scope";
import AddScope from "./pages/scope/addScope/AddScope";
import EditScope from "./pages/scope/editScope/EditScope";

import Timeline from "./pages/timeline/Timeline";
import AddTimeline from "./pages/timeline/addTimeline/AddTimeline";

import Achievement from "./pages/achievement/Achievement";
import AddAchievement from "./pages/achievement/addAchievement/AddAchievement";
import EditAchievement from "./pages/achievement/editAchievement/EditAchievement";

// import TlppdInitiative from "./pages/tlppdInitiative/tlppdInitiative";
// import AddtlppdInitiative from "./pages/tlppdInitiative/addtlppdInitiative/AddtlppdInitiative";
// import EdittlppdInitiative from "./pages/tlppdInitiative/edittlppdInitiative/EdittlppdInitiative";

import Initiatives from "./pages/initiative/Initiatives";
import AddInitiatve from "./pages/initiative/addInitiatives/AddInitiatve";
import EditInitiatve from "./pages/initiative/edtiInitiative/EditInitiative";

import Article from "./pages/article/Article";
import AddArticle from "./pages/article/addArticle/AddArticle";

import EditArticle from "./pages/article/editArticle/EditArticle";
import EditTimeline from "./pages/timeline/editTimeline/EditTimeline";

import ArticleCategory from "./pages/articleCategory/ArticleCategory";
import AddArticleCategory from "./pages/articleCategory/addArticleCategory/AddArticleCategory";
import EditArticleCategory from "./pages/articleCategory/editArticleCategory/EditArticleCategory";

import Donor from "./pages/donor/Donor";
import AddDonor from "./pages/donor/addDonor/AddDonor";
import EditDonor from "./pages/donor/editDonor/EditDonor";

import Donation from "./pages/donation/Donation";
import AddDonation from "./pages/donation/addDonation/AddDonation";
import EditDonation from "./pages/donation/editDonation/EditDonation";

// import Setting from "./pages/setting/Setting";
// import AddSetting from "./pages/setting/addSetting/AddSetting";
// import EditSetting from "./pages/setting/editSetting/EditSetting";

// import Event from "./pages/event/Event";
// import AddEvent from "./pages/event/addEvent/AddEvent";
// import EditEvent from "./pages/event/editEvent/EditEvent";

// import Product from "./pages/product/Product";
// import AddProduct from "./pages/product/addProduct/AddProduct";
// import EditProduct from "./pages/product/editProduct/EditProduct";

import ProductCategory from "./pages/productCategory/ProdcutCategory";
import AddProductCat from "./pages/productCategory/addProductCat/AddProductCat";
import EditProductCat from "./pages/productCategory/editProductCat/EditProductCat";

import Color from "./pages/color/Color";
import AddColor from "./pages/color/addColor/AddColor";
import EditColor from "./pages/color/editColor/EditColor";

import Merchandise from "./pages/merchandise/Merchandise";
import AddMerchandise from "./pages/merchandise/addMerchandise/AddMerchandise";
import EditMerchandise from "./pages/merchandise/EditMerchandise/EditMerchandise";

// import Contact from "./pages/contact/Contact";
import ContactInfo from "./pages/contactInfo/ContactInfo";
import EditContactInfo from "./pages/contactInfo/editContactInfo/EditContactInfo";
import AddContactInfo from "./pages/contactInfo/addContactInfo/AddContactInfo";

import Advertisement from "./pages/advertisement/Advertisement";
import AddAdvertisement from "./pages/advertisement/addAdvertisement/AddAdvertisement";
import EditAdvertisement from "./pages/advertisement/editAdvertisement/EditAdvertisement";

// import Notice from "./pages/notice/Notice";
// import AddNotice from "./pages/notice/addNotice/AddNotice";
// import EditNotice from "./pages/notice/editNotice/EditNotice";

import Sponsor from "./pages/sponsor/Sponsor";
import AddSponsor from "./pages/sponsor/addSponsor/AddSponsor";
import EditSponsor from "./pages/sponsor/editSponsor/EditSponsor";

// import InvolvementAchievements from "./pages/involvementAchievements/involvementAchievements.jsx";
import InvolvementAchievements from "./pages/involvementAchievements/InvolvementAchievements.jsx";
import AddinvolvementAchievements from "./pages/involvementAchievements/addinvolvementAchievements/AddinvolvementAchievements";
import EditinvolvementAchievements from "./pages/involvementAchievements/editinvolvementAchievements/EditinvolvementAchievements";

import InvolvementResponsibilities from "./pages/involvementResponsibilities/InvolvementResponsibilities.jsx";
import AddinvolvementResponsibilities from "./pages/involvementResponsibilities/addinvolvementResponsibilities/AddinvolvementResponsibilities.jsx";
import EditinvolvementResponsibilities  from "./pages/involvementResponsibilities/editinvolvementResponsibilities/EditinvolvementResponsibilities.jsx";

import EditEventSponsor from "./pages/eventSponsor/editEventSponsor/EditEventSponsor";
import AddEventSponsor from "./pages/eventSponsor/addEventSponsor/AddEventSponsor";
import EventSponsor from "./pages/eventSponsor/EventSponsor";

import Publication from "./pages/publication/Publication";
import AddPublication from "./pages/publication/addPublication/AddPublication";
import EditPublication from "./pages/publication/editPublication/EditPublication";

// import EditGlimpse from "./pages/glimpse/editGlimpse/EditGlimpse";
// import AddGlimpse from "./pages/glimpse/addGlimpse/AddGlimpse";
// import Glimpse from "./pages/glimpse/Glimpse";

import Project from "./pages/projects/Project";
import AddProject from "./pages/projects/addProject/AddProject";
import EditProject from "./pages/projects/editProject/EditProject";

// import Overview from "./pages/overview/Overview";
// import AddOverview from "./pages/overview/addOverview/AddOverview";
// import EditOverview from "./pages/overview/editOverview/EditOverview";

// import Objective from "./pages/objective/Objective";
// import AddObjective from "./pages/objective/addObjective/AddObjective";
// import EditObjective from "./pages/objective/editObjective.jsx/EditObjective";

// import EditTargetAudience from "./pages/targetAudience/editTargetAudience/EditTargetAudience";
// import AddTargetAudience from "./pages/targetAudience/addTargetAudience/AddTargetAudience";
// import TargetAudience from "./pages/targetAudience/TargetAudience";

// import Outcome from "./pages/outcomes/Outcomes";
// import AddOutcome from "./pages/outcomes/addOutcome/AddOutcome";
// import EditOutcome from "./pages/outcomes/editOutcome/EditOutcome";

// import Testimonial from "./pages/testimonial/Testimonial";
// import AddTestimonial from "./pages/testimonial/addTestimonial/AddTestimonial";
// import EditTestimonial from "./pages/testimonial/editTestimonial/EditTestimonial";

import ManageUsers from "./pages/manageUsers/ManageUsers"; 
import AddManageUsers from "./pages/manageUsers/addManageUsers/AddManageUsers";
import EditManageUsers from "./pages/manageUsers/editManageUsers/EditManageUsers";

import PaperPresent from "./pages/paperPresent/PaperPresent";
import AddPaperPresent from "./pages/paperPresent//addPaperPresents/AddPaperPresent";
import EditPaperPresents from "./pages/paperPresent/editPaperPresents/EditPaperPresents";

import InvolvementBanners from "./pages/involvement-banners/involvementBanners";
import AddInvolvementBanners from "./pages/involvement-banners/addInvolvementBanners/AddInvolvementBanners";
import EditInvolvementBanners from "./pages/involvement-banners/EditInvolvementBanners/EditInvolvementBanners";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Start of forntend url
import "./App.css";
import ClientLayout from "./layouts/ClientLayout";
import LandingPage from "./pages/LandingPage";
import AboutPage from "./pages/AboutPage";
import InitiativesPage from "./pages/InitiativesPage";
import ProjectDetailsPage from "./components/initiatives/ProjectDetailsPage";
import InvolvementPage from "./pages/InvolvementPage";
import InvolvementDetailPage from "./pages/InvolvementDetailPage";
import LoadingSpinner from "./components/common/LoadingSpinner";
import ContactPage from "./pages/ContactPage";
import Articles from "./pages/Articles";
import ArticleDetailPage from "./pages/ArticleDetailPage";
import PublicationPage from "./pages/PublicationPage";
import PublicationDetailPage from "./pages/PublicationDetailPage";
// End of forntend url
function AppRoutes() {
  return createBrowserRouter([
    {
      path: "/",
      element: <ClientLayout />,
      children: [
        { index: true, element: <LandingPage />,},
        { path: "abouts", element: <AboutPage />,},
        { path: "initiatives", element: <InitiativesPage />,},
        { path: "initiatives/:id", element: <ProjectDetailsPage />,},
        { path: "publications", element: <PublicationPage />, },
        { path: "publications/:id", element: <PublicationDetailPage />, },
        { path: "articles", element: <Articles />,},
        { path: "articles/details/:id", element: <ArticleDetailPage />,},
        { path: "contact", element: <ContactPage />,},
        { path: "involvement", element: <InvolvementPage />,},
        { path: "involvement/details/:id", element: <InvolvementDetailPage />,},
      ],
    },
    {
      path: "/admin/",
      element: <DashboardLayout />,
      children: [
        { index: true, element: <ProtectedRoute element={<Dashboard />} /> },

        // manage users
        { path: "access/users", element: <ProtectedRoute element={<ManageUsers />} /> },
        { path: "access/users/add", element: <ProtectedRoute element={<AddManageUsers />} /> },
        { path: "access/users/edit/:id", element: <ProtectedRoute element={<EditManageUsers />} /> },

        //achievements
        { path: "achievements", element: <ProtectedRoute element={<Achievement />} /> },
        { path: "achievements/add", element: <ProtectedRoute element={<AddAchievement />} /> },
        { path: "achievements/edit/:id", element: <ProtectedRoute element={<EditAchievement />} /> },

        //initiatives
        { path: "initiatives", element: <ProtectedRoute element={<Initiatives />} /> },
        { path: "initiatives/add", element: <ProtectedRoute element={<AddInitiatve />} /> },
        { path: "initiatives/edit/:id", element: <ProtectedRoute element={<EditInitiatve />} /> },
        
        //initiatives
        // { path: "initiatives", element: <ProtectedRoute element={<TlppdInitiative />} /> },
        // { path: "initiatives/add", element: <ProtectedRoute element={<AddtlppdInitiative />} /> },
        // { path: "initiatives/edit/:id", element: <ProtectedRoute element={<EdittlppdInitiative />} /> },
        
        // users
        { path: "users", element: <ProtectedRoute element={<Member />} /> },
        { path: "users/add", element: <ProtectedRoute element={<AddMember />} /> },
        { path: "users/edit/:id", element: <ProtectedRoute element={<EditMember />} /> },
        //abouts
        { path: "abouts", element: <ProtectedRoute element={<AboutMe />} /> },
        { path: "abouts/add", element: <ProtectedRoute element={<AddAboutMe />} /> },
        { path: "abouts/edit/:id", element: <ProtectedRoute element={<EditAboutMe />} /> },
         
        //advertisements
        { path: "advertisements", element: <ProtectedRoute element={<Advertisement />} /> },
        { path: "advertisements/add", element: <ProtectedRoute element={<AddAdvertisement />} /> },
        { path: "advertisements/edit/:id", element: <ProtectedRoute element={<EditAdvertisement />} /> },

        // Start of bannerads
        { path: "bannerpost", element: <ProtectedRoute element={<BannerAds />} /> },
        { path: "bannerpost/add", element: <ProtectedRoute element={<AddBannerAds />} /> },
        { path: "bannerpost/edit/:id", element: <ProtectedRoute element={<EditBannerAds />} /> },
        // End of Ads

          //AboutUS
        // { path: "/aboutUs", element: <ProtectedRoute element={<AboutUs />} /> },
        // { path: "/aboutUs/add", element: <ProtectedRoute element={<AddAboutUs />} /> },
        // { path: "/aboutUs/edit/:id", element: <ProtectedRoute element={<EditAboutUs />} /> },
        // End of AboutUS

         //start of scope
         { path: "scope", element: <ProtectedRoute element={<Scope />} /> },
         { path: "scope/add", element: <ProtectedRoute element={<AddScope />} /> },
        { path: "scope/edit/:id", element: <ProtectedRoute element={<EditScope />} /> },
        
        //sponsors
        { path: "sponsors", element: <ProtectedRoute element={<Sponsor />} /> },
        { path: "sponsors/add", element: <ProtectedRoute element={<AddSponsor />} /> },
        { path: "sponsors/edit/:id", element: <ProtectedRoute element={<EditSponsor />} /> },
        //events
        // { path: "events", element: <ProtectedRoute element={<Event />} /> },
        // { path: "events/add", element: <ProtectedRoute element={<AddEvent />} /> },
        // { path: "events/edit/:id", element: <ProtectedRoute element={<EditEvent />} /> },

        //involvements
        { path: "involvements", element: <ProtectedRoute element={<Project />} /> },
        { path: "involvements/add", element: <ProtectedRoute element={<AddProject />} /> },
        { path: "involvements/edit/:id", element: <ProtectedRoute element={<EditProject />} /> },

        //involvement-banners
        { path: "involvement-banners", element: <ProtectedRoute element={<InvolvementBanners />} /> },
        { path: "involvement-banners/add", element: <ProtectedRoute element={<AddInvolvementBanners />} /> },
        { path: "involvement-banners/edit/:id", element: <ProtectedRoute element={<EditInvolvementBanners />} /> },

        //involvement-achievements
        { path: "involvement-achievements", element: <ProtectedRoute element={<InvolvementAchievements />} /> },
        { path: "involvement-achievements/add", element: <ProtectedRoute element={<AddinvolvementAchievements />} /> },
        { path: "involvement-achievements/edit/:id", element: <ProtectedRoute element={<EditinvolvementAchievements />} /> },

        //involvement-Responsibilities
        { path: "involvementResponsibilities", element: <ProtectedRoute element={<InvolvementResponsibilities />} /> },
        { path: "involvementResponsibilities/add", element: <ProtectedRoute element={<AddinvolvementResponsibilities />} /> },
        { path: "involvementResponsibilities/edit/:id", element: <ProtectedRoute element={<EditinvolvementResponsibilities />} /> },


        //affiliations
        { path: "affiliations", element: <ProtectedRoute element={<Affiliation />} /> },
        { path: "affiliations/add", element: <ProtectedRoute element={< AddAffiliation/>} /> },
        { path: "affiliations/edit/:id", element: <ProtectedRoute element={<EditAffiliation />} /> },

        //EventSponsor
        { path: "event-sponsors", element: <ProtectedRoute element={<EventSponsor />} /> },
        { path: "event-sponsors/add", element: <ProtectedRoute element={<AddEventSponsor />} /> },
        { path: "event-sponsors/edit/:id", element: <ProtectedRoute element={<EditEventSponsor />} /> },

        //NewsAppearance
        { path: "publications", element: <ProtectedRoute element={<Publication />} /> },
        { path: "publications/add", element: <ProtectedRoute element={<AddPublication />} /> },
        { path: "publications/edit/:id", element: <ProtectedRoute element={<EditPublication />} /> },
        // //Glimpses
        // { path: "glimpses", element: <ProtectedRoute element={<Glimpse />} /> },
        // { path: "glimpses/add", element: <ProtectedRoute element={<AddGlimpse />} /> },
        // { path: "glimpses/edit/:id", element: <ProtectedRoute element={<EditGlimpse />} /> },
        // //members
        // { path: "members", element: <ProtectedRoute element={<Member />} /> },
        // { path: "members/add", element: <ProtectedRoute element={<AddMember />} /> },
        // { path: "members/edit/:id", element: <ProtectedRoute element={<EditMember />} /> },

        //paper present 
        { path: "paper/presents", element: <ProtectedRoute element={<PaperPresent />} /> },
        { path: "paper/presents/add", element: <ProtectedRoute element={<AddPaperPresent />} /> },
        { path: "paper/presents/edit/:id", element: <ProtectedRoute element={<EditPaperPresents />} /> },

        //product-categories
        { path: "product-categories", element: <ProtectedRoute element={<ProductCategory />} /> },
        { path: "product-categories/add", element: <ProtectedRoute element={<AddProductCat />} /> },
        { path: "product-categories/edit/:id", element: <ProtectedRoute element={<EditProductCat />} /> },
        //colors
        { path: "colors", element: <ProtectedRoute element={<Color />} /> },
        { path: "colors/add", element: <ProtectedRoute element={<AddColor />} /> },
        { path: "colors/edit/:id", element: <ProtectedRoute element={<EditColor />} /> },
        //products
        // { path: "/products", element: <ProtectedRoute element={<Product />} /> },
        // { path: "/products/add", element: <ProtectedRoute element={<AddProduct />} /> },
        // { path: "/products/edit/:id", element: <ProtectedRoute element={<EditProduct />} /> },

        //product-images for Timeline
        { path: "product-images", element: <ProtectedRoute element={<Timeline />} /> },
        // { path: "/product-images/add", element: <ProtectedRoute element={<Timeline />} /> },
        // { path: "/product-images/edit/:id", element: <ProtectedRoute element={<EditColor />} /> },

        // Timelines
        { path: "timelines", element: <ProtectedRoute element={<Timeline />} /> },
        { path: "timelines/add", element: <ProtectedRoute element={<AddTimeline />} /> },
        { path: "timelines/edit/:id", element: <ProtectedRoute element={<EditTimeline />} /> },
        
        //Article
        { path: "articles", element: <ProtectedRoute element={<Article />} /> },
        { path: "articles/add", element: <ProtectedRoute element={<AddArticle />} /> },
        { path: "articles/edit/:id", element: <ProtectedRoute element={<EditArticle />} /> },
        //article-categories
        { path: "article/categories", element: <ProtectedRoute element={<ArticleCategory />} /> },
        { path: "article/categories/add", element: <ProtectedRoute element={<AddArticleCategory />} /> },
        { path: "article/categories/edit/:id", element: <ProtectedRoute element={<EditArticleCategory />} /> },

        //merchandise
        { path: "merchandise", element: <ProtectedRoute element={<Merchandise />} /> },
        { path: "merchandise/add", element: <ProtectedRoute element={<AddMerchandise />} /> },
        { path: "merchandise/edit/:id", element: <ProtectedRoute element={<EditMerchandise />} /> },

        // //overviews
        // { path: "overviews", element: <ProtectedRoute element={<Overview />} /> },
        // { path: "overviews/add", element: <ProtectedRoute element={<AddOverview />} /> },
        // { path: "overviews/edit/:id", element: <ProtectedRoute element={<EditOverview />} /> },

        // //objectives
        // { path: "objectives", element: <ProtectedRoute element={<Objective />} /> },
        // { path: "objectives/add", element: <ProtectedRoute element={<AddObjective />} /> },
        // { path: "objectives/edit/:id", element: <ProtectedRoute element={<EditObjective />} /> },

        // //TargetAudience
        // { path: "target-audiences", element: <ProtectedRoute element={<TargetAudience />} /> },
        // { path: "target-audiences/add", element: <ProtectedRoute element={<AddTargetAudience />} /> },
        // { path: "target-audiences/edit/:id", element: <ProtectedRoute element={<EditTargetAudience />} /> },

        // //outcomes
        // { path: "outcomes", element: <ProtectedRoute element={<Outcome />} /> },
        // { path: "outcomes/add", element: <ProtectedRoute element={<AddOutcome />} /> },
        // { path: "outcomes/edit/:id", element: <ProtectedRoute element={<EditOutcome />} /> },

        // //testimonials
        // { path: "testimonials", element: <ProtectedRoute element={<Testimonial />} /> },
        // { path: "testimonials/add", element: <ProtectedRoute element={<AddTestimonial />} /> },
        // { path: "testimonials/edit/:id", element: <ProtectedRoute element={<EditTestimonial />} /> },

         //donations
         { path: "donations", element: <ProtectedRoute element={<Donation />} /> },
         { path: "donations/add", element: <ProtectedRoute element={<AddDonation />} /> },
         { path: "donations/edit/:id", element: <ProtectedRoute element={<EditDonation />} /> },
         //donors
         { path: "donors", element: <ProtectedRoute element={<Donor />} /> },
         { path: "donors/add", element: <ProtectedRoute element={<AddDonor />} /> },
         { path: "donors/edit/:id", element: <ProtectedRoute element={<EditDonor />} /> },

        //contacts adn contacts-info
        // { path: "/contacts", element: <ProtectedRoute element={<Contact />} /> },
        { path: "contacts", element: <ProtectedRoute element={<ContactInfo />} /> },
        { path: "contacts/add", element: <ProtectedRoute element={<AddContactInfo />} /> },
        { path: "contacts/edit/:id", element: <ProtectedRoute element={<EditContactInfo />} /> },

        // //settings
        // { path: "users", element: <ProtectedRoute element={<Setting />} /> },
        // { path: "users/add", element: <ProtectedRoute element={<AddSetting />} /> },
        // { path: "users/edit/:id", element: <ProtectedRoute element={<EditSetting />} /> },

        { path: "*", element: <PageNotFound /> }
      ],
    },
    {
      path: "/admin",
      element: <AuthLayout />,
      children: [
        { path: "login", element: <Login /> },
      ],
    },
    
    {
      path: "*",
      element: (
        <div className='w-full min-h-screen flex flex-col items-center justify-center bg-gray-100'>
          <div className='text-center'>
            <h1 className='text-6xl font-bold text-gray-800 mb-4'>404</h1>
            <p className='text-xl text-gray-600 mb-8'>Oops! Page not found.</p>
            <a href='/' className='text-blue-500 hover:underline'>
              Go back to home
            </a>
          </div>
        </div>
      ),
    },
  ]);
}

function App() {
  return (
    <AuthProvider>
      <Suspense fallback={<LoadingSpinner />}>
      {/* <Suspense fallback={<Loader />}> */}
        <RouterProvider router={AppRoutes()} />
        <ToastContainer />
      </Suspense>
    </AuthProvider>
  );
}

export default App;

{/* <>
      <Suspense fallback={<LoadingSpinner />}>
        <RouterProvider router={router} />
      </Suspense>
    </> */}