import { useState } from "react";
import TableContainer from "../../container/TableContainer";
import ToolTip from "../tooltip/ToolTip";
import { Pencil, Trash2 } from "lucide-react";
import Modal from "../../models/Model";
import { toast } from "react-toastify";
import useDataDeletion from "../../../hooks/useDataDeletion/usedDataDeletion";
import { Link } from "react-router-dom";
import Pagination from "../pagination/Pagination";
import PropTypes from "prop-types";
import { formatDate } from '../../../utility/dateFormate/formateDate';

const AchievementTable = ({ data, refetchData }) => {
  const apiUrl = `${import.meta.env.VITE_APP_API_URL}/achievements`;
  const { deleteData } = useDataDeletion();
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;

  const openModal = (_id) => {
    setDeleteItemId(_id);
  };

  const closeModal = () => {
    setDeleteItemId(null);
  };
  const handleDelete = async () => {
    deleteData(`${apiUrl}/${deleteItemId}`, () => {
      toast.success("Data deleted successfully!");
      refetchData();
      closeModal();
    });
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const newData = data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <TableContainer>
      {data && (
        <>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 capitalize font-bold ">
              <tr>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-left text-sm  text-gray-700"
                >
                  <span>SN</span>
                </th>

                <th
                  scope="col"
                  className="px-4 py-3.5 text-left text-sm  text-gray-700"
                >
                  Logo{" "}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-left text-sm  text-gray-700"
                >
                  Images{" "}
                </th>
                <th
                  scope="col"
                  className="px-8 py-3.5 text-left text-sm  text-gray-700"
                >
                  Title
                </th>

                <th
                  scope="col"
                  className="px-8 py-3.5 text-left text-sm  text-gray-700"
                >
                  Description{" "}
                </th>
                

                <th
                  scope="col"
                  className="px-12 py-3.5 text-left text-sm  text-gray-700"
                >
                  Address{" "}
                </th>
                <th scope="col" className="capitalize px-2 py-3 text-left text-sm font-bold text-gray-700">
                  <span>Date</span>
                </th>

                <th scope="col" className="relative px-0 py-3.5">
                  <span className="">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {newData?.map((item, index) => (
                <tr key={item._id}>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    {index + 1}
                  </td>

                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    <img
                      className="w-12 h-12 object-cover"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
                        item.logo
                      }`}
                      alt="Logo"
                    />
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    <img
                      className="w-14 h-14 object-cover"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
                        item.images
                      }`}
                      alt="Image"
                    />
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    {item.title}
                  </td>
                  {/* <td className="px-8 py-4 text-left text-sm text-gray-700">
                    {initiatives?.description}
                  </td> */}
                  <td className="whitespace-wrap">
                    <div className="text-sm px-8 py-4 text-gray-700 line-clamp-1">
                      {item?.description}
                    </div>
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    {item?.address}
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    {/* {item?.date} */}
                    {formatDate(item?.date)}

                  </td>
                  <td className="whitespace-wrap px-12 py-5 flex items-center justify-center gap-3">
                  <div className="text-sm text-gray-900 ">
                    <ToolTip content="edit">
                      <Link
                        to={`/admin/achievements/edit/${item._id}`}
                        className="text-red-500 hover:text-gray-700 bg-red-500 "
                      >
                        <Pencil size={16} />
                      </Link>
                      </ToolTip>
                      </div>
                    <div className="text-sm text-gray-700">
                      <ToolTip text="delete">
                        <span
                          onClick={() => openModal(item._id)}
                          className="flex items-center gap-2 rounded-full bg-orange-500 p-2 text-white"
                        >
                          <Trash2 className="h-4 w-4" aria-hidden="true" />
                        </span>
                      </ToolTip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={data.length}
            paginate={paginate}
            currentPage={currentPage}
          />
          <Modal isOpen={deleteItemId !== null} onClose={closeModal}>
            <button
              onClick={closeModal}
              className=" p-4 w-fit bg-indigo-600  text-white font-bold text-sm "
            >
              Cancel
            </button>
            <button
              onClick={() => handleDelete(deleteItemId)}
              className=" p-4 w-fit bg-orange-600 text-white font-bold text-sm "
            >
              Delete
            </button>
          </Modal>
          {/* {deleteItemId && (
            <Modal
              isOpen={!!deleteItemId}
              onClose={closeModal}
              onConfirm={handleDelete}
              title="Delete Confirmation"
              message="Are you sure you want to delete this item?"
            />
          )} */}
        </>
      )}
    </TableContainer>
  );
};

AchievementTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      images: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    })
  ).isRequired,
  refetchData: PropTypes.func.isRequired,
};

export default AchievementTable;

// /* eslint-disable react/prop-types */
// import { useState } from "react";
// import TableContainer from "./../../container/TableContainer";
// import ToolTip from "../tooltip/ToolTip";
// import { Pencil, Trash2 } from "lucide-react";
// import Modal from "../../models/Model";
// import { toast } from "react-toastify";
// import useDataDeletion from "../../../hooks/useDataDeletion/usedDataDeletion";
// import { Link } from "react-router-dom";
// import Pagination from "../pagination/Pagination";

// const AchievementTable = ({ data, refetchData }) => {
//   const apiUrl = `${import.meta.env.VITE_APP_API_URL}/achievements`;
//   const { deleteData } = useDataDeletion();
//   const [deleteItemId, setDeleteItemId] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const postsPerPage = 10; // Number of posts per page, adjust as needed

//   const openModal = (_id) => {
//     setDeleteItemId(_id);
//   };

//   const closeModal = () => {
//     setDeleteItemId(null);
//   };
//   const handleDelete = async () => {
//     deleteData(`${apiUrl}/${deleteItemId}`, () => {
//       toast.success("Data deleted successfully!");
//       refetchData();
//       closeModal();
//     });
//   };

//   // Get current posts
//   const indexOfLastPost = currentPage * postsPerPage;
//   const indexOfFirstPost = indexOfLastPost - postsPerPage;
//   const newData = data.slice(indexOfFirstPost, indexOfLastPost);

//   // Change page
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <TableContainer>
//       {data && (
//         <>
//           <table className="min-w-full divide-y divide-gray-200">
//             <thead className="bg-gray-50 capitalize font-bold ">
//               <tr>
//                 <th
//                   scope="col"
//                   className="px-4 py-3.5 text-left text-sm  text-gray-700"
//                 >
//                   <span>SN</span>
//                 </th>
//                 <th
//                   scope="col"
//                   className="px-4 py-3.5 text-left text-sm  text-gray-700"
//                 >
//                   Images{" "}
//                 </th>
//                 <th
//                   scope="col"
//                   className="px-4 py-3.5 text-left text-sm  text-gray-700"
//                 >
//                   Logo{" "}
//                 </th>
//                 <th
//                   scope="col"
//                   className="px-8 py-3.5 text-left text-sm  text-gray-700"
//                 >
//                   Title
//                 </th>

//                 <th
//                   scope="col"
//                   className="px-8 py-3.5 text-left text-sm  text-gray-700"
//                 >
//                   Description{" "}
//                 </th>
//                 <th
//                   scope="col"
//                   className="capitalize px-2 py-3 text-left text-sm font-bold text-gray-700"
//                 >
//                   <span>Date</span>
//                 </th>

//                 <th
//                   scope="col"
//                   className="px-12 py-3.5 text-left text-sm  text-gray-700"
//                 >
//                   Address{" "}
//                 </th>

//                 <th scope="col" className="relative px-0 py-3.5">
//                   <span className="">Actions</span>
//                 </th>
//               </tr>
//             </thead>
//             <tbody className="divide-y divide-gray-200 bg-white">
//               {newData?.map((achievements, index) => (
//                 <tr key={index}>
//                   <p>{achievements.id}</p>
//                   <td className="whitespace-wrap font-bold px-0 py-4 text-sm text-gray-700">
//                     <div className="text-sm text-gray-700">{index + 1}</div>
//                   </td>

//                   <td className="whitespace-wrap px-4 py-4">
//                     <div className="w-[10rem] text-sm font-medium text-black capitalize">
//                       <img
//                         className="w-full h-full object-contain rounded-lg"
//                         src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
//                           achievements?.images
//                         }`}
//                         alt="Images"
//                       />
//                     </div>
//                   </td>
//                   <td className="whitespace-wrap px-4 py-4">
//                     <div className="w-[10rem] text-sm font-medium text-black capitalize">
//                       <img
//                         className="w-full h-full object-contain rounded-lg"
//                         src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
//                           achievements?.logo
//                         }`}
//                         alt="Images"
//                       />
//                     </div>
//                   </td>
//                   <td className="whitespace-wrap px-4 py-4">
//                     <div className="text-sm font-medium text-black capitalize">
//                       {achievements?.title}
//                     </div>
//                   </td>
//                   <td className="whitespace-wrap px-4 py-4">
//                     <div className="text-sm font-medium text-black capitalize">
//                       {achievements?.description}
//                     </div>
//                   </td>
//                   <td className="whitespace-wrap px-4 py-4">
//                     <div className="text-sm font-medium text-black capitalize">
//                       {achievements?.date}
//                     </div>
//                   </td>
//                   <td className="whitespace-wrap px-4 py-4">
//                     <div className="text-sm font-medium text-black capitalize">
//                       {achievements?.address}
//                     </div>
//                   </td>

//                   <td className="whitespace-wrap px-12 py-5 flex items-center justify-center gap-3">
//                     <div className="text-sm text-gray-900 ">
//                       <ToolTip text="edit">
//                         <Link to={`/admin/achievements/edit/${achievements._id}`}>
//                           <span className="flex items-center gap-2 rounded-full bg-blue-500 p-2 text-white">
//                             <Pencil className="h-4 w-4  " aria-hidden="true" />
//                           </span>
//                         </Link>
//                       </ToolTip>
//                     </div>

//                     <div className="text-sm text-gray-700">
//                       <ToolTip text="delete">
//                         <span
//                           onClick={() => openModal(achievements._id)}
//                           className="flex items-center gap-2 rounded-full bg-orange-500 p-2 text-white"
//                         >
//                           <Trash2 className="h-4 w-4" aria-hidden="true" />
//                         </span>
//                       </ToolTip>
//                     </div>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <Pagination
//             className="my-10"
//             postsPerPage={postsPerPage}
//             totalPosts={data.length}
//             paginate={paginate}
//             currentPage={currentPage}
//           />
//         </>
//       )}
//       <Modal isOpen={deleteItemId !== null} onClose={closeModal}>
//         <button
//           onClick={closeModal}
//           className=" p-4 w-fit bg-indigo-600  text-white font-bold text-sm "
//         >
//           Cancel
//         </button>
//         <button
//           onClick={() => handleDelete(deleteItemId)}
//           className=" p-4 w-fit bg-orange-600 text-white font-bold text-sm "
//         >
//           Delete
//         </button>
//       </Modal>
//     </TableContainer>
//   );
// };

// export default AchievementTable;
