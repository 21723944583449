import Container from "../../components/container/Container";
import DataNotFound from "../errorPage/DataNotFound";
import AchievementTable from "../../components/forms/tables/AchievementTable";
import Loader from "../../components/loader/Loader";

import { useFetchData } from "../../hooks/useFetchData/useFetchData";

const Achievement = () => {
  const {
    data: achievementData,
    loading,
    error,
    refetchData,
  } = useFetchData({ endpoint: "achievements" });

  return (
    <Container
      breadcumbMenu="achievements"
      title="achievements"
      btnTitle="Add achievements"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <p>Error: {error}</p>
      ) : achievementData.length > 0 ? (
        <AchievementTable data={achievementData} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Achievement;
