/* eslint-disable no-unused-vars */
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import { useParams } from "react-router-dom";
import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";
import DataFetchingError from "../../errorPage/DataFetchingError";
import Loader from "../../../components/loader/Loader";
import ProductForm from "../../../components/forms/postForms/productForm/ProductForm";
import SponsorForm from "../../../components/forms/postForms/sponsorFrom/SponsorForm";

const EditSponsor = () => {
  const { id } = useParams();

  const { dataDetails, loading, error, refetchData } = useFetchDataDetails(
    "sponsors",
    id
  );

  return (
    <Container
      breadcumbMenu='sponsors'
      breadcumbSubMenu='edit sponsors'
      title='edit sponsors'
      btnTitle='back to sponsors'
      path='/admin/sponsors'
    >
      <FormContainer heading='edit sponsors'>
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <SponsorForm defaultData={dataDetails} refetchData={refetchData} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditSponsor;
