
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import CustomInputField from "../../customInputField/CustomInputField";
import CustomButton from "../../button/CustomButton";
import CustomLink from "../../customLink/CustomLink";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useAddData } from "../../../../hooks/useAddData/useAddData";
import { useEditData } from "../../../../hooks/useEdit/useEdit";
import { zodResolver } from "@hookform/resolvers/zod";
import { InvolvementSchema } from "../../../../zodSchema/projectSchema/projectSchema";

const ProjectForm = ({ defaultData }) => {
  const dateString = defaultData?.startingDate ? defaultData?.startingDate : "";
  const [startingDate] = dateString.split("T");

  const dateStringEnd = defaultData?.endDate ? defaultData?.endDate : "";
  const [endDate] = dateStringEnd.split("T");
  // yourPost
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: defaultData?.title || "",
      position: defaultData?.position || "",
      organization: defaultData?.organization || "",
      content: defaultData?.content || "",
      location: defaultData?.location || "",
      yourPost: defaultData?.yourPost || "",
      keyResponsibility: defaultData?.keyResponsibility || "",
      category: defaultData?.category || "",
      startingDate: startingDate || "",
      endDate: endDate || "",
      logo: defaultData?.logo || "",
      image: defaultData?.image || "",
      subImages: defaultData?.subImages || "",
    },
    resolver: zodResolver(InvolvementSchema),
  });

  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const { addData, isLoading: isAdding, error: addError } = useAddData();
  // eslint-disable-next-line no-unused-vars
  const { editData, isLoading: isEditing, error: editError } = useEditData();

  // const { addData, isLoading: isAdding, error: addError } = useAddData();
  // const { editData, isLoading: isEditing, error: editError } = useEditData();

  const categoryData = [
    { id: "1", category: "communityDevelopment" },
    { id: "2", category: "languageLogstic" },
    { id: "3", category: "teaching" },
  ];

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("position", data.position);
    formData.append("organization", data.organization);
    formData.append("keyResponsibility", data.keyResponsibility);
    formData.append("content", data.content);
    formData.append("location", data.location);
    formData.append("yourPost", data.yourPost);
    formData.append("category", data.category);
    formData.append("logo", data.logo[0]);
    formData.append("image", data.image[0]);
    formData.append("subImages", data.subImages[0]);
    formData.append("startingDate", data.startingDate);
    formData.append("endDate", data.endDate);

    try {
      if (defaultData) {
        await editData(formData, "involvements", defaultData._id);
        toast.success("Data updated successfully!");
      } else {
        await addData(formData, "involvements");
        toast.success("Data added successfully!");
      }
      navigate("/admin/involvements");
    } catch (error) {
      toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomInputField
        label="Title:"
        type="text"
        name="title"
        error={errors?.title?.message}
        {...register("title")}
      />
      <div className="grid grid-cols-3 gap-2 ">
        <CustomInputField
          label="Position:"
          type="text"
          name="position"
          error={errors?.position?.message}
          {...register("position")}
        />
        <CustomInputField
          label="Organization:"
          type="text"
          name="organization"
          error={errors?.organization?.message}
          {...register("organization")}
        />
        <CustomInputField
          label="Key Responsibility:"
          type="text"
          name="keyResponsibility"
          error={errors?.keyResponsibility?.message}
          {...register("keyResponsibility")}
        />
      </div>
      <div className="grid grid-cols-3 gap-2 ">
        <div className="w-full h-full">
          <label htmlFor="category" className="capitalize text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
            Category Name:
          </label>
          <select
            id="category"
            name="category"
            {...register("category")}
            className={`mt-2 capitalize transition duration-300 ease flex w-full border-2 border-slate-700/40 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:border-blue-500`}
          >
            <option value="">Select category</option>
            {categoryData?.map((item, index) => (
              <option key={index} value={item?.category}>
                {item?.category}
              </option>
            ))}
          </select>
        </div>
        <CustomInputField
          label="Start Date:"
          type="date"
          name="startingDate"
          error={errors?.startingDate?.message}
          {...register("startingDate")}
        />
        <CustomInputField
          label="End Date:"
          type="date"
          name="endDate"
          error={errors?.endDate?.message}
          {...register("endDate")}
        />
      </div>
      <CustomInputField
        label="Content:"
        type="textarea"
        name="content"
        error={errors?.content?.message}
        {...register("content")}
      />
      <div className="flex items-center gap-10">
      <CustomInputField
        label="Location:"
        type="text"
        name="location"
        error={errors?.location?.message}
        {...register("location")}
      />
      <CustomInputField
        label="Your Post:"
        type="text"
        name="yourPost"
        error={errors?.yourPost?.message}
        {...register("yourPost")}
      />
      </div>
      

      <div className="flex items-center gap-10">
        <CustomInputField
          label="Logo:"
          type="file"
          name="logo"
          error={errors?.logo?.message}
          control={control}
          onChange={(e) => setValue("logo", e.target.files)}
        />
        <CustomInputField
          label="Image:"
          type="file"
          name="image"
          error={errors?.image?.message}
          control={control}
          onChange={(e) => setValue("image", e.target.files)}
        />
        <CustomInputField
          label="Sub Images:"
          type="file"
          name="subImages"
          error={errors?.subImages?.message}
          control={control}
          onChange={(e) => setValue("subImages", e.target.files)}
        />
      </div>
      <div className="grid grid-cols-3 gap-5 items-center">
        {defaultData?.logo && (
          <div className="w-40 mb-5">
            <img
              className="w-full h-full object-cover"
              src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${defaultData?.logo}`}
              alt="Logo"
            />
          </div>
        )}
        {defaultData?.image && (
          <div className="w-fit mb-5">
            <Link
              to={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${defaultData?.image}`}
              target="image"
            >
              <div className="bg-yellow-600 text-xl text-center text-white p-2">
                Images
              </div>
            </Link>
          </div>
        )}
        {defaultData?.subImages && (
          <div className="w-fit mb-5">
            <Link
              to={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${defaultData?.subImages}`}
              target="image"
            >
              <div className="bg-green-700 text-xl text-center text-white p-2">
                Sub Images
              </div>
            </Link>
          </div>
        )}
      </div>
      
      <div className="grid grid-cols-2  gap-5 mt-5">
         <CustomLink
          path="/admin/involvements"
          btnTitle="Back"
          className="w-full text-center "
        />
        <CustomButton type="submit" disabled={isAdding || isEditing}>
          {isAdding || isEditing ? "Processing..." : "Submit"}
        </CustomButton>
      </div>
      {(addError || editError) && (
        <p className="text-red-500 mt-2">{addError || editError}</p>
      )}

      {/* <div className="flex items-center gap-10 justify-center">
        <CustomButton type="submit" text={`${defaultData ? "Update" : "Add"} Involvement`} />
        <CustomLink
          path="/admin/involvements"
          text="Cancel"
          className="bg-red-900 px-6 py-3 text-lg text-white rounded"
        />
      </div> */}
    </form>
  );
};

ProjectForm.propTypes = {
  defaultData: PropTypes.object,
};

export default ProjectForm;


















// /* eslint-disable no-unused-vars */
// import PropTypes from "prop-types";
// import { useForm } from "react-hook-form";
// import CustomInputField from "../../customInputField/CustomInputField";
// import CustomButton from "../../button/CustomButton";
// import CustomLink from "../../customLink/CustomLink";
// import SelectField from "../../selectField/SelectField";
// import { toast } from "react-toastify";
// import { Link, useNavigate } from "react-router-dom";
// import { useAddData } from "../../../../hooks/useAddData/useAddData";
// import { useEditData } from "../../../../hooks/useEdit/useEdit";
// import { ProductSchema } from "../../../../zodSchema/productSchema/ProductSchema";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { InvolvementSchema } from "../../../../zodSchema/projectSchema/projectSchema";

// // title
// // position
// // organization
// // content
// // keyResponsibility
// // category
// // image
// // subImages
// // logo
// // startingDate
// // endDate

// const ProjectForm = ({ defaultData }) => {
//   const dateString = defaultData?.startingDate ? defaultData?.startingDate : "";
//   const [startingDate] = dateString.split("T");

//   const dateStringEnd = defaultData?.endDate ? defaultData?.endDate : "";
//   const [endDate] = dateStringEnd.split("T");

//   const {
//     register,
//     handleSubmit,
//     control,
//     setValue,
//     formState: { errors },
//   } = useForm({
//     defaultValues: {
//       title: defaultData?.title || "",
//       position: defaultData?.position || "",
//       organization: defaultData?.organization || "",
//       content: defaultData?.content || "",
//       keyResponsibility: defaultData?.keyResponsibility || "",
//       category: defaultData?.category || "",
//       startingDate: startingDate || "",
//       endDate: endDate || "",
//       logo: defaultData?.logo || "",
//       image: defaultData?.image || "",
//       subImages: defaultData?.subImages || "",
      
//     },
//     resolver: zodResolver(InvolvementSchema),
//   });

//   const navigate = useNavigate();

//   const { addData, isLoading: isAdding, error: addError } = useAddData();
//   const { editData, isLoading: isEditing, error: editError } = useEditData();

//   const category = ["upcoming", "ongoing", "completed"];
//   // const status = ["upcoming", "ongoing", "completed"];
//   const categoryData = [
//     {
//       id: "1",
//       category: "communityDevelopment",
//     },
//     {
//       id: "2",
//       category: "languageLogstic",
//     },
//     {
//       id: "3",
//       category: "teaching",
//     },
//   ];
//   const onSubmit = async (data) => {
//     console.log(data);

//     const formData = new FormData();

//     console.log(data, ": this is form data");

//     formData.append("title", data?.title);
//     formData.append("position", data?.position);
//     formData.append("organization", data?.organization);
//     formData.append("keyResponsibility", data?.keyResponsibility);
//     formData.append("content", data?.content);
//     formData.append("category", data?.category);
//     formData.append("logo", data?.logo[0]);
//     formData.append("image", data?.image[0]);
//     formData.append("subImages", data?.subImages[0]);
//     formData.append("startingDate", data?.startingDate);
//     formData.append("endDate", data?.endDate);

//     try {
//       if (defaultData) {
//         // Editing existing data
//         await editData(formData, "involvements", defaultData._id);
//         toast.success("Data updated successfully!");
//       } else {
//         // Adding new data
//         await addData(formData, "involvements");
//         toast.success("Data added successfully!");
//       }
//       navigate("/admin/involvements");
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
//     }
//   };
// // title
// // position
// // organization
// // content
// // keyResponsibility
// // category
// // image imagefile
// // subImages imagefile
// // logo imagefile
// // startingDate
// // endDate

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <CustomInputField
//         label="title:"
//         type="text"
//         name="title"
//         error={errors?.title?.message}
//         {...register("title")}
//       />
//       <div className="grid grid-cols-3 gap-2 ">
//       <CustomInputField
//         label="position:"
//         type="text"
//         name="position"
//         error={errors?.position?.message}
//         {...register("position")}
//         />
//         <CustomInputField
//         label="organization:"
//         type="text"
//         name="organization"
//         error={errors?.organization?.message}
//         {...register("organization")}
//         />
//         <CustomInputField
//         label="keyResponsibility:"
//         type="text"
//         name="keyResponsibility"
//         error={errors?.keyResponsibility?.message}
//         {...register("keyResponsibility")}
//         />
//       </div>
//       <div className="grid grid-cols-3 gap-2 ">
//         {/* <SelectField
//           options={category}
//           {...register("category")}
//           error={errors?.category?.message}
//           label="category:"
//         /> */}
//         <div className="w-full h-full">
//           <label
//             htmlFor="category"
//             className="capitalize text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
//           >
//             Category Name:
//           </label>
//           <select
//             id="category"
//             name="category"
//             {...register("category")}
//             className={`mt-2 capitalize transition duration-300 ease flex w-full border-2 border-slate-700/40 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:border-blue-500`}
//           >
//             <option value="">select category</option>
//             {categoryData?.map((item, index) => (
//               <option key={index} value={item?.category}>
//                 {item?.category}
//               </option>
//             ))}
//           </select>
//         </div>
//         <CustomInputField
//           label="start Date:"
//           type="date"
//           name="startingDate"
//           error={errors?.startingDate?.message}
//           {...register("startingDate")}
//         />
//         <CustomInputField
//           label="end Date:"
//           type="date"
//           name="endDate"
//           error={errors?.endDate?.message}
//           {...register("endDate")}
//         />
//       </div>
//       <CustomInputField
//         label="content:"
//         type="text-area"
//         name="content"
//         error={errors?.content?.message}
//         {...register("content")}
//       />

//       <div className="flex items-center gap-10">
//         <CustomInputField
//           label=" Logo:"
//           type="file"
//           name="logo"
//           error={errors?.logo?.message}
//           control={control}
//           onChange={(e) => setValue("logo", e.target.files)}
//         />
//         <CustomInputField
//           label=" Image:"
//           type="file"
//           name="image"
//           error={errors?.image?.message}
//           control={control}
//           onChange={(e) => setValue("image", e.target.files)}
//         />
//         <CustomInputField
//           label="subImages:"
//           type="file"
//           name="subImages"
//           error={errors?.subImages?.message}
//           control={control}
//           onChange={(e) => setValue("subImages", e.target.files)}
//         />
//       </div>
//       <div className="grid grid-cols-3 gap-5 items-center">
//         {" "}
//         {defaultData?.logo && (
//           <div className="w-40 mb-5">
//             <img
//               className="w-full h-full object-cover"
//               src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
//                 defaultData?.logo
//               }`}
//               alt="Logo Images"
//             />
//           </div>
//         )}
//         {defaultData?.image && (
//           <div className="w-fit mb-5">
//             <Link
//               to={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
//                 defaultData?.image
//               }`}
//               target="Images"
//             >
//               <div className="bg-yellow-600  text-xl text-center  text-white p-2 ">
//                 Images
//               </div>
//             </Link>
//           </div>
//         )}
//         {defaultData?.subImages && (
//           <div className="w-fit mb-5">
//             <Link
//               to={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
//                 defaultData?.subImages
//               }`}
//               target="subImages"
//             >
//               <div className="bg-yellow-600  text-xl text-center  text-white p-2 ">
//               subImages
//               </div>
//             </Link>
//           </div>
//         )}
//       </div>
//       <div className="grid grid-cols-2  gap-5 mt-5">
//         <CustomLink
//           path="/admin/involvements"
//           btnTitle="Back"
//           className="w-full text-center "
//         />
//         <CustomButton type="submit" disabled={isAdding || isEditing}>
//           {isAdding || isEditing ? "Processing..." : "Submit"}
//         </CustomButton>
//       </div>
//       {(addError || editError) && (
//         <p className="text-red-500 mt-2">{addError || editError}</p>
//       )}
//     </form>
//   );
// };
// // title
// // position
// // organization
// // content
// // keyResponsibility
// // category
// // image imagefile
// // subImages imagefile
// // logo imagefile
// // startingDate
// // endDate
// ProjectForm.propTypes = {
//   defaultData: PropTypes.shape({
//     title: PropTypes.string,
//     position: PropTypes.string,
//     organization: PropTypes.string,
//     content: PropTypes.string,
//     keyResponsibility: PropTypes.string,
//     category: PropTypes.string,
//     status: PropTypes.string,
//     logo: PropTypes.string,
//     image: PropTypes.string,
//     subImages: PropTypes.string,
//     startingDate: PropTypes.number,
//     endDate: PropTypes.string,
//     _id: PropTypes.string,
//   }),
// };

// export default ProjectForm;
