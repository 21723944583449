import Container from '../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
import { useParams } from 'react-router-dom';
import useFetchDataDetails from '../../../hooks/useFetchDataDetail/useFetchDataDetails';
import Loader from '../../../components/loader/Loader';
import DataFetchingError from '../../errorPage/DataFetchingError';
import ArticleCategoryForm from './../../../components/forms/postForms/articleCategoryForm/ArticleCategoryForm';

const EditArticleCategory = () => {
  const { id } = useParams();
  //   custom hook for fetching data detaails from api with 2 parameters (endpoint and id)
  const { dataDetails, loading, error } = useFetchDataDetails(
    'article/categories',
    id
  );

  return (
    <Container
      breadcumbMenu="Article category"
      breadcumbSubMenu="edit Article category"
      title="Edit Article category"
      btnTitle="back to Article category"
      path="/admin/article/categories"
    >
      <FormContainer heading="edit Article category">
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <ArticleCategoryForm defaultData={dataDetails} isEdit="true " />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditArticleCategory;
