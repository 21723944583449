import axios from "axios";
import { useState } from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io";
import { Link } from "react-router-dom";
import { ChevronsRight } from "lucide-react";
import { ClipLoader } from "react-spinners";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactPage = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendMail = async (e) => {
    e.preventDefault();
    setLoading(true);
    const backendUrl = import.meta.env.VITE_APP_BASE_URL;
    try {
      const { data } = await axios.post(
        `${backendUrl}/send/mail`,
        {
          name,
          email,
          number,
          subject,
          message,
        },
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      );
      setName("");
      setEmail("");
      setNumber("");
      setSubject("");
      setMessage("");
      toast.success(data.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  return (
    <>
      <div className="h-[60vh] md:h-[70vh] relative">
        <div className="h-[60vh] md:h-[70vh] overflow-hidden">
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <clipPath id="clipPath">
              <path
                d={
                  !isSmallScreen
                    ? "M0,120 C150,180 300,80 500,120 L500,0 L0,0 Z"
                    : "M0,120 C200,140 180,80 500,120 L500,0 L0,0 Z"
                }
                style={{ stroke: "none", fill: "#e1efe3" }}
              ></path>
            </clipPath>
            <image
              xlinkHref="/images/ContactBg.png"
              clipPath="url(#clipPath)"
              style={{ objectPosition: "center" }}
              preserveAspectRatio="xMinYMin slice"
              width={"100%"}
              height={"100%"}
            />
          </svg>
        </div>
        <div className="absolute top-[30%] mx-auto w-full text-white">
          <h1
            className="text-4xl font-bold w-full my-auto mx-auto text-center"
            style={{ lineHeight: "42px" }}
          >
            Contact With Us
          </h1>
        </div>
      </div>
      <div className="p-5">
        <div className="md:w-[100%] lg:w-[80%] mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10 py-5">
          <div className="md:w-full bg-[#551618] p-5 md:p-10 rounded-xl">
            <div className="w-full md:h-[300px] lg:h-[429px] md:mx-auto mb-7">
              <img
                className="w-full h-full object-cover rounded-md"
                src="/images/contact-image.png"
                alt="contact"
              />
            </div>
            <div className="flex items-center gap-4 text-white">
              <Link to="https://www.facebook.com/AmritYonjan">
                <FaFacebookSquare size={20} />
              </Link>
              <Link to="https://www.linkedin.com/company/amrit-yonjan">
                <IoLogoLinkedin size={20} />
              </Link>
            </div>
            <div className="text-white mt-4">
              <p className="text-xs md:text-lg mb-2">You can mail me at</p>
              <h1 className="text-lg md:text-2xl lg:3xl border-b-2 py-2 font-bold">
                mail@amrityonjan.com
              </h1>
            </div>
          </div>
          <div className="p-5 md:p-8 bg-white border-2 rounded-xl">
            <h1 className="capitalize my-5 font-bold text-2xl md:text-3xl text-primary-headingColor">
              for any enquiries
            </h1>
            <div className="mt-6 md:mt-10">
              <form onSubmit={sendMail} className="space-y-4">
                <div>
                  <div>Your Name</div>
                  <input
                    className="transition duration-300 ease flex w-full border-b border-slate-700/40 bg-transparent px-3 py-1 text-sm placeholder:text-gray-600 focus:border-blue-500 focus:outline-none"
                    placeholder="Your Name"
                    name="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <div>Email Address</div>
                  <input
                    className="transition duration-300 ease flex w-full border-b border-slate-700/40 bg-transparent px-3 py-1 text-sm placeholder:text-gray-600 focus:border-blue-500 focus:outline-none"
                    placeholder="Email Address"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <div>Phone Number</div>
                  <input
                    className="transition duration-300 ease flex w-full border-b border-slate-700/40 bg-transparent px-3 py-1 text-sm placeholder:text-gray-600 focus:border-blue-500 focus:outline-none"
                    placeholder="Phone number"
                    name="phoneNumber"
                    type="tel"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                <div>
                  <div>Subject</div>
                  <input
                    className="transition duration-300 ease flex w-full border-b border-slate-700/40 bg-transparent px-3 py-1 text-sm placeholder:text-gray-600 focus:border-blue-500 focus:outline-none"
                    placeholder="subject"
                    name="subject"
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="message" className="py-5">
                    Your Message
                  </label>
                  <textarea
                    className="input-field textarea transition duration-300 ease w-full border rounded-xl border-slate-700/40 bg-transparent px-5 py-10 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:border-blue-500"
                    id="message"
                    placeholder="Your Message"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <button
                  className="flex items-center justify-center px-4 py-2 text-sm md:text-md font-semibold transition duration-300 bg-yellow-600 text-white rounded-3xl hover:bg-yellow-700"
                  type="submit"
                  disabled={loading}
                >
                  {loading && <ClipLoader size={20} color="white" />}
                  Send Message
                  <span>
                    <ChevronsRight className="w-4 h-4 md:w-5 md:h-5 ml-2" />
                  </span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
