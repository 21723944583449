import { useState } from "react";
import { useParams } from "react-router-dom";
import { ArrowLeft, ArrowRight } from "lucide-react";
import AliceCarousel from "react-alice-carousel";
import { useMediaQuery } from "react-responsive";
import useFetchDataDetails from "../hooks/useFetchDataDetail/useFetchDataDetails";
import { formatDate } from "../utility/dateFormates";
import { useFetchData } from "../hooks/useFetchData/useFetchData";

const InvolvementDetailPage = () => {
  const { id } = useParams();
  const { dataDetails: involvementData, loading } = useFetchDataDetails("involvements", id ?? "");
  const { data: involveAchieveData } = useFetchData({ endpoint: "involvement-achievements" });

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  const [expandedItems, setExpandedItems] = useState(Array(involvementData.length).fill(false));

  if (!id) {
    return <div>Invalid ID</div>;
  }

  const toggleReadMore = (index) => {
    const newExpandedItems = [...expandedItems];
    newExpandedItems[index] = !newExpandedItems[index];
    setExpandedItems(newExpandedItems);
  };

  const responsive = {
    0: { items: 1 },
    800: { items: 3 },
    1024: { items: 3 },
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <div className="h-[60vh] md:h-[70vh] overflow-hidden w-full">
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: "100%", width: "100%" }}>
          <clipPath id="clipPath-">
            <path
              d={
                !isSmallScreen
                  ? "M0,120 C150,180 300,80 500,120 L500,0 L0,0 Z"
                  : "M0,120 C200,140 180,80 500,120 L500,0 L0,0 Z"
              }
              style={{ stroke: "none", fill: "#e1efe3" }}
            ></path>
          </clipPath>
          <image
            xlinkHref={"/images/InvolvementDetails.png"}
            clipPath="url(#clipPath-)"
            style={{ objectPosition: "center" }}
            preserveAspectRatio="xMinYMin slice"
            width="100%"
            height="100%"
          />
        </svg>
      </div>
      <div className="px-5 lg:w-[90%] mx-auto my-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div className="px-10">
            <div className="w-full lg:w-[60%] mx-auto mb-7">
              <div className="w-[8rem] mx-auto ">
                {involvementData?.image ? (
                  <img
                    className="w-full"
                    src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${involvementData?.image}`}
                    alt=""
                  />
                ) : (
                  <img src="/logo/default-img.jpg" alt="" />
                )}
              </div>
              <h1 className="text-center font-bold text-xl mt-5 text-[#8c3d42] capitalize">
                {involvementData?.position}
              </h1>
              <p className="text-base text-center capitalize font-semibold mt-3 ">
                <span className="mr-2">{formatDate(involvementData?.startingDate ?? "")}</span> -
                <span className="ms-2">{formatDate(involvementData?.endDate ?? "")}</span>
              </p>
            </div>
            <h1 className="font-bold capitalize text-lg mb-5">{involvementData?.title}</h1>
            <p className="text-base text-justify">{involvementData?.content}</p>
          </div>

          <div className="w-[90%] mx-auto">
            <div className="w-[100%]">
              {involvementData?.image ? (
                <img
                  src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${involvementData?.image}`}
                  className="w-full h-full"
                  alt="Img"
                />
              ) : (
                <img className="w-full h-full" src="/logo/default-img.jpg" alt="" />
              )}
            </div>

            <div className="grid grid-cols-3 mt-5 gap-5">
              {involvementData?.subImages &&
                involvementData?.subImages.map((item, index) => (
                  <div className="w-full h-[9rem]" key={index}>
                    <img
                      className="w-full h-full object-contain"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item}`}
                      alt=""
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="w-[90%] mx-auto mt-20">
          {/* <Heading heading="key Responsibility" /> */}
          <h1 className="font-bold mb-5 text-2xl text-center text-[#8c3d42]">Key Responsibilities</h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-10">
            <div className="w-full">
              <p>{involvementData?.keyResponsibility}</p>
            </div>
          </div>
        </div>

        <div className="w-[90%] mx-auto mt-20">
          <h1 className="font-bold mb-5 text-2xl text-center text-[#853036]">Achievements</h1>

          <div>
            <AliceCarousel
              disableDotsControls
              responsive={responsive}
              mouseTracking
              renderPrevButton={() => (
                <div className="flex items-center justify-end">
                  <ArrowLeft className="text-primary-btnColor cursor-pointer" />
                </div>
              )}
              renderNextButton={() => (
                <div className="flex items-center justify-start">
                  <ArrowRight className="text-primary-btnColor cursor-pointer" />
                </div>
              )}
            >
              {involveAchieveData
                .filter((item) => item.involvement === involvementData?._id)
                .map((item, index) => (
                  <div className="border-2 p-7 mt-10 mx-3" key={index}>
                    <div className="flex flex-col items-center">
                      <div className="w-[10rem] h-[8rem]">
                        <img
                          className="w-full h-full object-contain"
                          src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item?.image}`}
                          alt=""
                        />
                      </div>
                      <h1 className="text-xl mt-3 capitalize font-semibold text-[#853036] line-clamp-2">
                        {item?.title}
                      </h1>
                    </div>

                    <p className={`mt-7 text-justify font-normal ${expandedItems[index] ? "block" : "line-clamp-6"}`}>
                      {item?.content}
                    </p>
                    {!expandedItems[index] ? (
                      <button className="mt-3 capitalize text-[#f38908]" onClick={() => toggleReadMore(index)}>
                        Read more
                      </button>
                    ) : (
                      <button className="mt-3 capitalize text-[#f38908]" onClick={() => toggleReadMore(index)}>
                        Close
                      </button>
                    )}
                  </div>
                ))}
            </AliceCarousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvolvementDetailPage;
