import PropTypes from "prop-types";
import CustomInputField from "../../customInputField/CustomInputField";
import CustomButton from "../../button/CustomButton";
import CustomLink from "../../customLink/CustomLink";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAddData } from "../../../../hooks/useAddData/useAddData";
import { useEditData } from "../../../../hooks/useEdit/useEdit";
import { zodResolver } from "@hookform/resolvers/zod";
import { achievmentsSchema } from "../../../../zodSchema/homeSchema/homeSchema";

const AchievementForm = ({ defaultData }) => {
  const dateString = defaultData?.date ? defaultData?.date : "";
  const [datePart] = dateString.split("T");

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: defaultData?.title || "",
      description: defaultData?.description || "",
      address: defaultData?.address || "",
      images: "", // initialize as empty string for file input
      // images: defaultData?.images, // initialize as empty string for file input
      logo: "", // initialize as empty string for file input
      date: datePart || "",
    },
    resolver: zodResolver(achievmentsSchema),
  });

  const navigate = useNavigate();
  const { addData, isLoading: isAdding, error: addError } = useAddData();
  const { editData, isLoading: isEditing, error: editError } = useEditData();

  const onSubmit = async (data) => {
    const formData = new FormData();
    // console.log(data, "this is image file");

    formData.append("images", data.images[0]);
        formData.append("logo", data.logo[0]);
        formData.append("title", data.title);
        formData.append("description", data.description);
        formData.append("address", data.address);
        formData.append("date", data.date);

    const header = {
      "Content-Type": "multipart/form-data", // Add the Content-Type header
    };

    try {
      if (defaultData) {
        // Editing existing data
        await editData(formData, "achievements", defaultData._id);
        toast.success("Data updated successfully!");
      } else {
        // Adding new data
        await addData(formData, "achievements", header);
        toast.success("Data added successfully!");
      }
      navigate("/admin/achievements");
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
    }
  };
  // const onSubmit = async (data) => {
  //   try {
  //     if (data.images && data.images.length > 0 && data.logo && data.logo.length > 0) {
  //       const formData = new FormData();
  //       formData.append("images", data.images[0]);
  //       formData.append("logo", data.logo[0]);
  //       formData.append("title", data.title);
  //       formData.append("description", data.description);
  //       formData.append("address", data.address);
  //       formData.append("date", data.date);

  //       const header = {
  //         "Content-Type": "multipart/form-data",
  //       };

  //       if (defaultData) {
  //         await editData(formData, "achievements", defaultData._id);
  //         toast.success("Data updated successfully!");
  //       } else {
  //         await addData(formData, "achievements", header);
  //         toast.success("Data added successfully!");
  //       }
  //       navigate("/admin/achievements");
  //     } else {
  //       console.error("Images or logo field is empty");
  //       toast.error("Images or logo field is empty");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
  //   }
  // };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomInputField
        label="Title:"
        type="text"
        name="title"
        {...register("title")}
        error={errors?.title?.message}
      />
      <CustomInputField
        label="Description:"
        type="text"
        name="description"
        {...register("description")}
        error={errors?.description?.message}
      />
      <CustomInputField
        label="Address:"
        type="text"
        name="address"
        {...register("address")}
        error={errors?.address?.message}
      />
      <CustomInputField
        label="Date:"
        type="date"
        name="date"
        error={errors?.date?.message}
        {...register("date")}
      />
      <CustomInputField
        label="Logo:"
        type="file"
        name="logo"
        control={control}
        onChange={(e) => setValue("logo", e.target.files)}
        error={errors?.logo?.message}
      />
      <CustomInputField
        label="Image:"
        type="file"
        name="images"
        control={control}
        onChange={(e) => setValue("images", e.target.files)}
        error={errors?.images?.message}
      />
      
      {defaultData?.images && (
        <div className="w-40 mb-5">
          <img
            className="w-full h-full object-cover"
            src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${defaultData?.images}`}
            alt="Images"
          />
        </div>
      )}
      {defaultData?.logo && (
        <div className="w-40 mb-5">
          <img
            className="w-full h-full object-cover"
            src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${defaultData?.logo}`}
            alt="Logo"
          />
        </div>
      )}
      <div className="grid grid-cols-2 gap-5 mt-5">
        <CustomLink
          path="/admin/achievements"
          btnTitle="Back"
          className="w-full text-center "
        />
        <CustomButton type="submit" disabled={isAdding || isEditing}>
          {isAdding || isEditing ? "Processing..." : "Submit"}
        </CustomButton>
      </div>
      {(addError || editError) && (
        <p className="text-red-500 mt-2">{addError || editError}</p>
      )}
    </form>
  );
};

AchievementForm.propTypes = {
  defaultData: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string,
    date: PropTypes.string,
    images: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(File),
    ]),
    logo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(File),
    ]),
  }),
};

export default AchievementForm;














// import PropTypes from "prop-types";
// import CustomInputField from "../../customInputField/CustomInputField";
// import CustomButton from "../../button/CustomButton";
// import CustomLink from "../../customLink/CustomLink";
// import { useForm } from "react-hook-form";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { useAddData } from "../../../../hooks/useAddData/useAddData";
// import { useEditData } from "../../../../hooks/useEdit/useEdit";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { achievmentsSchema } from "../../../../zodSchema/homeSchema/homeSchema";

// const AchievementForm = ({ defaultData }) => {
//   const dateString = defaultData?.date ? defaultData?.date : "";
//   const [datePart] = dateString.split("T");

//   // Initializing the form
//   const {
//     register,
//     handleSubmit,
//     control,
//     setValue,
//     formState: { errors },
//   } = useForm({
//     defaultValues: {
//       title: defaultData?.title || "",
//       description: defaultData?.description || "",
//       address: defaultData?.address || "",
//       images: defaultData?.images, // initialize as empty string for file input
//       logo: "", // initialize as empty string for file input
//       date: datePart || "",
//     },
//     resolver: zodResolver(achievmentsSchema),
//   });

//   const navigate = useNavigate();

//   const { addData, isLoading: isAdding, error: addError } = useAddData();
//   const { editData, isLoading: isEditing, error: editError } = useEditData();

//   const onSubmit = async (data) => {
//     try {
//       // Ensure images and logo fields are present and not empty
//       if (data.images && data.images.length > 0 && data.logo && data.logo.length > 0) {
//         const formData = new FormData();
//         formData.append("images", data.images[0]);
//         formData.append("logo", data.logo[0]);
//         formData.append("title", data.title);
//         formData.append("description", data.description);
//         formData.append("address", data.address);
//         formData.append("date", data.date);

//         const header = {
//           "Content-Type": "multipart/form-data",
//         };

//         if (defaultData) {
//           await editData(formData, "achievements", defaultData._id);
//           toast.success("Data updated successfully!");
//         } else {
//           await addData(formData, "achievements", header);
//           toast.success("Data added successfully!");
//         }
//         navigate("/admin/achievements");
//       } else {
//         // Handle case where images or logo fields are empty
//         console.error("Images or logo field is empty");
//         toast.error("Images or logo field is empty");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <CustomInputField
//         label="Title:"
//         type="text"
//         name="title"
//         {...register("title")}
//         error={errors?.title?.message}
//       />
//       <CustomInputField
//         label="Description:"
//         type="text"
//         name="description"
//         {...register("description")}
//         error={errors?.description?.message}
//       />
//       <CustomInputField
//         label="Address:"
//         type="text"
//         name="address"
//         {...register("address")}
//         error={errors?.address?.message}
//       />
//       <CustomInputField
//         label="Date:"
//         type="date"
//         name="date"
//         error={errors?.date?.message}
//         {...register("date")}
//       />

//       <CustomInputField
//         label="Image:"
//         type="file"
//         name="images"
//         control={control}
//         onChange={(e) => setValue("images", e.target.files)}
//         error={errors?.images?.message}
//       />
//       <CustomInputField
//         label="Logo:"
//         type="file"
//         name="logo"
//         control={control}
//         onChange={(e) => setValue("logo", e.target.files)}
//         error={errors?.logo?.message}
//       />
//       {defaultData?.images && (
//         <div className="w-40 mb-5">
//           <img
//             className="w-full h-full object-cover"
//             src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/images/${defaultData?.images}`}
//             alt="Images"
//           />
//         </div>
//       )}
//       {defaultData?.logo && (
//         <div className="w-40 mb-5">
//           <img
//             className="w-full h-full object-cover"
//             src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/images/${defaultData?.logo}`}
//             alt="Logo"
//           />
//         </div>
//       )}

//       <div className="grid grid-cols-2 gap-5 mt-5">
//         <CustomLink
//           path="/admin/achievements"
//           btnTitle="Back"
//           className="w-full text-center "
//         />
//         <CustomButton type="submit" disabled={isAdding || isEditing}>
//           {isAdding || isEditing ? "Processing..." : "Submit"}
//         </CustomButton>
//       </div>
//       {(addError || editError) && (
//         <p className="text-red-500 mt-2">{addError || editError}</p>
//       )}
//     </form>
//   );
// };

// AchievementForm.propTypes = {
//   defaultData: PropTypes.shape({
//     _id: PropTypes.string,
//     title: PropTypes.string,
//     description: PropTypes.string,
//     address: PropTypes.string,
//     date: PropTypes.string, // Changed from instanceOf(Date) to string
//     images: PropTypes.oneOfType([
//       PropTypes.string,
//       PropTypes.instanceOf(File),
//     ]),
//     logo: PropTypes.oneOfType([
//       PropTypes.string,
//       PropTypes.instanceOf(File),
//     ]),
//   }),
// };

// export default AchievementForm;
