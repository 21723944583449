// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
// import ReadmoreBtn from "../common/ReadmoreBtn";
// import { useMediaQuery } from "react-responsive";
// import PropTypes from 'prop-types';
// // import "./custom-alice-carousel.css";

// const ProjectInitiatives = ({ initiativesData }) => {
//   const [selectedIndex, setSelectedIndex] = useState(0);

//   const onProjectHover = (index) => {
//     setSelectedIndex(index);
//   };

//   const navigate = useNavigate();

//   const isSmallScreen = useMediaQuery({ maxWidth: 768 });
//   const carouselResponsive = isSmallScreen ? {
//     0: { items: 1 },
//     568: { items: 2 },
//     1024: { items: 3 }
//   } : {};
//   const selectedProject = initiativesData[selectedIndex];

//   const displayInitiativesDetailPage = () => {
//     navigate(`/initiatives/${selectedProject._id}`);
//     window.scrollTo(0, 0);
//   };

//   return (
//     <>
//       <div className='bg-secondary-detailsBackground py-10 px-8 md:px-8 lg:px-12 xl:px-36 h-fit'>
//         <h1
//           className='text-xl font-semibold border-b-[3px] border-primary-btnColor w-fit mx-auto'
//           style={{ lineHeight: "42px" }}
//         >
//           Project Initiatives
//         </h1>
//         <div className='md:flex font-mulish my-10'>
//           <div
//             className='w-full md:w-[40%] lg:w-[30%] cursor-pointer'
//             onClick={displayInitiativesDetailPage}
//           >
//             <h1 className='text-sm font-semibold capitalize border-b-[3px] border-primary-btnColor w-fit'style={{ lineHeight: "40px" }}>
//               {selectedProject?.status} Project
//             </h1>
//             <h1 className='text-2xl font-semibold capitalize line-clamp-2 whitespace-wrap overflow-hidden text-primary-headingColor font-inter py-4'>
//               {selectedProject?.title}
//             </h1>
//             <div
//               dangerouslySetInnerHTML={{
//                 __html: selectedProject?.description || "",
//               }}
//               className='text-justify line-clamp-5 overflow-hidden whitespace-wrap'
//             ></div>
//             <ReadmoreBtn />
//           </div>
//           <div className='w-full md:w-[70%] md:ml-12 mt-6'>
//             <AliceCarousel
//               dots
//               mouseTracking
//               autoWidth
//               onSlideChanged={(e) => setSelectedIndex(e.slide)}
//               disableButtonsControls
//               disableDotsControls={false}
//               renderDotsItem={(e) => (
//                 <button className={`alice-carousel__dots-item ${selectedIndex === e ? 'alice-carousel__dots-item.__active' : ''}`}>{e + 1}</button>
//               )}
//               responsive={carouselResponsive}
//             >
//               {initiativesData
//                 .filter((data) => data.category === "project")
//                 .map((item, index) => (
//                   <div
//                     key={item._id}
//                     onMouseOver={() => onProjectHover(index)}
//                     className={`${
//                       index === selectedIndex
//                         ? "w-[100%] h-[45vh] md:h-[45vh] md:w-[26vw]"
//                         : "w-[100%] h-[45vh] md:h-[30vh] lg:w-[15vw] xl:w-[13vw] md:mt-10 md:px-2 md:opacity-80"
//                     } cursor-pointer transition-opacity duration-700 ease-in-out mb-16`}
//                   >
//                     {item.image ? (
//                       <img
//                         src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
//                           item.image
//                         }`}
//                         alt='project'
//                         className='h-full w-full object-cover object-center'
//                       />
//                     ) : (
//                       <img src='/logo/default-img.jpg' alt='' />
//                     )}
//                   </div>
//                 ))}
//             </AliceCarousel>
//           </div>
//         </div>
//       </div>
//       <div className='h-0 sm:h-16 w-full overflow-hidden sm:mb-16'>
//         <svg
//           viewBox='0 0 500 70'
//           preserveAspectRatio='none'
//           style={{ height: "100%", width: "100%" }}
//         >
//           <path
//             d='M0,0 L500,0 L500,50 C300,110 150,20 0,50 Z'
//             style={{
//               stroke: "none",
//               fill: "#FFF0E180",
//             }}
//           ></path>
//         </svg>
//       </div>
//     </>
//   );
// };

// ProjectInitiatives.propTypes = {
//   initiativesData: PropTypes.arrayOf(PropTypes.shape({
//     _id: PropTypes.string.isRequired,
//     status: PropTypes.string.isRequired,
//     title: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//     category: PropTypes.string.isRequired,
//     image: PropTypes.string,
//   })).isRequired,
// };

// export default ProjectInitiatives;



















// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
// import ReadmoreBtn from "../common/ReadmoreBtn";
// import { useMediaQuery } from "react-responsive";
// import PropTypes from 'prop-types';
// // import "./custom-alice-carousel.css";

// const ProjectInitiatives = ({ initiativesData }) => {
//   const [selectedIndex, setSelectedIndex] = useState(0);

//   const onProjectHover = (index) => {
//     setSelectedIndex(index);
//   };

//   const navigate = useNavigate();

//   const isSmallScreen = useMediaQuery({ maxWidth: 768 });
//   const carouselResponsive = isSmallScreen ? {
//     0: { items: 1 },
//     568: { items: 2 },
//     1024: { items: 3 }
//   } : {};
//   const selectedProject = initiativesData[selectedIndex];

//   const displayInitiativesDetailPage = () => {
//     navigate(`/initiatives/${selectedProject._id}`);
//     window.scrollTo(0, 0);
//   };

//   return (
//     <>
//       <div className='bg-secondary-detailsBackground py-10 px-8 md:px-8 lg:px-12 xl:px-36 h-fit'>
//         <h1
//           className='text-xl font-semibold border-b-[3px] border-primary-btnColor w-fit mx-auto'
//           style={{ lineHeight: "42px" }}
//         >
//           Project Initiatives
//         </h1>
//         <div className='md:flex font-mulish my-10'>
//           <div className='w-full md:w-[40%] lg:w-[30%] cursor-pointer'
//             onClick={displayInitiativesDetailPage}>
//             <h1 className='text-sm font-semibold capitalize border-b-[3px] border-primary-btnColor w-fit' style={{ lineHeight: "40px" }}>
//               {selectedProject?.status} Project
//             </h1>
            
//             <h1 className='text-2xl font-semibold capitalize line-clamp-2 whitespace-wrap overflow-hidden text-primary-headingColor font-inter py-4'>
//               {selectedProject?.title}
//             </h1>
//             <div
//               dangerouslySetInnerHTML={{
//                 __html: selectedProject?.description || "",
//               }}
//               className='text-justify line-clamp-5 overflow-hidden whitespace-wrap'
//             ></div>
//             <ReadmoreBtn />
//           </div>
//           <div className='w-full md:w-[70%] md:ml-12 mt-6'>
//             <AliceCarousel
//               dots
//               mouseTracking
//               autoWidth
//               onSlideChanged={(e) => setSelectedIndex(e.slide)}
//               disableButtonsControls
//               disableDotsControls={false}
//               renderDotsItem={(e) => (
//                 <button className={`alice-carousel__dots-item ${selectedIndex === e ? 'alice-carousel__dots-item.__active' : ''}`}>{e + 1}</button>
//               )}
//               responsive={carouselResponsive}
//             >
//               {initiativesData
//                 .filter((data) => data.category === "project")
//                 .map((item, index) => (
//                   <div
//                     key={item._id}
//                     onMouseOver={() => onProjectHover(index)}
//                     className={`${
//                       index === selectedIndex
//                         ? "w-[100%] h-[45vh] md:h-[45vh] md:w-[26vw]"
//                         : "w-[100%] h-[45vh] md:h-[30vh] lg:w-[15vw] xl:w-[13vw] md:mt-10 md:px-2 md:opacity-80"
//                     } cursor-pointer transition-opacity duration-700 ease-in-out mb-16`}
//                   >
//                     {item.image ? (
//                       <img
//                         src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
//                           item.image
//                         }`}
//                         alt='project'
//                         className='h-full w-full object-cover object-center'
//                       />
//                     ) : (
//                       <img src='/logo/default-img.jpg' alt='' />
//                     )}
//                   </div>
//                 ))}
//             </AliceCarousel>
//           </div>
//         </div>
//       </div>
//       <div className='h-0 sm:h-16 w-full overflow-hidden sm:mb-16'>
//         <svg
//           viewBox='0 0 500 70'
//           preserveAspectRatio='none'
//           style={{ height: "100%", width: "100%" }}
//         >
//           <path
//             d='M0,0 L500,0 L500,50 C300,110 150,20 0,50 Z'
//             style={{
//               stroke: "none",
//               fill: "#FFF0E180",
//             }}
//           ></path>
//         </svg>
//       </div>
//     </>
//   );
// };

// ProjectInitiatives.propTypes = {
//   initiativesData: PropTypes.arrayOf(PropTypes.shape({
//     _id: PropTypes.string.isRequired,
//     status: PropTypes.string.isRequired,
//     title: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//     category: PropTypes.string.isRequired,
//     image: PropTypes.string,
//   })).isRequired,
// };

// export default ProjectInitiatives;








import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ReadmoreBtn from "../common/ReadmoreBtn";
import { useMediaQuery } from "react-responsive";
import PropTypes from 'prop-types';

const ProjectInitiatives = ({ initiativesData }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onProjectHover = (index) => {
    setSelectedIndex(index);
  };

  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const carouselResponsive = isSmallScreen ? {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 }
  } : {};
  const selectedProject = initiativesData[selectedIndex];

  const displayInitiativesDetailPage = () => {
    navigate(`/initiatives/${selectedProject._id}`);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className='bg-secondary-detailsBackground py-10 px-8 md:px-8 lg:px-12 xl:px-36 h-fit'>
        <h1
          className='text-xl font-semibold border-b-[3px] border-primary-btnColor w-fit mx-auto'
          style={{ lineHeight: "42px" }}
        >
          Project Initiatives
        </h1>
        <div className='md:flex font-mulish my-10'>
          <div
            className='w-full md:w-[40%] lg:w-[30%] cursor-pointer'
            onClick={displayInitiativesDetailPage}
          >
            <h1
              className='text-sm font-semibold capitalize border-b-[3px] border-primary-btnColor w-fit'
              style={{ lineHeight: "40px" }}
            >
              {selectedProject?.status} Project
            </h1>
            <h1 className='text-2xl font-semibold capitalize line-clamp-2 whitespace-wrap overflow-hidden text-primary-headingColor font-inter py-4'>
            {/* {selectedProject?.status} */}
              {selectedProject?.title}
            </h1>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedProject?.description || "",
              }}
              className='text-justify line-clamp-5 overflow-hidden whitespace-wrap'
            ></div>
            <ReadmoreBtn />
          </div>
          <div className='w-full md:w-[70%] md:ml-12 mt-6'>
            <AliceCarousel
              dots
              mouseTracking
              autoWidth
              onSlideChanged={(e) => setSelectedIndex(e.slide)}
              disableButtonsControls
              disableDotsControls={true}
              responsive={carouselResponsive}
            >
              {initiativesData
                .filter((data) => data.category === "project")
                .map((item, index) => (
                  <div
                    key={item._id}
                    onMouseOver={() => onProjectHover(index)}
                    className={`${
                      index === selectedIndex
                        ? "w-[100%] h-[45vh] md:h-[45vh] md:w-[26vw]"
                        : "w-[100%] h-[45vh] md:h-[30vh] lg:w-[15vw] xl:w-[13vw] md:mt-10 md:px-2 md:opacity-80"
                    } cursor-pointer transition-opacity duration-700 ease-in-out mb-16`}
                  >
                    {item.image ? (
                      <img
                        src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
                          item.image
                        }`}
                        alt='project'
                        className='h-full w-full object-cover object-center'
                      />
                    ) : (
                      <img src='/logo/default-img.jpg' alt='' />
                    )}
                  </div>
                ))}
            </AliceCarousel>
          </div>
        </div>
      </div>
      <div className='h-0 sm:h-16 w-full overflow-hidden sm:mb-16'>
        <svg
          viewBox='0 0 500 70'
          preserveAspectRatio='none'
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d='M0,0 L500,0 L500,50 C300,110 150,20 0,50 Z'
            style={{
              stroke: "none",
              fill: "#FFF0E180",
            }}
          ></path>
        </svg>
      </div>
    </>
  );
};
ProjectInitiatives.propTypes = {
  initiativesData: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    image: PropTypes.string,
  })).isRequired,
};

export default ProjectInitiatives;
