
import { z } from "zod";

const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
];

export const advertisementSchema = z.object({
  banner: z.any().refine(
    (files) => {
      if (!Array.isArray(files)) return true;
      return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
    },
    {
      message: "Accepted image types: JPG, JPEG, PNG, GIF",
    }
  ),
  // Using min method with a minimum length of 1
});
export const noticeSchema = z.object({
  image: z.any().refine(
    (files) => {
      if (!Array.isArray(files)) return true;
      return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
    },
    {
      message: "Accepted image types: JPG, JPEG, PNG, GIF",
    }
  ),
  // Using min method with a minimum length of 1
});

export const testimonialSchema = z.object({
  title: z.string().min(1, { message: "Title field is required" }),
  description: z.string().min(1, { message: "Description field is required" }),
  logo: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    )

    .optional(),
  images: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    )

    .optional(),
});
// export const achievmentsSchema = z.object({
//   title: z.string().min(1, { message: "Title field is required" }),
//   description: z.string().min(1, { message: "Description field is required" }),
//   address: z.string().min(1, { message: "project field is required" }),
//   image: z
//     .any()
//     .refine(
//       (files) => {
//         if (!Array.isArray(files)) return true;
//         return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
//       },
//       {
//         message: "Accepted image types: JPG, JPEG, PNG, GIF",
//       }
//     )

//     .optional(),
// });
// Create the schema for achievements
export const achievmentsSchema = z.object({
  title: z.string().min(1, { message: "Title field is required" }),
  description: z.string().min(1, { message: "Description field is required" }),
  address: z.string().min(1, { message: "Address field is required" }),
  date: z
    .string()
    .refine((date) => !isNaN(Date.parse(date)), {
      message: "Invalid date format",
    }),
  images: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    )

    .optional(),
  logo: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    )

    .optional(),
});




export const involvementBannerSchema = z.object({
  title: z.string().min(1, { message: "Title field is required" }),
  description: z.string().min(1, { message: "Description field is required" }),
  image: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    ).optional(),
});

export const bannerAdsSchema = z.object({
  image: z.any().refine(
    (files) => {
      if (!Array.isArray(files)) return true;
      return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
    },
    {
      message: "Accepted image types: JPG, JPEG, PNG, GIF",
    }
  ),
  // Using min method with a minimum length of 1
});

// export const tlppdInitiativeSchema = z.object({
//   title: z.string().min(1, { message: "Title field is required" }),
//   description: z.string().min(1, { message: "Description field is required" }),
//   project: z.string().min(1, { message: "project field is required" }),
//   impact: z.string().min(1, { message: "impact field is required" }),
//   image: z
//     .any()
//     .refine(
//       (files) => {
//         if (!Array.isArray(files)) return true;
//         return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
//       },
//       {
//         message: "Accepted image types: JPG, JPEG, PNG, GIF",
//       }
//     )

//     .optional(),
// });


// import { z } from "zod";

// const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif"];

// export const tlppdInitiativeSchema = z.object({
//   title: z.string().min(1, { message: "Title field is required" }),
//   description: z.string().min(1, { message: "Description field is required" }),
//   status: z.string().min(1, { message: "Status field is required" }),
//   establishedDate: z.string().optional(),
//   category: z.string().min(1, { message: "Category field is required" }),
//   socialMedia: z.object({
//     facebook: z.string().url().optional(),
//     email: z.string().email().optional(),
//     linkedin: z.string().url().optional(),
//     website: z.string().url().optional(),
//   }).optional(),
//   projectOverview: z.object({
//     description: z.string().min(1, { message: "Overview Description is required" }),
//     startingDate: z.string().optional(),
//     endDate: z.string().optional(),
//     projectBudget: z.string().optional(),
//     donationReceived: z.string().optional(),
//     projectReport: z.string().optional(),
//   }).optional(),
//   projectOutcomes: z.object({
//     description: z.string().min(1, { message: "Outcome Description is required" }),
//   }).optional(),
//   involvement: z.object({
//     description: z.string().min(1, { message: "Involvement Description is required" }),
//   }).optional(),
//   objectives: z.object({
//     description: z.string().min(1, { message: "Objectives Description is required" }),
//   }).optional(),
//   targetAudience: z.object({
//     description: z.string().min(1, { message: "Target Audience Description is required" }),
//   }).optional(),
//   image: z
//     .any()
//     .refine(
//       (files) => {
//         if (!Array.isArray(files)) return true;
//         return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
//       },
//       {
//         message: "Accepted image types: JPG, JPEG, PNG, GIF",
//       }
//     )
//     .optional(),
//   logo: z
//     .any()
//     .refine(
//       (files) => {
//         if (!Array.isArray(files)) return true;
//         return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
//       },
//       {
//         message: "Accepted image types: JPG, JPEG, PNG, GIF",
//       }
//     )
//     .optional(),
//   overviewImage: z
//     .any()
//     .refine(
//       (files) => {
//         if (!Array.isArray(files)) return true;
//         return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
//       },
//       {
//         message: "Accepted image types: JPG, JPEG, PNG, GIF",
//       }
//     )
//     .optional(),
//   outcomeImage: z
//     .any()
//     .refine(
//       (files) => {
//         if (!Array.isArray(files)) return true;
//         return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
//       },
//       {
//         message: "Accepted image types: JPG, JPEG, PNG, GIF",
//       }
//     )
//     .optional(),
//   involvementImage: z
//     .any()
//     .refine(
//       (files) => {
//         if (!Array.isArray(files)) return true;
//         return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
//       },
//       {
//         message: "Accepted image types: JPG, JPEG, PNG, GIF",
//       }
//     )
//     .optional(),
//   objectivesImage: z
//     .any()
//     .refine(
//       (files) => {
//         if (!Array.isArray(files)) return true;
//         return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
//       },
//       {
//         message: "Accepted image types: JPG, JPEG, PNG, GIF",
//       }
//     )
//     .optional(),
//   audienceImage: z
//     .any()
//     .refine(
//       (files) => {
//         if (!Array.isArray(files)) return true;
//         return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
//       },
//       {
//         message: "Accepted image types: JPG, JPEG, PNG, GIF",
//       }
//     )
//     .optional(),
// });




export const tlppdInitiativeSchema = z.object({
  title: z.string().min(1, { message: "Title field is required" }),
  description: z.string().min(1, { message: "Description field is required" }),
  status: z.string().min(1, { message: "Status field is required" }),
  establishedDate: z.string().optional(),
  category: z.string().min(1, { message: "Category field is required" }),
  socialMedia: z.object({
    facebook: z.string().url({ message: "Invalid URL" }).optional(),
    email: z.string().email({ message: "Invalid email" }).optional(),
    linkedin: z.string().url({ message: "Invalid URL" }).optional(),
    website: z.string().url({ message: "Invalid URL" }).optional(),
  }).optional(),
  projectOverview: z.object({
    description: z.string().min(1, { message: "Overview Description is required" }),
    startingDate: z.string().optional(),
    endDate: z.string().optional(),
    projectBudget: z.string().optional(),
    donationReceived: z.string().optional(),
    projectReport: z.string().optional(),
  }).optional(),
  projectOutcomes: z.object({
    description: z.string().min(1, { message: "Outcome Description is required" }),
  }).optional(),
  involvement: z.object({
    description: z.string().min(1, { message: "Involvement Description is required" }),
  }).optional(),
  objectives: z.object({
    description: z.string().min(1, { message: "Objectives Description is required" }),
  }).optional(),
  targetAudience: z.object({
    description: z.string().min(1, { message: "Target Audience Description is required" }),
  }).optional(),
  image: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    )
    .optional(),
  logo: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    )
    .optional(),
  overviewImage: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    )
    .optional(),
  outcomeImage: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    )
    .optional(),
  involvementImage: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    )
    .optional(),
  objectivesImage: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    )
    .optional(),
  audienceImage: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true;
        return files.every((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type));
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF",
      }
    )
    .optional(),
});


export const involvementAchievementSchema = z.object({
  title: z.string().min(1, { message: "Title field is required" }),
  content: z.string().min(1, { message: "Content field is required" }),
  involvement: z.string().min(1, { message: "Involvement field is required" }),
  image: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true; // If not an array, consider it valid
        // Add additional validation if needed
        return true; // Example: Ensure file type and size are valid
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF. Max file size is 2MB.",
      }
    )
    .optional(),
});


export const involvementResponsibilitiesSchema = z.object({
  content: z.string().min(1, { message: "Content field is required" }),
  involvement: z.string().min(1, { message: "Involvement field is required" }),
});


export const AffiliationSchema = z.object({
  organization: z.string().min(1, { message: "Organization field is required" }),
  position: z.string().min(1, { message: "Position field is required" }),
  image: z
    .any()
    .refine(
      (files) => {
        if (!Array.isArray(files)) return true; // If not an array, consider it valid
        // Add additional validation if needed
        return true; // Example: Ensure file type and size are valid
      },
      {
        message: "Accepted image types: JPG, JPEG, PNG, GIF. Max file size is 2MB.",
      }
    )
    .optional(),
  date: z.string().optional(), // Assuming date can be optional and is a string
});
