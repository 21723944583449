import { useState } from "react";
import axios from "axios";
  // Retrieve token from localStorage safely
  const storedData = localStorage.getItem("amrityonjan");
  const token = storedData ? JSON.parse(storedData).accessToken : null;
export const useAddData = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const addData = async (formData, endpoint, header) => {
    try {
      setIsLoading(true);

      const dataItem = await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/${endpoint}`,
        formData,
        {
          credentials: "include",
          headers: header,
          Authorization: `Bearer ${token}`,
        }
      );

      setIsLoading(false);
      return dataItem;
    } catch (error) {
      setIsLoading(false);
      setError("Error:", error.message);
      throw error;
    }
  };

  return { addData, isLoading, error };
};











// import { useState } from "react";
// import axios from "axios";

// export const useAddData = () => {
//   const [error, setError] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);

//   const addData = async (formData, endpoint, header) => {
//     console.log(formData, header, endpoint, "formData, endpoint");
//     try {
//       setIsLoading(true);

//       const dataItem = await axios.post(
//         `${import.meta.env.VITE_APP_API_URL}/${endpoint}`,
//         formData,
//         {
//           credentials: "include",
//           headers: header,
//         }
//       );

//       setIsLoading(false);
//       return dataItem;
//     } catch (error) {
//       setIsLoading(false);
//       setError("Error:", error.message);
//       throw error;
//     }
//   };

//   return { addData, isLoading, error };
// };


