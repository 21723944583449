/* eslint-disable no-unused-vars */
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import { useParams } from "react-router-dom";
import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";
import Loader from "../../../components/loader/Loader";
import DataFetchingError from "../../errorPage/DataFetchingError";
import DonationForm from "../../../components/forms/postForms/donorForm/DonorForm";
import AdvertisementForm from "../../../components/forms/postForms/advertisementForm/AdvertisementForm";

const EditAdvertisement = () => {
  const { id } = useParams();
  const { dataDetails, loading, error, refetchData } = useFetchDataDetails(
    "advertisements",
    id
  );

  console.log(dataDetails);

  return (
    <Container
      breadcumbMenu='Advertisement'
      breadcumbSubMenu='edit Advertisement'
      title='Edit Advertisement'
      btnTitle='back to Advertisement'
      path='/admin/advertisements'
    >
      <FormContainer heading='edit Advertisement'>
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError error={error} refetchData={refetchData} />
        ) : (
          <AdvertisementForm defaultData={dataDetails} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditAdvertisement;
