import { useState, useEffect } from "react";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { CalendarMinus2, BookOpen } from "lucide-react";
import Heading from "../common/Heading";
import { formatDate } from "../../utility/dateFormates";
import PropTypes from "prop-types";
import Container from "../../components/common/Container";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PapersPresented = ({ paperPresentData }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleReadMore = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  
  // eslint-disable-next-line react/prop-types
  const TimeAgo = ({ createdAt }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const now = new Date();
        const diffInMs = now.getTime() - new Date(createdAt).getTime();
        const diffInMin = Math.round(diffInMs / (1000 * 60));

        if (diffInMin < 1) {
          setTimeAgo("just now");
        } else if (diffInMin === 1) {
          setTimeAgo("1 minute ago");
        } else if (diffInMin < 60) {
          setTimeAgo(`${diffInMin} minutes ago`);
        } else if (diffInMin < 120) {
          setTimeAgo("1 hour ago");
        } else if (diffInMin < 1440) {
          setTimeAgo(`${Math.floor(diffInMin / 60)} hours ago`);
        } else if (diffInMin < 2880) {
          setTimeAgo("1 day ago");
        } else {
          setTimeAgo(`${Math.floor(diffInMin / 1440)} days ago`);
        }
      };

      calculateTimeAgo();

      const interval = setInterval(() => {
        calculateTimeAgo();
      }, 60000);

      return () => clearInterval(interval);
    }, [createdAt]);

    return <span>{timeAgo}</span>;
  };

  const ArrowLeft = (props) => (
    <div
      {...props}
      className="absolute bottom-4 right-1/2 transform translate-x-2 translate-y-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-8 pt-12"
    >
      <FaArrowLeft />
    </div>
  );

  const ArrowRight = (props) => (
    <div
      {...props}
      className="absolute bottom-4 left-1/2 transform translate-x-6 translate-y-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-8 pt-12"
    >
      <FaArrowRight />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="Container mx-auto">
      <div className="w-full overflow-hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 2000 200"
          fill="none"
        >
          <path
            d="M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z"
            fill="#FFF0E1"
            fillOpacity="0.5"
          />
          <foreignObject
            x="0"
            y="10%"
            width="100%"
            height="100%"
          ></foreignObject>
        </svg>
      </div>
      <div className="bg-secondary-detailsBackground w-full px-4 md:px-10 lg:px-12 xl:px-20 pb-20 ">
        <div className="pb-10">
          <Heading heading="Papers Presented" />
        </div>
        <Container>
          <Slider {...settings}>
            {paperPresentData.map((item, index) => (
              <div key={item._id} className="p-4">
                <div className="bg-white p-6 shadow-lg rounded-lg">
                  <div className="h-[fit] text-center justify-center">
                    <h1 className="text-primary-headingColor capitalize line-clamp-2 text-xl font-inter font-semibold">
                      {item.title}
                    </h1>
                    <h1 className="py-4 text-base font-semibold capitalize">
                      Organization:
                      <span className="ms-2">{item.organization}</span>
                    </h1>
                  </div>
                  <div className="my-6 sm:h-[14rem] m:h-[14rem] line-clamp-3 sm:line-clamp-6 whitespace-wrap overflow-hidden text-justify">
                    {expandedIndex === index || item.description.length <= 200 ? (
                      <p>{item.description}</p>
                    ) : (
                      <>
                        <p>{item.description.slice(0, 100)}</p>
                        <div className="text-primary-btnColor cursor-pointer" onClick={() => handleReadMore(index)}>
                          {/* Read {expandedIndex === index ? "Less" : "More"} */}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-4 text-sm">
                    <div className="flex">
                      <div className="flex items-center mr-3">
                        <CalendarMinus2 className="w-5 h-5 text-[#828587]" />
                        <p className="ml-1">{formatDate(item.date)}</p>
                      </div>
                      <div className="flex items-center my-4 md:my-0">
                        <BookOpen className="w-5 h-5 text-[#828587]" />
                        <p className="ml-1">
                          <span><TimeAgo createdAt={item.createdAt} /></span>
                        </p>
                      </div>
                    </div>
                    <p className="font-bold text-base text-primary-btnColor cursor-pointer justify-end text-end" onClick={() => handleReadMore(index)}>
                      {expandedIndex === index ? "Read Less" : "Read More"}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
      </div>
    </div>
  );
};

PapersPresented.propTypes = {
  paperPresentData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      organization: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PapersPresented;













// import { useEffect, useState } from "react";
// import Slider from "react-slick";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
// import { CalendarMinus2, BookOpen } from "lucide-react";
// import Heading from "../common/Heading";
// import { formatDate } from "../../utility/dateFormates";
// import PropTypes from "prop-types";
// import Container from "../../components/common/Container";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const PapersPresented = ({ paperPresentData }) => {
//   const [showAll, setShowAll] = useState(false);

//   const handleViewMore = () => {
//     setShowAll(true);
//   };

//   const handleViewLess = () => {
//     setShowAll(false);
//   };
//   // eslint-disable-next-line react/prop-types
//   const TimeAgo = ({ createdAt }) => {
//     const [timeAgo, setTimeAgo] = useState("");

//     useEffect(() => {
//       const calculateTimeAgo = () => {
//         const now = new Date();
//         const diffInMs = now.getTime() - new Date(createdAt).getTime();
//         const diffInMin = Math.round(diffInMs / (1000 * 60));

//         if (diffInMin < 1) {
//           setTimeAgo("just now");
//         } else if (diffInMin === 1) {
//           setTimeAgo("1 minute ago");
//         } else if (diffInMin < 60) {
//           setTimeAgo(`${diffInMin} minutes ago`);
//         } else if (diffInMin < 120) {
//           setTimeAgo("1 hour ago");
//         } else if (diffInMin < 1440) {
//           setTimeAgo(`${Math.floor(diffInMin / 60)} hours ago`);
//         } else if (diffInMin < 2880) {
//           setTimeAgo("1 day ago");
//         } else {
//           setTimeAgo(`${Math.floor(diffInMin / 1440)} days ago`);
//         }
//       };

//       calculateTimeAgo();

//       const interval = setInterval(() => {
//         calculateTimeAgo();
//       }, 60000);

//       return () => clearInterval(interval);
//     }, [createdAt]);

//     return <span>{timeAgo}</span>;
//   };

//   const ArrowLeft = (props) => (
//     <div
//       {...props}
//       className="absolute bottom-4 right-1/2 transform translate-x-2 translate-y-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-8 pt-12"

//     >
//       <FaArrowLeft />
//     </div>
//   );

//   const ArrowRight = (props) => (
//     <div
//       {...props}
//       className="absolute bottom-4 left-1/2 transform translate-x-6 translate-y-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-8 pt-12"

//     >
//       <FaArrowRight />
//     </div>
//   );

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 2,
//     slidesToScroll: 1,
//     autoplay: true, // Enable autoplay
//     autoplaySpeed: 3000, // Set autoplay speed (3 seconds)
//     nextArrow: <ArrowRight />,
//     prevArrow: <ArrowLeft />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };
  

//   return (
//     <div className="Container mx-auto">
//       <div className="w-full overflow-hidden">
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           width="100%"
//           height="100%"
//           viewBox="0 0 2000 200"
//           fill="none"
//         >
//           <path
//             d="M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z"
//             fill="#FFF0E1"
//             fillOpacity="0.5"
//           />
//           <foreignObject
//             x="0"
//             y="10%"
//             width="100%"
//             height="100%"
//           ></foreignObject>
//         </svg>
//       </div>
//       <div className="bg-secondary-detailsBackground w-full px-4 md:px-10 lg:px-12 xl:px-20 pb-20 ">
//         <div className="pb-10">
//           <Heading heading="Papers Presented" />
//         </div>
//         <Container>
//           <Slider {...settings}>
//             {paperPresentData.map((item) => (
//               <div key={item._id} className="p-4">
//                 <div className="bg-white p-6 shadow-lg rounded-lg">
//                   <div className="h-[fit] text-center justify-center">
//                     <h1 className="text-primary-headingColor capitalize line-clamp-2 text-xl font-inter font-semibold">
//                       {item.title}
//                     </h1>
//                     <h1 className="py-4 text-base font-semibold capitalize">
//                       Organization:
//                       <span className="ms-2">{item.organization}</span>
//                     </h1>
//                   </div>
//                   <div className="my-6 sm:h-[10rem] line-clamp-3 sm:line-clamp-6 whitespace-wrap overflow-hidden text-justify">
//                   {!showAll && item.length > 6 && (
//         <div className="w-fit mx-auto mt-10 px-10 text-sm font-semibold text-white py-3 bg-primary-btnColor rounded-full capitalize tranistion duration-300 hover:bg-orange-600 cursor-pointer">
//           <button onClick={handleViewMore}>View More</button>
//         </div>
//       )}
//       {showAll && (
//         <div className="w-fit mx-auto mt-10 px-10 text-sm font-semibold text-white py-3 bg-primary-btnColor rounded-full capitalize tranistion duration-300 hover:bg-orange-600 cursor-pointer">
//           <button onClick={handleViewLess}>View Less</button>
//         </div>
//       )}
//                     <p>{item.description}</p>
//                   </div>
//                   <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-4 text-sm">
//                     <div className="flex">
//                       <div className="flex items-center mr-3">
//                         <CalendarMinus2 className="w-5 h-5 text-[#828587]" />
//                         <p className="ml-1">{formatDate(item.date)}</p>
//                       </div>
//                       <div className="flex items-center my-4 md:my-0">
//                         <BookOpen className="w-5 h-5 text-[#828587]" />
//                         <p className="ml-1">
//                           <TimeAgo createdAt={item.createdAt} />
//                         </p>
//                       </div>
//                     </div>
//                     <p className="font-bold text-base text-primary-btnColor cursor-pointer">
//                       Read more
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             ))}
            
//           </Slider>
          
//         </Container>
        
//       </div>
//     </div>
//   );
// };

// PapersPresented.propTypes = {
//   paperPresentData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       organization: PropTypes.string.isRequired,
//       description: PropTypes.string.isRequired,
//       date: PropTypes.string.isRequired,
//       createdAt: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };

// export default PapersPresented;


































// import { useEffect, useState } from "react";
// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
// import { ArrowRight, ArrowLeft, CalendarMinus2, BookOpen } from "lucide-react";
// import Heading from "../common/Heading";
// import { formatDate } from "../../utility/dateFormates";
// import PropTypes from "prop-types";

// const responsive = {
//   0: { items: 1 },
//   568: { items: 2 },
//   1024: { items: 3 },
// };

// const PapersPresented = ({ paperPresentData }) => {
//   // eslint-disable-next-line react/prop-types
//   const TimeAgo = ({ createdAt }) => {
//     const [timeAgo, setTimeAgo] = useState("");

//     useEffect(() => {
//       const calculateTimeAgo = () => {
//         const now = new Date();
//         const diffInMs = now.getTime() - new Date(createdAt).getTime();
//         const diffInMin = Math.round(diffInMs / (1000 * 60));

//         if (diffInMin < 1) {
//           setTimeAgo("just now");
//         } else if (diffInMin === 1) {
//           setTimeAgo("1 minute ago");
//         } else if (diffInMin < 60) {
//           setTimeAgo(`${diffInMin} minutes ago`);
//         } else if (diffInMin < 120) {
//           setTimeAgo("1 hour ago");
//         } else if (diffInMin < 1440) {
//           setTimeAgo(`${Math.floor(diffInMin / 60)} hours ago`);
//         } else if (diffInMin < 2880) {
//           setTimeAgo("1 day ago");
//         } else {
//           setTimeAgo(`${Math.floor(diffInMin / 1440)} days ago`);
//         }
//       };

//       calculateTimeAgo();

//       const interval = setInterval(() => {
//         calculateTimeAgo();
//       }, 60000);

//       return () => clearInterval(interval);
//     }, [createdAt]);

//     return <span>{timeAgo}</span>;
//   };

//   const [expandedIndex, setExpandedIndex] = useState(null);

//   const handleReadMore = (index) => {
//     setExpandedIndex(expandedIndex === index ? null : index);
//   };

//   return (
//     <>
//       <div className="w-full overflow-hidden">
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           width="100%"
//           height="100%"
//           viewBox="0 0 2000 200"
//           fill="none"
//         >
//           <path
//             d="M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z"
//             fill="#FFF0E1"
//             fillOpacity="0.5"
//           />
//           <foreignObject x="0" y="10%" width="100%" height="100%"></foreignObject>
//         </svg>
//       </div>
//       <div className="bg-secondary-detailsBackground w-full px-4 md:px-10 lg:px-12 xl:px-20 pb-10">
//         <div className="pb-10">
//           <Heading heading="Papers Presented" />
//         </div>
//         <div>
//           <AliceCarousel
//             mouseTracking
//             disableDotsControls
//             responsive={responsive}
//             renderPrevButton={() => (
//               <div className="flex items-center justify-end">
//                 <ArrowLeft className="text-primary-btnColor cursor-pointer" />
//               </div>
//             )}
//             renderNextButton={() => (
//               <div className="flex items-center justify-start">
//                 <ArrowRight className="text-primary-btnColor cursor-pointer" />
//               </div>
//             )}
//           >
//             {paperPresentData.map((item, index) => (
//               <div
//                 key={item._id}
//                 className="sm:min-h-[fit] mb-8 px-8 py-9 font-mulish bg-white box-shadow mx-3 cursor-pointer hover:scale-105 transition ease-in-out my-4 duration-500"
//               >
//                 <div className="h-[fit] text-center justify-center">
//                   <h1 className="text-primary-headingColor capitalize line-clamp-2 text-xl font-inter font-semibold ">
//                     {item.title}
//                   </h1>
//                   <h1 className="py-4 text-base font-semibold capitalize">
//                     Organization:<span className="ms-2">{item.organization}</span>
//                   </h1>
//                 </div>
//                 <div className={`my-5 sm:h-[10rem] overflow-hidden text-justify ${expandedIndex === index ? "" : "line-clamp-3 sm:line-clamp-6"}`}>
//                   <p>{item.description}</p>
//                 </div>
//                 <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-4 text-sm">
//                   <div className="flex">
//                     <div className="flex items-center mr-3">
//                       <CalendarMinus2 className="w-5 h-5 text-[#828587]" />
//                       <p className="ml-1">{formatDate(item.date)}</p>
//                     </div>

//                     <div className="flex items-center my-4 md:my-0">
//                       <BookOpen className="w-5 h-5 text-[#828587]" />
//                       <p className="ml-1">
//                         <TimeAgo createdAt={item.createdAt} />
//                       </p>
//                     </div>
//                   </div>
//                   <p
//                     className="font-bold text-base text-primary-btnColor cursor-pointer"
//                     onClick={() => handleReadMore(index)}
//                   >
//                     {expandedIndex === index ? "Read less" : "Read more"}
//                   </p>
//                 </div>
//               </div>
//             ))}
//           </AliceCarousel>
//         </div>
//       </div>
//     </>
//   );
// };

// PapersPresented.propTypes = {
//   paperPresentData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       organization: PropTypes.string.isRequired,
//       organizer: PropTypes.string.isRequired,
//       description: PropTypes.string.isRequired,
//       date: PropTypes.string.isRequired,
//       createdAt: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };

// export default PapersPresented;

// PapersPresented.propTypes = {
//   paperPresentData: PropTypes.arrayOf(PropTypes.shape({
//     _id: PropTypes.string.isRequired,
//     title: PropTypes.string.isRequired,
//     organization: PropTypes.string.isRequired,
//     organizer: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//     date: PropTypes.string.isRequired,
//     createdAt: PropTypes.string.isRequired, // Added prop type validation for createdAt
//   })).isRequired,
// };

// PapersPresented.propTypes = {
//   paperPresentData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       organization: PropTypes.string.isRequired,
//       organizer: PropTypes.string.isRequired,
//       description: PropTypes.string.isRequired,
//       date: PropTypes.string.isRequired,
//       createdAt: PropTypes.string.isRequired, // Added prop type validation for createdAt
//       // createdAt: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };
// PapersPresented.propTypes = {
//   paperPresentData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       organization: PropTypes.string.isRequired,
//       organizer: PropTypes.string.isRequired,
//       description: PropTypes.string.isRequired,
//       date: PropTypes.string.isRequired,
//       createdAt: PropTypes.string.isRequired, // Added prop type validation for createdAt
//     })
//   ).isRequired,
// };

// export default PapersPresented;
// export default PapersPresented;
