import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
// import AboutmeForm from "../../../components/forms/postForms/aboutmeForm/AboutmeForm";
import ScopeForm from "../../../components/forms/postForms/scopeForm/ScopeForm";

const AddAboutMe = () => {
  return (
    <Container
      breadcumbMenu="Scope Me"
      breadcumbSubMenu="New Scope Me"
      title="Scope Me"
      btnTitle="Back to Scope Me"
      path="/admin/scope"
    >
      <FormContainer heading="Add Scope Me">
        <ScopeForm />
      </FormContainer>
    </Container>
  );
};

export default AddAboutMe;
