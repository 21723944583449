import Container from '../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
// import PublicationForm from '../../../components/forms/postForms/publicationForm/PublicationForm';
// import PublicationForm from '../../../components/forms/postForms/publicationForm/PublicationForm';
import { useParams } from 'react-router-dom';
import useFetchDataDetails from '../../../hooks/useFetchDataDetail/useFetchDataDetails';
import DataFetchingError from '../../errorPage/DataFetchingError';
import Loader from '../../../components/loader/Loader';

import TimelineForm from "../../../components/forms/postForms/timelineForm/TimelineForm";


const EditTimeline = () => {
  const { id } = useParams();
  //   custom hook for fetching data detaails from api with 2 parameters (endpoint and id)
  const { dataDetails, loading, error } = useFetchDataDetails('timelines', id);

  return (
    <Container
      breadcumbMenu="Timeline"
      breadcumbSubMenu="edit Timeline"
      title="edit Timeline"
      btnTitle="back to Timeline"
      path="/admin/timelines"
    >
      <FormContainer heading="edit Timeline">
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <TimelineForm defaultData={dataDetails} isEdit="true " />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditTimeline;
