import { useState } from "react";
import axios from "axios";
import Heading from "../components/common/Heading";
import HerosectionCarousel from "../components/common/HerosectionCaarousel";
import Timeline from "../components/publicationPage/timelines/Timeline";
import PublicationContent from "./../components/publicationPage/publicationContent/PublicationContent";
import { ArrowLeft, ArrowRight, ChevronsRight } from "lucide-react";
import AliceCarousel from "react-alice-carousel";
import { useFetchData } from "../hooks/useFetchData/useFetchData";
import PublicationLatestCard from "../components/card/PublicationLatestCard";
import PublicationSlider from "../components/publicationPage/publicationSlider/PublicationSlider";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PublicationPage = () => {
  const myHeroImages = [
    {
      image: "/images/AboutBg.png",
    },
    {
      image: "/images/Herosectionimg.png",
    },
    {
      image: "images/Herosectionimg.png",
    },
  ];
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { data: publicationData } = useFetchData({ endpoint: "publications" });
  const [selectItem, setSelectItem] = useState(0);
  const [selectTimeline, setSelectTimeline] = useState(0);

  const onPublicationSelect = (index) => {
    setSelectItem(index);
  };
  const selectByTimeline = (index) => {
    setSelectTimeline(index);
  };
  const currentDate = new Date();
  const isUpcoming = publicationData?.filter((item) => {
    const publishedDate = new Date(item.publishedDate);
    return currentDate < publishedDate;
  });

  const [btnClick, setBtnClick] = useState(false);
  const onBtnClick = () => setBtnClick(!btnClick);
  const sendMail = async (e) => {
    e.preventDefault();
    setLoading(true);
    const backendUrl = import.meta.env.VITE_APP_BASE_URL;
    try {
      const { data } = await axios.post(
        `${backendUrl}/send/mail`,
        {
          name,
          email,
          number,
          subject,
          message,
        },
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      );
      setName("");
      setEmail("");
      setNumber("");
      setSubject("");
      setMessage("");
      toast.success(data.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  return (
    <>
      <div className="pb-12">
        <HerosectionCarousel myHeroImages={myHeroImages} />
      </div>
      <Heading heading="Latest Publications" />
      <PublicationContent
        publicationData={publicationData}
        item={selectItem}
        className="w-full lg:w-[65%] mx-auto mt-10 lg:flex-row"
      />
      <PublicationSlider
        onPublicationSelect={onPublicationSelect}
        data={publicationData}
      />
      {isUpcoming && isUpcoming.length > 0 ? (
        <>
          <div className="bg-secondary-detailsBackground px-8 md:px-16 lg:px-20 xl:px-32">
            <div className="py-2">
              <Heading heading="Upcoming Publications" className="my-8" />
            </div>

            <div className="w-[95%] lg:w-[90%] mx-auto">
              <div className="flex items-center justify-center flex-col gap-10 lg:flex-row">
                <Timeline
                  publicationData={publicationData}
                  selectByTimeline={selectByTimeline}
                />
                {isUpcoming && (
                  <PublicationContent
                    item={selectTimeline}
                    publicationData={publicationData}
                    className="w-[60rem] mx-auto mt-10 lg:flex-row-reverse"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="h-fit w-full mb-4">
            <svg
              viewBox="0 0 500 70"
              preserveAspectRatio="none"
              style={{ height: "100%", width: "100%" }}
            >
              <path
                d="M0,0 L500,0 L500,50 C300,110 150,20 0,50 Z"
                style={{
                  stroke: "none",
                  fill: "#FFF0E180",
                }}
              ></path>
            </svg>
          </div>
        </>
      ) : null}
      <div className="my-40 px-8 md:px-16 lg:px-20 xl:px-32">
        <Heading heading="list of publications" />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mt-10">
          {publicationData.length <= 6 || btnClick
            ? publicationData.map((item, index) => (
                <PublicationLatestCard item={item} key={index} />
              ))
            : publicationData
                .slice(0, 6)
                .map((item, index) => (
                  <PublicationLatestCard item={item} key={index} />
                ))}
        </div>
        <div
          onClick={onBtnClick}
          className="flex items-center mt-16 justify-center cursor-pointer"
        >
          {publicationData.length > 6 && (
            <>
              {btnClick ? (
                <p className="capitalize">View less</p>
              ) : (
                <p className="capitalize">View more</p>
              )}
            </>
          )}
        </div>
      </div>

      <div className=" flex flex-col lg:flex-row items-center justify-center gap-16 w-full px-5 lg:w-[90%] mx-auto mb-16">
        <div className="w-[100%] mx-auto bg-white ">
          <h2 className="text-2xl italic font-semibold text-[#89363c] ">
            If you want my publication, contact me
          </h2>
          <div className="p-5">
            <div className="md:w-[100%] lg:w-[100%] mx-auto grid gap-16 md:gap-10 p-10">
              <div className="p-5 md:p-8 bg-white border-2 rounded-xl">
                <div className="mt-6 md:mt-10">
                  <form onSubmit={sendMail} className="space-y-4">
                    <div>
                      <div>Your Name</div>
                      <input
                        className="transition duration-300 ease flex w-full border-b border-slate-700/40 bg-transparent px-3 py-1  text-sm placeholder:text-gray-600   focus:border-blue-500 focus:outline-none"
                        // placeholder="Your Name"
                        name="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        // required
                      />
                    </div>
                    <div>
                      <div>Email Address</div>
                      <input
                        className="transition duration-300 ease flex w-full border-b border-slate-700/40 bg-transparent px-3 py-1  text-sm placeholder:text-gray-600   focus:border-blue-500 focus:outline-none"
                        // placeholder="Email Address"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        // required
                      />
                    </div>
                    <div>
                      <div>Phone Number</div>
                      <input
                        className="transition duration-300 ease flex w-full border-b border-slate-700/40 bg-transparent px-3 py-1  text-sm placeholder:text-gray-600   focus:border-blue-500 focus:outline-none"
                        // placeholder="Phone number"
                        name="phoneNumber"
                        type="tel"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        // required
                      />
                    </div>
                    <div>
                      <div>Subject</div>
                      <input
                        className="transition duration-300 ease flex w-full border-b border-slate-700/40 bg-transparent px-3 py-1  text-sm placeholder:text-gray-600   focus:border-blue-500 focus:outline-none"
                        // placeholder="subject"
                        name="subject"
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        // required
                      />
                    </div>
                    <div>
                      <label htmlFor="message">Your Message</label>
                      <textarea
                        className="input-field textarea transition duration-300 ease w-full border rounded-xl border-slate-700/40 bg-transparent px-3 py-10 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:border-blue-500"
                        id="message"
                        // placeholder="Your Message"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        // required
                      />
                    </div>

                    <button
                      className="flex items-center justify-center px-4 py-2 text-sm md:text-md font-semibold transition duration-300 bg-yellow-600 text-white rounded-3xl hover:bg-yellow-700"
                      type="submit"
                      disabled={loading}
                    >
                      {loading && <ClipLoader size={20} color="white" />}
                      Send Message
                      <span>
                        <ChevronsRight className="w-4 h-4 md:w-5 md:h-5 ml-2" />
                      </span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mt-10 border p-10">
            <form action="">
              <InputField label="your name" name="name" type="text" />
              <InputField
                label="phone number"
                name="phoneNumber"
                type="number"
              />
              <InputField label="subject" name="subject" type="text" />
              <InputField
                label="your message"
                name="message"
                type="text-area"
              />
              <button className="flex items-center justify-center px-4 py-2 text-sm md:text-md font-semibold transition duration-300 bg-yellow-600 text-white rounded-3xl hover:bg-yellow-700">
                <span>Send Message</span>
                <span>
                  <ChevronsRight className="w-4 h-4 md:w-5 md:h-5 ml-2" />
                </span>
              </button>
            </form>
          </div> */}
        </div>
        <div className="w-[100%] mx-auto flex items-center justify-center">
          <div className="relative w-[20rem] ">
            <span className="absolute -top-7 -left-1">
              <img src="/images/vector.png" alt="" />
            </span>

            <AliceCarousel
              disableDotsControls
              responsive={{
                0: {
                  items: 1,
                },
                800: {
                  items: 1,
                },
                1024: {
                  items: 1,
                },
                1200: {
                  items: 1,
                },
              }}
              mouseTracking
              renderPrevButton={() => (
                <div className="flex items-center justify-end">
                  <ArrowLeft className="text-primary-btnColor cursor-pointer" />
                </div>
              )}
              renderNextButton={() => (
                <div className="flex items-center justify-start">
                  <ArrowRight className="text-primary-btnColor cursor-pointer" />
                </div>
              )}
            >
              {publicationData.map((item, index) => (
                <div
                  className="w-[90%] h-[90%] p-5 flex items-center justify-center"
                  key={index}
                >
                  <img
                    src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
                      item.image
                    }`}
                    alt=""
                    className="w-full h-full object-contain"
                  />
                </div>
              ))}
            </AliceCarousel>

            <span className="absolute transform rotate-180 bottom-4 -right-1">
              <img src="/images/vector.png" alt="" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicationPage;
