/* eslint-disable no-unused-vars */
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import { useParams } from "react-router-dom";
import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";
import Loader from "../../../components/loader/Loader";
import DataFetchingError from "../../errorPage/DataFetchingError";
import EventMemberForm from "../../../components/forms/postForms/InvolvementAchievementsForm/InvolvementAchievementsForm";
import EventSponsorForm from "../../../components/forms/postForms/eventSponsorForm/EventSponsorForm";

const EditEventSponsor = () => {
  const { id } = useParams();
  const { dataDetails, loading, error } = useFetchDataDetails(
    "event-sponsors",
    id
  );

  return (
    <Container
      breadcumbMenu='Event Sponsor'
      breadcumbSubMenu='edit Event Sponsor'
      title='edit Event Sponsor'
      btnTitle='back to Event Sponsor'
      path='/admin/event-sponsors'
    >
      <FormContainer heading='edit Event Sponsor'>
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <EventSponsorForm defaultData={dataDetails} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditEventSponsor;
