import Container from "../../components/container/Container";
import DataNotFound from "../errorPage/DataNotFound";
import Loader from "../../components/loader/Loader";
import DataFetchingError from "../errorPage/DataFetchingError";
import { useFetchData } from "../../hooks/useFetchData/useFetchData";
import ManageUsersTable from "../../components/forms/tables/ManageUsersTable";

const ManageUsers = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: "access/users",
  });
  return (
    <Container
      breadcumbMenu="Manage Users"
      title="Manage Users"
      btnTitle="Add User"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refetchData={refetchData} />
      ) : data.length > 0 ? (
        <ManageUsersTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default ManageUsers;
