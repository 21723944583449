import Container from '../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
import ArticleForm from '../../../components/forms/postForms/articleForm/ArticleForm';
import { useParams } from 'react-router-dom';
import useFetchDataDetails from '../../../hooks/useFetchDataDetail/useFetchDataDetails';
import Loader from '../../../components/loader/Loader';
import DataFetchingError from '../../errorPage/DataFetchingError';
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';

const EditArticle = () => {
  const { id } = useParams();
  //   custom hook for fetching data detaails from api with 2 parameters (endpoint and id)
  const { dataDetails, loading, error } = useFetchDataDetails('articles', id);
  // const { data } = useFetchData({
  //   endpoint: 'article',
  // });

  const { data } = useFetchData({
    endpoint: 'article/categories',
  });

  return (
    <Container
      breadcumbMenu="Article"
      breadcumbSubMenu="edit Article"
      title="Edit Article"
      btnTitle="back to Article"
      path="/admin/articles"
    >
      <FormContainer heading="edit Article">
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <ArticleForm defaultData={dataDetails} categoryData={data} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditArticle;
