import Container from "../../components/container/Container";
import DataNotFound from "../errorPage/DataNotFound";
import InvolvementBannersTable from "../../components/forms/tables/InvolvementBannersTable";
import Loader from "../../components/loader/Loader";

import { useFetchData } from "../../hooks/useFetchData/useFetchData";

const InvolvementBanners = () => {
  const {
    data: achievementData,
    loading,
    error,
    refetchData,
  } = useFetchData({ endpoint: "involvement-banners" });

  return (
    <Container
      breadcumbMenu="involvement-banners "
      title="involvement banners "
      btnTitle="Add involvement-banners"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <p>Error: {error}</p>
      ) : achievementData.length > 0 ? (
        <InvolvementBannersTable data={achievementData} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default InvolvementBanners;
