import HerosectionCarousel from "../components/common/HerosectionCaarousel";
import AboutMe from "../components/aboutpage/Aboutme";
import Affiliations from "../components/aboutpage/Affiliations";
import Publications from "../components/aboutpage/Publications";
import PapersPresented from "../components/aboutpage/PapersPresented";
import LatestArticle from "../components/aboutpage/LatestArticles";
import { useFetchData } from "../hooks/useFetchData/useFetchData";
// import { useState } from "react";

const myHeroImages = [
  {
    image: "/images/AboutBg.png",
  },
  {
    image: "/images/Herosectionimg.png",
  },
  {
    image: "images/Herosectionimg.png",
  },
];



const AboutPage = () => {
  const { data } = useFetchData({ endpoint: "abouts" });
  const { data: affiliationData } = useFetchData({ endpoint: "affiliations" });
  const { data: publicationData } = useFetchData({ endpoint: "publications" });
  const { data: paperPresentData } = useFetchData({ endpoint: "paper/presents"});
  const { data: articleData } = useFetchData({ endpoint: "articles" });

  return (
    <>
      <container>
        <HerosectionCarousel myHeroImages={myHeroImages} />
        <div>
          <AboutMe dataDetails={data}/>
        </div>
      <Affiliations affiliationData={affiliationData} />
      <Publications publicationData={publicationData} />
      <PapersPresented paperPresentData={paperPresentData} />
        <LatestArticle articleData={articleData} />
        </container>
    </>
  );
};

export default AboutPage;
