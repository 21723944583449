import { useState } from "react";
import { useForm } from "react-hook-form";
import CustomInputField from "../../customInputField/CustomInputField";
import CustomButton from "../../button/CustomButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAddData } from "../../../../hooks/useAddData/useAddData";
import { useEditData } from "../../../../hooks/useEdit/useEdit";
import CustomLink from "../../customLink/CustomLink";
import PropTypes from "prop-types";
import { zodResolver } from "@hookform/resolvers/zod";
import { involvementAchievementSchema } from "../../../../zodSchema/homeSchema/homeSchema";

const InvolvementAchievementsForm = ({ defaultData, involvementData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    // setValue,
    // control,
  } = useForm({
    defaultValues: {
      title: defaultData?.title || "",
      content: defaultData?.content || "",
      involvement: defaultData?.involvement || "",
      image: defaultData?.image || null,
    },
    resolver: zodResolver(involvementAchievementSchema),
  });

  const navigate = useNavigate();
  const { addData, isLoading: isAdding, error: addError } = useAddData();
  const { editData, isLoading: isEditing, error: editError } = useEditData();

  const [image, setImage] = useState(defaultData?.image || null);
  const [imageFile, setImageFile] = useState(null);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("involvement", data.involvement);
    if (imageFile) {
      formData.append("image", imageFile[0]);
    }

    try {
      if (defaultData) {
        // Editing existing data
        await editData(formData, "involvement-achievements", defaultData.id);
        toast.success("Data updated successfully!");
      } else {
        // Adding new data
        await addData(formData, "involvement-achievements");
        toast.success("Data added successfully!");
      }
      navigate("/admin/involvement-achievements");
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(e.target.files);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2 gap-5 mb-2">
        <CustomInputField
          label="Title:"
          type="text"
          name="title"
          error={errors?.title?.message}
          {...register("title")}
        />
        <CustomInputField
          label="Content:"
          type="text"
          name="content"
          error={errors?.content?.message}
          {...register("content")}
        />
        <div className="w-full">
          <label
            htmlFor="involvement"
            className="capitalize text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Involvement
          </label>
          <select
            id="involvement"
            name="involvement"
            {...register("involvement")}
            className={`capitalize transition duration-300 ease flex w-full border-2 border-slate-700/40 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:border-blue-500`}
          >
            <option value="">Select Involvement</option>
            {involvementData?.map((option) => (
              <option key={option._id} value={option._id}>
                {option.title}
              </option>
            ))}
          </select>
          {errors?.involvement?.message && (
            <span className="text-red-500 text-base">
              {errors?.involvement?.message}
            </span>
          )}
        </div>

        <div className="flex items-center gap-10">
          <div>
            <label className="block text-sm font-medium text-gray-700">Featured Image:</label>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
              className="mt-1 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
            />
          </div>
          {image && (
            <div className="w-40 mb-5">
              <img
                className="w-full h-full object-cover"
                src={typeof image === 'string' ? `${import.meta.env.VITE_APP_BASE_URL}/uploads/${image}` : image}
                alt="Uploaded"
              />
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-5 mt-5">
        <CustomLink
          path="/admin/involvement-achievements"
          btnTitle="Back"
          className="w-full text-center"
        />
        <CustomButton type="submit" disabled={isAdding || isEditing}>
          {isAdding || isEditing ? "Processing..." : "Submit"}
        </CustomButton>
      </div>

      {(addError || editError) && (
        <p className="text-red-500 mt-2">{addError || editError}</p>
      )}
    </form>
  );
};

InvolvementAchievementsForm.propTypes = {
  defaultData: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    involvement: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string,
  }),
  involvementData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

export default InvolvementAchievementsForm;








// import { useForm } from "react-hook-form";
// import CustomInputField from "../../customInputField/CustomInputField";
// import CustomButton from "../../button/CustomButton";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { useAddData } from "../../../../hooks/useAddData/useAddData";
// import { useEditData } from "../../../../hooks/useEdit/useEdit";
// import CustomLink from "../../customLink/CustomLink";
// import PropTypes from "prop-types";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { involvementAchievementSchema } from "../../../../zodSchema/homeSchema/homeSchema";

// const InvolvementAchievementsForm = ({ defaultData, involvementData }) => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     setValue,
//     control,
//   } = useForm({
//     defaultValues: {
//       title: defaultData?.title || "",
//       content: defaultData?.content || "",
//       involvement: defaultData?.involvement || "",
//       image: defaultData?.image || null,
//     },
//     resolver: zodResolver(involvementAchievementSchema),
//   });

//   const navigate = useNavigate();
//   const { addData, isLoading: isAdding, error: addError } = useAddData();
//   const { editData, isLoading: isEditing, error: editError } = useEditData();

//   const onSubmit = async (data) => {
//     try {
//       if (defaultData) {
//         // Editing existing data
//         await editData(data, "involvement-achievements", defaultData._id);
//         toast.success("Data updated successfully!");
//       } else {
//         // Adding new data
//         await addData(data, "involvement-achievements");
//         toast.success("Data added successfully!");
//       }
//       navigate("/admin/involvement-achievements");
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <div className="grid grid-cols-2 gap-5 mb-2">
//         <CustomInputField
//           label="Title:"
//           type="text"
//           name="title"
//           error={errors?.title?.message}
//           {...register("title")}
//         />
//         <CustomInputField
//           label="Content:"
//           type="text"
//           name="content"
//           error={errors?.content?.message}
//           {...register("content")}
//         />
//         <div className="w-full">
//           <label
//             htmlFor="involvement"
//             className="capitalize text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
//           >
//             Involvement
//           </label>
//           <select
//             id="involvement"
//             name="involvement"
//             {...register("involvement")}
//             className={`capitalize transition duration-300 ease flex w-full border-2 border-slate-700/40 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:border-blue-500`}
//           >
//             <option value="">Select Involvement</option>
//             {involvementData?.map((option) => (
//               <option key={option._id} value={option._id}>
//                 {option.title}
//               </option>
//             ))}
//           </select>
//           {errors?.involvement?.message && (
//             <span className="text-red-500 text-base">
//               {errors?.involvement?.message}
//             </span>
//           )}
//         </div>

//         <div className="flex items-center gap-10">
//           <CustomInputField
//             label="Featured Image:"
//             type="file"
//             name="image"
//             control={control}
//             onChange={(e) => setValue('image', e.target.files)}
//           />
//           {defaultData?.image && (
//             <div className="w-40 mb-5">
//              <img
//               className="w-full h-full object-cover"
//               src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${defaultData?.image}`}
//               alt=""
//             />
//             </div>
//           )}
//         </div>
//       </div>

//       <div className="grid grid-cols-2 gap-5 mt-5">
//         <CustomLink
//           path="/admin/involvement-achievements"
//           btnTitle="Back"
//           className="w-full text-center"
//         />
//         <CustomButton type="submit" disabled={isAdding || isEditing}>
//           {isAdding || isEditing ? "Processing..." : "Submit"}
//         </CustomButton>
//       </div>

//       {(addError || editError) && (
//         <p className="text-red-500 mt-2">{addError || editError}</p>
//       )}
//     </form>
//   );
// };

// InvolvementAchievementsForm.propTypes = {
//   defaultData: PropTypes.shape({
//     title: PropTypes.string,
//     content: PropTypes.string,
//     involvement: PropTypes.string,
//     id: PropTypes.string,
//     image: PropTypes.string,
//   }),
//   involvementData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string,
//       title: PropTypes.string,
//     })
//   ),
// };
// export default InvolvementAchievementsForm;










// import { useForm } from "react-hook-form";
// import CustomInputField from "../../customInputField/CustomInputField";
// import CustomButton from "../../button/CustomButton";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { useAddData } from "../../../../hooks/useAddData/useAddData";
// import { useEditData } from "../../../../hooks/useEdit/useEdit";
// import CustomLink from "../../customLink/CustomLink";
// import PropTypes from "prop-types";
// import { useFetchData } from "../../../../hooks/useFetchData/useFetchData";
// import SelectField from "../../selectField/SelectField";
// // import { ProductSchema } from "../../../../zodSchema/productSchema/ProductSchema";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { eventMemberSchema } from "../../../../zodSchema/eventMemberSchema/eventMemberSchema";

// const EventMemberForm = ({ defaultData }) => {
//   const {
//     register,
//     handleSubmit,

//     formState: { errors },
//   } = useForm({
//     defaultValues: {
//       memberId: defaultData?.memberId || "",
//       eventId: defaultData?.eventId || "",
//       position: defaultData?.position || "",
//       category: defaultData?.category || "",
//     },
//     resolver: zodResolver(eventMemberSchema),
//   });

//   const navigate = useNavigate();
//   const { data: members, isLoading: isProductCatLoading } = useFetchData({
//     endpoint: "members",
//   });
//   const { data: events, isLoading: isColorsLoading } = useFetchData({
//     endpoint: "events",
//   });
//   const { addData, isLoading: isAdding, error: addError } = useAddData();
//   const { editData, isLoading: isEditing, error: editError } = useEditData();
//   const category = ["event", "coordinator", "member"];

//   if (isProductCatLoading || isColorsLoading) {
//     return <div>Loading...</div>;
//   }

//   const onSubmit = async (data) => {
//     console.log(data, "this is data");
//     // const formData = new FormData();
//     // console.log(data, ": thjis is form data");
//     // formData.append("position", data?.position);
//     // formData.append("category", data?.category);
//     // formData.append("memberId", data?.memberId);
//     // formData.append("eventId", data?.eventId);
//     try {
//       if (defaultData) {
//         // Editing existing data
//         await editData(data, "event-members", defaultData.id);
//         toast.success("Data updated successfully!");
//       } else {
//         // Adding new data
//         await addData(data, "event-members");
//         toast.success("Data added successfully!");
//       }
//       navigate("/admin/involvement-achievements");
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <div className='grid grid-cols-3 gap-5 mb-2'>
//         <div className='w-full'>
//           <label
//             htmlFor='memberId'
//             className='capitalize text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
//           >
//             Member
//           </label>

//           <select
//             id='memberId'
//             name='memberId'
//             {...register("memberId")}
//             className={`capitalize transition duration-300 ease flex w-full border-2 border-slate-700/40 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:border-blue-500 `}
//           >
//             <option value=''> select Members </option>
//             {members?.map((option) => (
//               <option key={option.id} value={option.id}>
//                 {option.name}
//               </option>
//             ))}
//           </select>
//           {errors?.memberId?.message && (
//             <span className='text-red-500 text-base'>
//               {errors?.memberId?.message}
//             </span>
//           )}
//         </div>
//         <div className='w-full'>
//           <label
//             htmlFor='eventId'
//             className='capitalize text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
//           >
//             events
//           </label>

//           <select
//             id='eventId'
//             name='eventId'
//             {...register("eventId")}
//             className={`capitalize transition duration-300 ease flex w-full border-2 border-slate-700/40 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:border-blue-500 `}
//           >
//             <option value=''> select events </option>
//             {events?.map((option) => (
//               <option key={option.id} value={option.id}>
//                 {option.title}
//               </option>
//             ))}
//           </select>

//           {errors?.eventId?.message && (
//             <span className='text-red-500 text-base'>
//               {errors?.eventId?.message}
//             </span>
//           )}
//         </div>
//         <SelectField
//           options={category}
//           {...register("category")}
//           error={errors?.category?.message}
//           label='category:'
//         />
//       </div>
//       <CustomInputField
//         label='position:'
//         type='text'
//         name='position'
//         error={errors?.position?.message}
//         {...register("position")}
//       />
//       {/* <CustomInputField
//         label='category:'
//         type='text'
//         name='category'
//         error={errors?.category?.message}
//         {...register("category")}
//       /> */}

//       <div className='grid grid-cols-2  gap-5 mt-5'>
//         <CustomLink
//           path='/admin/involvement-achievements'
//           btnTitle='Back'
//           className='w-full text-center '
//         />
//         <CustomButton type='submit' disabled={isAdding || isEditing}>
//           {isAdding || isEditing ? "Processing..." : "Submit"}
//         </CustomButton>
//       </div>
//       {(addError || editError) && (
//         <p className='text-red-500 mt-2'>{addError || editError}</p>
//       )}
//     </form>
//   );
// };

// EventMemberForm.propTypes = {
//   defaultData: PropTypes.shape({
//     memberId: PropTypes.string,
//     eventId: PropTypes.string,
//     position: PropTypes.string,
//     category: PropTypes.string,
//     id: PropTypes.string,
//   }),
// };

// export default EventMemberForm;
