export const formatDate = (dateString) => {
    if (!dateString) {
      return ""; // or any default value you prefer for undefined input
    }
  
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
  
    return date.toLocaleDateString("en-US", options);
  };
  