import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import SponsorForm from "../../../components/forms/postForms/sponsorFrom/SponsorForm";

const AddSponsor = () => {
  return (
    <Container
      breadcumbMenu='Sponsor'
      breadcumbSubMenu='new Sponsor'
      title='New Sponsor'
      btnTitle='back to Sponsor'
      path='/admin/sponsors'
    >
      <FormContainer heading='Add product'>
        <SponsorForm />
      </FormContainer>
    </Container>
  );
};

export default AddSponsor;
