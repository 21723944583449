// import React, { useEffect, useState } from 'react';
import Container from './../../components/container/Container';
// import axios from 'axios';
import DataNotFound from './../errorPage/DataNotFound';
import PaperPresentTable from '../../components/forms/tables/PaperPresentTable';
import Loader from '../../components/loader/Loader';
import DataFetchingError from '../errorPage/DataFetchingError';
import { useFetchData } from '../../hooks/useFetchData/useFetchData';

const PaperPresent = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: 'paper/presents',
  });
  return (
    <Container
      breadcumbMenu="paper present"
      title="Paper Presents"
      btnTitle="Add paper present"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refetchData={refetchData} />
      ) : data.length > 0 ? (
        <PaperPresentTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default PaperPresent;
