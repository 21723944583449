import PropTypes from 'prop-types';

const Heading = ({ heading, className = '' }) => {
  return (
    <h1
      className={`text-primary-headingColor font-bold capitalize font-inter text-center ${className} text-2xl md:text-3xl lg:text-3xl xl:text-4xl`}
    >
      {heading}
    </h1>
  );
};
Heading.propTypes = {
  heading: PropTypes.string.isRequired,
  className: PropTypes.string,
};
export default Heading;
