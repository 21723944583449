import Slider from 'react-slick';
import { ChevronRight } from 'lucide-react';
import { formatDate } from '../../utility/dateFormates';
import { Link } from 'react-router-dom';
import Heading from '../common/Heading';
import PropTypes from 'prop-types';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Initiatives = ({ initiativesData }) => {

  const ArrowLeft = (props) => (
    <div
      {...props}
      className="absolute bottom-2 right-1/2 transform -translate-x-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-12 pt-12 items-center justify-center"
    >
      <ChevronRight className="rotate-180" />
    </div>
  );

  const ArrowRight = (props) => (
    <div
      {...props}
      className="absolute bottom-2 left-1/2 transform -translate-x-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-12 pt-12 items-center justify-center"
    >
      <ChevronRight />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="px-8 md:px-20 lg:px-28 xl:px-36">
      <Heading heading="Initiatives" />
      <div className="py-16">
        <Slider {...settings}>
          {initiativesData.map((item) => (
            <div key={item._id} className="p-4">
              <div className="flex items-center md:h-[48vh] justify-between w-full mb-4 flex-col md:flex-row">
                <div className="w-full md:w-[55%] lg:w-[45%] h-[45vh] md:h-[35vh] lg:h-full relative mb-12 md:mb-0">
                  <img
                    src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
                    alt="initiatives"
                    className="w-full md:w-[92%] lg:w-[90%] h-full object-center object-cover md:object-fill lg:object-cover"
                  />
                  <div className="bg-white rounded-full h-24 w-24 shadow-lg absolute top-[85%] md:top-1/3 right-[40%] md:right-[-4%] lg:right-0 flex items-center justify-center">
                    <img
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
                      alt="logo"
                      className="h-20 w-20 rounded-full cursor-pointer rotate transition duration-500 ease-in-out"
                    />
                  </div>
                </div>
                <div className="md:ml-8 lg:ml-12 md:w-[60%] pb-8">
                  <h1 className="text-xl font-semibold capitalize font-inter text-primary-headingColor pt-4 md:pt-0">
                    {item.title}
                  </h1>
                  <h1 className="font-mular text-base font-bold py-4">
                    Established Date: {formatDate(item.establishedDate)}
                  </h1>
                  <p
                    className="text-base text-justify font-mulish"
                    style={{
                      lineHeight: '28px',
                    }}
                  >
                    {item.description}
                  </p>
                  <Link
                    to={`/initiatives/${item._id}`}
                    className="w-fit text-white font-mulish text-sm bg-primary-btnColor transition duration-300 ease-in-out hover:text-black hover:border hover:border-primary-btnColor px-3 py-2 rounded-full hover:bg-white flex items-center mt-8 "
                  >
                    More Info
                    <ChevronRight className="w-4 h-4 ml-1 " />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

Initiatives.propTypes = {
  initiativesData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      establishedDate: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Initiatives;




// import { useState } from 'react';
// import Heading from '../common/Heading';
// import { ChevronRight } from 'lucide-react';
// import { formatDate } from '../../utility/dateFormates';
// import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

// const Initiatives = ({ initiativesData }) => {
//   const [visibleItems, setVisibleItems] = useState(1);

//   const handleViewMore = () => {
//     setVisibleItems((prev) => prev + 1);
//   };

//   const handleViewLess = () => {
//     setVisibleItems(1);
//   };

//   return (
//     <div className="px-8 md:px-20 lg:px-28 xl:px-36">
//       <Heading heading="Initiatives" />
//       <div className="py-16">
//         {initiativesData.slice(0, visibleItems).map((item) => (
//           <div
//             key={item._id}
//             className="flex items-center md:h-[48vh] justify-between w-full mb-4 flex-col md:flex-row"
//           >
//             <div className="w-full md:w-[55%] lg:w-[45%] h-[45vh] md:h-[35vh] lg:h-full relative mb-12 md:mb-0">
//               <img
//                 src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
//                 alt="initiatives"
//                 className="w-full md:w-[92%] lg:w-[90%] h-full object-center object-cover md:object-fill lg:object-cover"
//               />
//               <div className="bg-white rounded-full h-24 w-24 shadow-lg absolute top-[85%] md:top-1/3 right-[40%] md:right-[-4%] lg:right-0 flex items-center justify-center">
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                   alt="logo"
//                   className="h-20 w-20 rounded-full cursor-pointer rotate transition duration-500 ease-in-out"
//                 />
//               </div>
//             </div>
//             <div className="md:ml-8 lg:ml-12 md:w-[60%]">
//               <h1 className="text-xl font-semibold capitalize font-inter text-primary-headingColor pt-4 md:pt-0">
//                 {item.title}
//               </h1>
//               <h1 className="font-mular text-base font-bold py-4">
//                 Established Date: {formatDate(item.establishedDate)}
//               </h1>
//               <p
//                 className="text-base text-justify font-mulish"
//                 style={{
//                   lineHeight: '28px',
//                 }}
//               >
//                 {item.description}
//               </p>
//               <Link
//                 to={`/initiatives/${item._id}`}
//                 className="w-fit text-white font-mulish text-sm bg-primary-btnColor transition duration-300 ease-in-out hover:text-black hover:border hover:border-primary-btnColor px-3 py-2 rounded-full hover:bg-white flex items-center mt-8"
//               >
//                 More Info
//                 <ChevronRight className="w-4 h-4 ml-1" />
//               </Link>
//             </div>
//           </div>
//         ))}
//         <div className="flex justify-center mt-8">
//           {visibleItems < initiativesData.length && (
//             <button
//               onClick={handleViewMore}
//               className="w-fit text-white font-mulish text-sm transition duration-300 ease-in-out bg-primary-btnColor hover:text-white px-3 py-2 rounded-full hover:border-primary-btnColor flex items-center"
//             >More
//               <ChevronRight className="w-4 h-4 ml-1" />
//             </button>
//           )}
//           {visibleItems > 1 && (
//             <button
//               onClick={handleViewLess}
//               className="w-fit text-white font-mulish text-sm transition duration-300 ease-in-out bg-primary-btnColor hover:text-white px-3 py-2 rounded-full hover:border-primary-btnColor flex items-center ml-4"
//             >
//               Less
//               <ChevronRight className="w-4 h-4 ml-1" />
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// Initiatives.propTypes = {
//   initiativesData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       image: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       establishedDate: PropTypes.string.isRequired,
//       description: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };

// export default Initiatives;


























// // import AliceCarousel from 'react-alice-carousel';
// // import 'react-alice-carousel/lib/alice-carousel.css';
// // import { ArrowLeft, ArrowRight } from 'lucide-react';
// import Heading from '../common/Heading';
// import { ChevronRight } from 'lucide-react';
// import { formatDate } from '../../utility/dateFormates';
// import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

// // interface Initiative {
// //   _id: string;
// //   title: string;
// //   image: string;
// //   logo: string;
// //   establishedDate: string;
// //   description: string;
// // }

// // interface InitiativesProps {
// //   initiativesData: Initiative[];
// // }

// const Initiatives = ({ initiativesData }) => {
//   return (
//     <div className="px-8 md:px-20 lg:px-28 xl:px-36">
//       <Heading heading="Initiatives" />
//       <div className="py-16">
//         {/* <AliceCarousel
//           mouseTracking
//           disableDotsControls
//           renderPrevButton={() => (
//             <div className="flex items-center justify-end">
//               <ArrowLeft className="text-primary-btnColor cursor-pointer" />
//             </div>
//           )}
//           renderNextButton={() => (
//             <div className="flex items-center justify-start">
//               <ArrowRight className="text-primary-btnColor cursor-pointer" />
//             </div>
//           )}
//         > */}
//           {initiativesData.map((item) => (
//             <div
//               key={item._id}
//               className="flex items-center md:h-[48vh] justify-between w-full mb-4 flex-col md:flex-row"
//             >
//               <div className="w-full md:w-[55%] lg:w-[45%] h-[45vh] md:h-[35vh] lg:h-full relative mb-12 md:mb-0">
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
//                     item.image
//                   }`}
//                   alt="initiatives"
//                   className="w-full md:w-[92%] lg:w-[90%] h-full  object-center object-cover md:object-fill lg:object-cover"
//                 />
//                 <div className="bg-white rounded-full h-24 w-24 shadow-lg absolute top-[85%] md:top-1/3 right-[40%] md:right-[-4%] lg:right-0 flex items-center justify-center">
//                   <img
//                     src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
//                       item.logo
//                     }`}
//                     alt="logo"
//                     className="h-20 w-20 rounded-full  cursor-pointer rotate transition duration-500 ease-in-out"
//                   />
//                 </div>
//               </div>
//               <div className="md:ml-8 lg:ml-12 md:w-[60%]">
//                 <h1 className="text-xl font-semibold capitalize font-inter text-primary-headingColor pt-4 md:pt-0">
//                   {item.title}
//                 </h1>
//                 <h1 className="font-mular text-base font-bold py-4">
//                   Established Date: {formatDate(item.establishedDate)}
//                 </h1>
//                 <p
//                   className="text-base text-justify font-mulish"
//                   style={{
//                     lineHeight: '28px',
//                     overflow: 'hidden',
//                     display: '-webkit-box',
//                     WebkitBoxOrient: 'vertical',
//                     WebkitLineClamp: 5,
//                   }}
//                 >
//                   {item.description}
//                 </p>
//                 <Link
//                   to={`/initiatives/${item._id}`}
//                   className="w-fit text-white font-mulish text-sm bg-primary-btnColor transition duration-300 ease-in-out hover:text-black hover:border hover:border-primary-btnColor px-3 py-2 rounded-full hover:bg-white flex items-center mt-8"
//                 >
//                   More Info
//                   <ChevronRight className="w-4 h-4 ml-1" />
//                 </Link>
//               </div>
//             </div>
//           ))}
//         {/* </AliceCarousel> */}
//       </div>
//     </div>
//   );
// };
// Initiatives.propTypes = {
//   initiativesData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       image: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       establishedDate: PropTypes.string.isRequired,
//       description: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };
// export default Initiatives;
















































// import { useState, useEffect } from 'react';
// import { ChevronRight } from 'lucide-react';
// import Heading from '../common/Heading';
// import { formatDate } from '../../utility/dateFormates';
// import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
// import 'swiper/css';
// import 'swiper/css/navigation';
// // import 'swiper/swiper-bundle.min.css';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from 'swiper';

// const Initiatives = ({ initiativesData }) => {
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       await new Promise(resolve => setTimeout(resolve, 500)); // Simulating network delay
//       if (initiativesData && initiativesData.length > 0) {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, [initiativesData]);

//   if (loading) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <div className="loader">Loading...</div>
//       </div>
//     );
//   }

//   return (
//     <div className="px-8 md:px-20 lg:px-28 xl:px-36">
//       <Heading heading="Initiatives" />
//       <div className="py-16">
//         <Swiper
//           modules={[Navigation]}
//           navigation={{
//             nextEl: '.swiper-button-next',
//             prevEl: '.swiper-button-prev',
//           }}
//           slidesPerView={1}
//           spaceBetween={30}
//           loop={initiativesData.length > 1}
//           autoplay={{
//             delay: 3000,
//             disableOnInteraction: false,
//           }}
//           className="swiper-container"
//         >
//           {initiativesData.map((item) => (
//             <SwiperSlide key={item._id}>
//               <div className="flex items-center md:h-[48vh] justify-between w-full mb-4 flex-col md:flex-row">
//                 <div className="w-full md:w-[55%] lg:w-[45%] h-[45vh] md:h-[35vh] lg:h-full relative mb-12 md:mb-0">
//                   <img
//                     src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
//                     alt="initiatives"
//                     className="w-full md:w-[92%] lg:w-[90%] h-full object-center object-cover md:object-fill lg:object-cover"
//                   />
//                   <div className="bg-white rounded-full h-24 w-24 shadow-lg absolute top-[85%] md:top-1/3 right-[40%] md:right-[-4%] lg:right-0 flex items-center justify-center">
//                     <img
//                       src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                       alt="logo"
//                       className="h-20 w-20 rounded-full cursor-pointer rotate transition duration-500 ease-in-out"
//                     />
//                   </div>
//                 </div>
//                 <div className="md:ml-8 lg:ml-12 md:w-[60%]">
//                   <h1 className="text-xl font-semibold capitalize font-inter text-primary-headingColor pt-4 md:pt-0">
//                     {item.title}
//                   </h1>
//                   <h1 className="font-mular text-base font-bold py-4">
//                     Established Date: {formatDate(item.establishedDate)}
//                   </h1>
//                   <p
//                     className="text-base text-justify font-mulish"
//                     style={{
//                       lineHeight: '28px',
//                       overflow: 'hidden',
//                       display: '-webkit-box',
//                       WebkitBoxOrient: 'vertical',
//                       WebkitLineClamp: 5,
//                     }}
//                   >
//                     {item.description}
//                   </p>
//                   <Link
//                     to={`/initiatives/${item._id}`}
//                     className="w-fit text-white font-mulish text-sm bg-primary-btnColor transition duration-300 ease-in-out hover:text-black hover:border hover:border-primary-btnColor px-3 py-2 rounded-full hover:bg-white flex items-center mt-8"
//                   >
//                     More Info
//                     <ChevronRight className="w-4 h-4 ml-1" />
//                   </Link>
//                 </div>
//               </div>
//             </SwiperSlide>
//           ))}
//           <div className="swiper-button-next text-primary-btnColor cursor-pointer text-2xl">
//             <FaArrowRight />
//           </div>
//           <div className="swiper-button-prev text-primary-btnColor cursor-pointer text-2xl">
//             <FaArrowLeft />
//           </div>
//         </Swiper>
//       </div>
//     </div>
//   );
// };

// Initiatives.propTypes = {
//   initiativesData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       image: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       establishedDate: PropTypes.string.isRequired,
//       description: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };

// export default Initiatives;




// import { useState, useEffect } from 'react';
// import { ChevronRight } from 'lucide-react';
// import Heading from '../common/Heading';
// import { formatDate } from '../../utility/dateFormates';
// import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import Slider from "react-slick";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// const Initiatives = ({ initiativesData }) => {
//   const [loading, setLoading] = useState(true);

//   const ArrowLeft = (props) => (
//     <div
//       {...props}
//       className="absolute bottom-4 right-1/2 transform translate-x-2 translate-y-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-8 pt-12"
//     >
//       <FaArrowLeft />
//     </div>
//   );

//   const ArrowRight = (props) => (
//     <div
//       {...props}
//       className="absolute bottom-4 left-1/2 transform translate-x-6 translate-y-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-8 pt-12"
//     >
//       <FaArrowRight />
//     </div>
//   );

//   useEffect(() => {
//     const fetchData = async () => {
//       await new Promise(resolve => setTimeout(resolve, 500)); // Simulating network delay
//       if (initiativesData && initiativesData.length > 0) {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [initiativesData]);

//   if (loading) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <div className="loader">Loading...</div>
//       </div>
//     );
//   }

//   const settings = {
//     dots: false,
//     infinite: initiativesData.length > 1,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: initiativesData.length > 1,
//     autoplaySpeed: 3000,
//     nextArrow: <ArrowRight />,
//     prevArrow: <ArrowLeft />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="px-8 md:px-20 lg:px-28 xl:px-36">
//       <Heading heading="Initiatives" />
//       <div className="py-16">
//         <Slider {...settings}>
//           {initiativesData.map((item) => (
//             <div
//               key={item._id}
//               className="flex items-center justify-center w-full mb-4 flex-col md:flex-col gap-8"
//             >
//               <div className="w-full md:w-[55%] lg:w-[45%] h-[45vh] md:h-[35vh] lg:h-full relative mb-12 md:mb-0">
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
//                   alt="initiatives"
//                   className="w-full md:w-[92%] lg:w-[90%] h-full object-center object-cover md:object-fill lg:object-cover"
//                 />
//                 <div className="bg-white rounded-full h-24 w-24 shadow-lg absolute top-[85%] md:top-1/3 right-[40%] md:right-[-4%] lg:right-0 flex items-center justify-center">
//                   <img
//                     src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                     alt="logo"
//                     className="h-20 w-20 rounded-full cursor-pointer rotate transition duration-500 ease-in-out"
//                   />
//                 </div>
//               </div>
//               <div className="md:ml-8 lg:ml-12 md:w-[60%]">
//                 <h1 className="text-xl font-semibold capitalize font-inter text-primary-headingColor pt-4 md:pt-0">
//                   {item.title}
//                 </h1>
//                 <h1 className="font-mular text-base font-bold py-4">
//                   Established Date: {formatDate(item.establishedDate)}
//                 </h1>
//                 <p
//                   className="text-base text-justify font-mulish"
//                   style={{
//                     lineHeight: '28px',
//                     overflow: 'hidden',
//                     display: '-webkit-box',
//                     WebkitBoxOrient: 'vertical',
//                     WebkitLineClamp: 5,
//                   }}
//                 >
//                   {item.description}
//                 </p>
//                 <Link
//                   to={`/initiatives`}
//                   className="w-fit text-white font-mulish text-sm bg-primary-btnColor transition duration-300 ease-in-out hover:text-black hover:border hover:border-primary-btnColor px-3 py-2 rounded-full hover:bg-white flex items-center mt-8"
//                 >
//                   More Info
//                   <ChevronRight className="w-4 h-4 ml-1" />
//                 </Link>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// Initiatives.propTypes = {
//   initiativesData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       image: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       establishedDate: PropTypes.string.isRequired,
//       description: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };

// export default Initiatives;




































// import { useState, useEffect } from 'react';
// import {
//   // ArrowLeft,
//   // ArrowRight,
//   ChevronRight
// } from 'lucide-react';
// import Heading from '../common/Heading';
// import { formatDate } from '../../utility/dateFormates';
// import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import Slider from "react-slick";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// const Initiatives = ({ initiativesData }) => {
//   const [loading, setLoading] = useState(true);
//   const ArrowLeft = (props) => (
//     <div
//       {...props}
//       className="absolute bottom-4 right-1/2 transform translate-x-2 translate-y-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-8 pt-12"
//     >
//       <FaArrowLeft />
//     </div>
//   );

//   const ArrowRight = (props) => (
//     <div
//       {...props}
//       className="absolute bottom-4 left-1/2 transform translate-x-6 translate-y-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-8 pt-12"
//     >
//       <FaArrowRight />
//     </div>
//   );

//   useEffect(() => {
//     // Simulate data fetching delay
//     const fetchData = async () => {
//       await new Promise(resolve => setTimeout(resolve, 500)); // Simulating network delay
//       if (initiativesData && initiativesData.length > 0) {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [initiativesData]);

//   if (loading) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <div className="loader">Loading...</div>
//       </div>
//     );
//   }

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     nextArrow: <ArrowRight />,
//     prevArrow: <ArrowLeft />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="px-8 md:px-20 lg:px-28 xl:px-36">
//       <Heading heading="Initiatives" />
//       <div className="py-16">
//       <Slider {...settings}>
//           {initiativesData.map((item) => (
//             <div
//               key={item._id}
//               className="flex items-center justify-center w-full mb-4 flex-col md:flex-col"
//             >
//               <div className="w-full md:w-[55%] lg:w-[45%] h-[45vh] md:h-[35vh] lg:h-full relative mb-12 md:mb-0">
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
//                   alt="initiatives"
//                   className="w-full md:w-[92%] lg:w-[90%] h-full object-center object-cover md:object-fill lg:object-cover"
//                 />
//                 <div className="bg-white rounded-full h-24 w-24 shadow-lg absolute top-[85%] md:top-1/3 right-[40%] md:right-[-4%] lg:right-0 flex items-center justify-center">
//                   <img
//                     src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                     alt="logo"
//                     className="h-20 w-20 rounded-full cursor-pointer rotate transition duration-500 ease-in-out"
//                   />
//                 </div>
//               </div>
//               <div className="md:ml-8 lg:ml-12 md:w-[60%]">
//                 <h1 className="text-xl font-semibold capitalize font-inter text-primary-headingColor pt-4 md:pt-0">
//                   {item.title}
//                 </h1>
//                 <h1 className="font-mular text-base font-bold py-4">
//                   Established Date: {formatDate(item.establishedDate)}
//                 </h1>
//                 <p
//                   className="text-base text-justify justify-center font-mulish"
//                   style={{
//                     lineHeight: '28px',
//                     overflow: 'hidden',
//                     display: '-webkit-box',
//                     WebkitBoxOrient: 'vertical',
//                     WebkitLineClamp: 5,
//                   }}
//                 >
//                   {item.description}
//                 </p>
//                 <Link
//                   to={`/initiatives/${item._id}`}
//                   className="w-fit text-white font-mulish text-sm bg-primary-btnColor transition duration-300 ease-in-out hover:text-black hover:border hover:border-primary-btnColor px-3 py-2 rounded-full hover:bg-white flex items-center mt-8"
//                 >
//                   More Info
//                   <ChevronRight className="w-4 h-4 ml-1" />
//                 </Link>
//               </div>
//             </div>
//           ))}
//           </Slider>

//       </div>
//     </div>
//   );
// };

// Initiatives.propTypes = {
//   initiativesData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       image: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       establishedDate: PropTypes.string.isRequired,
//       description: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };

// export default Initiatives;


















