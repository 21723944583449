// import Slider from "react-slick";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
// import Heading from "./../common/Heading";
// import PropTypes from "prop-types";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// // import { Link } from 'react-router-dom';
// const Testimonials = ({ articleData }) => {
//   const ArrowLeft = (props) => (
//     <div
//       {...props}
//       className="absolute bottom-2 right-1/2 transform translate-x-2 translate-y-1/2 z-10 cursor-pointer text-1xl text-primary-btnColor mt-8 pt-12"
//     >
//       <FaArrowLeft />
//     </div>
//   );

//   const ArrowRight = (props) => (
//     <div
//       {...props}
//       className="absolute bottom-2 left-1/2 transform translate-x-6 translate-y-1/2 z-10 cursor-pointer text-1xl text-primary-btnColor mt-8 pt-12"
//     >
//       <FaArrowRight />
//     </div>
//   );

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 600,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     spaceBetween: 30,
//     nextArrow: <ArrowRight />,
//     prevArrow: <ArrowLeft />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="my-5 px-4 md:px-10 lg:px-12 xl:px-20 pt-8 flex flex-col w-full items-center">
//       <div className="p-2">
//         <Heading heading="Testimonials" />
//       </div>
//       <div className="w-[60%] max-w-screen-lg my-4">
//         <Slider {...settings} className="gap-6 p-2">
//           {articleData.map((item) => (
//             <div
//               key={item.id}
//               className="bg-white p-8 shadow-lg rounded-lg border mx-0"
//             >
//               <div className="w-full h-56">
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
//                   alt="article"
//                   className="w-full h-full object-cover rounded-md"
//                 />
//               </div>
//               <div className="mt-4">
//                 <div
//                   dangerouslySetInnerHTML={{
//                     __html: item.description,
//                   }}
//                   className="mt-2 text-justify text-gray-700"
//                   style={{
//                     lineHeight: '1.15',
//                     overflow: 'hidden',
//                     display: '-webkit-box',
//                     WebkitBoxOrient: 'vertical',
//                     WebkitLineClamp: 3,
//                   }}
//                 ></div>
//                 <h1 className="text-primary-headingColor text-xl font-semibold font-inter pt-2 pb-2">
//                   {item.title}
//                 </h1>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// Testimonials.propTypes = {
//   articleData: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number.isRequired,
//       title: PropTypes.string.isRequired,
//       image: PropTypes.string.isRequired,
//       createdAt: PropTypes.string.isRequired,
//       views: PropTypes.number.isRequired,
//       description: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };

// export default Testimonials;


// import Slider from "react-slick";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
// import Heading from "./../common/Heading";
// import PropTypes from "prop-types";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const Testimonials = ({ articleData }) => {
//   const ArrowLeft = (props) => (
//     <div
//       {...props}
//       className="absolute bottom-2 right-1/2 transform translate-x-2 translate-y-1/2 z-10 cursor-pointer text-1xl text-primary-btnColor mt-8 pt-12"
//     >
//       <FaArrowLeft />
//     </div>
//   );

//   const ArrowRight = (props) => (
//     <div
//       {...props}
//       className="absolute bottom-2 left-1/2 transform translate-x-6 translate-y-1/2 z-10 cursor-pointer text-1xl text-primary-btnColor mt-8 pt-12"
//     >
//       <FaArrowRight />
//     </div>
//   );

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 600,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 4000,
//     spaceBetween: 30,
//     nextArrow: <ArrowRight />,
//     prevArrow: <ArrowLeft />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="my-5 px-4 md:px-10 lg:px-12 xl:px-20 pt-8 flex flex-col w-full items-center justify-center">
//       <div className="p-2">
//         <Heading heading="Testimonials" />
//       </div>
//       <div className="w-[60%] my-4">
//         <Slider {...settings} className="gap-6 p-2">
//         {articleData.map((item) => (
//             <div key={item.id}
//               className="h-[80vh] py-6 md:h-[45vh] lg:h-[40vh] xl:h-[40vh] my-10 mb-12 shadow cursor-pointer transition duration-300 ease-in-out flex flex-col md:flex-row px-6">
              
//             <div className="md:flex w-full md:w-[100%] font-mulish md:ml-6 h-2/2 md:mt-0 md:h-full">
//               <div className="sm:w-[100%] md:w-[95%] lg:w-[95%] h-[95%] md:h-full">
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
//                     item.image
//                   }`}
//                   alt="article"
//                   className="w-[45vh] h-[30vh] object-cover m-2"
//                 />
//               </div>
//               <div
//               className="px-10 p-2 w-[100vw] h-[10vh] "
//               >

//               <div
//                   dangerouslySetInnerHTML={{
//                     __html: item.description,
//                   }}
//                   style={{
//                     textAlign: 'justify',
//                     lineHeight: '25px',
//                     overflow: 'hidden',
//                     display: '-webkit-box',
//                     WebkitBoxOrient: 'vertical',
//                     WebkitLineClamp: 8,
//                 }} />

//               <h1 className="text-primary-headingColor text-xl font-semibold font-inter pt-2 pb-1 lg:pb-3">
//                 {item.title}
//               </h1>
//               </div>
//             </div>
//           </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// Testimonials.propTypes = {
//   articleData: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number.isRequired,
//       title: PropTypes.string.isRequired,
//       image: PropTypes.string.isRequired,
//       createdAt: PropTypes.string.isRequired,
//       views: PropTypes.number.isRequired,
//       description: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };

// export default Testimonials;








import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Heading from "./../common/Heading";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./path/to/your/custom/styles.css"; // Add the path to your custom styles

const Testimonials = ({ articleData }) => {
  const ArrowLeft = (props) => (
    <div
      {...props}
      className="absolute bottom-2 right-1/2 transform translate-x-2 translate-y-1/2 z-10 cursor-pointer text-1xl text-primary-btnColor mt-8 pt-12"
    >
      <FaArrowLeft />
    </div>
  );

  const ArrowRight = (props) => (
    <div
      {...props}
      className="absolute bottom-2 left-1/2 transform translate-x-6 translate-y-1/2 z-10 cursor-pointer text-1xl text-primary-btnColor mt-8 pt-12"
    >
      <FaArrowRight />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    spaceBetween: 30,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonial-container">
      <div className="p-2">
        <Heading heading="Testimonials" />
      </div>
      <div className="testimonial-wrapper">
        <Slider {...settings} className="gap-6 p-2">
          {articleData.map((item) => (
            <div key={item.id} className="testimonial-slide">
              <div className="testimonial-content">
                <div className="testimonial-image-wrapper">
                  <img
                    src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
                    alt="article"
                    className="testimonial-image"
                  />
                </div>
                <div className="testimonial-description">
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                  style={{
                    textAlign: 'justify',
                    overflow: 'hidden',
                }} />
                 
                  <h1 className="testimonial-title text-wrap">
                    {item.title}
                  </h1>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

Testimonials.propTypes = {
  articleData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      views: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Testimonials;







