import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import Heading from "../common/Heading";
import { useNavigate } from "react-router-dom";
import InvolvementCard from "../card/InvolvementCard";
import InvolvementTeachCard from "../card/InvolvementTeachCard";
// import TimelineCard from "../../components/card/TimelineCard";

const InvolvementCategories = ({ involvementData }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [viewAll, setViewAll] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const uniqueTabs = Array.from(
      new Set(
        involvementData
          .filter((item) => item.category !== null)
          .map((item) => item.category)
      )
    );
    setTabs(uniqueTabs);
  }, [involvementData]);

  const navigate = useNavigate();

  const navigateToDetailsPage = (id) => {
    navigate(`/involvement/details/${id}`);
  };

  const onTabChange = (index) => setActiveTab(index);

  const activeTabDetails = involvementData?.filter(
    (detail) => detail.category === tabs[activeTab]
  );

  return (
    <div className='px-4 md:px-12 lg:px-20 xl:px-36'>
      <Heading heading='Involvement' />
      {/* Tabs */}
      <div className='flex flex-wrap justify-center items-center my-6 md:my-10'>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`cursor-pointer px-4 md:px-6 py-2 md:py-4 border-b-4 border-solid font-mulish font-bold ${
              index === activeTab
                ? "border-primary-btnColor"
                : "border-gray-200"
            }`}
            onClick={() => onTabChange(index)}
          >
            <p className='capitalize text-sm md:text-base'>{tab}</p>
          </div>
        ))}
      </div>
      {/* Category Details */}
      <div className='w-full mb-6 md:w-[90%] md:mb-16'>
        <div
          className={`${
            tabs[activeTab] === "Teaching"
              ? "border-l border-neutral-100 lg:mt-[20rem] rounded-l-lg rounded-r-lg md:flex md:justify-center md:gap-6 md:border-l-0 md:border-t-8"
              : ""
          }`}
        >
          {activeTabDetails.map((detail, index) => (
            tabs[activeTab] === "teaching" ? (
              <InvolvementTeachCard key={detail._id} detail={detail} />
            ) : (
              viewAll || activeTabDetails.length <= 2 ? (
                <InvolvementCard
                  key={detail._id}
                  detail={detail}
                  index={index}
                  navigateToDetailsPage={navigateToDetailsPage}
                />
              ) : (
                <InvolvementCard
                  key={detail._id}
                  detail={detail}
                  index={index}
                  navigateToDetailsPage={navigateToDetailsPage}
                />
              )
            )
          ))}
          {/* View All/View Less Button */}
          {tabs[activeTab] !== "Teaching" && activeTabDetails.length > 2 && (
            <div
              className='text-black font-mulish font-bold text-sm cursor-pointer my-6 md:my-10 text-center'
              onClick={() => setViewAll(!viewAll)}
            >
              {viewAll ? "View Less" : "View All"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

InvolvementCategories.propTypes = {
  involvementData: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    // Add other required properties here
  })).isRequired,
};

export default InvolvementCategories;









// // mobile responsive but web design affected
// import PropTypes from 'prop-types';
// import { useState, useEffect } from "react";
// import Heading from "../common/Heading";
// import { useNavigate } from "react-router-dom";
// import InvolvementCard from "../card/InvolvementCard";
// import InvolvementTeachCard from "../card/InvolvementTeachCard";

// const InvolvementCategories = ({ involvementData }) => {
//   const [activeTab, setActiveTab] = useState(0); // Initialize activeTab to 0
//   const [viewAll, setViewAll] = useState(false);
//   const [tabs, setTabs] = useState([]);

//   useEffect(() => {
//     // Extract unique categories from involvementData
//     const uniqueTabs = Array.from(
//       new Set(
//         involvementData
//           .filter((item) => item.category !== null)
//           .map((item) => item.category)
//       )
//     );
//     setTabs(uniqueTabs);
//   }, [involvementData]);

//   const navigate = useNavigate();

//   const navigateToDetailsPage = (id) => {
//     navigate(`/involvement/details/${id}`);
//   };

//   const onTabChange = (index) => setActiveTab(index);

//   // Filtered details based on active tab's category
//   const activeTabDetails = involvementData?.filter(
//     (detail) => detail.category === tabs[activeTab]
//   );

//   return (
//     <div className='px-4 md:px-12 lg:px-20 xl:px-36'>
//       <Heading heading='Involvement' />
//       {/* Tabs */}
//       <div className='flex flex-wrap justify-center items-center my-6 md:my-10'>
//         {tabs.map((tab, index) => (
//           <div
//             key={index}
//             className={`cursor-pointer px-4 md:px-6 py-2 md:py-4 border-b-4 border-solid font-mulish font-bold ${
//               index === activeTab
//                 ? "border-primary-btnColor"
//                 : "border-gray-200"
//             }`}
//             onClick={() => onTabChange(index)}
//           >
//             <p className='capitalize text-sm md:text-base'>{tab}</p>
//           </div>
//         ))}
//       </div>
//       {/* Category Details */}
//       <div className='w-full mb-6 md:w-[90%] md:mb-16'>
//         <div
//           className={`${
//             tabs[activeTab] === "Teaching"
//               ? "border-l border-neutral-100 lg:mt-[20rem] rounded-l-lg rounded-r-lg md:flex md:justify-center md:gap-6 md:border-l-0 md:border-t-8"
//               : ""
//           }`}
//         >
//           {activeTabDetails.map((detail, index) => (
//             detail.category !== "Teaching" ? (
//               viewAll || activeTabDetails.length <= 2 ? (
//                 <InvolvementCard
//                   key={detail._id}
//                   detail={detail}
//                   index={index}
//                   navigateToDetailsPage={navigateToDetailsPage}
//                 />
//               ) : (
//                 <InvolvementCard
//                   key={detail._id}
//                   detail={detail}
//                   index={index}
//                   navigateToDetailsPage={navigateToDetailsPage}
//                 />
//               )
//             ) : (
//               <InvolvementTeachCard key={detail._id} detail={detail} />
//             )
//           ))}
//           {/* View All/View Less Button */}
//           {activeTabDetails.length > 2 && (
//             <div
//               className='text-black font-mulish font-bold text-sm cursor-pointer my-6 md:my-10 text-center'
//               onClick={() => setViewAll(!viewAll)}
//             >
//               {viewAll ? "View Less" : "View All"}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// InvolvementCategories.propTypes = {
//   involvementData: PropTypes.arrayOf(PropTypes.shape({
//     _id: PropTypes.string.isRequired,
//     category: PropTypes.string.isRequired,
//     // Add other required properties here
//   })).isRequired,
// };

// export default InvolvementCategories;


















// Not a mobile responsive
// import PropTypes from 'prop-types';
// import { useState, useEffect } from "react";
// import Heading from "../common/Heading";
// import { useNavigate } from "react-router-dom";
// import InvolvementCard from "../card/InvolvementCard";
// import InvolvementTeachCard from "../card/InvolvementTeachCard";

// const InvolvementCategories = ({ involvementData }) => {
//   const [activeTab, setActiveTab] = useState(1);
//   const [viewAll, setViewAll] = useState(false);
//   const [tabs, setTabs] = useState([]);

//   useEffect(() => {
//     // Extract unique categories from involvementData including "Teaching"
//     const uniqueTabs = Array.from(
//       new Set(
//         involvementData
//           .filter((item) => item.category !== null)
//           .map((item) => item.category)
//       )
//     );
//     setTabs(uniqueTabs);
//   }, [involvementData]);

//   const navigate = useNavigate();

//   const navigateToDetailsPage = (id) => {
//     navigate(`/involvement/details/${id}`);
//   };

//   const onTabChange = (id) => setActiveTab(id);

//   // Filtered details based on active tab's category
//   const activeTabDetails = involvementData?.filter(
//     (detail) =>
//       detail?.category === tabs[activeTab - 1]
//   );

//   return (
//     <div className='px-6 md:px-12 lg:px-20 xl:px-36'>
//       <Heading heading='Involvement' />
//       {/* Tabs */}
//       <div className='flex justify-center items-center my-10'>
//         {tabs?.map((tab, index) => (
//           <div
//             key={index + 1}
//             className={`cursor-pointer px-6 border-b-4 border-solid pb-4 font-mulish font-bold ${
//               index + 1 === activeTab
//                 ? "border-primary-btnColor"
//                 : "border-gray-200"
//             }`}
//             onClick={() => onTabChange(index + 1)}
//           >
//             <p className='capitalize'>{tab}</p>
//           </div>
//         ))}
//       </div>
//       {/* Category Details */}
//       <div className='md:w-[90%] mb-16'>
//         <div
//           className={`${
//             tabs[activeTab - 1] === "Teaching"
//               ? "border-l border-neutral-100 lg:mt-[20rem] rounded-l-lg rounded-r-lg md:flex md:justify-center md:gap-6 md:border-l-0 md:border-t-8"
//               : ""
//           }`}
//         >
//           {activeTabDetails.map((detail, index) => (
//             detail.category !== "Teaching" ? (
//               viewAll || activeTabDetails.length <= 2 ? (
//                 <InvolvementCard
//                   key={detail._id}
//                   detail={detail}
//                   index={index}
//                   navigateToDetailsPage={navigateToDetailsPage}
//                 />
//               ) : (
//                 <InvolvementCard
//                   key={detail._id}
//                   detail={detail}
//                   index={index}
//                   navigateToDetailsPage={navigateToDetailsPage}
//                 />
//               )
//             ) : (
//               <InvolvementTeachCard key={detail._id} detail={detail} />
//             )
//           ))}
//           {/* View All/View Less Button */}
//           {activeTabDetails.length > 2 && tabs[activeTab - 1] !== "Teaching" && (
//             <div
//               className='text-black font-mulish font-bold text-sm cursor-pointer my-10 text-center'
//               onClick={() => setViewAll(!viewAll)}
//             >
//               {viewAll ? "View Less" : "View All"}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// InvolvementCategories.propTypes = {
//   involvementData: PropTypes.arrayOf(PropTypes.shape({
//     _id: PropTypes.string.isRequired,
//     category: PropTypes.string,
//     // Add other required properties here
//   })).isRequired,
// };

// export default InvolvementCategories;













// import PropTypes from 'prop-types';
// import { useState, useEffect } from "react";
// import Heading from "../common/Heading";
// import { useNavigate } from "react-router-dom";
// import InvolvementCard from "../card/InvolvementCard";
// import InvolvementTeachCard from "../card/InvolvementTeachCard";

// const InvolvementCategories = ({ involvementData }) => {
//   const [activeTab, setActiveTab] = useState(1);
//   const [viewAll, setViewAll] = useState(false);
//   const [tabs, setTabs] = useState([]);

//   useEffect(() => {
//     // Extract unique categories from involvementData
//     const uniqueTabs = Array.from(
//       new Set(
//         involvementData
//           .filter((item) => item.category !== null)
//           .map((item) => item.category)
//       )
//     );
//     setTabs(uniqueTabs);
//   }, [involvementData]);

//   const navigate = useNavigate();

//   const navigateToDetailsPage = (id) => {
//     navigate(`/involvement/details/${id}`);
//   };

//   const onTabChange = (id) => setActiveTab(id);

//   // Filtered details based on active tab's category
//   const activeTabDetails = involvementData?.filter(
//     (detail) =>
//       detail?.category === tabs[activeTab - 1]
//   );

//   return (
//     <div className='px-6 md:px-12 lg:px-20 xl:px-36'>
//       <Heading heading='Involvement' />
//       {/* tab  */}
//       <div className='flex justify-center items-center my-10'>
//         {tabs?.map((tab, index) => (
//           <div
//             key={index + 1}
//             className={`cursor-pointer px-6 border-b-4 border-solid pb-4 font-mulish font-bold ${
//               index + 1 === activeTab
//                 ? "border-primary-btnColor"
//                 : "border-gray-200"
//             }`}
//             onClick={() => onTabChange(index + 1)}
//           >
//             <p className='capitalize'>{tab}</p>
//           </div>
//         ))}
//       </div>
//       <div className='md:w-[90%] mb-16'>
//         <div
//           className={`${
//             tabs[activeTab - 1] === "Teaching"
//               ? "border-l border-neutral-100 lg:mt-[20rem] rounded-l-lg rounded-r-lg md:flex md:justify-center md:gap-6 md:border-l-0 md:border-t-8"
//               : ""
//           }`}
//         >
//           {activeTabDetails.map((detail, index) =>
//             detail.category !== "Teaching" ? (
//               viewAll || activeTabDetails.length <= 2 ? (
//                 <InvolvementCard
//                   key={detail._id}
//                   detail={detail}
//                   index={index}
//                   navigateToDetailsPage={navigateToDetailsPage}
//                 />
//               ) : (
//                 <InvolvementCard
//                   key={detail._id}
//                   detail={detail}
//                   index={index}
//                   navigateToDetailsPage={navigateToDetailsPage}
//                 />
//               )
//             ) : (
//               <InvolvementTeachCard key={detail._id} detail={detail} />
//             )
//           )}
//           {activeTabDetails.length > 2 &&
//             tabs[activeTab - 1] !== "Teaching" && (
//               <div
//                 className='text-black font-mulish font-bold text-sm cursor-pointer my-10 text-center'
//                 onClick={() => setViewAll(!viewAll)}
//               >
//                 {viewAll ? "View Less" : "View All"}
//               </div>
//             )}
//         </div>
//       </div>
//     </div>
//   );
// };

// InvolvementCategories.propTypes = {
//   involvementData: PropTypes.arrayOf(PropTypes.shape({
//     _id: PropTypes.string.isRequired,
//     category: PropTypes.string,
//     // Add other required properties here
//   })).isRequired,
// };

// export default InvolvementCategories;






// import PropTypes from 'prop-types';
// import { useState } from "react";
// import Heading from "../common/Heading";
// import { useNavigate } from "react-router-dom";
// import InvolvementCard from "../card/InvolvementCard";
// import InvolvementTeachCard from "../card/InvolvementTeachCard";

// const InvolvementCategories = ({ involvementData }) => {
//   const [activeTab, setActiveTab] = useState(1);
//   const [viewAll, setViewAll] = useState(false);

//   const tabs = Array.from(
//     new Set(
//       involvementData
//         .filter((item) => item.category !== null) // Remove items with null category
//         .map((item) => item.category) // Extract category values
//     )
//   );

//   const myTabs = tabs.map((category, index) => ({
//     id: index + 1,
//     category,
//   }));

//   const navigate = useNavigate();

//   const navigateToDetailsPage = (id) => {
//     navigate(`/involvement/details/${id}`);
//   };

//   const onTabChange = (id) => setActiveTab(id);

//   // Filtered details based on active tab's category
//   const activeTabDetails = involvementData?.filter(
//     (detail) =>
//       detail?.category === myTabs.find((tab) => tab.id === activeTab)?.category
//   );

//   return (
//     <div className='px-6 md:px-12 lg:px-20 xl:px-36'>
//       <Heading heading='Involvement' />
//       {/* tab  */}
//       <div className='flex justify-center items-center my-10'>
//         {myTabs?.map((tab) => (
//           <div
//             key={tab?.id}
//             className={`cursor-pointer px-6 border-b-4 border-solid pb-4 font-mulish font-bold ${
//               tab?.id === activeTab
//                 ? "border-primary-btnColor"
//                 : "border-gray-200"
//             }`}
//             onClick={() => onTabChange(tab?.id)}
//           >
//             {tab?.category === "commuunityDevelopment" && (
//               <p className='capitalize'>Commuunity Development</p>
//             )}
//             {tab?.category === "languageLogstic" && (
//               <p className='capitalize'>language Logstic</p>
//             )}
//             {tab?.category === "languageLogstic" && (
//               <p className='capitalize'>teaching</p>
//             )}
//             {tab?.category === "teaching" && <p className='capitalize'>teaching</p>}
//           </div>
//         ))}
//       </div>
//       <div className='md:w-[90%] mb-16'>
//         <div
//           className={`${
//             activeTabDetails[0].category === "teaching"
//               ? "border-l border-neutral-100 lg:mt-[20rem] rounded-l-lg rounded-r-lg md:flex md:justify-center md:gap-6 md:border-l-0 md:border-t-8"
//               : ""
//           }`}
//         >
//           {activeTabDetails.map((detail, index) =>
//             detail.category !== "teaching" ? (
//               viewAll || activeTabDetails.length <= 2 ? (
//                 <InvolvementCard
//                   key={detail._id}
//                   detail={detail}
//                   index={index}
//                   navigateToDetailsPage={navigateToDetailsPage}
//                 />
//               ) : (
//                 <InvolvementCard
//                   key={detail._id}
//                   detail={detail}
//                   index={index}
//                   navigateToDetailsPage={navigateToDetailsPage}
//                 />
//               )
//             ) : (
//               <InvolvementTeachCard key={detail._id} detail={detail} />
//             )
//           )}
//           {activeTabDetails.length > 2 &&
//             activeTabDetails[0].category !== "Teaching" && (
//               <div
//                 className='text-black font-mulish font-bold text-sm cursor-pointer my-10 text-center'
//                 onClick={() => setViewAll(!viewAll)}
//               >
//                 {viewAll ? "View Less" : "View All"}
//               </div>
//             )}
//         </div>
//       </div>
//     </div>
//   );
// };
// InvolvementCategories.propTypes = {
//   involvementData: PropTypes.arrayOf(PropTypes.shape({
//     _id: PropTypes.string.isRequired,
//     category: PropTypes.string,
//     // Add other required properties here
//   })).isRequired,
// };

// export default InvolvementCategories;
