import PropTypes from 'prop-types';
import { FaFacebook } from 'react-icons/fa';
import { FiGlobe } from 'react-icons/fi';
import ReadmoreBtn from '../common/ReadmoreBtn';
import { formatDate } from '../../utility/dateFormates';
import { Link } from 'react-router-dom';

const InitiativeCard = ({ item }) => {
  return (
    <div className={`flex items-center gap-10 w-full justify-center mb-20 initiative-card`}>
      <div className="w-full md:w-[55%] lg:w-[45%] xl:w-[46%] h-[45vh] lg:h-full relative mb-12 md:mb-0">
        {item.image ? (
          <img
            src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image} `}
            alt="initiatives"
            className="w-full md:w-[92%] lg:w-[90%] h-full object-center object-cover lg:object-cover"
          />
        ) : (
          <img
            src="/logo/default-img.jpg"
            alt="initiatives"
            className="w-full md:w-[92%] lg:w-[90%] h-full object-center object-cover lg:object-cover"
          />
        )}

        <div className={`bg-white rounded-full h-[7rem] w-[7rem] shadow-lg absolute top-[85%] md:top-1/3 logo z-20`}>
          {item.logo ? (
            <img
              src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo} `}
              alt="initiatives"
              className="w-full h-full object-contain rounded-full"
            />
          ) : (
            <img
              src="/logo/initiativeLogo.png"
              alt="initiatives"
              className="w-full h-full object-contain rounded-full"
            />
          )}
        </div>
      </div>
      <div className="sm:w-[50%] card-body">
        <h1 className="text-xl font-semibold font-inter capitalize text-primary-headingColor pt-4 md:pt-0">
          {item.title}
        </h1>
        <h1 className="font-mular text-base font-bold py-4">
          Established Date:<span className="ms-2">{formatDate(item.establishedDate)}</span>
        </h1>
        <p className="text-base text-justify font-mulish line-clamp-4 whitespace-wrap w-fit">
          {item.description} Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam enim tempora dolorem quis quo reiciendis necessitatibus rerum non possimus nulla recusandae ab aperiam, unde asperiores voluptatum cupiditate voluptate repellat. Molestias.
        </p>
        <div className="flex items-center gap-2 my-3">
          <FaFacebook className="w-5 h-5 text-[#828587]" />
          <FiGlobe className="w-5 h-5 text-[#828587]" />
        </div>
        <Link to={`/initiatives/${item._id}`}>
          <ReadmoreBtn />
        </Link>
      </div>
    </div>
  );
};
InitiativeCard.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    establishedDate: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
};

export default InitiativeCard;
