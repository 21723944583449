import PropTypes from 'prop-types';
import { formatDate } from "../../utility/dateFormates";

const TimelineCard = ({ item }) => {
  return (
    <div
      className={`timeline-card flex px-2 md:px-0 py-3 md:py-0 md:items-center bg-white mt-10 md:h-[30vh] lg:[28vh] w-[100%] md:w-[100%] lg:w-[85%] box-shadow font-mulish justify-center`}
    >
      <div className='font-mulish font-bold md:text-sm lg:text-base md:w-[18%] lg:w-[16%] flex items-center justify-center gap-2 sm:flex-col py-4 md:py-0'>
        <p>
          {formatDate(item.startingDate)}
          <span className='ms-2'>AD</span>
        </p>
        {!item.endDate ? (
          <p> onwards</p>
        ) : (
          <>
            <p className='text-center'>to</p>
            <p>
              {formatDate(item.endDate)}
              <span className='ms-2'>AD</span>
            </p>
          </>
        )}
      </div>
      <div className='h-full w-0.5 bg-primary-subHeading'></div>
      <div className='flex flex-col justify-center md:w-[40%] px-2 md:px-6 pb-2 md:pb-0'>
        <h1 className='text-primary-subHeading capitalize text-base font-bold pb-2 pt-4 md:pt-0 text-center md:text-start'>
          {item.title}
        </h1>
        <p className='text-base line-clamp-3 sm:line-clamp-5'>
          {item.description}
        </p>
      </div>
      <div className='w-[100%] h-[30vh] md:w-[36%] lg:w-[40%] md:h-[85%]'>
        <img
          src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
          alt='timeline'
          className='w-full h-full object-cover'
        />
      </div>
    </div>
  );
};
TimelineCard.propTypes = {
  item: PropTypes.shape({
    startingDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};
export default TimelineCard;





// import React from "react";
// import PropTypes from "prop-types";

const TimelineItem = ({ data }) => (
  <div className="tech-timeline-item">
    <div className="tech-timeline-item-content">
      <span className="tech-tag" style={{ background: data.category.color }}>
        {data.category.tag}
      </span>
      <time>{data.date}</time>
      <p>{data.text}</p>
      {data.link && (
        <a href={data.link.url} target="_blank" rel="noopener noreferrer">
          {data.link.text}
        </a>
      )}
      <span className="tech-circle" />
    </div>
  </div>
);

TimelineItem.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    category: PropTypes.shape({
      tag: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired,
    link: PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
    }),
  }).isRequired,
};

// export default TimelineItem;

