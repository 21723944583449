import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PublicationLatestCard = ({ item }) => {
  return (
    <div
      className="w-full h-fit py-6 md:h-[45vh] lg:h-[40vh] xl:h-[40vh] mx-2 lg:mx-4 box-shadow cursor-pointer hover:scale-105 transition duration-300 ease-in-out flex flex-col md:flex-row px-6"
    >
      <div className="w-full sm:w-[70%] md:w-[30%] lg:w-[28%] h-[45%] md:h-full">
        <img
          src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
          alt="book"
          className="w-full h-full object-contain"
        />
      </div>
      <div className="w-full md:w-[68%] font-mulish md:ml-6 h-1/2 mt-7 md:mt-0 md:h-full">
        <h1 className="capitalize text-primary-headingColor text-xl font-semibold font-inter pb-1 lg:pb-3 line-clamp-2 overflow-hidden whitespace-wrap">
          {item.bookTitle}
        </h1>
        <div className="md:flex mt-2 lg:mt-0">
          {item.isPurchased ? (
            <p className="bg-primary-btnColor capitalize text-white p-2 text-sm font-semibold cursor-pointer duration-300 ease-in-out">
              Purchase book
            </p>
          ) : (
            <p className="bg-primary-btnColor text-white capitalize p-2 text-sm font-semibold cursor-pointer duration-300 ease-in-out">
              Free download
            </p>
          )}
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: item.summary,
          }}
          className="my-2"
          style={{
            textAlign: 'justify',
            lineHeight: '28px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
          }}
        ></div>
        <p className="text-primary-btnColor text-sm font-bold cursor-pointer duration-300 ease-in-out mt-4">
          <Link to={`/publications/${item._id}`}> View Detail </Link>
        </p>
      </div>
    </div>
  );
};

PublicationLatestCard.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    bookTitle: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    isPurchased: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PublicationLatestCard;
