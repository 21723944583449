/* eslint-disable react/prop-types */
import { useState } from "react";
import TableContainer from "../../container/TableContainer";
import ToolTip from "../tooltip/ToolTip";
import { Pencil, Trash2 } from "lucide-react";
import Modal from "../../models/Model";
import { toast } from "react-toastify";
import useDataDeletion from "../../../hooks/useDataDeletion/usedDataDeletion";
import { Link } from "react-router-dom";
import Pagination from "../pagination/Pagination";
import { formatDate } from "../../../utility/dateFormate/formateDate";
import { CornerRightDown, Facebook, MailOpen, Linkedin, Globe } from 'lucide-react';

const TlppdInitiativeTable = ({ data, refetchData }) => {
  const apiUrl = `${import.meta.env.VITE_APP_API_URL}/initiatives`;
  const { deleteData } = useDataDeletion();
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;

  const openModal = (id) => {
    setDeleteItemId(id);
  };

  const closeModal = () => {
    setDeleteItemId(null);
  };

  const handleDelete = async () => {
    deleteData(`${apiUrl}/${deleteItemId}`, () => {
      toast.success("Data deleted successfully!");
      refetchData();
      closeModal();
    });
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const newData = data.slice(indexOfFirstPost, indexOfLastPost + postsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <TableContainer>
      {data && (
        <>
          <div className="space-y-6">
            {newData.map((initiative, index) => (
              <div key={index} className="p-2 rounded-lg shadow-md space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-7 gap-4 p-5">
                  <div><strong>Logo:</strong>
                    <img
                      className="w-full h-auto object-cover rounded-lg"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.logo}`}
                      alt="Logo"
                    />
                  </div>
                  <div><strong>Image:</strong>
                    <img
                      className="w-full h-auto object-cover rounded-lg"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.image}`}
                      alt="Image"
                    />
                  </div>
                  <div><strong>Overview Image:</strong>
                    <img
                      className="w-full h-auto object-cover rounded-lg"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.projectOverview?.overviewImage}`}
                      alt="Overview Image"
                    />
                  </div>
                  <div><strong>Project Outcome Image:</strong>
                    <img
                      className="w-full h-auto object-cover rounded-lg"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.projectOutcomes?.outcomeImage}`}
                      alt="Project Outcome Image"
                    />
                  </div>
                  <div><strong>Involvement Image:</strong>
                    <img
                      className="w-full h-auto object-cover rounded-lg"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.involvement?.involvementImage}`}
                      alt="Involvement Image"
                    />
                  </div>
                  <div><strong>Objectives Image:</strong>
                    <img
                      className="w-full h-auto object-cover rounded-lg"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.objectives?.objectivesImage}`}
                      alt="Objectives Image"
                    />
                  </div>

                  <div><strong>Target Audience Image:</strong>
                    <img
                      className="w-full h-auto object-cover rounded-lg"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.targetAudience?.audienceImage}`}
                      alt="Target Audience Image"
                    />
                  </div>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-6 gap-4 p-5">

                  <div className="mb-10 capitalize"><strong>Initiative Name:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{initiative?.title}</h3>
                  </div>

                  <div className="mb-10 capitalize"><strong>Description:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700 line-clamp-2">{initiative?.description}</h3>
                  </div>

                  {/* <div className="mb-10 capitalize"><strong>Project Overview:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700 line-clamp-2">{initiative?.projectOverview?.description}</h3>
                  </div> */}



                  <div className="mb-10 capitalize"><strong>Project Outcomes:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700 line-clamp-2">{initiative?.projectOutcomes?.description}</h3>
                  </div>

                  <div className="mb-10 capitalize"><strong>Involvement:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700 line-clamp-2">{initiative?.involvement?.description}</h3>
                  </div>

                  <div className="mb-10 capitalize"><strong>Objectives: </strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{initiative?.objectives?.description}</h3>
                  </div>

                  <div className="mb-10 capitalize"><strong>Target Audience:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700 line-clamp-2">{initiative?.targetAudience?.description}</h3>
                  </div>

                  <div className="mb-10 capitalize"><strong>Status:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{initiative?.status}</h3>
                  </div>

                  <div className="mb-10"><strong>Established Date:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{formatDate(initiative?.establishedDate)}</h3>
                  </div>
                  <div className="mb-10 capitalize"><strong>Category:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{initiative?.category}</h3>
                  </div>

                  {/* <div className="mb-10"><strong>Social Media:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{initiative?.facebook}</h3>
                  </div> */}

                  

                </div>
                <p className="px-5 pb-0 flex"><strong>Social Media </strong> <CornerRightDown/>  </p>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 px-5 pb-0">
                
                <div className="mb-10">
                    <div className="pt-5 text-1xl font-normal text-gray-700">
                    <strong><Facebook /></strong>
                      <div className="pt-3">
                        {initiative?.socialMedia?.facebook || "N/A"}<br />
                      </div>

                    </div>
                  </div>
                  <div className="mb-10">
                    <div className="pt-5 text-1xl font-normal text-gray-700">
                    <strong><MailOpen /></strong> 
                      <div className="pt-3">
                        {initiative?.socialMedia?.email || "N/A"}<br />
                      </div>
                    </div>
                  </div>

                  <div className="mb-10">
                    <div className="pt-5 text-1xl font-normal text-gray-700">
                    <strong><Linkedin /></strong>
                      <div className="pt-3">
                         {initiative?.socialMedia?.linkedin || "N/A"}<br />
                      </div>
                      
                    </div>
                  </div>

                  <div className="mb-10">
                    <div className="pt-5 text-1xl font-normal text-gray-700">
                    <strong><Globe /></strong>
                      <div className="pt-3">
                         {initiative?.socialMedia?.website || "N/A"}
                      </div>
                      
                    </div>
                  </div>


                </div>

                

                <div className="grid grid-cols-1 md:grid-cols-6 gap-4 p-5">

                <div className="mb-10 capitalize"><strong>Project Overview Description:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700 line-clamp-2">{initiative?.projectOverview?.description}</h3>
                  </div>

                  <div className="mb-10 capitalize"><strong>Project_Overview Budget: </strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{initiative?.projectOverview?.projectBudget}</h3>
                  </div>

                  <div className="mb-10 capitalize"><strong>Project_Overview Donation Received:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700 line-clamp-2">{initiative?.projectOverview?.donationReceived}</h3>
                  </div>

                  <div className="mb-10 capitalize"><strong>Project_Overview Report:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{initiative?.projectOverview?.projectReport}</h3>
                  </div>

                  <div className="mb-10 capitalize"><strong>Project_Overview Starting Date:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{formatDate(initiative?.projectOverview?.startingDate)}</h3>
                  </div>

                  <div className="mb-10"><strong>Project_Overview End Date :</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{formatDate(initiative?.projectOverview?.endDate)}</h3>
                  </div>

                  

                </div>



                <div className="flex justify-end space-x-4">
                  <ToolTip text="edit">
                    <Link
                      to={`/admin/initiatives/edit/${initiative._id}`}
                      className="inline-block text-blue-500"
                    >
                      <Pencil className="h-6 w-6" />
                    </Link>
                  </ToolTip>
                  <ToolTip text="delete">
                    <button
                      onClick={() => openModal(initiative._id)}
                      className="inline-block text-red-500"
                    >
                      <Trash2 className="h-6 w-6" />
                    </button>
                  </ToolTip>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            className="mt-6"
            postsPerPage={postsPerPage}
            totalPosts={data.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </>
      )}
      <Modal isOpen={deleteItemId !== null} closeModal={closeModal}>
        <button onClick={handleDelete} className="btn btn-danger">
          Delete
        </button>
        <button onClick={closeModal} className="btn btn-secondary">
          Cancel
        </button>
      </Modal>
    </TableContainer>
  );
};

export default TlppdInitiativeTable;




















// /* eslint-disable react/prop-types */
// import { useState } from "react";
// import TableContainer from "../../container/TableContainer";
// import ToolTip from "../tooltip/ToolTip";
// import { Pencil, Trash2 } from "lucide-react";
// import Modal from "../../models/Model";
// import { toast } from "react-toastify";
// import useDataDeletion from "../../../hooks/useDataDeletion/usedDataDeletion";
// import { Link } from "react-router-dom";
// import Pagination from "../pagination/Pagination";
// import { formatDate } from "../../../utility/dateFormate/formateDate";

// const TlppdInitiativeTable = ({ data, refetchData }) => {
//   const apiUrl = `${import.meta.env.VITE_APP_API_URL}/initiatives`;
//   const { deleteData } = useDataDeletion();
//   const [deleteItemId, setDeleteItemId] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const postsPerPage = 10;

//   const openModal = (id) => {
//     setDeleteItemId(id);
//   };

//   const closeModal = () => {
//     setDeleteItemId(null);
//   };

//   const handleDelete = async () => {
//     deleteData(`${apiUrl}/${deleteItemId}`, () => {
//       toast.success("Data deleted successfully!");
//       refetchData();
//       closeModal();
//     });
//   };

//   const indexOfLastPost = currentPage * postsPerPage;
//   const indexOfFirstPost = indexOfLastPost - postsPerPage;
//   const newData = data.slice(indexOfFirstPost, indexOfLastPost + postsPerPage);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <TableContainer>
//       {data && (
//         <>
//           <div className="space-y-6">
//             {newData.map((initiative, index) => (
//               <div key={index} className=" p-2 rounded-lg shadow-md space-y-4">
//                 <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-6">
//                   <div className="md:w-1/4"><p><strong>Logo:</strong>
//                     <img
//                       className="w-full h-auto object-cover rounded-lg"
//                       src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.logo}`}
//                       alt="Logo"
//                     />
//                     </p>
//                   </div>
//                   <div className="md:w-1/4"><p><strong>Image:</strong></p>
//                     <img
//                       className="w-full h-auto object-cover rounded-lg"
//                       src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.image}`}
//                       alt="Image"
//                     />
//                   </div>
//                   <div className="md:w-1/4"><p><strong>Overview Image:</strong></p>
//                     <img
//                       className="w-full h-auto object-cover rounded-lg"
//                       src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.projectOverview?.overviewImage}`}
//                       alt="Overview Image"
//                     />
//                   </div>
//                   <div className="md:w-1/4"><p><strong>Project_Outcome Image:</strong></p>
//                     <img
//                       className="w-full h-auto object-cover rounded-lg"
//                       src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.projectOutcomes?.outcomeImage}`}
//                       alt="projectOutcomes Image"
//                     />
//                   </div>
//                   <div className="md:w-1/4"><p><strong>Involvement Image:</strong></p>
//                     <img
//                       className="w-full h-auto object-cover rounded-lg"
//                       src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.involvement?.involvementImage}`}
//                       alt="involvement Image"
//                     />
//                   </div>
//                   <div className="md:w-1/4"><p><strong>Objectives Image:</strong></p>
//                     <img
//                       className="w-full h-auto object-cover rounded-lg"
//                       src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.objectives?.objectivesImage}`}
//                       alt="objectives Image"
//                     />
//                   </div>
//                   <div className="md:w-1/4"><p><strong>Target Audience Image:</strong></p>
//                     <img
//                       className="w-full h-auto object-cover rounded-lg"
//                       src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiative?.targetAudience?.audienceImage}`}
//                       alt="targetAudience Image"
//                     />
//                   </div>
//                   <div className="md:w-3/4 space-y-2">
//                     <p><strong>Initiative Name:</strong>
//                     <h3 className="m-4 text-xl font-bold text-gray-800">{initiative?.title}</h3></p>
//                     <div className="text-sm text-gray-600 capitalize">
//                       <div  className="m-4 text-xl font-bold text-gray-800"><strong>Description:</strong> <p className="pt-2 line-clamp-2">{initiative?.description}</p></div>
//                       <div  className="m-4 text-xl font-bold text-gray-800"><strong>Status:</strong> <p className="pt-2">{initiative?.status}</p></div>
//                       <div  className="m-4 text-xl font-bold text-gray-800"><strong>Category:</strong><p className="pt-2"> {initiative?.category}</p></div>
//                       {/* <div  className="m-4 text-xl font-bold text-gray-800"><strong>Social Media:</strong><p className="pt-2"> {initiative?.socialMedia}</p></div> */}
//                       <div  className="m-4 text-xl font-bold text-gray-800"><strong>Project Overview:</strong> <p className="pt-2">{initiative?.projectOverview?.description}</p></div>
//                       <div  className="m-4 text-xl font-bold text-gray-800"><strong>Project Outcomes:</strong> <p className="pt-2">{initiative?.projectOutcomes?.description}</p></div>
//                       <div  className="m-4 text-xl font-bold text-gray-800"><strong>Involvement:</strong> <p className="pt-2">{initiative?.involvement?.description}</p></div>
//                       <div  className="m-4 text-xl font-bold text-gray-800"><strong>Objectives:</strong> <p className="pt-2">{initiative?.objectives?.description}</p></div>
//                       <div  className="m-4 text-xl font-bold text-gray-800"><strong>Target Audience:</strong> <p className="pt-2">{initiative?.targetAudience?.description}</p></div>
//                       <div  className="m-4 text-xl font-bold text-gray-800"><strong>Established Date:</strong> <p className="pt-2"> {formatDate(initiative?.establishedDate)}</p></div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="flex justify-end space-x-4">
//                   <ToolTip text="edit">
//                     <Link
//                       to={`/admin/initiatives/edit/${initiative._id}`}
//                       className="inline-block text-blue-500"
//                     >
//                       <Pencil className="h-6 w-6" />
//                     </Link>
//                   </ToolTip>
//                   <ToolTip text="delete">
//                     <button
//                       onClick={() => openModal(initiative._id)}
//                       className="inline-block text-red-500"
//                     >
//                       <Trash2 className="h-6 w-6" />
//                     </button>
//                   </ToolTip>
//                 </div>
//               </div>
//             ))}
//           </div>
//           <Pagination
//             className="mt-6"
//             postsPerPage={postsPerPage}
//             totalPosts={data.length}
//             paginate={paginate}
//             currentPage={currentPage}
//           />
//         </>
//       )}
//       <Modal isOpen={deleteItemId !== null} closeModal={closeModal}>
//         {/* <p>Are you sure you want to delete this item?</p> */}
//         <button onClick={handleDelete} className="btn btn-danger">
//           Delete
//         </button>
//         <button onClick={closeModal} className="btn btn-secondary">
//           Cancel
//         </button>
//       </Modal>
//     </TableContainer>
//   );
// };

// export default TlppdInitiativeTable;


























// /* eslint-disable react/prop-types */
// import { useState } from "react";
// import TableContainer from "./../../container/TableContainer";
// import ToolTip from "../tooltip/ToolTip";
// import { Pencil, Trash2 } from "lucide-react";
// import Modal from "../../models/Model";
// import { toast } from "react-toastify";
// import useDataDeletion from "../../../hooks/useDataDeletion/usedDataDeletion";
// import { Link } from "react-router-dom";
// import Pagination from "../pagination/Pagination";
// import { formatDate } from "../../../utility/dateFormate/formateDate";

// const TlppdInitiativeTable = ({ data, refetchData }) => {
//   const apiUrl = `${import.meta.env.VITE_APP_API_URL}/initiatives`;
//   const { deleteData } = useDataDeletion();
//   const [deleteItemId, setDeleteItemId] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const postsPerPage = 10;

//   const openModal = (id) => {
//     setDeleteItemId(id);
//   };

//   const closeModal = () => {
//     setDeleteItemId(null);
//   };

//   const handleDelete = async () => {
//     deleteData(`${apiUrl}/${deleteItemId}`, () => {
//       toast.success("Data deleted successfully!");
//       refetchData();
//       closeModal();
//     });
//   };

//   const indexOfLastPost = currentPage * postsPerPage;
//   const indexOfFirstPost = indexOfLastPost - postsPerPage;
//   const newData = data.slice(indexOfFirstPost, indexOfLastPost);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);
//     // title,
//     // description,
//     // status,
//     // establishedDate,
//     // category,
//     // socialMedia,
//     // image: getImagePath(req, "image"),
//     // logo: getImagePath(req, "logo"),

//   return (
//     <TableContainer>
//       {data && (
//         <>
//           <table className="w-full divide-y divide-gray-200">
//             <thead className="bg-gray-50 capitalize font-bold">
//               <tr>
//                 <th className="px-4 py-3 text-left text-sm text-gray-700">
//                   SN
//                 </th>
//                 <th className="px-4 py-3 text-left text-sm text-gray-700">
//                 logo
//                 </th>
//                 <th className="px-4 py-3 text-left text-sm text-gray-700">
//                   Image
//                 </th>
//                 <th className="px-4 py-3 text-left text-sm text-gray-700">
//                   title
//                 </th>
//                 <th className="px-4 py-3 text-left text-sm text-gray-700">
//                   Description
//                 </th>
//                 {/* <th className="px-4 py-3 text-left text-sm text-gray-700">
//                 status
//                 </th> */}
                
//                 {/* <th className="px-4 py-3 text-left text-sm text-gray-700">
//                 category
//                 </th> */}
                
//                 <th className="px-4 py-3 text-left text-sm font-bold text-gray-700">
//                   Established Date
//                 </th>
                
//                 <th className="px-4 py-3">Actions</th>
//               </tr>
//             </thead>
//             <tbody className="divide-y divide-gray-200 bg-white">
//               {newData?.map((initiatives, index) => (
//                 <tr key={index}>
//                   <td className="px-4 py-4 text-left text-sm text-gray-700">
//                     {index + 1}
//                   </td>
//                   <td className="px-4 py-4">
//                     <img className="w-24 h-20 object-cover rounded-lg"
//                       src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiatives?.logo}`}
//                       alt="Images"/>
//                   </td>
//                   <td className="px-4 py-4">
//                     <img
//                       className="w-24 h-24 object-cover rounded-lg"
//                       src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiatives?.image}`}
//                       alt="Images"
//                     />
//                   </td>
//                   <td className="px-8 py-4 text-left text-sm text-gray-700">
//                     {initiatives?.title}
//                   </td>
//                   <td className="px-8 py-4 text-left text-sm text-gray-700">
//                     {initiatives?.description}
//                   </td>
                  
//                   <td className="px-4 py-4 text-left text-sm text-gray-700">
//                     {formatDate(initiatives?.establishedDate)}
//                   </td>
//                   {/* <td className="px-8 py-4 text-left text-sm text-gray-700">
//                     {initiatives?.project}
//                   </td>
//                   <td className="px-8 py-4 text-left text-sm text-gray-700">
//                     {initiatives?.impact}
//                   </td> */}
//                   <td className="px-4 py-4">
//                     <ToolTip text="edit">
//                       <Link
//                         to={`/admin/initiatives/edit/${initiatives._id}`}
//                         className="inline-block text-blue-500"
//                       >
//                         <Pencil className="h-5 w-5" />
//                       </Link>
//                     </ToolTip>
//                     <ToolTip text="delete">
//                       <button onClick={() => openModal(initiatives._id)}
//                         className="ml-2 inline-block text-red-500">
//                         <Trash2 className="h-5 w-5" />
//                       </button>
//                     </ToolTip>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <Pagination
//             className="mt-4"
//             postsPerPage={postsPerPage}
//             totalPosts={data.length}
//             paginate={paginate}
//             currentPage={currentPage}
//           />
//         </>
//       )}
//       <Modal isOpen={deleteItemId !== null} onClose={closeModal}>
//         <button
//           onClick={closeModal}
//           className="p-2 bg-gray-300 text-gray-800 font-bold text-sm rounded-md mr-2"
//         >
//           Cancel
//         </button>
//         <button
//           onClick={handleDelete}
//           className="p-2 bg-red-500 text-white font-bold text-sm rounded-md"
//         >
//           Delete
//         </button>
//       </Modal>
//     </TableContainer>
//   );
// };

// export default TlppdInitiativeTable;
