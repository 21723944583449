import React, { useState } from "react";
import { ChevronRight } from "lucide-react";
import { IoMail } from "react-icons/io5";
import Heading from "../common/Heading";
import PropTypes from "prop-types";
import { FaFacebookSquare, FaLinkedinIn } from "react-icons/fa";

const AboutMe = ({ dataDetails }) => {
  const [showMore] = useState(false);

  // const handleToggleShowMore = () => {
  //   setShowMore(!showMore);
  // };

  return (
    <div className='py-8 px-8 md:px-10 lg:px-28 xl:px-36 pb-20'>
      <div className='pb-16'>
        <Heading heading='About Me' />
      </div>
      {dataDetails?.map((about, index) => (
        <React.Fragment key={index}>
          <div className={`h-fit w-full pb-4 flex flex-col md:flex-row ${!showMore && index > 0 ? 'hidden' : ''}`}>
            <div className='h-[45vh] md:h-[50vh] lg:h-[50vh] xl:h-[50vh] w-full md:w-[45%] mb-6 md:ml-12 md:order-2'>
              <div className='h-[62%] md:h-[80%] w-full'>
                <img
                  src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${about.image}`}
                  alt='about-me'
                  className=' px-12 h-[90%] w-[90%] rounded-tr-[80px] rounded-bl-[80px]'
                />
              </div>
              <div className='flex items-center justify-center'>
                <button className='text-white font-mulish text-base bg-primary-btnColor transition duration-300 ease-in-out hover:text-black hover:border hover:border-primary-btnColor px-4 py-2 rounded-full hover:bg-white flex items-center mt-8'>
                  Download CV
                  <ChevronRight className='w-5 h-5 ml-2' />
                </button>
              </div>
            </div>
            <div
              style={{ lineHeight: "30px" }}
              className='font-mulish text-base text-justify md:w-[55%] md:order-1'
              dangerouslySetInnerHTML={{ __html: about.name }}
            ></div>
          </div>
          <div className={`flex items-center justify-end py-6 ${!showMore && index > 0 ? 'hidden' : ''}`}>
            {about?.socialMedia?.facebook && (
              <div className='rounded-full w-[34px] h-[34px] bg-primary-headingColor flex items-center justify-center shadow-lg hover:scale-105 cursor-pointer'>
                <a
                  href={`https://${about?.socialMedia?.facebook}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaFacebookSquare className='text-white cursor-pointer ease-in-out rotate transition duration-300' />
                </a>
              </div>
            )}
            {about?.socialMedia?.email && (
              <div className='rounded-full w-[34px] h-[34px] bg-primary-headingColor flex items-center justify-center ml-3 shadow-lg hover:scale-105 cursor-pointer'>
                <a
                  href={`mailto:${about?.socialMedia?.email}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <IoMail className='text-white cursor-pointer ease-in-out rotate transition duration-300' />
                </a>
              </div>
            )}
            {about?.socialMedia?.linkedin && (
              <div className='rounded-full w-[34px] h-[34px] bg-primary-headingColor flex items-center justify-center ml-3 shadow-lg hover:scale-105 cursor-pointer'>
                <a
                  href={`https://${about?.socialMedia?.linkedin}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaLinkedinIn className='text-white cursor-pointer ease-in-out rotate transition duration-300' />
                </a>
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
      {dataDetails.length > 1 && (
        <div className="flex justify-center mt-4">
          <button
            // onClick={handleToggleShowMore}
            className="text-white font-mulish text-base transition duration-300 ease-in-out hover:text-white px-4 py-2 rounded-full hover:bg-white"
          >
            {showMore ? 'View Less' : 'View More'}
          </button>
        </div>
      )}
    </div>
  );
};

AboutMe.propTypes = {
  dataDetails: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      socialMedia: PropTypes.shape({
        facebook: PropTypes.string,
        email: PropTypes.string,
        linkedin: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default AboutMe;



















// import React from "react";
// import { ChevronRight } from "lucide-react";
// import { IoMail } from "react-icons/io5";
// import Heading from "../common/Heading";
// import PropTypes from "prop-types";
// import { FaFacebookSquare, FaLinkedinIn } from "react-icons/fa";

// const AboutMe = ({ dataDetails }) => {

//   return (
//     <div className='py-8 px-8 md:px-10 lg:px-28 xl:px-36 pb-20'>
//       <div className='pb-16'>
//         <Heading heading='About Me' />
//       </div>
//       {dataDetails?.map((about, index) => (
//         <React.Fragment key={index}>
//           <div className='h-fit w-full pb-4 flex flex-col md:flex-row'>
//             <div className='h-[45vh] md:h-[50vh] lg:h-[50vh] xl:h-[50vh] w-full md:w-[45%] mb-6 md:ml-12 md:order-2'>
//               <div className='h-[62%] md:h-[80%] w-full'>
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${about.image}`}
//                   alt='about-me'
//                   className='h-full w-full rounded-tr-[80px] rounded-bl-[80px]'
//                 />
//               </div>
//               <div className='flex items-center justify-center'>
//                 <button className='text-white font-mulish text-base bg-primary-btnColor transition duration-300 ease-in-out hover:text-black hover:border hover:border-primary-btnColor px-4 py-2 rounded-full hover:bg-white flex items-center mt-8'>
//                   Download CV
//                   <ChevronRight className='w-5 h-5 ml-2' />
//                 </button>
//               </div>
//             </div>
//             <div
//               style={{ lineHeight: "30px" }}
//               className='font-mulish text-base text-justify md:w-[55%] md:order-1'
//               dangerouslySetInnerHTML={{ __html: about.name }}
//             ></div>
//           </div>
//           <div className='flex items-center justify-end py-6'>
//             {about?.socialMedia?.facebook && (
//               <div className='rounded-full w-[34px] h-[34px] bg-primary-headingColor flex items-center justify-center shadow-lg hover:scale-105 cursor-pointer'>
//                 <a
//                   href={`https://${about?.socialMedia?.facebook}`}
//                   target='_blank'
//                   rel='noopener noreferrer'
//                 >
//                   <FaFacebookSquare className='text-white cursor-pointer ease-in-out rotate transition duration-300' />
//                 </a>
//               </div>
//             )}
//             {about?.socialMedia?.email && (
//               <div className='rounded-full w-[34px] h-[34px] bg-primary-headingColor flex items-center justify-center ml-3 shadow-lg hover:scale-105 cursor-pointer'>
//                 <a
//                   href={`mailto:${about?.socialMedia?.email}`}
//                   target='_blank'
//                   rel='noopener noreferrer'
//                 >
//                   <IoMail className='text-white cursor-pointer ease-in-out rotate transition duration-300' />
//                 </a>
//               </div>
//             )}
//             {about?.socialMedia?.linkedin && (
//               <div className='rounded-full w-[34px] h-[34px] bg-primary-headingColor flex items-center justify-center ml-3 shadow-lg hover:scale-105 cursor-pointer'>
//                 <a
//                   href={`https://${about?.socialMedia?.linkedin}`}
//                   target='_blank'
//                   rel='noopener noreferrer'
//                 >
//                   <FaLinkedinIn className='text-white cursor-pointer ease-in-out rotate transition duration-300' />
//                 </a>
//               </div>
//             )}
            
//           </div>
//         </React.Fragment>
//       ))}
//     </div>
//   );
// };

// AboutMe.propTypes = {
//   dataDetails: PropTypes.arrayOf(
//     PropTypes.shape({
//       image: PropTypes.string,
//       name: PropTypes.string,
//       socialMedia: PropTypes.shape({
//         facebook: PropTypes.string,
//         email: PropTypes.string,
//         linkedin: PropTypes.string,
//         // youtube: PropTypes.string,
//       }),
//     })
//   ).isRequired,
// };

// export default AboutMe;




























































// import React from "react";
// import { ChevronRight } from "lucide-react";
// import { IoMail } from "react-icons/io5";
// import Heading from "../common/Heading";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import { FaFacebookSquare, FaLinkedinIn, FaYoutube } from "react-icons/fa";

// const AboutMe = ({ dataDetails }) => {
//   return (
//     <div className='py-8 px-8 md:px-10 lg:px-28 xl:px-36 pb-20'>
//       <div className='pb-16'>
//         <Heading heading='About Me' />
//       </div>
//       {dataDetails?.map((about, index) => (
//         <React.Fragment key={index}>
//           <div className='h-fit w-full pb-4'>
//             <div className='h-[60vh] md:h-[65vh] lg:h-[78vh] xl:h-[83vh] w-full md:w-[45%] md:float-right mb-6 md:ml-12'>
//               <div className='h-[82%] md:h-[92%] w-full'>
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
//                     about.image
//                   }`}
//                   alt='about-me'
//                   className='h-full w-full rounded-tr-[80px] rounded-bl-[80px]'
//                 />
//               </div>
//               <div className='flex items-center justify-center'>
//                 <button className='text-white font-mulish text-base bg-primary-btnColor transition duration-300 ease-in-out hover:text-black hover:border hover:border-primary-btnColor px-4 py-2 rounded-full hover:bg-white flex items-center mt-8'>
//                   Download CV
//                   <ChevronRight className='w-5 h-5 ml-2' />
//                 </button>
//               </div>
//             </div>
//             <div
//               style={{ lineHeight: "30px" }}
//               className='font-mulish text-base text-justify'
//               dangerouslySetInnerHTML={{ __html: about.name }}
//             ></div>
//           </div>
//           <div className='flex items-center justify-end py-6'>
//             {about?.socialMedia?.facebook && (
//               <div className='rounded-full w-[34px] h-[34px] bg-primary-headingColor flex items-center justify-center shadow-lg hover:scale-105 cursor-pointer'>
//                 <Link
//                   to={`${about?.socialMedia?.facebook}`}
//                   target='_blank'
//                   rel='noopener noreferrer'
//                 >
//                   <FaFacebookSquare className='text-white cursor-pointer ease-in-out rotate transition duration-300' />
//                 </Link>
//               </div>
//             )}
//             {about?.socialMedia?.email && (
//               <div className='rounded-full w-[34px] h-[34px] bg-primary-headingColor flex items-center justify-center ml-3 shadow-lg hover:scale-105 cursor-pointer'>
//                 <Link
//                   to={`mailto:${about?.socialMedia?.email}`}
//                   target='_blank'
//                   rel='noopener noreferrer'
//                 >
//                   <IoMail className='text-white cursor-pointer ease-in-out rotate transition duration-300' />
//                 </Link>
//               </div>
//             )}
//             {about?.socialMedia?.linkedin && (
//               <div className='rounded-full w-[34px] h-[34px] bg-primary-headingColor flex items-center justify-center ml-3 shadow-lg hover:scale-105 cursor-pointer'>
//                 <Link
//                   to={`${about?.socialMedia?.linkedin}`}
//                   target='_blank'
//                   rel='noopener noreferrer'
//                 >
//                   <FaLinkedinIn className='text-white cursor-pointer ease-in-out rotate transition duration-300' />
//                 </Link>
//               </div>
//             )}
//             {about?.socialMedia?.youtube && (
//               <div className='rounded-full w-[34px] h-[34px] bg-primary-headingColor flex items-center justify-center ml-3 shadow-lg hover:scale-105 cursor-pointer'>
//                 <Link
//                   to={`${about?.socialMedia?.youtube}`}
//                   target='_blank'
//                   rel='noopener noreferrer'
//                 >
//                   <FaYoutube className='text-white cursor-pointer ease-in-out rotate transition duration-300' />
//                 </Link>
//               </div>
//             )}
//           </div>
//         </React.Fragment>
//       ))}
//     </div>
//   );
// };
// AboutMe.propTypes = {
//   dataDetails: PropTypes.arrayOf(PropTypes.shape({
//     image: PropTypes.string,
//     name: PropTypes.string,
//     socialMedia: PropTypes.shape({
//       facebook: PropTypes.string,
//       email: PropTypes.string,
//       linkedin: PropTypes.string,
//       youtube: PropTypes.string,
//     }),
//   })).isRequired,
// };

// export default AboutMe;
