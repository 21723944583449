/* eslint-disable no-unused-vars */
import React from "react";
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import AchievementForm from "../../../components/forms/postForms/achievementForm/AchievementForm";

const AddAchievement = () => {
  return (
    <Container
      breadcumbMenu="achievements"
      breadcumbSubMenu="achievements"
      title="New achievements "
      btnTitle="back to_Achievements"
      path="/admin/achievements"
    >
      <FormContainer heading="Add achievements">
        <AchievementForm />
      </FormContainer>
    </Container>
  );
};

export default AddAchievement;
