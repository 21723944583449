const correctPassword = '1'; // Replace with your actual password

// Check if the user is already authenticated
if (localStorage.getItem('authenticated') === 'true') {
  document.getElementById('auth-container').style.display = 'none';
  document.getElementById('main-content').style.display = 'block';
}

document.getElementById('submit-button').addEventListener('click', () => {
  const inputPassword = document.getElementById('password-input').value;
  if (inputPassword === correctPassword) {
    localStorage.setItem('authenticated', 'true');
    document.getElementById('auth-container').style.display = 'none';
    document.getElementById('main-content').style.display = 'block';
  } else {
    document.getElementById('error-message').style.display = 'block';
  }
});














// const correctPassword = '1'; // Replace with your actual password

// document.getElementById('submit-button').addEventListener('click', () => {
//   const inputPassword = document.getElementById('password-input').value;
//   if (inputPassword === correctPassword) {
//     document.getElementById('auth-container').style.display = 'none';
//     document.getElementById('main-content').style.display = 'block';
//   } else {
//     document.getElementById('error-message').style.display = 'block';
//   }
// });
