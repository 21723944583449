import { useState } from 'react';
import ArticleCard from '../card/ArticleCard';
import PropTypes from 'prop-types';

const Tabs = ({ tabs, articles }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]._id);
  
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  
  return (
    <div className="mx-auto">
      <div className="w-fit  mx-auto flex">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(tab?._id)}
            className={`w-fit ${
              activeTab === tab?._id
                ? 'border-b-2 border-yellow-500 text-yellow-500'
                : 'border-b-2'
            } py-2 px-4 focus:outline-none capitalize`}
          >
            {tab?.categoryName}
          </button>
        ))}
      </div>
      <div className="mt-10 ">
        {tabs?.map((tab) => (
          <div
            key={tab?._id}
            className={`${activeTab === tab?._id ? 'block' : 'hidden'} p-4`}
          >
            <div className="grid grid-cols-1 my-10 md:grid-cols-2 lg:grid-cols-2 gap-10 lg:gap-[2rem] p-5 lg:px-[9rem]">
              {articles
                ?.filter((article) => article.category?._id === tab?._id)
                ?.map((article, index) => (
                  <ArticleCard key={index} article={article} />
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      categoryName: PropTypes.string.isRequired,
    })
  ).isRequired,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      category: PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }).isRequired,
      // Add other article properties if necessary
    })
  ).isRequired,
};
export default Tabs;
