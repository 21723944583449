import Heading from '../components/common/Heading';
import Card from '../components/card/Card';
import Tabs from '../components/tabs/Tabs';
import parse from 'html-react-parser';
import { ChevronsRight } from 'lucide-react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useMediaQuery } from 'react-responsive';
import { useFetchData } from '../hooks/useFetchData/useFetchData';

// eslint-disable-next-line no-unused-vars
const myHeroImages = [
  {
    image: '/images/ArticlesBg.png',
  },
  {
    image: '/images/ArticlesBg.png',
  },
  {
    image: '/images/ArticlesBg.png',
  },
];

const Articles = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const { data, loading } = useFetchData({ endpoint: 'articles' });
  const { data: articleCategory, loading: categoryLoading } = useFetchData({
    endpoint: 'article/categories',
  });

  if (categoryLoading || loading) {
    return null;
  }

  return (
    <>
      <div className="w-full min-h-[80vh] relative">
        <AliceCarousel
          mouseTracking
          disableButtonsControls
          autoPlay
          infinite
          autoPlayStrategy="none"
          autoPlayInterval={3000}
          items={data?.map((item, index) => (
            <div key={index} className="h-[60vh] md:h-[70vh] overflow-hidden w-screen">
              <svg
                viewBox="0 0 500 150"
                preserveAspectRatio="none"
                style={{ height: '100%', width: '100%' }}
              >
                <clipPath id={`clipPath-${index}`}>
                  <path
                    d={
                      !isSmallScreen
                        ? 'M0,120 C150,180 300,80 500,120 L500,0 L0,0 Z'
                        : 'M0,120 C200,140 180,80 500,120 L500,0 L0,0 Z'
                    }
                    style={{
                      stroke: 'none',
                      fill: '#e1efe3',
                    }}
                  ></path>
                </clipPath>
                <image
                  xlinkHref='/images/ArticlesBg.png'
                  clipPath={`url(#clipPath-${index})`}
                  style={{ objectPosition: 'center' }}
                  preserveAspectRatio="xMinYMin slice"
                  width={'100%'}
                  height={'100%'}
                />
              </svg>
              <div className="absolute top-[30%] md:top-14 w-[85%] md:w-[50%] lg:w-[32%] right-4 md:right-12 lg:right-24 z-20 md:z-0">
                <h1 className="text-2xl md:text-3xl font-bold">{item.title}</h1>
                <p
                  className="my-5 font-mulish font-bold text-base text-justify line-clamp-3 overflow-hidden whitespace-wrap"
                  style={{ lineHeight: '30px' }}
                >
                  {parse(`${item?.description}`)}
                </p>
                <button className="text-white font-mulish text-base bg-primary-headingColor transition duration-300 ease-in-out hover:text-black hover:border hover:border-primary-headingColor px-4 py-2 rounded-full hover:bg-white flex items-center mt-8">
                  Learn More
                  <ChevronsRight className="w-5 h-5 ml-2" />
                </button>
              </div>
            </div>
          ))}
        />
      </div>
      <div className="py-10">
        <Heading heading="Latest Articles" />
        {data && (
          <div className="grid grid-cols-1 my-10 md:grid-cols-2 lg:grid-cols-2 gap-10 lg:gap-[5rem] p-5 lg:px-[9rem]">
            {data?.map((article, index) => (
              <Card key={index} article={article} />
            ))}
          </div>
        )}

        <Heading heading="List of article" className="mt-[10rem] mb-7" />

        <Tabs tabs={articleCategory} articles={data} />
      </div>
    </>
  );
};

export default Articles;










// import Heading from '../components/common/Heading';
// import Card from '../components/card/Card';
// import Tabs from '../components/tabs/Tabs';
// import parse from 'html-react-parser';
// import { ChevronsRight } from 'lucide-react';
// import AliceCarousel from 'react-alice-carousel';
// import 'react-alice-carousel/lib/alice-carousel.css';
// import { useMediaQuery } from 'react-responsive';
// import { useFetchData } from '../hooks/useFetchData/useFetchData';

// const Articles = () => {
//   const isSmallScreen = useMediaQuery({ maxWidth: 768 });
//   const { data: articles, loading: articlesLoading } = useFetchData({ endpoint: 'articles' });
//   const { data: articleCategories, loading: categoriesLoading } = useFetchData({
//     endpoint: 'article/categories',
//   });

//   if (categoriesLoading || articlesLoading) {
//     return null;
//   }

//   return (
//     <>
//       <div className="w-full min-h-[80vh] relative">
//         <AliceCarousel
//           mouseTracking
//           disableButtonsControls
//           autoPlay
//           infinite
//           autoPlayStrategy="none"
//           autoPlayInterval={3000}
//           items={articles?.map((item, index) => (
//             <div key={index} className="h-[60vh] md:h-[70vh] overflow-hidden w-screen">
//               <svg
//                 viewBox="0 0 500 150"
//                 preserveAspectRatio="none"
//                 style={{ height: '100%', width: '100%' }}
//               >
//                 <clipPath id={`clipPath-${index}`}>
//                   <path
//                     d={
//                       !isSmallScreen
//                         ? 'M0,120 C150,180 300,80 500,120 L500,0 L0,0 Z'
//                         : 'M0,120 C200,140 180,80 500,120 L500,0 L0,0 Z'
//                     }
//                     style={{
//                       stroke: 'none',
//                       fill: '#e1efe3',
//                     }}
//                   ></path>
//                 </clipPath>
//                 <image
//                   xlinkHref='/images/ArticlesBg.png'
//                   clipPath={`url(#clipPath-${index})`}
//                   style={{ objectPosition: 'center' }}
//                   preserveAspectRatio="xMinYMin slice"
//                   width={'100%'}
//                   height={'100%'}
//                 />
//               </svg>
//               <div className="absolute top-[30%] md:top-14 w-[85%] md:w-[50%] lg:w-[32%] right-4 md:right-12 lg:right-24 z-20 md:z-0">
//                 <h1 className="text-2xl md:text-3xl font-bold">{item.title}</h1>
//                 <p
//                   className="my-5 font-mulish font-bold text-base text-justify line-clamp-3 overflow-hidden whitespace-wrap"
//                   style={{ lineHeight: '30px' }}
//                 >
//                   {parse(`${item?.description}`)}
//                 </p>
//                 <button className="text-white font-mulish text-base bg-primary-headingColor transition duration-300 ease-in-out hover:text-black hover:border hover:border-primary-headingColor px-4 py-2 rounded-full hover:bg-white flex items-center mt-8">
//                   Learn More
//                   <ChevronsRight className="w-5 h-5 ml-2" />
//                 </button>
//               </div>
//             </div>
//           ))}
//         />
//       </div>
//       <div className="py-10">
//         <Heading heading="Latest Articles" />
//         {articles && (
//           <div className="grid grid-cols-1 my-10 md:grid-cols-2 lg:grid-cols-2 gap-10 lg:gap-[5rem] p-5 lg:px-[9rem]">
//             {articles?.map((article, index) => (
//               <Card key={index} article={article} />
//             ))}
//           </div>
//         )}

//         <Heading heading="List of article" className="mt-[10rem] mb-7" />

//         <Tabs tabs={articleCategories} articles={articles} />
//       </div>
//     </>
//   );
// };

// export default Articles;
