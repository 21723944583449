import Container from "../../components/container/Container";
import Loader from "../../components/loader/Loader";
import DataFetchingError from "../errorPage/DataFetchingError";
import { useFetchData } from "../../hooks/useFetchData/useFetchData";
import DataNotFound from "../errorPage/DataNotFound";
import ColorTable from "../../components/forms/tables/ColorTable";

const Color = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: "colors",
  });

  return (
    <Container
      breadcumbMenu='Color'
      title='Color'
      btnTitle='Add Color'
      path='add'
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refetchData={refetchData} />
      ) : data.length > 0 ? (
        <ColorTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Color;
