/* eslint-disable no-unused-vars */
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import EventMemberForm from "../../../components/forms/postForms/InvolvementAchievementsForm/InvolvementAchievementsForm";
import EventSponsorForm from "../../../components/forms/postForms/eventSponsorForm/EventSponsorForm";

const AddEventSponsor = () => {
  return (
    <Container
      breadcumbMenu='event Sponsor'
      breadcumbSubMenu='new event Sponsor'
      title='New event Sponsor'
      btnTitle='back to event Sponsor'
      path='/admin/event-sponsors'
    >
      <FormContainer heading='Add event Sponsor'>
        <EventSponsorForm />
      </FormContainer>
    </Container>
  );
};

export default AddEventSponsor;
