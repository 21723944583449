import { useEffect, useState } from "react";
import axios from "axios";

const useFetchDataDetails = (endpoint, id) => {
  const [dataDetails, setDataDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const token = JSON.parse(localStorage.getItem("amrityonjan")).accessToken;

    // Retrieve token and isAuthenticated from localStorage safely
    const storedData = localStorage.getItem("amrityonjan");
    const parsedData = storedData ? JSON.parse(storedData) : {};
    const { isAuthenticated, accessToken: token } = parsedData;

  const fetchDataDetails = async () => {

    let headers = {
      "Content-Type": "application/json",
    };

    if (isAuthenticated) {
      if (!token) {
        setError("No access token found. Please log in again.");
        setLoading(false);
        return;
      }
      headers["Authorization"] = `Bearer ${token}`;
    }
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/${endpoint}/${id}`,
        {
          credentials: "include", // Add credentials option here
          headers: headers,
          // headers: {
          //   "Content-Type": "multipart/form-data",
          //   Authorization: `Bearer ${token}`,
          // },
        }
      );

      if (response.status === 200) {
        setDataDetails(response.data.data);
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const refetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/${endpoint}/${id}`
      );
      if (response.status === 200) {
        setDataDetails(response.data.data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { dataDetails, loading, error, refetchData };
};

export default useFetchDataDetails;




















// import { useEffect, useState } from "react";
// import axios from "axios";

// const useFetchDataDetails = (endpoint, id) => {
//   const [dataDetails, setDataDetails] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchDataDetails = async () => {
//     try {
//       const response = await axios.get(
//         `${import.meta.env.VITE_APP_API_URL}/${endpoint}/${id}`,
//         {
//           credentials: "include", // Add credentials option here
//           headers: {
//             "Content-Type": "multipart/form-data",
//             // Add other headers if needed
//           },
//         }
//       );

//       if (response.status === 200) {
//         setDataDetails(response.data.data);
//       }
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const refetchData = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.get(
//         `${import.meta.env.VITE_APP_API_URL}/${endpoint}/${id}`
//       );
//       if (response.status === 200) {
//         setDataDetails(response.data.data);
//       }
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchDataDetails();
//   }, [id]);

//   return { dataDetails, loading, error, refetchData };
// };

// export default useFetchDataDetails;









// amrityonjan typescript conversion
// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const useFetchDataDetails = (endpoint, id) => {
//   const [dataDetails, setDataDetails] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchDataDetails = async () => {
//     try {
//       const response = await axios.get(
//         `${import.meta.env.VITE_APP_API_URL}/${endpoint}/${id}`
//       );

//       if (response.status === 200) {
//         setDataDetails(response.data.data);
//       } else {
//         throw new Error(`Unexpected status code: ${response.status}`);
//       }
//     } catch (error) {
//       handleRequestError(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleRequestError = (error) => {
//     if (error.response) {
//       setError(`Error fetching data: ${error.response.status}`);
//     } else if (error.request) {
//       setError("No response received from the server");
//     } else {
//       setError(`Error setting up the request: ${error.message}`);
//     }
//   };

//   useEffect(() => {
//     fetchDataDetails();
//   }, []);

//   return { dataDetails, loading, error };
// };

// export default useFetchDataDetails;
