/* eslint-disable no-unused-vars */
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import { useParams } from "react-router-dom";
import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";
import Loader from "../../../components/loader/Loader";
import DataFetchingError from "../../errorPage/DataFetchingError";
import DonationForm from "../../../components/forms/postForms/donorForm/DonorForm";
import ColorForm from "../../../components/forms/postForms/colorForm/ColorForm";

const EditColor = () => {
  const { id } = useParams();
  const { dataDetails, loading, error, refetchData } = useFetchDataDetails(
    "colors",
    id
  );

  return (
    <Container
      breadcumbMenu='colors'
      breadcumbSubMenu='edit colors'
      title='Edit colors'
      btnTitle='back to colors'
      path='/colors'
    >
      <FormContainer heading='edit colors'>
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError error={error} refetchData={refetchData} />
        ) : (
          <ColorForm defaultData={dataDetails} isEdit='true ' />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditColor;
