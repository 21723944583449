import { useEffect, useRef } from "react";
import { MailCheck } from "lucide-react";
import { FaFacebook } from "react-icons/fa";
import { formatDate } from "../../utility/dateFormates";
import PropTypes from 'prop-types';

const DonorPopup = ({ donarDetails, closePopup }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target)
      ) {
        closePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closePopup]);

  return (
    <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm z-40 overflow-y-auto h-full'>
      <div
        ref={popupRef}
        className='h-fit my-4 bg-orange-50	 box-shadow mx-auto flex items-center justify-center flex-col px-8 py-10 w-[93%] md:w-[70%] lg:w-[60%] xl:w-[55%] relative'
      >
        <h1
          className='text-3xl text-right absolute right-2 top-0 font-semibold transition-colors duration-500 transform  hover:text-primary-btnColor cursor-pointer active:scale-110'
          onClick={closePopup}
        >
          X
        </h1>
        <h1 className='font-sourceSerifPro italic text-primary-btnColor text-2xl font-semibold'>
          Thank you for your contribution!
        </h1>
        <h1 className='my-4 text-primary-subHeading font-fold capitalize text-3xl'>
          {donarDetails?.donorId?.name}
        </h1>
        <div className='w-[90%] md:w-[80%] lg:w-[70%] h-[45vh]'>
          <img
            src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
              donarDetails?.donorId?.image
            }`}
            alt='donor'
            className='rounded w-full h-full object-cover object-center'
          />
        </div>

        <div
          className='text-justify font-roboto py-6'
          style={{
            lineHeight: "30px",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: "8",
            width: "95%",
          }}
        >
          <p>{donarDetails?.donorId?.description}</p>
        </div>
        <div className='flex items-center'>
          <MailCheck className='w-5 h-5 mr-2 text-gray-400' />
          <p className='font-roboto font-semibold'>
            {donarDetails?.donorId?.socialMedia?.facebook}
          </p>
        </div>
        <div className='flex items-center py-4'>
          <FaFacebook className='w-5 h-5 mr-2 text-blue-500' />
          <p className='font-roboto font-semibold'>
            {donarDetails?.donorId?.socialMedia?.email}
          </p>
        </div>
        <div className='font-semibold text-xl'>
          Contribution Amount{" "}
          <span className='italic font-sourceSerifPro text-primary-headingColor'>
            Rs {donarDetails?.donationAmt}
          </span>
        </div>
        <p className='font-semibold text-xl pt-2'>
          {formatDate(donarDetails?.donateDate)}
        </p>
      </div>
    </div>
  );
};
DonorPopup.propTypes = {
  donarDetails: PropTypes.shape({
    donorId: PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      socialMedia: PropTypes.shape({
        facebook: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    donationAmt: PropTypes.number.isRequired,
    donateDate: PropTypes.string.isRequired,
  }).isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default DonorPopup;
