import { useState } from 'react';
import Heading from '../common/Heading';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
const responsive = {
  0: { items: 1 },
  700: { items: 2 },
};

const Publications = ({ publicationData }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const numberOfBooks = publicationData.length;
  const bookHeight = 100 / numberOfBooks - 2;
  const onBookSelect = (index) => {
    setSelectedIndex(index);
  };

  const selectedPublication = publicationData[selectedIndex];

  return (
    <div className="px-8 md:px-8 lg:px-12 xl:px-36 pb-16 md:pb-20">
      <div className="pb-8 md:pb-10">
        <Heading heading="List Of Publications" />
      </div>
      <div className="lg:flex w-full h-fit lg:h-fit xl:h-fit">
        <div className="px-10 w-full lg:w-[50%] md:h-[60vh] lg:h-[40rem] box-shadow py-6 flex flex-col md:flex-row lg:flex-col">
          <div className="sm:w-[95%] md:w-[45%] lg:h-[30rem] lg:w-[50%] mx-auto">
            <img
              src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${selectedPublication?.image}`}
              alt="book"
              className="h-full w-full object-cover"
            />
          </div>
          <div className="md:ml-8 lg:ml-0 h-full w-full md:w-1/2 lg:w-full">
            <h1 className="font-inter text-primary-headingColor capitalize text-xl line-clamp-1 sm:line-clamp-2 font-semibold pt-4 lg:pt-8">
              {selectedPublication?.bookTitle}
            </h1>
            <h1 className="text-base font-semibold pt-3 capitalize">
              Publisher:
              <span className="ms-2">{selectedPublication?.publication}</span>
            </h1>
            <button className="my-5 text-white bg-primary-btnColor capitalize px-2 py-1 hover:text-primary-btnColor hover:bg-white hover:border hover:border-primary-btnColor">
              {selectedPublication?.isPurchased ? 'paid' : 'Free Download'}
            </button>
            <div className="text-justify line-clamp-2 sm:line-clamp-5 text-base overflow-hidden whitespace-wrap">
              <p>{selectedPublication?.summary}</p>
            </div>
            <p className="text-primary-btnColor text-sm font-bold mt-4 cursor-pointer duration-300 ease-in-out">
              <Link to={`/publications/${selectedPublication?._id}`}>
                View Detail
              </Link>
            </p>
          </div>
        </div>
        <div
          className={`hidden lg:block w-1/2 px-9 lg:px-6 xl:px-10 h-full lg:h-[40rem] box-shadow ml-4 ${
            numberOfBooks > 4 ? 'overflow-scroll overflow-x-hidden' : ''
          }`}
        >
          {publicationData.map((item, index) => (
            <div
              key={index}
              className={`lg:h-[${bookHeight - 1}%] xl:h-[${bookHeight}%] transition duration-500 cursor-pointer ease-in-out px-3 lg:px-5 border border-gray-200 rounded-md flex w-full my-5 lg:my-5 xl:my-4 py-3 ${
                index === selectedIndex
                  ? 'bg-secondary-footerBg text-white'
                  : 'bg-white'
              }`}
              onMouseOver={() => onBookSelect(index)}
            >
              <div className="w-[20%] h-[90%] xl:h-[60%] my-auto">
                <img
                  src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
                  alt="book"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="h-full w-[75%] ml-4">
                <h1 className="font-inter text-xl font-semibold line-clamp-1 xl:mt-4 capitalize">
                  {item.bookTitle}
                </h1>
                <h1 className="text-base font-semibold pt-1 capitalize line-clamp-1">
                  Publisher: {item.publication}
                </h1>
                <div className="text-justify line-clamp-2 sm:line-clamp-3 text-base overflow-hidden whitespace-wrap">
                  <p>{item.summary}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-10 lg:hidden">
          <AliceCarousel
            mouseTracking
            disableDotsControls
            responsive={responsive}
            renderPrevButton={() => (
              <div className="flex items-center justify-end">
                <ArrowLeft className="text-primary-btnColor cursor-pointer" />
              </div>
            )}
            renderNextButton={() => (
              <div className="flex items-center justify-start">
                <ArrowRight className="text-primary-btnColor cursor-pointer" />
              </div>
            )}
          >
            {publicationData.map((item, index) => (
              <div
                key={index}
                className={`cursor-pointer h-fit px-3 lg:px-5 border border-gray-200 rounded-md py-4 transition ease-in-out duration-500 ${
                  index === selectedIndex
                    ? 'bg-secondary-footerBg text-white'
                    : 'bg-white'
                }`}
                onMouseOver={() => onBookSelect(index)}
              >
                <div className="w-[40%] h-fit md:h-[50%] mx-auto">
                  <img
                    src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
                    alt="book"
                    className="h-full w-full object-contain"
                  />
                </div>
                <div className="w-full md:text-center px-2 py-2">
                  <h1 className="font-inter capitalize text-xl font-semibold mt-4">
                    {item.bookTitle}
                  </h1>
                  <h1 className="text-base font-semibold pt-1">
                    Publisher: {item.publication}
                  </h1>
                  <div className="text-justify line-clamp-2 text-base">
                    <p>{item.summary}</p>
                  </div>
                </div>
              </div>
            ))}
          </AliceCarousel>
        </div>
      </div>
    </div>
  );
};
Publications.propTypes = {
  publicationData: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    bookTitle: PropTypes.string.isRequired,
    publication: PropTypes.string.isRequired,
    isPurchased: PropTypes.bool.isRequired,
    summary: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  })).isRequired,
};


export default Publications;
