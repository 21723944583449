import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import { useParams } from "react-router-dom";
import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";
import DataFetchingError from "../../errorPage/DataFetchingError";
import Loader from "../../../components/loader/Loader";
import ContactInfoForm from "../../../components/forms/postForms/contactInfoForm/ContactInfoForm";

const EditContactInfo = () => {
  const { id } = useParams();

  const { dataDetails, loading, error } = useFetchDataDetails(
    "contacts",
    id
  );

  return (
    <Container
      breadcumbMenu='contacts'
      breadcumbSubMenu='edit contacts'
      title='edit contacts-info'
      btnTitle='back to contacts'
      path='/admin/contacts'
    >
      <FormContainer heading='edit contacts'>
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <ContactInfoForm defaultData={dataDetails} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditContactInfo;
