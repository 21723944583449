import { useState } from 'react';
import { ChevronsDown, ChevronsUp } from 'lucide-react';
import Heading from '../common/Heading';
import AffiliationCard from '../card/AffiliationCard';
import PropTypes from 'prop-types';
const MoreBtn = () => {
  return (
    <button className="text-black font-mulish text-base transition duration-300 ease-in-out hover:border hover:text-primary-btnColor px-4 py-2 rounded-full hover:border-primary-btnColor flex items-center ">
      More
      <ChevronsDown className="w-5 h-5 ml-2" />
    </button>
  );
};

const LessBtn = () => {
  return (
    <button className="text-black font-mulish text-base transition duration-300 ease-in-out hover:border hover:text-primary-btnColor px-4 py-2 rounded-full hover:border-primary-btnColor flex items-center ">
      Less
      <ChevronsUp className="w-5 h-5 ml-2" />
    </button>
  );
};

const Affiliations = ({ affiliationData }) => {
  const [btnClicked, setBtnClicked] = useState(false);
  const onBtnClick = () => setBtnClicked(!btnClicked);
  return (
    <>
      <div className="bg-[url('/images/affiliationbg.png')] bg-cover bg-no-repeat h-fit w-full bg-center pt-16 px-8 md:px-20 lg:px-28 xl:px-36">
        {btnClicked || affiliationData.length <= 5 ? (
          <>
            <div className="py-10">
              <Heading heading="Affiliations" />
            </div>
            <div className="flex flex-wrap justify-center items-center mt-8">
              {affiliationData.map((items, index) => (
                <AffiliationCard key={index} items={items} />
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="py-10">
              <Heading heading="Affiliations" />
            </div>
            <div className="flex flex-wrap justify-center items-center mt-16">
              {affiliationData.slice(0, 5).map((items, index) => (
                <AffiliationCard key={index} items={items} />
              ))}
            </div>
          </>
        )}
        {affiliationData.length > 5 && (
          <div onClick={onBtnClick} className="flex items-center justify-center">
            {btnClicked ? <LessBtn /> : <MoreBtn />}
          </div>
        )}
      </div>
      <div className="w-full mb-4">
        <svg
          viewBox="0 0 500 70"
          preserveAspectRatio="none"
          style={{ height: '100%', width: '100%' }}
        >
          <defs>
            <pattern
              id="image-pattern"
              patternUnits="userSpaceOnUse"
              width="100%"
              height="100%"
            >
              <image
                href="/images/affiliationbg.png"
                width="100%"
                height="100%"
                preserveAspectRatio="xMidYMid slice"
              />
            </pattern>
          </defs>
          <path
            d="M0,0 L500,0 L500,50 C300,110 150,20 0,50 Z"
            style={{
              stroke: 'none',
              fill: 'url(#image-pattern)',
            }}
          ></path>
        </svg>
      </div>
    </>
  );
};
Affiliations.propTypes = {
  affiliationData: PropTypes.arrayOf(PropTypes.shape({
    // Define the shape of each object in the array
    // For example: id: PropTypes.number, name: PropTypes.string, etc.
  })).isRequired,
};
export default Affiliations;
