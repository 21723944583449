import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { formatDate } from "../../utility/dateFormates";
import Heading from "../common/Heading";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const Achievements = ({ achievementData }) => {
  const [loading, setLoading] = useState(true);
  const [activeAchievement, setActiveAchievement] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [achievementData]);

  const handleClick = (achievement) => {
    setActiveAchievement(activeAchievement?._id === achievement._id ? null : achievement);
  };

  const closePopup = (e) => {
    if (e.target.id === "popupOverlay") {
      setActiveAchievement(null);
    }
  };

  return (
    <>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <div>Loading...</div>
        </div>
      ) : (
        <div className="w-full h-full overflow-hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 2000 200"
            fill="none"
          >
            <path
              d="M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z"
              fill="#FFF0E1"
              fillOpacity="0.5"
            />
          </svg>
        </div>
      )}
      <div className="w-full bg-secondary-detailsBackground pt-8 px-4 md:px-8 lg:px-16 xl:px-24 relative h-[32rem] sm:h-[140vh] overflow-hidden">
        <Heading heading="Achievements | Awards" />
        <div className="items-center justify-center w-[100%] lg:w-[100%] xl:w-[100%] mx-auto relative top-[4rem] md:top-[2rem] lg:top-[2rem] left-[1%] md:left-[1%] lg:left-[1%] xl:left-[1%]">
          <img
            src="/images/common/Decortop.png"
            alt="upwraper"
            className="absolute top-[-4%] md:top-[-2%] left-[-6%] sm:left-[-4rem] m-1"
          />
          <div className="w-full py-5 relative z-0"> 
            <img
              src="/images/AwardsBg.png"
              alt="Achievements"
              className="w-full h-fit md:h-[60vh] lg:h-[70vh] object-contain object-center p-2" 
            />
          </div>
          <img
            src="/images/common/Decorbottom.png"
            alt="upwraper"
            className="absolute right-[-5%] md:right-[-8%] top-[80%] md:top-[95%] lg:right-[-4%] m-1"
          />
        </div>
        <div className="w-[95%] lg:w-[90%] xl:w-[80%] mx-auto absolute top-[12rem] md:top-[20rem] lg:top-[32rem] left-[5%] md:left-[5%] lg:left-[8%] xl:left-[12%]">
          <Swiper
            modules={[Navigation]}
            spaceBetween={30}
            navigation={{
              prevEl: '.swiper-button-prev',
              nextEl: '.swiper-button-next',
            }}
            breakpoints={{
              0: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
          >
            {Array.isArray(achievementData) &&
              achievementData.map((item) => (
                <SwiperSlide key={item._id} className='relative swiperslide  '>
                  <div className="pt-3 pb-8 h-fit rounded-lg cursor-pointer transition-transform hover:scale-105 ease-in-out duration-300 flex items-center flex-col">
                    <div
                      className="bg-white shadow-lg rounded-full w-32 h-32 sm:w-40 sm:h-40 md:w-50 md:h-50 flex items-center justify-center relative"
                      onClick={() => handleClick(item)}
                    >
                      <img
                        src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
                        alt="logo"
                        className="w-full h-full object-contain p-1 rounded-full"
                      />
                    </div>
                    <div
                      className="mt-4 font-sourceSerifPro flex flex-col items-center"
                      onClick={() => handleClick(item)}
                    >
                      <Link
                        key={item._id}
                        to={``}
                        className="flex flex-col items-center"
                      >
                        <p className="text-xl md:text-2xl pt-4 italic font-semibold capitalize justify-center">
                          {item.title}
                        </p>
                        <p className="text-xl md:text-2xl italic font-semibold capitalize justify-center">
                          {item.address}
                        </p>
                      </Link>
                      <p className="text-lg md:text-xl italic">{formatDate(item.date)}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            <div className="swiper-button-prev text-primary-btnColor cursor-pointer">
              <FaArrowLeft className="w-4 h-4" />
            </div>
            <div className="swiper-button-next text-primary-btnColor cursor-pointer">
              <FaArrowRight className="w-4 h-4" />
            </div>
          </Swiper>
        </div>
      </div>
      {activeAchievement && (
  <div
    id="popupOverlay"
    className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
    onClick={closePopup}
  >
    <div className="bg-secondary-details p-8 md:p-10 rounded-lg shadow-lg max-w-7xl max-h-[90vh] overflow-auto relative">
      <button
        onClick={() => setActiveAchievement(null)}
        className="absolute top-4 right-4 text-2xl font-bold text-gray-600"
      >
        &times;
      </button>
      <div className="flex flex-col items-center">
        <img
          src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.images}`}
          alt="achievement"
          className="w-72 h-48 md:w-96 md:h-60 object-contain p-1"
        />
        <div className="flex justify-center mb-4 p-1">
          <img
            src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.logo}`}
            alt="logo"
            className="w-16 h-16 md:w-20 md:h-20 object-contain rounded-full bg-white"
          />
        </div>
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-2">{activeAchievement.title}</h2>
        <p className="text-lg md:text-xl italic text-center mb-2">{formatDate(activeAchievement.date)}</p>
        <p className="text-base md:text-xl mb-4 text-center">{activeAchievement.description}</p>
      </div>
    </div>
  </div>
)}

      {/* {activeAchievement && (
        <div
          id="popupOverlay"
          className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
          onClick={closePopup}
        >
          <div className="bg-secondary-details p-8 md:p-10 rounded-lg shadow-lg max-w-7xl max-h-90 overflow-auto">
            <button
              onClick={() => setActiveAchievement(null)}
              className="absolute top-4 right-4 text-2xl font-bold text-gray-600"
            >
              &times;
            </button>
            <div className="flex flex-col items-center">
              <img
                src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.images}`}
                alt="achievement"
                className="w-72 h-48 md:w-96 md:h-60 object-contain p-1"
              />
              <div className="flex justify-center mb-4 p-1">
                <img
                  src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.logo}`}
                  alt="logo"
                  className="w-16 h-16 md:w-20 md:h-20 object-contain rounded-full bg-white"
                />
              </div>
              <h2 className="text-2xl md:text-3xl font-bold text-center mb-2">{activeAchievement.title}</h2>
              <p className="text-lg md:text-xl italic text-center mb-2">{formatDate(activeAchievement.date)}</p>
              <p className="text-base md:text-xl mb-4 text-center">{activeAchievement.description}</p>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

Achievements.propTypes = {
  achievementData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      image: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
};

export default Achievements;






// import { useState, useEffect } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Navigation } from 'swiper/modules';
// import { formatDate } from "../../utility/dateFormates";
// import Heading from "../common/Heading";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// const Achievements = ({ achievementData }) => {
//   const [loading, setLoading] = useState(true);
//   const [activeAchievement, setActiveAchievement] = useState(null);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setLoading(false);
//     }, 1000);

//     return () => clearTimeout(timer);
//   }, [achievementData]);

//   const handleClick = (achievement) => {
//     setActiveAchievement(activeAchievement?._id === achievement._id ? null : achievement);
//   };

//   const closePopup = (e) => {
//     if (e.target.id === "popupOverlay") {
//       setActiveAchievement(null);
//     }
//   };

//   return (
//     <>
//       {loading ? (
//         <div className="w-full h-full flex items-center justify-center">
//           <div>Loading...</div>
//         </div>
//       ) : (
//         <div className="w-full h-full overflow-hidden">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="100%"
//             height="100%"
//             viewBox="0 0 2000 200"
//             fill="none"
//           >
//             <path
//               d="M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z"
//               fill="#FFF0E1"
//               fillOpacity="0.5"
//             />
//           </svg>
//         </div>
//       )}
//       <div className="w-full bg-secondary-detailsBackground pt-8 px-4 md:px-8 lg:px-16 xl:px-24 relative h-[32rem] sm:h-[140vh] overflow-hidden">
//         <Heading heading="Achievements | Awards" />

//         <div className="relative mt-16">
//           <img
//             src="/images/common/Decortop.png"
//             alt="upwraper"
//             className="absolute w-8 top-[-4%] md:top-[-2%] left-[-6%] sm:left-[-4rem] m-1 mt-[-3%]"
//             style={{ zIndex: 10 }} // Ensure it's above other elements
//           />
//           <div className="w-full py-5 relative z-0"> {/* Ensure stacking context */}
//             <img
//               src="/images/AwardsBg.png"
//               alt="Achievements"
//               className="w-full h-full object-contain object-center p-2"
//             />
//           </div>
//           <img
//             src="/images/common/Decorbottom.png"
//             alt="upwraper"
//             className="absolute w-8 sm:w-10 right-[-7%] md:right-[-4%] lg:right-[-3%] m-1 mt-[-8%]"
//             style={{ zIndex: 10 }} // Ensure it's above other elements
//           />
//         </div>

//         <div className="w-[95%] h-fit lg:w-[90%] xl:w-[80%] mx-auto absolute top-[12rem] md:top-[20rem] lg:top-[32rem] left-[5%] md:left-[5%] lg:left-[8%] xl:left-[12%]">
//           <Swiper
//             modules={[Navigation]}
//             spaceBetween={30}
//             navigation={{
//               prevEl: '.swiper-button-prev',
//               nextEl: '.swiper-button-next',
//             }}
//             // loop={true}
//             breakpoints={{
//               0: { slidesPerView: 1 },
//               768: { slidesPerView: 2 },
//               1024: { slidesPerView: 3 },
//             }}
//           >
//             {Array.isArray(achievementData) &&
//               achievementData.map((item) => (
//                 <SwiperSlide key={item._id} className="">
//                   <div className="pt-5 pb-8 h-fit rounded-lg cursor-pointer transition-transform hover:scale-105 ease-in-out duration-300 flex items-center flex-col">
//                     <div
//                       className="bg-white shadow-lg rounded-full w-32 h-32 sm:w-40 sm:h-40 md:w-50 md:h-50 flex items-center justify-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <img
//                         src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                         alt="logo"
//                         className="w-full h-full object-contain p-1 rounded-full"
//                       />
//                     </div>
//                     <div
//                       className="mt-4 font-sourceSerifPro flex flex-col items-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <Link
//                         key={item._id}
//                         to={``}
//                         className="flex flex-col items-center"
//                       >
//                         <p className="text-xl md:text-2xl pt-4 italic font-semibold capitalize justify-center">
//                           {item.title}
//                         </p>
//                         <p className="text-xl md:text-2xl italic font-semibold capitalize justify-center">
//                           {item.address}
//                         </p>
//                       </Link>
//                       <p className="text-lg md:text-xl italic">{formatDate(item.date)}</p>
//                     </div>
//                   </div>
//                 </SwiperSlide>
//               ))}



//               <div className="swiper-button-prev text-primary-btnColor cursor-pointer">
//                 {/* <div className="swiper-button-prev absolute bottom-4 left-1/2 transform -translate-x-20 translate-y-1/2 z-10 cursor-pointer text-4xl text-primary-btnColor p-2 bg-white rounded-full shadow-lg"> */}
//                   <FaArrowLeft className="w-4 h-4" />
//                 </div>
//                 <div className="swiper-button-next text-primary-btnColor cursor-pointer">
//                 {/* <div className="swiper-button-next absolute bottom-4 left-1/2 transform translate-x-20 translate-y-1/2 z-10 cursor-pointer text-4xl text-primary-btnColor p-2 bg-white rounded-full shadow-lg"> */}
//                   <FaArrowRight className="w-4 h-4" />
//                 </div>


//             {/* <div className="swiper-button-wrapper flex justify-center mt-4"> */}
//               {/* <div className="swiper-button-prev absolute bottom-4 right-1/2 transform translate-x-2 translate-y-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-8 pt-3"><FaArrowLeft /></div> */}
//               {/* <div className="swiper-button-next absolute bottom-4 left-1/2 transform translate-x-6 translate-y-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-8 pt-3"><FaArrowRight /></div> */}
//             {/* </div> */}

//             {/* <div className="swiper-button-prev text-xl md:text-2xl text-primary-btnColor cursor-pointer">
//               <FaArrowLeft /></div>
//             <div className="swiper-button-next text-xl md:text-2xl text-primary-btnColor cursor-pointer">
//               <FaArrowRight /></div> */}
//           </Swiper>
//         </div>
//       </div>
//       {activeAchievement && (
//         <div
//           id="popupOverlay"
//           className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
//           onClick={closePopup}
//         >
//           <div className="bg-secondary-details p-8 md:p-10 rounded-lg shadow-lg max-w-7xl max-h-90 overflow-auto">
//             <button
//               onClick={() => setActiveAchievement(null)}
//               className="absolute top-4 right-4 text-2xl font-bold text-gray-600"
//             >
//               &times;
//             </button>
//             <div className="flex flex-col items-center">
//               <img
//                 src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.images}`}
//                 alt="achievement"
//                 className="w-72 h-48 md:w-96 md:h-60 object-contain p-1"
//               />
//               <div className="flex justify-center mb-4 p-1">
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.logo}`}
//                   alt="logo"
//                   className="w-16 h-16 md:w-20 md:h-20 object-contain rounded-full bg-white"
//                 />
//               </div>
//               <h2 className="text-2xl md:text-3xl font-bold text-center mb-2">{activeAchievement.title}</h2>
//               <p className="text-lg md:text-xl italic text-center mb-2">{formatDate(activeAchievement.date)}</p>
//               <p className="text-base md:text-xl mb-4 text-center">{activeAchievement.description}</p>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// Achievements.propTypes = {
//   achievementData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       address: PropTypes.string.isRequired,
//       date: PropTypes.string.isRequired,
//       image: PropTypes.string,
//       description: PropTypes.string,
//     })
//   ).isRequired,
// };

// export default Achievements;










// import { useState, useEffect } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Navigation } from 'swiper/modules';
// import { formatDate } from "../../utility/dateFormates";
// import Heading from "../common/Heading";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// const Achievements = ({ achievementData }) => {
//   const [loading, setLoading] = useState(true);
//   const [activeAchievement, setActiveAchievement] = useState(null);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setLoading(false);
//     }, 1000);

//     return () => clearTimeout(timer);
//   }, [achievementData]);

//   const handleClick = (achievement) => {
//     setActiveAchievement(activeAchievement?._id === achievement._id ? null : achievement);
//   };

//   const closePopup = (e) => {
//     if (e.target.id === "popupOverlay") {
//       setActiveAchievement(null);
//     }
//   };

//   return (
//     <>
//       {loading ? (
//         <div className="w-full h-full flex items-center justify-center">
//           <div>Loading...</div>
//         </div>
//       ) : (
//         <div className="w-full h-full overflow-hidden">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="100%"
//             height="100%"
//             viewBox="0 0 2000 200"
//             fill="none"
//           >
//             <path
//               d="M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z"
//               fill="#FFF0E1"
//               fillOpacity="0.5"
//             />
//           </svg>
//         </div>
//       )}
//       <div className="w-full h-auto bg-secondary-detailsBackground pt-8 px-8 md:px-20 lg:px-28 xl:px-36 relative">
//         <Heading heading="Achievements | Awards" />

//         <div className="w-full h-full mt-16 relative">
//           <img
//             src="/images/common/Decortop.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-7 top-[-2%] md:top-[-3%] left-[-8%] sm:left-[-4rem]"
//             style={{ zIndex: 10 }} // Ensure it's above other elements
//           />
//           <div className="w-full py-5 relative z-0"> {/* Ensure stacking context */}
//             <img
//               src="/images/AwardsBg.png"
//               alt="Achievements"
//               className="w-full h-full object-contain object-center"
//             />
//           </div>
//           <img
//             src="/images/common/Decorbottom.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-10 right-[-7%] md:right-[-4%] lg:right-[-3%]"
//             style={{ zIndex: 10 }} // Ensure it's above other elements
//           />
//         </div>


//         {/* <div className="w-full overflow-hidden absolute bottom-0 left-0 top-[30rem] inline-block"> */}
//         <div className="w-[95%] h-fit lg:w-[90%] xl:w-[80%] mx-auto absolute top-[10rem] md:top-[30rem] lg:top-[35rem] left-[6%] md:left-[5%] lg:left-[8%] xl:left-[12%]">
//           <Swiper
//             modules={[Navigation]}
//             spaceBetween={30}
//             navigation={{
//               prevEl: '.swiper-button-prev',
//               nextEl: '.swiper-button-next',
//             }}
//             breakpoints={{
//               0: { slidesPerView: 1 },
//               768: { slidesPerView: 2 },
//               1024: { slidesPerView: 3 },
//             }}
//           >
//             {Array.isArray(achievementData) &&
//               achievementData.map((item) => (
//                 <SwiperSlide key={item._id} className="px-2">
//                   <div className="pt-5 h-fit rounded-lg cursor-pointer transition-transform hover:scale-105 ease-in-out duration-300 flex items-center flex-col">
//                     <div
//                       className="bg-white shadow-lg rounded-full w-40 h-40 sm:w-52 sm:h-52 flex items-center justify-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <img
//                         src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                         alt="logo"
//                         className="w-full h-full object-contain p-1 rounded-full"
//                       />
//                     </div>
//                     <div
//                       className="mt-4 font-sourceSerifPro flex flex-col items-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <Link
//                         key={item._id}
//                         to={``}
//                         className="flex flex-col items-center"
//                       >
//                         <p className="text-2xl pt-4 italic font-semibold capitalize justify-center">
//                           {item.title}
//                         </p>
//                         <p className="text-2xl italic font-semibold capitalize justify-center">
//                           {item.address}
//                         </p>
//                       </Link>
//                       <p className="text-xl italic">{formatDate(item.date)}</p>
//                     </div>
//                   </div>
//                 </SwiperSlide>
//               ))}
//             <div className="swiper-button-prev text-1xl text-primary-btnColor cursor-pointer"><FaArrowLeft /></div>
//             <div className="swiper-button-next text-1xl text-primary-btnColor"><FaArrowRight /></div>
//           </Swiper>
//         </div>
//       </div>
//       {activeAchievement && (
//         <div
//           id="popupOverlay"
//           className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
//           onClick={closePopup}
//         >
//           <div className="bg-secondary-details p-10 rounded-lg shadow-lg max-w-7xl max-h-90 overflow-auto">
//             <button
//               onClick={() => setActiveAchievement(null)}
//               className="absolute top-4 right-4 text-2xl font-bold text-gray-600"
//             >
//               &times;
//             </button>
//             <div className="flex flex-col items-center">
//               <img
//                 src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.images}`}
//                 alt="achievement"
//                 className="w-96 h-60 object-contain p-1"
//               />
//               <div className="flex justify-center mb-4 p-1">
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.logo}`}
//                   alt="logo"
//                   className="w-20 h-20 object-contain rounded-full bg-white"
//                 />
//               </div>
//               <h2 className="text-3xl font-bold text-center mb-2">{activeAchievement.title}</h2>
//               <p className="text-xl italic text-center mb-2">{formatDate(activeAchievement.date)}</p>
//               <p className="text-1xl mb-4 text-center">{activeAchievement.description}</p>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// Achievements.propTypes = {
//   achievementData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       address: PropTypes.string.isRequired,
//       date: PropTypes.string.isRequired,
//       image: PropTypes.string,
//       description: PropTypes.string,
//     })
//   ).isRequired,
// };

// export default Achievements;













// import { useState, useEffect } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Navigation } from 'swiper/modules';
// import { formatDate } from "../../utility/dateFormates";
// import Heading from "../common/Heading";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// const Achievements = ({ achievementData }) => {
//   const [ setLoading] = useState(true);
//   const [activeAchievement, setActiveAchievement] = useState(null);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setLoading(false);
//     }, 1000);

//     return () => clearTimeout(timer);
//   }, [achievementData]);

//   const handleClick = (achievement) => {
//     setActiveAchievement(activeAchievement?._id === achievement._id ? null : achievement);
//   };

//   const closePopup = (e) => {
//     if (e.target.id === "popupOverlay") {
//       setActiveAchievement(null);
//     }
//   };

//   return (
//     <>
      
//       <div className="w-full bg-secondary-detailsBackground pt-8 px-8 md:px-20 lg:px-28 xl:px-36 relative h-[35rem] sm:h-[145vh] justify-center overflow-hidden">
//         <Heading heading="Achievements | Awards" />

//         <div className="w-full h-full mt-16 relative">
//           <img
//             src="/images/common/Decortop.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-7 top-[-2%] md:top-[-3%] left-[-8%] sm:left-[-4rem]"
//           />
//           <div className="w-full py-5">
//             <img
//               src="/images/AwardsBg.jpg"
//               alt="Achievements"
//               className="w-full h-full object-contain object-center"
//             />
//           </div>
//           <img
//             src="/images/common/Decorbottom.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-10 right-[-7%] md:right-[-4%] lg:right-[-3%]"
//           />
//         </div>
//         {/* <div className="justify-center w-[95%] lg:w-[90%] xl:w-[80%] mx-auto absolute top-[15rem] md:top-[30rem] lg:top-[35rem] left-[6%] md:left-[5%] lg:left-[8%] xl:left-[12%]"> */}
//         <div className="w-[95%] h-fit lg:w-[90%] xl:w-[80%] mx-auto absolute top-[15rem] md:top-[30rem] lg:top-[35rem] left-[6%] md:left-[5%] lg:left-[8%] xl:left-[12%]">
          
//           <Swiper
//             modules={[Navigation]}
//             spaceBetween={30}
//             navigation={{
//               prevEl: '.swiper-button-prev',
//               nextEl: '.swiper-button-next',
//             }}
//             breakpoints={{
//               0: { slidesPerView: 1 },
//               768: { slidesPerView: 2 },
//               1024: { slidesPerView: 3 },
//             }}
//           >
//             {Array.isArray(achievementData) &&
//               achievementData.map((item) => (
//                 <SwiperSlide key={item._id} className="">
//                   <div className="pt-5 h-fit w-[92%] md:w-[82%] rounded-lg cursor-pointer transition-transform hover:scale-105 ease-in-out duration-300 flex items-center flex-col">
//                     <div
//                       className="bg-white shadow-lg rounded-full w-[7rem] h-[7rem] sm:w-40 sm:h-40 flex items-center justify-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <img
//                         src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                         alt="logo"
//                         className="w-full h-full object-contain p-1 rounded-full"
//                       />
//                     </div>
//                     <div
//                       className="mt-4 font-sourceSerifPro flex flex-col items-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <Link
//                         key={item._id}
//                         to={``}
//                         className="flex flex-col items-center"
//                       >
//                         <p className="text-2xl pt-4 italic font-semibold capitalize justify-center">
//                           {item.title}
//                         </p>
//                         <p className="text-2xl italic font-semibold capitalize justify-center">
//                           {item.address}
//                         </p>
//                       </Link>
//                       <p className="text-xl italic">{formatDate(item.date)}</p>
//                     </div>
//                   </div>
//                 </SwiperSlide>
//               ))}
//             <div className="swiper-button-prev text-1xl text-primary-btnColor cursor-pointer"><FaArrowLeft /></div>
//             <div className="swiper-button-next text-1xl text-primary-btnColor"><FaArrowRight /></div>
//           </Swiper>
//         </div>
//       </div>
//       {activeAchievement && (
//         <div
//           id="popupOverlay"
//           className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 "
//           onClick={closePopup}
//         >
//           <div className="bg-secondary-details p-10 rounded-lg shadow-lg w-[90%] max-w-7xl max-h-90 relative">
//             <button
//               onClick={() => setActiveAchievement(null)}
//               className="absolute top-4 right-4 text-2xl font-bold text-gray-600"
//             >
//               &times;
//             </button>
//             <div className="flex flex-col items-center ">
//               <img
//                 src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.images}`}
//                 alt="achievement"
//                 className="w-[35rem] h-[20rem] object-contain p-1"
//               />
//               <div className="flex justify-center mb-4 p-1">
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.logo}`}
//                   alt="logo"
//                   className="w-20 h-20 object-contain rounded-full bg-white"
//                 />
//               </div>
//               <h2 className="text-3xl font-bold text-center mb-2">{activeAchievement.title}</h2>
//               <p className="text-xl italic text-center mb-2">{formatDate(activeAchievement.date)}</p>
//               <p className="text-1xl mb-4 text-center">{activeAchievement.description}</p>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// Achievements.propTypes = {
//   achievementData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       address: PropTypes.string.isRequired,
//       date: PropTypes.string.isRequired,
//       image: PropTypes.string,
//       description: PropTypes.string,
//     })
//   ).isRequired,
// };

// export default Achievements;













// import { useState, useEffect } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Navigation } from 'swiper/modules';
// import { formatDate } from "../../utility/dateFormates";
// import Heading from "../common/Heading";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// const Achievements = ({ achievementData }) => {
//   const [loading, setLoading] = useState(true);
//   const [activeAchievement, setActiveAchievement] = useState(null);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setLoading(false);
//     }, 1000);

//     return () => clearTimeout(timer);
//   }, [achievementData]);

//   const handleClick = (achievement) => {
//     setActiveAchievement(activeAchievement?._id === achievement._id ? null : achievement);
//   };

//   const closePopup = (e) => {
//     if (e.target.id === "popupOverlay") {
//       setActiveAchievement(null);
//     }
//   };

//   return (
//     <>
//       {loading ? (
//         <div className="w-full h-full flex items-center justify-center">
//           <div>Loading...</div>
//         </div>
//       ) : (
//         <div className="w-full h-full overflow-hidden">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="100%"
//             height="100%"
//             viewBox="0 0 2000 200"
//             fill="none"
//           >
//             <path
//               d="M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z"
//               fill="#FFF0E1"
//               fillOpacity="0.5"
//             />
//             <foreignObject
//               x="0"
//               y="10%"
//               width="100%"
//               height="100%"
//             ></foreignObject>
//           </svg>
//         </div>
//       )}
//       <div className="w-full bg-secondary-detailsBackground pt-8 px-8 md:px-20 lg:px-28 xl:px-30 relative h-[35rem] sm:h-[120vh] justify-center">
//         <Heading heading="Achievements | Awards" />
//         <div className="w-full h-full mt-16 relative">
//           <img
//             src="/images/common/Decortop.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-7 top-[-2%] md:top-[-3%] left-[-8%] sm:left-[-4rem]"
//           />
//           <div className="w-full py-5">
//             <img
//               src="/images/AwardsBg.jpg"
//               alt="Achievements"
//               className="w-full h-full object-contain object-center"
//             />
//           </div>
//           <img
//             src="/images/common/Decorbottom.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-10 right-[-7%] md:right-[-4%] lg:right-[-3%]"
//           />
//         </div>
//         <div className="justify-center w-[95%] lg:w-[90%] xl:w-[80%] mx-auto absolute top-[15rem] md:top-[30rem] lg:top-[35rem] left-[6%] md:left-[5%] lg:left-[8%] xl:left-[12%]">
//           <Swiper
//             modules={[Navigation]}
//             spaceBetween={30}
//             navigation={{
//               prevEl: '.swiper-button-prev',
//               nextEl: '.swiper-button-next',
//             }}
//             breakpoints={{
//               0: { slidesPerView: 1 },
//               768: { slidesPerView: 2 },
//               1024: { slidesPerView: 3 },
//             }}
//           >
//             {Array.isArray(achievementData) &&
//               achievementData.map((item) => (
//                 <SwiperSlide key={item._id}>
//                   <div className="pt-5 h-[80%] w-[92%] md:w-[82%] rounded-lg cursor-pointer transition-transform hover:scale-105 ease-in-out duration-300 flex items-center flex-col">
//                     <div
//                       className="bg-white shadow-lg rounded-full w-[7rem] h-[7rem] sm:w-44 sm:h-44 flex items-center justify-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <img
//                         src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                         alt="logo"
//                         className="w-full h-full object-contain p-1 rounded-full"
//                       />
//                     </div>
//                     <div
//                       className="mt-4 font-sourceSerifPro flex flex-col items-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <Link
//                         key={item._id}
//                         to={``}
//                         className="flex flex-col items-center"
//                       >
//                         <p className="text-2xl pt-4 italic font-semibold capitalize justify-center">
//                           {item.title}
//                         </p>
//                         <p className="text-2xl italic font-semibold capitalize justify-center">
//                           {item.address}
//                         </p>
//                       </Link>
//                       <p className="text-xl italic">{formatDate(item.date)}</p>
//                     </div>
//                   </div>
//                 </SwiperSlide>
//               ))}
//             <div className="swiper-button-prev text-1xl text-primary-btnColor"><FaArrowLeft /></div>
//             <div className="swiper-button-next text-1xl text-primary-btnColor"><FaArrowRight /></div>
//           </Swiper>
//         </div>
//       </div>
//       {activeAchievement && (
//         <div
//           id="popupOverlay"
//           className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
//           onClick={closePopup}
//         >
//           <div className="bg-secondary-detailsBackground font-mulish bg-opacity-60 p-12 rounded-lg shadow-lg w-[90%] max-w-2xl relative">
//             <button
//               onClick={() => setActiveAchievement(null)}
//               className="absolute top-4 right-4 text-2xl font-bold text-gray-600"
//             >
//               &times;
//             </button>
//             <img
//               src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.images}`}
//               alt="achievement"
//               className="w-full h-fit object-contain mb-4"
//             />
//             <div className="flex items-center justify-center mb-4 bg-white rounded-full w-[4em] h-[4rem] sm:w-40 sm:h-40 center">
//               <img
//                 src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.logo}`}
//                 alt="logo"
//                 className="w-full h-full object-contain p-1 rounded-full "
//               />
//             </div>
//             <h2 className="text-3xl font-bold text-center mb-4">{activeAchievement.title}</h2>
//             <p className="text-xl italic text-center">{formatDate(activeAchievement.date)}</p>
//             <p className="text-lg mb-4 text-center">{activeAchievement.description}</p>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// Achievements.propTypes = {
//   achievementData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       address: PropTypes.string.isRequired,
//       date: PropTypes.string.isRequired,
//       image: PropTypes.string,
//       description: PropTypes.string,
//     })
//   ).isRequired,
// };

// export default Achievements;























// import { useState, useEffect } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Navigation } from 'swiper/modules';
// import { formatDate } from "../../utility/dateFormates";
// import Heading from "../common/Heading";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// const Achievements = ({ achievementData }) => {
//   const [loading, setLoading] = useState(true);
//   const [activeAchievement, setActiveAchievement] = useState(null);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setLoading(false);
//     }, 1000);

//     return () => clearTimeout(timer);
//   }, [achievementData]);

//   const handleClick = (achievement) => {
//     setActiveAchievement(activeAchievement?._id === achievement._id ? null : achievement);
//   };

//   const closePopup = () => {
//     setActiveAchievement(null);
//   };

//   return (
//     <>
//       {loading ? (
//         <div className="w-full h-full flex items-center justify-center">
//           <div>Loading...</div>
//         </div>
//       ) : (
//         <div className="w-full h-full overflow-hidden">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="100%"
//             height="100%"
//             viewBox="0 0 2000 200"
//             fill="none"
//           >
//             <path
//               d="M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z"
//               fill="#FFF0E1"
//               fillOpacity="0.5"
//             />
//             <foreignObject
//               x="0"
//               y="10%"
//               width="100%"
//               height="100%"
//             ></foreignObject>
//           </svg>
//         </div>
//       )}
//       <div className="w-full bg-secondary-detailsBackground pt-8 px-8 md:px-20 lg:px-28 xl:px-30 relative h-[35rem] sm:h-[120vh] justify-center">
//         <Heading heading="Achievements | Awards" />
//         <div className="w-full h-full mt-16 relative">
//           <img
//             src="/images/common/Decortop.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-7 top-[-2%] md:top-[-3%] left-[-8%] sm:left-[-4rem]"
//           />
//           <div className="w-full py-5">
//             <img
//               src="/images/AwardsBg.jpg"
//               alt="Achievements"
//               className="w-full h-full object-contain object-center"
//             />
//           </div>
//           <img
//             src="/images/common/Decorbottom.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-10 right-[-7%] md:right-[-4%] lg:right-[-3%]"
//           />
//         </div>
//         <div className="justify-center w-[95%] lg:w-[90%] xl:w-[80%] mx-auto absolute top-[15rem] md:top-[30rem] lg:top-[35rem] left-[6%] md:left-[5%] lg:left-[8%] xl:left-[12%]">
//           <Swiper
//             modules={[Navigation]}
//             spaceBetween={30}
//             navigation={{
//               prevEl: '.swiper-button-prev',
//               nextEl: '.swiper-button-next',
//             }}
//             breakpoints={{
//               0: { slidesPerView: 1 },
//               768: { slidesPerView: 2 },
//               1024: { slidesPerView: 3 },
//             }}
//           >
//             {Array.isArray(achievementData) &&
//               achievementData.map((item) => (
//                 <SwiperSlide key={item._id}>
//                   <div className="pt-5 h-[80%] w-[92%] md:w-[82%] rounded-lg cursor-pointer transition-transform hover:scale-105 ease-in-out duration-300 flex items-center flex-col">
//                     <div
//                       className="bg-white shadow-lg rounded-full w-[7rem] h-[7rem] sm:w-44 sm:h-44 flex items-center justify-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <img
//                         src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                         alt="logo"
//                         className="w-full h-full object-contain p-1 rounded-full"
//                       />
//                     </div>
//                     <div
//                       className="mt-4 font-sourceSerifPro flex flex-col items-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <Link
//                         key={item._id}
//                         to={``}
//                         className="flex flex-col items-center"
//                       >
//                         <p className="text-2xl pt-4 italic font-semibold capitalize justify-center">
//                           {item.title}
//                         </p>
//                         <p className="text-2xl italic font-semibold capitalize justify-center">
//                           {item.address}
//                         </p>
//                       </Link>
//                       <p className="text-xl italic">{formatDate(item.date)}</p>
//                     </div>
//                   </div>
//                 </SwiperSlide>
//               ))}
//             <div className="swiper-button-prev text-1xl text-primary-btnColor"><FaArrowLeft /></div>
//             <div className="swiper-button-next text-1xl text-primary-btnColor"><FaArrowRight /></div>
//           </Swiper>
//         </div>
//       </div>
//       {activeAchievement && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-secondary-detailsBackground p-6 rounded-lg shadow-lg w-[90%] max-w-3xl">
//             <button
//               onClick={closePopup}
//               className="absolute top-4 right-4 text-2xl font-bold text-gray-600"
//             >
//               &times;
//             </button>
//             <img
//               src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${activeAchievement.images}`}
//               alt="achievement"
//               className="w-full h-auto object-contain mb-4"
//             />
            
//             <h2 className="text-3xl font-bold mb-4 justify-center items-center">{activeAchievement.title}</h2>
//             <p className="text-xl italic">{formatDate(activeAchievement.date)}</p>
//             <p className="text-lg mb-4">{activeAchievement.description}</p>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// Achievements.propTypes = {
//   achievementData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       address: PropTypes.string.isRequired,
//       date: PropTypes.string.isRequired,
//       image: PropTypes.string,
//       description: PropTypes.string,
//     })
//   ).isRequired,
// };

// export default Achievements;































// import { useState, useEffect } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Navigation } from 'swiper/modules';
// import { formatDate } from "../../utility/dateFormates";
// import Heading from "../common/Heading";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// const Achievements = ({ achievementData }) => {
//   const [loading, setLoading] = useState(true);
//   const [activeAchievement, setActiveAchievement] = useState(null);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setLoading(false);
//     }, 1000);

//     return () => clearTimeout(timer);
//   }, [achievementData]);

//   const handleClick = (achievement) => {
//     setActiveAchievement(activeAchievement?._id === achievement._id ? null : achievement);
//   };

//   return (
//     <>
//       {loading ? (
//         <div className="w-full h-full flex items-center justify-center">
//           <div>Loading...</div>
//         </div>
//       ) : (
//         <div className="w-full h-full overflow-hidden">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="100%"
//             height="100%"
//             viewBox="0 0 2000 200"
//             fill="none"
//           >
//             <path
//               d="M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z"
//               fill="#FFF0E1"
//               fillOpacity="0.5"
//             />
//             <foreignObject
//               x="0"
//               y="10%"
//               width="100%"
//               height="100%"
//             ></foreignObject>
//           </svg>
//         </div>
//       )}
//       <div className="w-full bg-secondary-detailsBackground pt-8 px-8 md:px-20 lg:px-28 xl:px-30 relative h-[35rem] sm:h-[120vh] justify-center">
//         <Heading heading="Achievements | Awards" />
//         <div className="w-full h-full mt-16 relative">
//           <img
//             src="/images/common/Decortop.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-7 top-[-2%] md:top-[-3%] left-[-8%] sm:left-[-4rem]"
//           />
//           <div className="w-full py-5">
//             <img
//               src="/images/AwardsBg.jpg"
//               alt="Achievements"
//               className="w-full h-full object-contain object-center"
//             />
//           </div>
//           <img
//             src="/images/common/Decorbottom.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-10 right-[-7%] md:right-[-4%] lg:right-[-3%]"
//           />
//         </div>
//         <div className="justify-center w-[95%] lg:w-[90%] xl:w-[80%] mx-auto absolute top-[15rem] md:top-[30rem] lg:top-[35rem] left-[6%] md:left-[5%] lg:left-[8%] xl:left-[12%]">
//           <Swiper
//             modules={[Navigation]}
//             spaceBetween={30}
//             navigation={{
//               prevEl: '.swiper-button-prev',
//               nextEl: '.swiper-button-next',
//             }}
//             breakpoints={{
//               0: { slidesPerView: 1 },
//               768: { slidesPerView: 2 },
//               1024: { slidesPerView: 3 },
//             }}
//           >
//             {Array.isArray(achievementData) &&
//               achievementData.map((item) => (
//                 <SwiperSlide key={item._id}>
//                   <div className="pt-5 h-[80%] w-[92%] md:w-[82%] rounded-lg cursor-pointer transition-transform hover:scale-105 ease-in-out duration-300 flex items-center flex-col">
//                     <div
//                       className="bg-white shadow-lg rounded-full w-[7rem] h-[7rem] sm:w-44 sm:h-44 flex items-center justify-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <img
//                         src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                         alt="logo"
//                         className="w-full h-full object-contain p-1 rounded-full"
//                       />
//                     </div>
//                     <div
//                       className="mt-4 font-sourceSerifPro flex flex-col items-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <Link
//                         key={item._id}
//                         to={``}
//                         className="flex flex-col items-center"
//                       >
//                         <p className="text-2xl pt-4 italic font-semibold capitalize justify-center">
//                           {item.title}
//                         </p>
//                         <p className="text-2xl italic font-semibold capitalize justify-center">
//                           {item.address}
//                         </p>
//                       </Link>
//                       <p className="text-xl italic">{formatDate(item.date)}</p>
//                     </div>
//                     {activeAchievement?._id === item._id && (
//                       <div className="mt-4 bg-white p-4 rounded-lg shadow-lg w-full max-h-[300px] overflow-y-auto">
//                         <div className="flex flex-col">
//                           <img
//                             src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.images}`}
//                             alt="achievement"
//                             className="w-full h-auto object-contain mb-4"
//                           />
//                           <h2 className="text-3xl font-bold">{item.title}</h2>
//                           <p className="text-lg">{item.description}</p>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 </SwiperSlide>
//               ))}
//             <div className="swiper-button-prev text-1xl text-primary-btnColor"><FaArrowLeft /></div>
//             <div className="swiper-button-next text-1xl text-primary-btnColor"><FaArrowRight /></div>
//           </Swiper>
//         </div>
//       </div>
//     </>
//   );
// };

// Achievements.propTypes = {
//   achievementData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       address: PropTypes.string.isRequired,
//       date: PropTypes.string.isRequired,
//       image: PropTypes.string,
//       description: PropTypes.string,
//     })
//   ).isRequired,
// };

// export default Achievements;




















// import { useState, useEffect } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Navigation } from 'swiper/modules';
// import { formatDate } from "../../utility/dateFormates";
// import Heading from "../common/Heading";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// const Achievements = ({ achievementData }) => {
//   const [loading, setLoading] = useState(true);
//   const [activeAchievement, setActiveAchievement] = useState(null);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setLoading(false);
//     }, 1000);

//     return () => clearTimeout(timer);
//   }, [achievementData]);

//   const handleClick = (achievement) => {
//     setActiveAchievement(activeAchievement?._id === achievement._id ? null : achievement);
//   };

//   return (
//     <>
//       {loading ? (
//         <div className="w-full h-full flex items-center justify-center">
//           <div>Loading...</div>
//         </div>
//       ) : (
//         <div className="w-full h-full overflow-hidden">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="100%"
//             height="100%"
//             viewBox="0 0 2000 200"
//             fill="none"
//           >
//             <path
//               d="M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z"
//               fill="#FFF0E1"
//               fillOpacity="0.5"
//             />
//             <foreignObject
//               x="0"
//               y="10%"
//               width="100%"
//               height="100%"
//             ></foreignObject>
//           </svg>
//         </div>
//       )}
//       <div className="w-full bg-secondary-detailsBackground pt-8 px-8 md:px-20 lg:px-28 xl:px-30 relative h-[35rem] sm:h-[120vh] justify-center">
//         <Heading heading="Achievements | Awards" />
//         <div className="w-full h-full mt-16 relative">
//           <img
//             src="/images/common/Decortop.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-7 top-[-2%] md:top-[-3%] left-[-8%] sm:left-[-4rem]"
//           />
//           <div className="w-full py-5">
//             <img
//               src="/images/AwardsBg.jpg"
//               alt="Achievements"
//               className="w-full h-full object-contain object-center"
//             />
//           </div>
//           <img
//             src="/images/common/Decorbottom.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-10 right-[-7%] md:right-[-4%] lg:right-[-3%]"
//           />
//         </div>
//         <div className="justify-center w-[95%] lg:w-[90%] xl:w-[80%] mx-auto absolute top-[15rem] md:top-[30rem] lg:top-[35rem] left-[6%] md:left-[5%] lg:left-[8%] xl:left-[12%]">
//           <Swiper
//             modules={[Navigation]}
//             spaceBetween={30}
//             navigation={{
//               prevEl: '.swiper-button-prev',
//               nextEl: '.swiper-button-next',
//             }}
//             breakpoints={{
//               0: { slidesPerView: 1 },
//               768: { slidesPerView: 2 },
//               1024: { slidesPerView: 3 },
//             }}
//           >
//             {Array.isArray(achievementData) &&
//               achievementData.map((item) => (
//                 <SwiperSlide key={item._id}>
//                   <div className="pt-5 h-[80%] w-[92%] md:w-[82%] rounded-lg cursor-pointer transition-transform hover:scale-105 ease-in-out duration-300 flex items-center flex-col">
//                     <div
//                       className="bg-white shadow-lg rounded-full w-[7rem] h-[7rem] sm:w-44 sm:h-44 flex items-center justify-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <img
//                         src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                         alt="logo"
//                         className="w-full h-full object-contain p-1 rounded-full"
//                       />
//                     </div>
//                     <div
//                       className="mt-4 font-sourceSerifPro flex flex-col items-center"
//                       onClick={() => handleClick(item)}
//                     >
//                       <Link
//                         key={item._id}
//                         to={``}
//                         className="flex flex-col items-center"
//                       >
//                         <p className="text-2xl pt-4 italic font-semibold capitalize justify-center">
//                           {item.title}
//                         </p>
//                         <p className="text-2xl italic font-semibold capitalize justify-center">
//                           {item.address}
//                         </p>
//                       </Link>
//                       <p className="text-xl italic">{formatDate(item.date)}</p>
//                     </div>
//                     {activeAchievement?._id === item._id && (
//                       <div className="mt-4 bg-white p-4 rounded-lg shadow-lg w-full max-h-[300px] overflow-y-auto">
//                         <div className="flex flex-col">
//                           <img
//                             src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.images}`}
//                             alt="achievement"
//                             className="w-full h-auto object-contain mb-4"
//                           />
//                           <h2 className="text-3xl font-bold">{item.title}</h2>
//                           <p className="text-lg">{item.description}</p>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 </SwiperSlide>
//               ))}
//             <div className="swiper-button-prev text-1xl text-primary-btnColor"><FaArrowLeft /></div>
//             <div className="swiper-button-next text-1xl text-primary-btnColor"><FaArrowRight /></div>
//           </Swiper>
//         </div>
//       </div>
//     </>
//   );
// };

// Achievements.propTypes = {
//   achievementData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       address: PropTypes.string.isRequired,
//       date: PropTypes.string.isRequired,
//       image: PropTypes.string,
//       description: PropTypes.string,
//     })
//   ).isRequired,
// };

// export default Achievements;






















// import { useState, useEffect } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Navigation } from 'swiper/modules';
// import { formatDate } from "../../utility/dateFormates";
// import Heading from "../common/Heading";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// const Achievements = ({ achievementData }) => {
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setLoading(false);
//     }, 1000); 

//     return () => clearTimeout(timer);
//   }, [achievementData]);

//   return (
//     <>
//       {loading ? (
//         <div className="w-full h-full flex items-center justify-center">
//           <div>Loading...</div>
//         </div>
//       ) : (
//         <div className="w-full h-full overflow-hidden">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="100%"
//             height="100%"
//             viewBox="0 0 2000 200"
//             fill="none"
//           >
//             <path
//               d="M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z"
//               fill="#FFF0E1"
//               fillOpacity="0.5"
//             />
//             <foreignObject
//               x="0"
//               y="10%"
//               width="100%"
//               height="100%"
//             ></foreignObject>
//           </svg>
//         </div>
//       )}
//       <div className="w-full bg-secondary-detailsBackground pt-8 px-8 md:px-20 lg:px-28 xl:px-30 relative h-[35rem] sm:h-[120vh] justify-center">
//         <Heading heading="Achievements | Awards" />
//         <div className="w-full h-full mt-16 relative">
//           <img
//             src="/images/common/Decortop.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-7 top-[-2%] md:top-[-3%] left-[-8%] sm:left-[-4rem]"
//           />
//           <div className="w-full py-5">
//             <img
//               src="/images/AwardsBg.jpg"
//               alt="Achievements"
//               className="w-full h-full object-contain object-center"
//             />
//           </div>
//           <img
//             src="/images/common/Decorbottom.png"
//             alt="upwraper"
//             className="absolute w-7 sm:w-10 right-[-7%] md:right-[-4%] lg:right-[-3%]"
//           />
//         </div>
//         <div className="justify-center w-[95%] lg:w-[90%] xl:w-[80%] mx-auto absolute top-[15rem] md:top-[30rem] lg:top-[35rem] left-[6%] md:left-[5%] lg:left-[8%] xl:left-[12%]">
//           <Swiper
//             modules={[Navigation]}
//             spaceBetween={30}
//             navigation={{
//               prevEl: '.swiper-button-prev',
//               nextEl: '.swiper-button-next',
//             }}
//             breakpoints={{
//               0: { slidesPerView: 1 },
//               768: { slidesPerView: 2 },
//               1024: { slidesPerView: 3 },
//             }}
//           >
//             {Array.isArray(achievementData) &&
//               achievementData.map((item) => (
//                 <SwiperSlide key={item._id}>
//                   <div className="pt-5 h-[80%] w-[92%] md:w-[82%] rounded-lg cursor-pointer transition-transform hover:scale-105 ease-in-out duration-300 flex items-center flex-col">
//                     <div className="bg-white shadow-lg rounded-full w-[7rem] h-[7rem] sm:w-44 sm:h-44 flex items-center justify-center">
//                       <img
//                         src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                         alt="logo"
//                         className="w-full h-full object-contain p-1 rounded-full"
//                       />
//                     </div>
//                     <div className="mt-4 font-sourceSerifPro flex flex-col items-center">
//                       <Link
//                         key={item._id}
//                         to={``}
//                         className="flex flex-col items-center"
//                       >
//                         <p className="text-2xl pt-4 italic font-semibold capitalize justify-center">
//                           {item.title}
//                         </p>
//                         <p className="text-2xl italic font-semibold capitalize justify-center">
//                           {item.address}
//                         </p>
//                       </Link>
//                       <p className="text-xl italic">{formatDate(item.date)}</p>
//                     </div>
//                   </div>
//                 </SwiperSlide>
//               ))}
//             <div className="swiper-button-prev text-1xl text-primary-btnColor"><FaArrowLeft /></div>
//             <div className="swiper-button-next text-1xl text-primary-btnColor"><FaArrowRight /></div>
//           </Swiper>
//         </div>
//       </div>
//     </>
//   );
// };

// Achievements.propTypes = {
//   achievementData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       logo: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       address: PropTypes.string.isRequired,
//       date: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };

// export default Achievements;




























// import React from "react";
// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
// import { formatDate } from "../../utility/dateFormates";
// import { ArrowRight, ArrowLeft } from "lucide-react";

// import Heading from "../common/Heading";
// import { Link } from "react-router-dom";

// // Remove TypeScript interfaces and use plain JavaScript props
// const Achievements = ({ achievementData }) => {
//   return (
//     <>
//       <div className='w-full h-full overflow-hidden'>
//         <svg
//           xmlns='http://www.w3.org/2000/svg'
//           width='100%'
//           height='100%'
//           viewBox='0 0 2000 200'
//           fill='none'
//         >
//           <path
//             d='M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z'
//             fill='#FFF0E1'
//             fillOpacity='0.5'
//           />
//           <foreignObject
//             x='0'
//             y='10%'
//             width='100%'
//             height='100%'
//           ></foreignObject>
//         </svg>
//       </div>
//       <div className='w-full bg-secondary-detailsBackground pt-8 px-8 md:px-20 lg:px-28 xl:px-30 relative h-[35rem] sm:h-[140vh]'>
//         <Heading heading='Achievements | Awards' />
//         <div className='w-full h-full mt-16 relative'>
//           <img
//             src='/images/common/Decortop.png'
//             alt='upwraper'
//             className='absolute w-7 sm:w-7 top-[-2%] md:top-[-3%] left-[-8%] sm:left-[-4rem]'
//           />
//           <div className='w-full py-5'>
//             <img
//               src='/images/AwardsBg.jpg'
//               alt='Achievements'
//               className='w-full h-full object-contain object-center'
//             />
//           </div>
//           <img
//             src='/images/common/Decorbottom.png'
//             alt='upwraper'
//             className='absolute w-7 sm:w-10 right-[-7%] md:right-[-4%] lg:right-[-3%]'
//           />
//         </div>
//         <div className='w-[95%] lg:w-[90%] xl:w-[80%] mx-auto absolute top-[15rem] md:top-[30rem] lg:top-[35rem] left-[6%] md:left-[5%] lg:left-[8%] xl:left-[12%]'>
//           <AliceCarousel
//             disableDotsControls
//             mouseTracking
//             responsive={{
//               0: { items: 1 },
//               768: { items: 2 },
//               1024: { items: 3 },
//             }}
//             renderPrevButton={() => (
//               <div className='flex items-center justify-end'>
//                 <ArrowLeft className='text-primary-btnColor cursor-pointer' />
//               </div>
//             )}
//             renderNextButton={() => (
//               <div className='flex items-center justify-start'>
//                 <ArrowRight className='text-primary-btnColor cursor-pointer' />
//               </div>
//             )}
//           >
//             {Array.isArray(achievementData) && achievementData.map((item) => (
//               <div
//                 key={item._id}
//                 className='pt-5 h-fit w-[92%] md:w-[82%] rounded-lg cursor-pointer transition-transform hover:scale-105 ease-in-out duration-300 flex items-center flex-col'
//               >
//                 <div className='bg-white shadow-lg rounded-full w-[7rem] h-[7rem] sm:w-44 sm:h-44 flex items-center justify-center'>
//                   <img
//                     src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.logo}`}
//                     alt='logo'
//                     className='w-full h-full object-contain p-1 rounded-full'
//                   />
//                 </div>
//                 <div className='mt-4 font-sourceSerifPro flex flex-col items-center'>
//                   <Link
//                     key={item._id}
//                     to={``}
//                     className='flex flex-col items-center'
//                   >
//                     <p className='text-2xl italic font-semibold capitalize'>
//                       {item.title}
//                     </p>
//                     <p className='text-2xl italic font-semibold capitalize'>
//                       {item.address}
//                     </p>
//                   </Link>
//                   <p className='text-xl italic'>{formatDate(item.date)}</p>
//                 </div>
//               </div>
//             ))}
//           </AliceCarousel>
//         </div>
//       </div>
//     </>
//   );
// };
// export default Achievements;

// return (
//   <>
//     <div className='w-full h-full overflow-hidden'>
//       <svg
//         xmlns='http://www.w3.org/2000/svg'
//         width='100%'
//         height='100%'
//         viewBox='0 0 2000 200'
//         fill='none'
//       >
//         <path
//           d='M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z'
//           fill='#FFF0E1'
//           fillOpacity='0.5'
//         />
//         <foreignObject
//           x='0'
//           y='10%'
//           width='100%'
//           height='100%'
//         ></foreignObject>
//       </svg>
//     </div>
//     <div className=' w-full  bg-secondary-detailsBackground pt-12 px-8 md:px-20 lg:px-28 xl:px-36 relative h-[35rem] sm:h-[140vh]'>
//       <Heading heading='Achievements/Awards' />

//       <div className='w-full h-full  mt-16 relative'>
//         <img
//           src='/images/common/Decortop.png'
//           alt='upwraper'
//           className='absolute w-7 sm:w-7 top-[-2%] md:top-[-3%] left-[-8%] sm:left-[-4rem]'
//         />
//         <div className='w-full py-5 '>
//           <img
//             src='/images/AwardsBg.png'
//             alt='Achievements'
//             className='w-full h-full object-contain object-center'
//           />
//         </div>
//         <img
//           src='/images/common/Decorbottom.png'
//           alt='upwraper'
//           className='absolute w-7 sm:w-10 right-[-7%]  md:right-[-4%] lg:right-[-3%] '
//         />
//       </div>
//       <div className=' w-[95%] lg:w-[90%] xl:w-[80%] mx-auto absolute top-[15rem] md:top-[30rem] lg:top-[35rem] left-[6%] md:left-[5%] lg:left-[8%] xl:left-[12%]'>
//         <AliceCarousel
//           disableDotsControls
//           mouseTracking
//           responsive={{
//             0: { items: 1 },
//             768: { items: 2 },
//             1024: { items: 3 },
//           }}
//           renderPrevButton={() => (
//             <div className='flex items-center justify-end'>
//               <ArrowLeft className='text-primary-btnColor cursor-pointer' />
//             </div>
//           )}
//           renderNextButton={() => (
//             <div className='flex items-center justify-start'>
//               <ArrowRight className='text-primary-btnColor cursor-pointer' />
//             </div>
//           )}
//         >
//           {achievementData?.map((item) => (
//             <div
//               key={item._id}
//               className=' pt-5 h-fit w-[92%] md:w-[82%] rounded-lg cursor-pointer  transition-transform hover:scale-105 ease-in-out duration-300 flex items-center flex-col'
//             >
//               <div className='bg-white shadow-lg rounded-full w-[7rem] h-[7rem] sm:w-44 sm:h-44 flex items-center justify-center'>
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
//                     item.logo
//                   }`}
//                   alt='logo'
//                   className='w-full h-full object-contain p-1 rounded-full'
//                 />
//               </div>
//               <div className='mt-4 font-sourceSerifPro flex flex-col items-center'>
//                 <Link
//                   to={`/achievements/${item._id}`}
//                   className='flex flex-col items-center'
//                 >
//                   <p className='text-2xl italic font-semibold capitalize '>
//                     {item.title}
//                   </p>
//                   <p className='text-2xl italic font-semibold capitalize'>
//                     {item.address}
//                   </p>
//                 </Link>
//                 <p className='text-xl italic'>{formatDate(item.date)}</p>
//               </div>
//             </div>
//           ))}
//         </AliceCarousel>
//       </div>
//     </div>
//   </>
// );
