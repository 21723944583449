import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

const HerosectionCarousel = ({ data = [] }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  const carouselSettings = {
    mouseTracking: true,
    disableButtonsControls: true,
    autoPlay: true,
    autoPlayInterval: 3000,
    items: data.map((item, index) => (
      <div
        key={index}
        className="relative"
        style={{
          height: isSmallScreen ? "30vh" : "60vh", // Adjust height based on screen size
          overflow: "hidden",
        }}
      >
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ position: "absolute", bottom: 0, left: 0, right: 0, height: "100%" }}
        >
          <clipPath id={`waveClipPath-${index}`}>
            <path
              d={
                !isSmallScreen
                  ? "M0,120 C150,180 300,80 500,120 L500,0 L0,0 Z"
                  : "M0,120 C200,140 180,80 500,120 L500,0 L0,0 Z"
              }
              style={{
                stroke: "none",
                fill: "#000",
              }}
            ></path>
          </clipPath>
          <image
            xlinkHref={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
            clipPath={`url(#waveClipPath-${index})`}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            preserveAspectRatio="xMinYMin slice"
          />
        </svg>
      </div>
    )),
  };

  return (
    <AliceCarousel
      mouseTracking={carouselSettings.mouseTracking}
      disableButtonsControls={carouselSettings.disableButtonsControls}
      autoPlay={carouselSettings.autoPlay}
      autoPlayInterval={carouselSettings.autoPlayInterval}
      items={carouselSettings.items}
    />
  );
};

HerosectionCarousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      // Add other properties if needed
    })
  ).isRequired,
};

export default HerosectionCarousel;





















// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
// import PropTypes from "prop-types";
// import { useMediaQuery } from "react-responsive";

// const HerosectionCarousel = ({ data = [] }) => {
//   const isSmallScreen = useMediaQuery({ maxWidth: 768 });

//   const carouselSettings = {
//     mouseTracking: true,
//     disableButtonsControls: true,
//     autoPlay: true,
//     autoPlayInterval: 3000,
//     items: data.map((item, index) => (
//       <div
//         key={index}
//         className="bg-cover bg-center"
//         style={{
//           backgroundImage: `url(${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image})`,
//           height: isSmallScreen ? "30vh" : "60vh", // Adjust height based on screen size
//           overflow: "hidden",
//         }}
//       >
//         <div
//           className="font-robotoSlab text-3xl lg:text-4xl font-bold flex items-center justify-start h-full"
//           style={{
//             width: "100%",
//             padding: "52px 0", // Adjust padding as needed
//           }}
//         >
//           {/* Optional: Add banner text or other elements */}
//           {/* {bannerText} */}
//         </div>
//       </div>
//     )),
//   };

//   return (
//     <AliceCarousel
//       mouseTracking={carouselSettings.mouseTracking}
//       disableButtonsControls={carouselSettings.disableButtonsControls}
//       autoPlay={carouselSettings.autoPlay}
//       autoPlayInterval={carouselSettings.autoPlayInterval}
//       items={carouselSettings.items}
//     />
//   );
// };

// HerosectionCarousel.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       image: PropTypes.string.isRequired,
//       // Add other properties if needed
//     })
//   ).isRequired,
// };

// export default HerosectionCarousel;
















// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
// import PropTypes from 'prop-types';
// import { useMediaQuery } from "react-responsive";

// const HerosectionCarousel = ({data = [], }) => {
//   const isSmallScreen = useMediaQuery({ maxWidth: 768 });

//   return (
//     <>
//       <AliceCarousel
//         mouseTracking
//         disableButtonsControls
//         autoPlay
//         infinite
//         autoPlayStrategy="none"
//         autoPlayInterval={3000}
//         items={data?.map((item, index) => (
//           <div className="h-[30vh] md:h-[60vh] overflow-hidden w-screen" key={index}>
//             <svg
//               viewBox="0 0 500 150"
//               preserveAspectRatio="none"
//               style={{ height: "100%", width: "100%" }}
//             >
//               <clipPath id={`clipPath-${index}`}>
//                 <path
//                   d={
//                     !isSmallScreen
//                       ? "M0,120 C150,180 300,80 500,120 L500,0 L0,0 Z"
//                       : "M0,120 C200,140 180,80 500,120 L500,0 L0,0 Z"
//                   }
//                   style={{
//                     stroke: "none",
//                     fill: "#e1efe3",
//                   }}
//                 ></path>
//               </clipPath>
//               <image
//                 xlinkHref={item.image}
//                 clipPath={`url(#clipPath-${index})`}
//                 style={{ objectPosition: "center" }}
//                 preserveAspectRatio="xMinYMin slice"
//                 width={"100%"}
//                 height={"100%"}
//               />
//             </svg>
//           </div>
//         ))}
//       />
//     </>
//   );
// };
// HerosectionCarousel.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.shape({
//     image: PropTypes.string.isRequired,
//     // Add other properties if needed
//   })).isRequired,
// };
// export default HerosectionCarousel;
