/* eslint-disable no-unused-vars */
import React from 'react';
import Container from '../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
import ArticleForm from '../../../components/forms/postForms/articleForm/ArticleForm';
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';

const AddArticle = () => {
  
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: 'article/categories',
  });

  return (
    <Container
      breadcumbMenu="Article"
      breadcumbSubMenu="new Article"
      title="New Article"
      btnTitle="back to Article"
      path="/admin/articles"
    >
      <FormContainer heading="Add Article">
        <ArticleForm categoryData={data} />
      </FormContainer>
    </Container>
  );
};

export default AddArticle;
