import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import InvolvementResponsibilitiesForm from "../../../components/forms/postForms/InvolvementResponsibilitiesForm/InvolvementResponsibilitiesForm";
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';

const AddInvolvementAchievements = () => {

  // involvementResponsibility
  const { data } = useFetchData({
    endpoint: 'involvements',
  });
  return (
    <Container
      breadcumbMenu='involvementResponsibilities'
      breadcumbSubMenu='new Responsibilities'
      title='New Responsibilities'
      btnTitle='back to Responsibilities'
      path='/admin/involvementResponsibilities'
    >
      <FormContainer heading='Add Key-Responsibility'>
        <InvolvementResponsibilitiesForm  involvementData={data}/>
      </FormContainer>
    </Container>
  );
};

export default AddInvolvementAchievements;
