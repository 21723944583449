
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../layouts/AuthContext';

function ProtectedRoute({ element }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/admin/login" />;
}

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
};

export default ProtectedRoute;
