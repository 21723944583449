import DefaultImage from "../defaultImage/DefaultImage";
import PropTypes from 'prop-types';

const DonorCard = ({ donor, onDonarClick }) => {
  return (
    <div className='flexs flex-col items-center justify-center w-full'>
      <div
        className='w-full h-[28vh] mb-2 relative'
        onClick={() => onDonarClick(donor)}
      >
        {donor?.donorId?.image ? (
          <img
            src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${donor?.donorId?.image}`}
            alt='donor'
            className='w-full h-full object-cover object-center rounded'
          />
        ) : (
          <DefaultImage />
        )}
        <div className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-50 bg-black transition-opacity duration-500'>
          <h1 className='text-white capitalize text-lg font-semibold cursor-pointer'>
            View More
          </h1>
        </div>
      </div>

      <h1 className='mt-3 font-semibold capitalize text-primary-headingColor text-xl'>
        {donor?.donorId?.name}
      </h1>
      <h1 className='my-2 font-semibold text-primary-headingColor text-xl'>
        Rs.{donor?.donationAmt}
      </h1>
    </div>
  );
};
DonorCard.propTypes = {
  donor: PropTypes.shape({
    donorId: PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
    donationAmt: PropTypes.number.isRequired,
  }).isRequired,
  onDonarClick: PropTypes.func.isRequired,
};
export default DonorCard;
