/* eslint-disable no-unused-vars */
import Container from './../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
import DonationForm from '../../../components/forms/postForms/donationForm/DonationForm';
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';
import useFetchDataDetails from '../../../hooks/useFetchDataDetail/useFetchDataDetails';
import { useParams } from 'react-router-dom';

const EditDonation = () => {
  const { data: initiativesData } = useFetchData({
    endpoint: 'initiatives',
  });
  const { data: donorsData } = useFetchData({
    endpoint: 'donors',
  });

  const { id } = useParams();
  //   custom hook for fetching data detaails from api with 2 parameters (endpoint and id)
  const { dataDetails, loading, error } = useFetchDataDetails('donations', id);
  console.log(dataDetails, 'dataDetails');

  return (
    <Container
      breadcumbMenu="Donations"
      breadcumbSubMenu="Edit Donations"
      title="Edit Donations"
      btnTitle="back to Donations"
      path="/admin/donations"
    >
      <FormContainer heading="Edit Donations Detail">
        <DonationForm
          defaultData={dataDetails}
          donorsData={donorsData}
          initiativesData={initiativesData}
        />
      </FormContainer>
    </Container>
  );
};

export default EditDonation;
