import Container from '../../components/container/Container';
import DataNotFound from '../errorPage/DataNotFound';
import PublicationTable from '../../components/forms/tables/PublicationTable';
import { useFetchData } from '../../hooks/useFetchData/useFetchData';
import Loader from '../../components/loader/Loader';
import DataFetchingError from '../errorPage/DataFetchingError';

const Publication = () => {

  const { data, loading, error, refetchData } = useFetchData({
    endpoint: 'publications',
  });

  return (
    <Container
      breadcumbMenu="Publication"
      title="Publication"
      btnTitle="Add Publication"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refetchData={refetchData} />
      ) : data.length > 0 ? (
        <PublicationTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Publication;
