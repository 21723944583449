import PropTypes from 'prop-types';

const AffiliationCard = ({ items }) => {
  return (
    <div
      className="mt-2 sm:mt-8 sm:mx-8 py-12 relative w-[100%] md:w-[45%] lg:w-[25%] mb-24 hover:scale-105 transition ease-in-out duration-300 cursor-pointer flex justify-center items-center flex-col bg-white rounded border border-primary-btnColor"
      key={items.id}
    >
      <div className="bg-white rounded-full h-24 w-24 absolute top-[-20%] shadow-lg transform translate-x-[0]">
        <img
          src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${items.image}`}
          alt="logo"
          className="h-24 w-24 rounded-full"
        />
      </div>
      <h1 className="font-semibold capitalize text-xl text-primary-headingColor pt-8">
        {items.organization}
      </h1>
      <h1 className="font-semibold capitalize text-xl my-4">
        {items.position}
      </h1>
      <h1 className="text-xl font-semibold">{items.date}</h1>
    </div>
  );
};
AffiliationCard.propTypes = {
  items: PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    organization: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired,
};

export default AffiliationCard;
