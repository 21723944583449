import { useState } from 'react';
import { Link } from 'react-router-dom';
import Heading from '../common/Heading';
import ReadmoreBtn from '../common/ReadmoreBtn';
import parser from 'html-react-parser';
import PropTypes from 'prop-types';

const Aboutme = ({ data }) => {
  const [showMore, setShowMore] = useState(false);

  const handleToggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="my-16 px-8 md:px-20 lg:px-28 xl:px-36 relative">
      <Heading heading="About Me" />

      {data?.map((item, index) => (
        <div
          key={index}
          className={`my-16 h-fit bg-white sm:rounded-tr-[80px] sm:rounded-bl-[80px] box-shadow flex items-center mx-2 lg:mx-6 px-4 md:px-10 lg:px-12 py-4 md:py-12 lg:py-16 flex-col lg:flex-row relative ${
            !showMore && index > 0 ? 'hidden' : ''
          }`}
        >
          <img
            src="/images/common/Upwrapper.png"
            alt="upwraper"
            className="absolute top-[-2%] md:top-[-3%] lg:top-[-4%] left-[-5%] md:left-[-3%] lg:left-[-2%]"
          />
          <div className="h-[10rem] md:h-[45vh] lg:h-[45vh] w-[85%] sm:w-[75%] md:w-[60%] lg:w-[38%]">
            <img
              src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
              alt="aboutme"
              className="w-full h-full object-cover sm:rounded-tr-[80px] sm:rounded-bl-[80px]"
            />
          </div>
          <div className="w-[98%] lg:w-[55%] text-justify lg:ml-8 mt-2 p-0 lg:mt-0 mb-4 md:mb-0">
            <div
              className="line-clamp-9 font-mulish text-base lg:w-[100%] font-medium"
              style={{ lineHeight: '35px', fontWeight: '500', fontSize:'18px'}}
            >
              {typeof item.name === 'string' ? parser(item.name) : item.name}
            </div>
            <div>
              <Link to={`abouts`}>
                <ReadmoreBtn />
              </Link>
            </div>
          </div>
          <img
            src="/images/common/Downwrapper.png"
            alt="upwraper"
            className="absolute right-[-5%] md:right-[-3%] lg:right-[-2%] bottom-[-2%] md:bottom-[-3%] lg:bottom-[-4%]"
          />
        </div>
      ))}

      {data.length > 1 && (
        <div className="flex justify-center mt-4">
          <button
            onClick={handleToggleShowMore}
            className="text-white font-mulish text-base transition duration-300 ease-in-out hover:text-white px-4 py-2 rounded-full hover:bg-white"
          >
            {showMore ? 'View Less' : 'View More'}
          </button>
        </div>
      )}
    </div>
  );
};

Aboutme.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // Allow name to be a string or a React node
    })
  ).isRequired,
};

export default Aboutme;
























// import { Link } from 'react-router-dom';
// import Heading from '../common/Heading';
// import ReadmoreBtn from '../common/ReadmoreBtn';
// import parser from 'html-react-parser';
// import PropTypes from 'prop-types';

// const Aboutme = ({ data }) => {
//   return (
//     <div className="my-16 px-8 md:px-20 lg:px-28 xl:px-36 relative">
//       <Heading heading="About Me" />

//       {data?.map((item, index) => (
//         <div
//           key={index}
//           className="my-16 h-fit bg-white sm:rounded-tr-[80px] sm:rounded-bl-[80px] box-shadow flex items-center mx-2 lg:mx-6 px-4 md:px-10 lg:px-12 py-4 md:py-12 lg:py-16 flex-col lg:flex-row relative"
//         >
//           <img
//             src="/images/common/Upwrapper.png"
//             alt="upwraper"
//             className="absolute top-[-2%] md:top-[-3%] lg:top-[-4%] left-[-5%] md:left-[-3%] lg:left-[-2%]"
//           />
//           <div className="h-[15rem] md:h-[50vh] lg:h-[58vh] w-[95%] sm:w-[85%] md:w-[70%] lg:w-[42%]">
//             <img
//               src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
//               alt="aboutme"
//               className="w-full h-full object-cover sm:rounded-tr-[80px] sm:rounded-bl-[80px] "
//             />
//           </div>
//           <div className="w-[98%] lg:w-[55%] text-justify lg:ml-8 mt-6 lg:mt-0 mb-4 md:mb-0">
//             <div
//               className="line-clamp-9 font-mulish text-base lg:w-[95%]"
//               style={{ lineHeight: '30px', fontWeight: '400' }}
//             >
//               {typeof item.name === 'string' ? parser(item.name) : item.name}
//             </div>
//             <div>
//               <Link to={`abouts`}>
//                 <ReadmoreBtn />
//               </Link>
//             </div>
//           </div>
//           <img
//             src="/images/common/Downwrapper.png"
//             alt="upwraper"
//             className="absolute right-[-5%] md:right-[-3%] lg:right-[-2%] bottom-[-2%] md:bottom-[-3%] lg:bottom-[-4%]"
//           />
//         </div>
//       ))}
//     </div>
//   );
// };

// Aboutme.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       image: PropTypes.string.isRequired,
//       name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // Allow name to be a string or a React node
//     })
//   ).isRequired,
// };

// export default Aboutme;











































































// import { Link } from 'react-router-dom';
// import Heading from '../common/Heading';
// import ReadmoreBtn from '../common/ReadmoreBtn';
// import parser from 'html-react-parser';
// import PropTypes from 'prop-types';

// // interface AboutmeProps {
// //   data: {
// //     image: string;
// //     name: string;
// //   }[];
// // }

// const Aboutme = ({ data }) => {
//   return (
//     <div className="my-16 px-8 md:px-20 lg:px-28 xl:px-36 relative">
//       <Heading heading="About Me" />

//       {data?.map((item, index) => (
//         <div
//           key={index}
//           className="my-16 h-fit bg-white sm:rounded-tr-[80px] sm:rounded-bl-[80px] box-shadow flex items-center mx-2 lg:mx-6 px-4 md:px-10 lg:px-12 py-4 md:py-12 lg:py-16 flex-col lg:flex-row relative"
//         >
//           <img
//             src="/images/common/Upwrapper.png"
//             alt="upwraper"
//             className="absolute top-[-2%] md:top-[-3%] lg:top-[-4%] left-[-5%] md:left-[-3%] lg:left-[-2%]"
//           />
//           <div className="h-[15rem] md:h-[50vh] lg:h-[58vh] w-[95%] sm:w-[85%] md:w-[70%] lg:w-[42%]">
//             <img
//               src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
//               alt="aboutme"
//               className="w-full h-full object-cover sm:rounded-tr-[80px] sm:rounded-bl-[80px] "
//             />
//           </div>
//           <div className="w-[98%] lg:w-[55%] text-justify lg:ml-8 mt-6 lg:mt-0 mb-4 md:mb-0">
//             <div
//               className="line-clamp-9 font-mulish text-base lg:w-[95%]"
//               style={{ lineHeight: '30px', fontWeight: '400' }}
//             >
//               {parser(item.name)}
//             </div>
//             <div>
//               <Link to={`abouts`}>
//                 <ReadmoreBtn />
//               </Link>
//             </div>
//           </div>
//           <img
//             src="/images/common/Downwrapper.png"
//             alt="upwraper"
//             className="absolute right-[-5%] md:right-[-3%] lg:right-[-2%] bottom-[-2%] md:bottom-[-3%] lg:bottom-[-4%]"
//           />
//         </div>
//       ))}
//     </div>
//   );
// };
// Aboutme.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       image: PropTypes.string.isRequired,
//       name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // Allow name to be a string or a React node
//     })
//   ).isRequired,
// };

// // Aboutme.propTypes = {
// //   data: PropTypes.arrayOf(
// //     PropTypes.shape({
// //       image: PropTypes.string.isRequired,
// //       name: PropTypes.string.isRequired,
// //     })
// //   ).isRequired,
// // };

// export default Aboutme;
