import Container from "../../components/container/Container";
import DataNotFound from "../errorPage/DataNotFound";
import MerchandiseTable from "../../components/forms/tables/MerchandiseTable";
import Loader from "../../components/loader/Loader";
import DataFetchingError from "../errorPage/DataFetchingError";
import { useFetchData } from "../../hooks/useFetchData/useFetchData";

const Merchandise = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: "merchandise",
  });

  return (
    <Container
      breadcumbMenu="Merchandise"
      title="Merchandise"
      btnTitle="Add Merchandise"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refetchData={refetchData} />
      ) : data.length > 0 ? (
        <MerchandiseTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Merchandise;
