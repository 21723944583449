/* eslint-disable no-unused-vars */
import React from "react";
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import InvolvementBannersForm from "../../../components/forms/postForms/InvolvementBannersForm/InvolvementBannersForm";

const AddInvolvementBanners = () => {
  return (
    <Container
      breadcumbMenu="involvement"
      breadcumbSubMenu="banners"
      title="New involvement banners"
      btnTitle="back to involvement-banners"
      path="/admin/involvement-banners"
    >
      <FormContainer heading="Add involvement-banners">
        <InvolvementBannersForm />
      </FormContainer>
    </Container>
  );
};

export default AddInvolvementBanners;
