import { useState } from "react";
import Heading from "../common/Heading";
import TimelineCard from "../card/TimelineCard";
import PropTypes from 'prop-types';
import { ChevronUp ,ChevronDown  } from 'lucide-react';

const Timeline = ({ timelineData }) => {
  const [visibleItems, setVisibleItems] = useState(3);

  const handleViewMore = () => {
    setVisibleItems((prev) => prev + 3);
  };

  const handleViewLess = () => {
    setVisibleItems(3);
  };

  return (
    <>
      <div className='bg-secondary-detailsBackground justify-center h-fit px-8 md:px-20 lg:px-28 xl:px-36 w-full'>
        <div className='pt-12'>
          <Heading heading='TimeLine' />
        </div>
        <div className=''>
          {timelineData.slice(0, visibleItems).map((item) => (
            <TimelineCard key={item._id} item={item} />
          ))}

          <div className='w-full flex justify-center mt-10'>
            {visibleItems < timelineData.length && (
              <button
                onClick={handleViewMore}
                className='w-fit text-white font-mulish text-sm transition duration-300 ease-in-out bg-primary-btnColor hover:text-white px-3 py-2 rounded-full hover:border-primary-btnColor flex items-center'
              >
                More
                <ChevronDown className='w-4 h-4 ml-1' />
              </button>
            )}
            {visibleItems > 3 && (
              <button
                onClick={handleViewLess}
                className='w-fit text-white font-mulish text-sm transition duration-300 ease-in-out bg-primary-btnColor hover:text-white px-3 py-2 rounded-full hover:border-primary-btnColor flex items-center ml-4'
              >
                Less
                <ChevronUp className='w-4 h-4 ml-1' />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className='h-fit w-full mb-2'>
        <svg
          viewBox='0 0 400 70'
          preserveAspectRatio='none'
          style={{ height: "50%", width: "100%" }}
        >
          <path
            d='M0,0 L500,0 L500,50 C300,110 150,20 0,50 Z'
            style={{
              stroke: "none",
              fill: "#FFF0E180",
            }}
          ></path>
        </svg>
      </div>
    </>
  );
};

Timeline.propTypes = {
  timelineData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      startingDate: PropTypes.string.isRequired,
      endDate: PropTypes.string,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Timeline;



















// import { useState } from "react";
// import LessBtn from "../common/LessBtn";
// import MoreBtn from "../common/MoreBtn";
// import Heading from "../common/Heading";
// import TimelineCard from "../card/TimelineCard";
// import PropTypes from 'prop-types';


// const Timeline = ({ timelineData }) => {
//   const [btnClick, setBtnClick] = useState(false);
//   // const [btnClick, setBtnClick] = useState<boolean>(false);
//   const onBtnClick = () => setBtnClick(!btnClick);

//   return (
//     <>
//       <div className='bg-secondary-detailsBackground justify-center h-fit px-8 md:px-20 lg:px-28 xl:px-36 w-full'>
//         <div className='pt-12'>
//           <Heading heading='TimeLine' />
//         </div>
//         <div className=''>
//           {timelineData.length <= 3 || btnClick
//             ? timelineData.map((item) => (
//                 <TimelineCard key={item._id} item={item} />
//               ))
//             : timelineData
//                 .slice(0, 3)
//                 .map((item) => <TimelineCard key={item._id} item={item} />)}

//           <div
//             onClick={onBtnClick}
//             className='w-full flex justify-center mt-10'
//           >
//             {timelineData.length > 3 && (
//               <>{btnClick ? <LessBtn /> : <MoreBtn />}</>
//             )}
//           </div>
//         </div>
//       </div>
//       <div className='h-fit w-full mb-4'>
//         <svg
//           viewBox='0 0 400 70'
//           preserveAspectRatio='none'
//           style={{ height: "60%", width: "100%" }}
//         >
//           <path
//             d='M0,0 L500,0 L500,50 C300,110 150,20 0,50 Z'
//             style={{
//               stroke: "none",
//               fill: "#FFF0E180",
//             }}
//           ></path>
//         </svg>
//       </div>
//     </>
//   );
// };

// Timeline.propTypes = {
//   timelineData: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.string.isRequired,
//       startingDate: PropTypes.string.isRequired,
//       endDate: PropTypes.string,
//       title: PropTypes.string.isRequired,
//       description: PropTypes.string.isRequired,
//       image: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };

// export default Timeline;
