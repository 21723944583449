import Container from '../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
import ArticleCategoryForm from '../../../components/forms/postForms/articleCategoryForm/ArticleCategoryForm';

const AddArticleCategory = () => {
  return (
    <Container
      breadcumbMenu="Article category"
      breadcumbSubMenu="new Article category"
      title="New Article category"
      btnTitle="back to Article category"
      path="/admin/article/categories"
    >
      <FormContainer heading="Add Article category">
        <ArticleCategoryForm />
      </FormContainer>
    </Container>
  );
};

export default AddArticleCategory;
