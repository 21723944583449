import Container from '../../components/container/Container';
import DataNotFound from '../errorPage/DataNotFound';
import { useFetchData } from '../../hooks/useFetchData/useFetchData';
import Loader from '../../components/loader/Loader';
import ArticleCategoryTable from '../../components/forms/tables/ArticleCategoryTable';
import DataFetchingError from '../errorPage/DataFetchingError';
import { useNavigate } from 'react-router-dom';

const ArticleCategory = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: 'article/categories',
  });
  const navigate = useNavigate();
  const refresh = () => {
    navigate('article/categories');
  };

  return (
    <Container
      breadcumbMenu="Article Category"
      title="Article Category"
      btnTitle="Add Article Category"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refresh={refresh} />
      ) : data.length > 0 ? (
        <ArticleCategoryTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default ArticleCategory;
