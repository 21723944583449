import Container from '../../components/container/Container';
import DataNotFound from '../errorPage/DataNotFound';
import DonationTable from '../../components/forms/tables/DonorTable';
import Loader from '../../components/loader/Loader';
import DataFetchingError from '../errorPage/DataFetchingError';
import { useFetchData } from '../../hooks/useFetchData/useFetchData';

const Donor = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: 'donors',
  });

  return (
    <Container
      breadcumbMenu="Donors"
      title="Donors"
      btnTitle="Add Donor"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refetchData={refetchData} />
      ) : data.length > 0 ? (
        <DonationTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Donor;
