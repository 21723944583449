import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import ContactInfoForm from "../../../components/forms/postForms/contactInfoForm/ContactInfoForm";

const AddContactInfo = () => {
  return (
    <Container
      breadcumbMenu='contact information'
      breadcumbSubMenu='new contact information'
      title='New product'
      btnTitle='back to contact information'
      path='/admin/contacts'
    >
      <FormContainer heading='Add contact information'>
        <ContactInfoForm />
      </FormContainer>
    </Container>
  );
};

export default AddContactInfo;
