/* eslint-disable no-unused-vars */
import React from 'react';

const DataNotFound = () => {
  return (
    <div className="w-full h-[70dvh] p-5 py-10 bg-slate-50 flex flex-col items-center justify-center">
      <div className="w-48 ">
        <img src="/data-not-found.png" className="w-full h-full object-cover" />
      </div>
      <h1 className="text-3xl font-extrabold text-gray-500">
        No results found
      </h1>
    </div>
  );
};

export default DataNotFound;
