import { useState } from "react";
import axios from "axios";

const useDataDeletion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Retrieve token from localStorage safely
  const storedData = localStorage.getItem("amrityonjan");
  const token = storedData ? JSON.parse(storedData).accessToken : null;

  const deleteData = async (url, onSuccess) => {
    if (!token) {
      setError("No access token found. Please log in again.");
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.delete(url, {
        credentials: "include", // Add credentials option here
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        onSuccess();
      } else {
        setError(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      setError(`Error deleting data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return { deleteData, loading, error };
};

export default useDataDeletion;


































// // useDataDeletion.js or useDataDeletion.ts
// import { useState } from "react";
// import axios from "axios";

// const useDataDeletion = () => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const deleteData = async (url, onSuccess) => {
//     try {
//       setLoading(true);
//       const response = await axios.delete(url, {
//         credentials: "include", // Add credentials option here
//         headers: {
//           "Content-Type": "application/json",
//           // Add other headers if needed
//         },
//       });

//       if (response.status === 200) {
//         onSuccess();
//       } else {
//         setError(`Unexpected status code: ${response.status}`);
//       }
//     } catch (error) {
//       setError(`Error deleting data: ${error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return { deleteData, loading, error };
// };

// export default useDataDeletion;
