/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Container from "./../../components/container/Container";
import axios from "axios";
import TimelineTable from "../../components/forms/tables/TimelineTable";
import DataNotFound from "../errorPage/DataNotFound";
import { useFetchData } from "../../hooks/useFetchData/useFetchData";
import Loader from "../../components/loader/Loader";
import DataFetchingError from "../errorPage/DataFetchingError";

const Timeline = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: "timelines",
  });

  return (
    <Container
      breadcumbMenu="Timelines"
      title="Timelines"
      btnTitle="Add timeline"
      path="/admin/timelines/add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refetchData={refetchData} />
      ) : data.length > 0 ? (
        <TimelineTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Timeline;
