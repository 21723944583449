import { ArrowLeft, ArrowRight } from 'lucide-react';
import AliceCarousel from 'react-alice-carousel';
import PropTypes from 'prop-types';

// interface PublicationSliderProps {
//   data: { image: string }[];
//   onPublicationSelect: (index: number) => void;
// }

const PublicationSlider = ({
  data,
  onPublicationSelect,
}) => {
  const responsive = {
    0: { items: 1 },
    800: { items: 2 },
    1024: { items: 4 },
  };

  return (
    <>
      <div className="w-[90%] lg:w-[80%] mx-auto relative px-10 my-16">
        <AliceCarousel
          disableDotsControls
          responsive={responsive}
          mouseTracking
          renderPrevButton={() => (
            <div className="absolute -left-12 lg:-left-20 top-1/2 -translate-y-1/2 p-2 rounded-full shadow-lg bg-white">
              <ArrowLeft className="text-primary-btnColor cursor-pointer" />
            </div>
          )}
          renderNextButton={() => (
            <div className="absolute -right-12 lg:-right-16 top-1/2 -translate-y-1/2 p-2 rounded-full shadow-lg bg-white">
              <ArrowRight className="text-primary-btnColor cursor-pointer" />
            </div>
          )}
        >
          {data?.map((item, index) => (
            <div
              key={index}
              className="w-full sm:w-[13rem] sm:h-[13rem] mx-auto "
            >
              <img
                onClick={() => onPublicationSelect(index)}
                className="w-full h-full object-contain"
                src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
                  item.image
                }`}
                alt={`img-${index}`}
              />
            </div>
          ))}
        </AliceCarousel>
      </div>
    </>
  );
};
PublicationSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
    })
  ).isRequired,
  onPublicationSelect: PropTypes.func.isRequired,
};
export default PublicationSlider;
