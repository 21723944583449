import DateViews from "../components/dateViews/DateViews";
import Heading from "../components/common/Heading";
import SliderCard from "../components/sliderCards/SliderCard";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import useFetchDataDetails from "../hooks/useFetchDataDetail/useFetchDataDetails";
import { useFetchData } from "../hooks/useFetchData/useFetchData";

const ArticleDetailPage = () => {
  const { id } = useParams();
  const { dataDetails, loading } = useFetchDataDetails("articles", id || "");
  const { data: articleData } = useFetchData({ endpoint: "articles" });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className=''>
        <div
          className='w-full h-52 lg:h-[450px]'
          style={{
            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(/images/articleDetail-bg.png)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>

        <>
          <div className=' w-full mx-auto mt-[-45%] lg:mt-[-24%] flex items-center justify-between flex-col'>
            <h1 className='text-white w-[80%] font-bold text-center capitalize pb-5 text-lg md:text-4xl lg:pb-10'>
              {dataDetails?.title}
            </h1>

            <div className='w-[75%] lg:w-[40%] h-auto mb-5'>
              <img
                className='w-full h-full object-contain'
                src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${dataDetails?.image}`}
                alt='IMG'
                srcSet=''
              />
            </div>
          </div>
          <div className='px-10 lg:w-[60%] lg:mx-auto'>
            <div className='my-6 md:my-5'>
              <DateViews data={dataDetails} />
            </div>
            <div className='mt-5 lg:mb-40 space-y-2'>
              {parse(`${dataDetails?.description}`)}
            </div>
          </div>
        </>
      </div>

      <div className='my-20'>
        <Heading heading='Similar Articles' />
        <SliderCard dataDetails={articleData} />
      </div>
    </>
  );
};

export default ArticleDetailPage;
