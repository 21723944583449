/* eslint-disable no-unused-vars */
import React from "react";
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import InitiativeForm from "../../../components/forms/postForms/initiativeForm/InitiativeForm";
// import Loader from '../../components/loader/Loader';
// import DataFetchingError from '../errorPage/DataFetchingError';
import { useFetchData } from "../../../hooks/useFetchData/useFetchData";

const AddInitiatve = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: "initiative/categories",
  });

  // const categoryName = data.map((item) => item.categoryName);

  return (
    <Container
      breadcumbMenu='Initiatives'
      breadcumbSubMenu='new Initiative'
      title='New Initiative'
      btnTitle='back to Initiative'
      path='/admin/initiatives'
    >
      <FormContainer heading='Add Initiative Data'>
        <InitiativeForm />
      </FormContainer>
    </Container>
  );
};

export default AddInitiatve;
