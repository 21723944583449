import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import { useParams } from "react-router-dom";
import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";
import Loader from "../../../components/loader/Loader";
import DataFetchingError from "../../errorPage/DataFetchingError";
import InvolvementAchievementsForm from "../../../components/forms/postForms/InvolvementAchievementsForm/InvolvementAchievementsForm";
import { useFetchData } from "../../../hooks/useFetchData/useFetchData";


const EditInvolvementAchievementsForm = () => {
  const { id } = useParams();
  const { dataDetails, loading, error } = useFetchDataDetails("involvement-achievements", id);

  const { data } = useFetchData({
    endpoint: 'involvements',
  });

  
  return (
    <Container
      breadcumbMenu='involvement-achievements'
      breadcumbSubMenu='edit involvement-achievements'
      title='edit involvement-achievements'
      btnTitle='back to involvement-achievements'
      path='/admin/involvement-achievements'
    >
      <FormContainer heading='edit involvement-achievements'>
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
              <InvolvementAchievementsForm defaultData={dataDetails} involvementData={data} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditInvolvementAchievementsForm;
