import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import { useParams } from "react-router-dom";
import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";
import Loader from "../../../components/loader/Loader";
import DataFetchingError from "../../errorPage/DataFetchingError";
import MerchandiseForm from "../../../components/forms/postForms/merchandise/MerchandiseForm";

const EditMerchandise = () => {
  const { id } = useParams();
  //   custom hook for fetching data details from api with 2 parameters (endpoint and id)
  const { dataDetails, loading, error } = useFetchDataDetails(
    "merchandise",
    id
  );

  return (
    <Container
      breadcumbMenu="merchandise"
      breadcumbSubMenu="edit merchandise"
      title="Edit merchandise"
      btnTitle="Back to Merchandise"
      path="/admin/merchandise"
    >
      <FormContainer heading="edit Merchandise">
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <MerchandiseForm defaultData={dataDetails} isEdit="true " />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditMerchandise;
