import TopSection from "../components/involvement/TopSection";
import InvolvementCategories from "../components/involvement/InvolvementCategories";
import { useFetchData } from "../hooks/useFetchData/useFetchData";

const InvolvementPage = () => {
  const { data: involvementData, loading } = useFetchData({
    endpoint: "involvements",
  });
  
  if (loading) {
    return null;
  }
  return (
    <>
      <TopSection />
      <InvolvementCategories involvementData={involvementData} />
    </>
  );
};

export default InvolvementPage;
