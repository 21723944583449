/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import Container from '../../components/container/Container';
import DataNotFound from '../errorPage/DataNotFound';
import ArticleTable from '../../components/forms/tables/ArticleTable';
import axios from 'axios';
import { useFetchData } from '../../hooks/useFetchData/useFetchData';
import Loader from '../../components/loader/Loader';

const Article = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: 'articles',
  });

  return (
    <Container
      breadcumbMenu="Article"
      title="Article"
      btnTitle="Add Article"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <p>Error: {error}</p>
      ) : data.length > 0 ? (
        <ArticleTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Article;
