import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import ProductCatForm from "../../../components/forms/postForms/productCatForm/ProductCatForm";

const AddProductCat = () => {
  return (
     <Container
      breadcumbMenu='Product Category'
      breadcumbSubMenu='new Product Category'
      title='New Product Category'
      btnTitle='back to Product Category'
      path='/admin/product-categories'
    >
      <FormContainer heading='Add Product Category'>
        <ProductCatForm />
      </FormContainer>
    </Container>
  );
};

export default AddProductCat;
