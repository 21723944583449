/* eslint-disable no-unused-vars */
import { useParams } from "react-router-dom";
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import Loader from "../../../components/loader/Loader";
import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";
import DataFetchingError from "../../errorPage/DataFetchingError";

import ManageUsersForm from "../../../components/forms/postForms/ManageUsersForm/ManageUsersForm";
import { useFetchData } from "../../../hooks/useFetchData/useFetchData";

const EditManageUsers = () => {
  const { id } = useParams();
  //   custom hook for fetching data detaails from api with 2 parameters (endpoint and id)
  const { dataDetails, loading, error } = useFetchDataDetails(
    "access/users",
    id
  );
  const { data } = useFetchData({
    endpoint: "access/users",
  });

  // console.log(data, "this is data");
  const categoryName = data.map((item) => item.categoryName);

  return (
    <Container
      breadcumbMenu="Users"
      breadcumbSubMenu="edit Users"
      title="edit Users"
      btnTitle="back to Users"
      path="/admin/access/users"
    >
      <FormContainer heading="edit Users">
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <ManageUsersForm user={dataDetails} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditManageUsers;








// /* eslint-disable no-unused-vars */
// import Container from "../../../components/container/Container";
// import FormContainer from "../../../components/forms/formContainer/FormContainer";
// import { useParams } from "react-router-dom";
// import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";
// import Loader from "../../../components/loader/Loader";
// import DataFetchingError from "../../errorPage/DataFetchingError";

// import { useFetchData } from "../../../hooks/useFetchData/useFetchData";
// import InitiativeForm from "../../../components/forms/postForms/initiativeForm/InitiativeForm";
// import ManageUsersForm from "../../../components/forms/postForms/ManageUsersForm/ManageUsersForm";

// const EditManageUsers = () => {
//   const { id } = useParams();
//   //   custom hook for fetching data detaails from api with 2 parameters (endpoint and id)
//   const { dataDetails, loading, error } = useFetchDataDetails("access/users", id);
//   const { data } = useFetchData({
//     endpoint: "access/users",
//   });

//   // console.log(data, "this is data");
//   const categoryName = data.map((item) => item.categoryName);

//   return (
//     <Container
//       breadcumbMenu="Users"
//       breadcumbSubMenu="edit Users"
//       title="edit Users"
//       btnTitle="back to Users"
//       path="/admin/access/users"
//     >
//       <FormContainer heading="edit Users">
//         {loading ? (
//           <Loader />
//         ) : error ? (
//           <DataFetchingError />
//         ) : (
//           <ManageUsersForm user={dataDetails} />
//         )}
//       </FormContainer>
//     </Container>
//   );
// };

// export default EditManageUsers;
