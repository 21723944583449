import Container from '../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
import PublicationForm from '../../../components/forms/postForms/publicationForm/PublicationForm';
import { useParams } from 'react-router-dom';
import useFetchDataDetails from '../../../hooks/useFetchDataDetail/useFetchDataDetails';
import DataFetchingError from '../../errorPage/DataFetchingError';
import Loader from '../../../components/loader/Loader';

const EditPublication = () => {
  const { id } = useParams();
  //   custom hook for fetching data detaails from api with 2 parameters (endpoint and id)
  const { dataDetails, loading, error } = useFetchDataDetails(
    'publications',
    id
  );

  return (
    <Container
      breadcumbMenu="publication"
      breadcumbSubMenu="edit publication"
      title="edit publication"
      btnTitle="back to publication"
      path="/admin/publications"
    >
      <FormContainer heading="edit publication">
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <PublicationForm defaultData={dataDetails} isEdit="true " />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditPublication;
