import PropTypes from 'prop-types';
import { ArrowLeft, ArrowRight, ChevronRight } from 'lucide-react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from 'react-router-dom';

const OtherInitiatives = ({ initiativesData }) => {
  const responsive = {
    0: { items: 1 },
    800: { items: 2 },
    1024: { items: 2 },
  };

  return (
    <div className="my-6 px-8 md:px-8 lg:px-12 xl:px-32">
      <h1
        className="text-xl font-semibold border-b-[3px] border-primary-btnColor mb-16 w-fit mx-auto"
        style={{ lineHeight: '42px' }}
      >
        Other Initiatives
      </h1>
      <div className="my-4">
        <AliceCarousel
          disableDotsControls
          responsive={responsive}
          mouseTracking
          renderPrevButton={() => (
            <div className="flex items-center justify-end">
              <ArrowLeft className="text-primary-btnColor cursor-pointer" />
            </div>
          )}
          renderNextButton={() => (
            <div className="flex items-center justify-start">
              <ArrowRight className="text-primary-btnColor cursor-pointer" />
            </div>
          )}
        >
          {initiativesData.filter((data) => data.category === "other").map((initiatives, index) => (
            <div
              key={index}
              className="box-shadow transition duration-300 ease-in-out md:mx-4 lg:mx-12 my-10 rounded-md hover:shadow-xl"
            >
              <div className="w-full h-1/2">
                <img
                  src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${initiatives.image}`}
                  alt="initiatives"
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div className="px-4 py-4 font-mulish relative">
                <h1 className="font-inter capitalize text-primary-headingColor text-2xl font-semibold pt-3">
                  {initiatives.title}
                </h1>
                <div className="text-justify mt-2 mb-4 line-clamp-4 whitespace-wrap overflow-hidden">
                  <p>
                    {initiatives.description}
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Esse cupiditate hic iusto, nesciunt ex sunt natus temporibus
                    explicabo libero perferendis laborum tempora veniam corporis
                    exercitationem fugiat molestiae expedita tempore unde!
                  </p>
                </div>
                <Link
                  to={`/initiatives/${initiatives._id}`}
                  className="text-white w-fit font-mulish px-5 text-base bg-primary-btnColor transition duration-300 ease-in-out py-2 rounded-full hover:bg-orange-600 flex items-center"
                >
                  View Detail
                  <ChevronRight className="w-5 h-5 ml-1" />
                </Link>
              </div>
            </div>
          ))}
        </AliceCarousel>
      </div>
    </div>
  );
};
OtherInitiatives.propTypes = {
  initiativesData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string,
    })
  ).isRequired,
};

export default OtherInitiatives;
