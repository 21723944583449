import Container from '../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
import { useParams } from 'react-router-dom';
import useFetchDataDetails from '../../../hooks/useFetchDataDetail/useFetchDataDetails';
import Loader from '../../../components/loader/Loader';
import DataFetchingError from '../../errorPage/DataFetchingError';

import { useFetchData } from '../../../hooks/useFetchData/useFetchData';
import InitiativeForm from '../../../components/forms/postForms/initiativeForm/InitiativeForm';

const EditInitiatve = () => {
  const { id } = useParams();
  //   custom hook for fetching data detaails from api with 2 parameters (endpoint and id)
  const { dataDetails, loading, error } = useFetchDataDetails(
    'initiatives',
    id
  );
  const { data } = useFetchData({
    endpoint: 'initiative/categories',
  });

  console.log(data, 'this is data');
  //   const categoryName = data.map((item) => item.categoryName);

  return (
    <Container
      breadcumbMenu="Initiatives"
      breadcumbSubMenu="new Initiative"
      title="New Initiative"
      btnTitle="back to Initiative"
      path="/admin/initiatives"
    >
      <FormContainer heading="Add Initiative Data">
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <InitiativeForm category={data} defaultData={dataDetails} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditInitiatve;
