import Aboutme from "../components/landingpage/Aboutme";
import Timeline from "../components/landingpage/Timeline";
import Initiatives from "../components/landingpage/Initiatives";
import Achievements from "../components/landingpage/Achievements";
import Videos from "../components/landingpage/Videos";
import HerosectionCarousel from "../components/common/HerosectionCaarousel";
import { useFetchData } from "../hooks/useFetchData/useFetchData";
import Heading from "../components/common/Heading";


const myHeroImages = [
  {
    image: "/images/Herosectionimg.png",
  },
  {
    image: "/images/AboutBg.png",
  },
  {
    image: "images/Herosectionimg.png",
  },
];

const LandingPage = () => {
  const { data, refetchData } = useFetchData({endpoint: "bannerpost",});
  const { data: AboutData } = useFetchData({ endpoint: "abouts" });
  const { data: timelineData } = useFetchData({
    endpoint: "timelines",
  });
  const { data: achievementData } = useFetchData({
    endpoint: "achievements",
  });
  const { data: initiativesData } = useFetchData({
    endpoint: "initiatives",
  });
  const heading1 = "Videos";
  // const heading2 = "How it began";
  return (
    <>
      {/* <HerosectionCarousel apiUrl="http://localhost:8000/api/v1/bannerpost" /> */}
      
      <HerosectionCarousel data={data} refetchData={refetchData} myHeroImages={myHeroImages} />
      {/* <HerosectionCarousel myHeroImages={myHeroImages} /> */}
      <Aboutme data={AboutData} />
      <Timeline timelineData={timelineData} />
      <Initiatives initiativesData={initiativesData} />
      <div className="pt-8 pb-8 overflow-hidden">
        <Heading heading={heading1} className="pb-8" />
        <Videos  className="overflow-hidden"/> 
      </div>
      <Achievements achievementData={achievementData} />
    </>
  );
};

export default LandingPage;
