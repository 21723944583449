/* eslint-disable no-unused-vars */
import Container from "../../components/container/Container";
import DataNotFound from "../errorPage/DataNotFound";
import { useFetchData } from "../../hooks/useFetchData/useFetchData";
import Loader from "../../components/loader/Loader";
import DataFetchingError from "../errorPage/DataFetchingError";
import ProductTable from "../../components/forms/tables/ProductTable";
import ProjectTable from "../../components/forms/tables/ProjectTable";

const Project = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: "involvements",
  });

  return (
    <Container
      breadcumbMenu='Involvements'
      title='Involvements'
      btnTitle='Add Involvements'
      path='add'
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refetchData={refetchData} />
      ) : data.length > 0 ? (
        <ProjectTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Project;
