import { formatDate } from '../../../utility/dateFormates';
import PropTypes from 'prop-types';

// interface TimelineProps {
//   publicationData: Array<{
//     publishedDate: string;
//   }>;
//   selectByTimeline: (index: number) => void;
// }

const Timeline = ({
  publicationData,
  selectByTimeline,
}) => {
  // Get the current date
  const currentDate = new Date();

  return (
    <ol className="relative flex-row border-t-[4px] lg:border-t-0 lg:border-s-[4px] flex gap-5 lg:flex-col justify-around my-5 border-[#828587]">
      {publicationData
        ?.filter((item) => {
          const publishedDate = new Date(item.publishedDate);
          return currentDate < publishedDate;
        })
        .map((item, index) => (
          <li
            className="mb-10 ms-4 cursor-pointer"
            key={index}
            onClick={() => {
              selectByTimeline(index);
            }}
          >
            <div className="absolute w-5 h-5 rounded-full bg-[#f38500] lg:-start-[12px] border-[3px] border-white"></div>
            <h3 className="mb-1 text-xl w-fit font-semibold mt-4 lg:mt-0">
              {formatDate(item.publishedDate)}
            </h3>
          </li>
        ))}
    </ol>
  );
};
Timeline.propTypes = {
  publicationData: PropTypes.arrayOf(
    PropTypes.shape({
      publishedDate: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectByTimeline: PropTypes.func.isRequired,
};

export default Timeline;
