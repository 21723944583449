/* eslint-disable no-unused-vars */
import React from "react";
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
// import AffiliationForm from "../../../components/forms/postForms/affiliationForm/AffiliationForm";
import useFetchDataDetails from "./../../../hooks/useFetchDataDetail/useFetchDataDetails";
import { useParams } from "react-router-dom";
import DataFetchingError from "../../errorPage/DataFetchingError";
import Loader from "../../../components/loader/Loader";
import AchievementForm from "./../../../components/forms/postForms/achievementForm/AchievementForm";

const EditAchievement = () => {
  const { id } = useParams();
  //   custom hook for fetching data detaails from api with 2 parameters (endpoint and id)
  const { dataDetails, loading, error } = useFetchDataDetails(
    "achievements",
    id
  );

  return (
    <Container
      breadcumbMenu=" Achievement"
      breadcumbSubMenu="Edit Achievement"
      title="edit Achievement"
      btnTitle="back toAchievement"
      path="/admin/achievements"
    >
      <FormContainer heading="edit Achievement">
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <AchievementForm defaultData={dataDetails} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditAchievement;
