/* eslint-disable no-unused-vars */
import Container from "../../components/container/Container";
import Loader from "../../components/loader/Loader";
import DataFetchingError from "../errorPage/DataFetchingError";
import { useFetchData } from "../../hooks/useFetchData/useFetchData";
import DataNotFound from "../errorPage/DataNotFound";
import ColorTable from "../../components/forms/tables/ColorTable";
import AdvertisementTable from "../../components/forms/tables/AdvertisementTable";

const Advertisement = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: "advertisements",
  });

  return (
    <Container
      breadcumbMenu="Advertisement"
      title="Advertisement"
      btnTitle="Add Advertisement"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refetchData={refetchData} />
      ) : data.length > 0 ? (
        <AdvertisementTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Advertisement;
