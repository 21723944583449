import Container from '../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
import { useParams } from 'react-router-dom';
import useFetchDataDetails from '../../../hooks/useFetchDataDetail/useFetchDataDetails';
import Loader from '../../../components/loader/Loader';
import DataFetchingError from '../../errorPage/DataFetchingError';
import PaperPresentForm from '../../../components/forms/postForms/paperPresent/PaperPresentForm';

const EditPaperPresents = () => {
  const { id } = useParams();
  //   custom hook for fetching data detaails from api with 2 parameters (endpoint and id)
  const { dataDetails, loading, error } = useFetchDataDetails(
    'paper/presents',
    id
  );
  return (
    <Container
      breadcumbMenu="Paper Presents"
      breadcumbSubMenu="edit Paper Presents"
      title="Edit Paper Presents"
      btnTitle="back to Paper Presents"
      path="/admin/paper/presents"
    >
      <FormContainer heading="edit Paper Presents">
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <PaperPresentForm defaultData={dataDetails} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditPaperPresents;
