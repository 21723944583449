import HerosectionCarousel from '../components/common/HerosectionCaarousel';
import OrganizationalInitiatives from '../components/initiatives/OrganizationalInitiatives';
import OtherInitiatives from '../components/initiatives/OtherInitiatives';
import ProjectInitiatives from '../components/initiatives/ProjectInitiatives';
import Heading from '../components/common/Heading';
import { useFetchData } from '../hooks/useFetchData/useFetchData';

const myHeroImages = [
  {
    image: '/images/AboutBg.png',
  },
  {
    image: '/images/Herosectionimg.png',
  },
  {
    image: 'images/Herosectionimg.png',
  },
];

const InitiativesPage = () => {
  const { data: initiativesData } = useFetchData({
    endpoint: 'initiatives',
  });

  return (
    <>
      <HerosectionCarousel myHeroImages={myHeroImages} />
      <div className="py-10">
        <Heading heading="Initiatives" />
      </div>
      <OrganizationalInitiatives initiativesData={initiativesData} />
      <ProjectInitiatives initiativesData={initiativesData} />
      <OtherInitiatives initiativesData={initiativesData} />
    </>
  );
};

export default InitiativesPage;
