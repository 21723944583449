import ToolTip from "../tooltip/ToolTip";
import PropTypes from "prop-types";
import { Trash2, Pencil } from "lucide-react";
import TableContainer from "../../container/TableContainer";
import Modal from "../../models/Model";
import { toast } from "react-toastify";
import useDataDeletion from "../../../hooks/useDataDeletion/usedDataDeletion";
import { Link } from "react-router-dom";
import { useState } from "react";
import Pagination from "../pagination/Pagination";
import { formatDate } from "../../../utility/dateFormate/formateDate";


const ProjectTable = ({ data, refetchData }) => {
  const apiUrl = `${import.meta.env.VITE_APP_API_URL}/involvements`;

  const { deleteData } = useDataDeletion();
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;

  const openModal = (id) => {
    setDeleteItemId(id);
  };

  const closeModal = () => {
    setDeleteItemId(null);
  };

  const handleDelete = async () => {
    deleteData(`${apiUrl}/${deleteItemId}`, () => {
      toast.success("Data deleted successfully!");
      refetchData();
      closeModal();
    });
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const newData = data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <TableContainer>
      {data && (
        <>
          <div className="space-y-5">
            {newData.map((item, index) => (
              <div key={index} className="p-2 rounded-lg shadow-md space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-6 gap-2 p-5">
                  <div><strong>Logo:</strong>
                    <img
                      className="w-[40%] h-auto object-cover rounded-lg"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item?.logo}`}
                      alt="Logo"
                    />
                  </div>
                  <div><strong>Image:</strong>
                    <img
                      className="w-[65%] h-auto object-cover rounded-lg"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item?.image}`}
                      alt="Image"
                    />
                  </div>
                  <div><strong>Sub Image:</strong>
                    <img
                      className="w-[55%] h-auto object-cover rounded-lg"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item?.subImages}`}
                      alt="Sub Image"
                    />
                  </div>

                  <div><strong>Title:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{item?.title}</h3>
                  </div>

                  {/* <div><strong>Category:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{item?.category}</h3>
                  </div> */}
                  
                  <div className="mb-10 capitalize"><strong>Your Post:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{item?.yourPost}</h3>
                  </div>

                  <div className="mb-10 capitalize"><strong>Your Position:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{item?.position}</h3>
                  </div>

                  
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-7 gap-2 p-2">
                  {/* <div className="mb-10 capitalize"><strong>Title:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{item?.title}</h3>
                  </div> */}

                  <div className="mb-10 capitalize"><strong>Content:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700 line-clamp-2">{item?.content}</h3>
                  </div>

                  <div className="mb-10 capitalize"><strong>Location:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700 line-clamp-2">{item?.location}</h3>
                  </div>

                  <div className="mb-10"><strong>Start Date:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{formatDate(item?.startingDate)}</h3>
                  </div>

                  <div className="mb-10"><strong>End Date:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{formatDate(item?.endDate)}</h3>
                  </div>

                  <div className="mb-10 capitalize"><strong>Organization:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{item?.organization}</h3>
                  </div>

                  <div><strong>Category:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{item?.category}</h3>
                  </div>

                  {/* <div className="mb-10 capitalize"><strong>Your Post:</strong>
                    <h3 className="pt-5 text-1xl font-normal text-gray-700">{item?.yourPost}</h3>
                  </div> */}


                </div>

                <div className="flex justify-end space-x-4">
                  <ToolTip text="edit">
                    <Link
                      to={`/admin/involvements/edit/${item._id}`}
                      className="inline-block text-blue-500"
                    >
                      <Pencil className="h-6 w-6" />
                    </Link>
                  </ToolTip>
                  <ToolTip text="delete">
                    <button
                      onClick={() => openModal(item._id)}
                      className="inline-block text-red-500"
                    >
                      <Trash2 className="h-6 w-6" />
                    </button>
                  </ToolTip>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            className="mt-6"
            postsPerPage={postsPerPage}
            totalPosts={data.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </>
      )}
      <Modal isOpen={deleteItemId !== null} closeModal={closeModal}>
        <button onClick={handleDelete} className="btn btn-danger">
          Delete
        </button>
        <button onClick={closeModal} className="btn btn-secondary">
          Cancel
        </button>
      </Modal>
    </TableContainer>
  );
};

ProjectTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  refetchData: PropTypes.func.isRequired,
};

export default ProjectTable;



















// import ToolTip from "../tooltip/ToolTip";
// import PropTypes from "prop-types";
// import { Trash2, Pencil } from "lucide-react";
// import TableContainer from "../../container/TableContainer";
// import Modal from "../../models/Model";
// import { toast } from "react-toastify";
// import useDataDeletion from "../../../hooks/useDataDeletion/usedDataDeletion";
// import { Link } from "react-router-dom";
// import { useState } from "react";
// import { formatDate } from "../../../utility/dateFormate/formateDate";

// const ProjectTable = ({ data, refetchData }) => {
//   const apiUrl = `${import.meta.env.VITE_APP_API_URL}/involvements`;

//   const { deleteData } = useDataDeletion();
//   const [deleteItemId, setDeleteItemId] = useState(null);

//   const openModal = (id) => {
//     setDeleteItemId(id);
//   };

//   const closeModal = () => {
//     setDeleteItemId(null);
//   };

//   const handleDelete = async () => {
//     deleteData(`${apiUrl}/${deleteItemId}`, () => {
//       toast.success("Data deleted successfully!");
//       refetchData();
//       closeModal();
//     });
//   };

//   return (
//     <TableContainer>
//       <table className="min-w-full divide-y divide-gray-200">
//         <thead className="bg-gray-50 capitalize font-bold ">
//           <tr>
//             <th
//               scope="col"
//               className="w-fit px-4 py-3.5 text-left text-sm  text-gray-700"
//             >
//               <span>SN</span>
//             </th>

//             <th scope="col" className="px-4 py-3.5  text-sm  text-gray-700">
//               Logo
//             </th>

//             <th scope="col" className="px-4 py-3.5  text-sm  text-gray-700">
//               image
//             </th>

//             <th scope="col" className="px-4 py-3.5  text-sm  text-gray-700">
//               subImage
//             </th>
//             <th
//               scope="col"
//               className="w-[20%] px-5 py-3.5 text-left text-sm  text-gray-700"
//             >
//               title
//             </th>

//             <th
//               scope="col"
//               className=" px-5 py-3.5 text-left text-sm  text-gray-700"
//             >
//               content
//             </th>

//             <th
//               scope="col"
//               className="w-fit px-2 py-3.5 text-left text-sm  text-gray-700"
//             >
//               start Date
//             </th>
//             <th
//               scope="col"
//               className="w-fit px-2 py-3.5 text-left text-sm  text-gray-700"
//             >
//               end Date
//             </th>
//             <th
//               scope="col"
//               className=" px-2 py-3.5 text-left text-sm  text-gray-700"
//             >
//               category
//             </th>
//             <th
//               scope="col"
//               className=" px-2 py-3.5 text-left text-sm  text-gray-700"
//             >
//               organization
//             </th>

//             <th scope="col" className="text-gray-700 px-4 py-3.5">
//               <span className="">Actions</span>
//             </th>
//           </tr>
//         </thead>
//         <tbody className="divide-y divide-gray-200 bg-white">
//           {data?.map((item, index) => (
//             <tr key={index}>
//               <td className="whitespace-wrap font-bold px-4 py-4 text-sm text-gray-700">
//                 <div className="text-sm text-gray-700">{index + 1}</div>
//               </td>

//               <td className="whitespace-wrap px-4 py-4">
//                 <div className="flex items-center pl-4 ">
//                   <div className="h-20 w-20  flex-shrink-0 p-2">
//                     <Link to={`/admin/involvements/details/${item?._id}`}>
//                       {item?.image ? (
//                         <img
//                           className="h-full w-full object-contain object-center"
//                           src={`${
//                             import.meta.env.VITE_APP_BASE_URL
//                           }/uploads/${item?.logo}`}
//                           alt=""
//                         />
//                       ) : (
//                         <img
//                           className="h-full w-full object-contain object-center"
//                           src="/no-image.png"
//                           alt="logo"
//                         />
//                       )}
//                     </Link>
//                   </div>
//                 </div>
//               </td>

//               <td className="whitespace-wrap px-4 py-4">
//                 <div className="flex items-center pl-4 ">
//                   <div className="h-20 w-20  flex-shrink-0 p-2">
//                     <Link to={`/admin/involvements/details/${item?._id}`}>
//                       {item?.image ? (
//                         <img
//                           className="h-full w-full object-contain object-center"
//                           src={`${
//                             import.meta.env.VITE_APP_BASE_URL
//                           }/uploads/${item?.image}`}
//                           alt=""
//                         />
//                       ) : (
//                         <img
//                           className="h-full w-full object-contain object-center"
//                           src="/no-image.png"
//                           alt="image"
//                         />
//                       )}
//                     </Link>
//                   </div>
//                 </div>
//               </td>

//               <td className="whitespace-wrap px-4 py-4">
//                 <div className="flex items-center pl-4 ">
//                   <div className="h-20 w-20  flex-shrink-0 p-2">
//                     <Link to={`/admin/involvements/details/${item?._id}`}>
//                       {item?.image ? (
//                         <img
//                           className="h-full w-full object-contain object-center"
//                           src={`${
//                             import.meta.env.VITE_APP_BASE_URL
//                           }/uploads/${item?.subImages}`}
//                           alt=""
//                         />
//                       ) : (
//                         <img
//                           className="h-full w-full object-contain object-center"
//                           src="/no-image.png"
//                           alt="image"
//                         />
//                       )}
//                     </Link>
//                   </div>
//                 </div>
//               </td>


//               <td className="whitespace-wrap px-2 py-2">
//                 <div className="text-sm font-semibold  capitalize line-clamp-1 sm:line-clamp-2 overflow-hidden ">
//                   <Link to={`/admin/involvements/details/${item?._id}`}>
//                     {item?.title}
//                   </Link>
//                 </div>
//               </td>

//               <td className="w-auto px-2 py-4">
//                 <div className="text-sm font-medium text-black capitalize line-clamp-3 overflow-hidden  ">
//                   {item?.content}
//                 </div>
//               </td>
//               <td className="w-[9rem]  px-4 py-4">
//                 <div className="text-sm font-medium text-black capitalize">
//                   {formatDate(item?.startingDate)}
//                 </div>
//               </td>
//               <td className="w-[9rem] px-4 py-4">
//                 <div className="text-sm font-medium text-black capitalize line-clamp-3 overflow-hidden  ">
//                   {formatDate(item?.endDate)}
//                 </div>
//               </td>
//               <td className="whitespace-wrap px-4 py-4">
//                 <div className="text-sm font-medium text-black capitalize line-clamp-3 overflow-hidden  ">
//                   {item?.category}
//                 </div>
//               </td>

//               <td className="whitespace-wrap px-4 py-4">
//                 <div className="text-sm font-medium text-black capitalize line-clamp-3 overflow-hidden  ">
//                   {item?.organization}
//                 </div>
//               </td>

//               <td className="whitespace-wrap px-12 w-full py-6 flex items-center justify-center gap-3">
//                 <div className="text-sm text-gray-900 ">
//                   <ToolTip text="edit">
//                     <Link to={`/admin/involvements/edit/${item._id}`}>
//                       <span className="flex items-center gap-2 rounded-full bg-blue-500 p-2 text-white">
//                         <Pencil className="h-4 w-4  " aria-hidden="true" />
//                       </span>
//                     </Link>
//                   </ToolTip>
//                 </div>
//                 <div className="text-sm text-gray-700">
//                   <ToolTip text="delete">
//                     <span
//                       onClick={() => openModal(item._id)}
//                       className="flex items-center gap-2 rounded-full bg-orange-500 p-2 text-white"
//                     >
//                       <Trash2 className="h-4 w-4" aria-hidden="true" />
//                     </span>
//                   </ToolTip>
//                 </div>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <Modal isOpen={deleteItemId !== null} onClose={closeModal}>
//         <button
//           onClick={closeModal}
//           className=" p-4 w-fit bg-indigo-600  text-white font-bold text-sm "
//         >
//           Cancel
//         </button>
//         <button
//           onClick={() => handleDelete(deleteItemId)}
//           className=" p-4 w-fit bg-orange-600 text-white font-bold text-sm "
//         >
//           Delete
//         </button>
//       </Modal>
//     </TableContainer>
//   );
// };

// ProjectTable.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.object), // Assuming data is an array of objects
//   refetchData: PropTypes.func.isRequired,
// };

// export default ProjectTable;
