import { useForm } from "react-hook-form";
import CustomInputField from "../../customInputField/CustomInputField";
import CustomButton from "../../button/CustomButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAddData } from "../../../../hooks/useAddData/useAddData";
import { useEditData } from "../../../../hooks/useEdit/useEdit";
import PropTypes from "prop-types";

const MerchandiseForm = ({ defaultData }) => {
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      name: defaultData?.name || "",
      description: defaultData?.description || "",
      category: defaultData?.category || "",
      image: defaultData?.image || "",
      productPrice: defaultData?.productPrice || "",
      size: defaultData?.size || "",
      // "socialMedia.facebook": defaultData?.socialMedia?.facebook || "",
      // "socialMedia.email": defaultData?.socialMedia?.email || "",
      // "socialMedia.linkedin": defaultData?.socialMedia.linkedin || "",
    },
  });

  const categorData = [
    {
      id: "1",
      category: "T-shirt",
    },
    {
      id: "2",
      category: "cap",
    },
    {
      id: "3",
      category: "cup",
    },
    {
      id: "4",
      category: "others",
    },
  ];
  const sizeData = [
    {
      id: "1",
      size: "S",
    },
    {
      id: "2",
      size: "M",
    },
    {
      id: "3",
      size: "L",
    },
    {
      id: "4",
      size: "XL",
    },
    {
      id: "5",
      size: "XXL",
    },
  ];
  const navigate = useNavigate();

  const { addData, isLoading: isAdding, error: addError } = useAddData();
  const { editData, isLoading: isEditing, error: editError } = useEditData();
  const header = {
    "Content-Type": "multipart/form-data", // Add the Content-Type header
  };

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("name", data?.name);
    formData.append("image", data?.image[0]);
    formData.append("description", data?.description);
    formData.append("category", data?.category);
    formData.append("size", data?.size);
    formData.append("productPrice", data?.productPrice);
    // formData.append("socialMedia[facebook]", data.socialMedia.facebook);
    // formData.append("socialMedia[email]", data.socialMedia.email);
    // formData.append("socialMedia[linkedin]", data.socialMedia.linkedin);

    try {
      if (defaultData) {
        // eslint-disable-next-line react/prop-types
        await editData(formData, "merchandise", defaultData._id);
        toast.success("Data updated successfully!");
      } else {
        await addData(formData, "merchandise", header);
        toast.success("Data added successfully!");
      }
      navigate("/merchandise");
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomInputField
        label="Product Name:"
        type="text"
        name="name"
        {...register("name")}
      />

      <div className="grid grid-cols-2 gap-3 items-center">
        <div className="w-full h-full">
          <label
            htmlFor="category"
            className="capitalize text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Category Name:
          </label>
          <select
            id="category"
            name="category"
            {...register("category")}
            className={`mt-2 capitalize transition duration-300 ease flex w-full border-2 border-slate-700/40 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:border-blue-500`}
          >
            <option value="">select category</option>
            {categorData?.map((item, index) => (
              <option key={index} value={item?.category}>
                {item?.category}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full h-full">
          <label
            htmlFor="size"
            className="capitalize text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            size:
          </label>
          <select
            id="size"
            name="size"
            {...register("size")}
            className={`mt-2 capitalize transition duration-300 ease flex w-full border-2 border-slate-700/40 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:border-blue-500`}
          >
            <option value="">select size</option>
            {sizeData?.map((item, index) => (
              <option key={index} value={item?.size}>
                {item?.size}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center gap-10">
          <CustomInputField
            label="Featured Image:"
            type="file"
            name="image"
            control={control}
            onChange={(e) => setValue("image", e.target.files)}
          />
        </div>
        {defaultData?.image && (
          <div className="w-40 mb-5">
            <img
              className="w-full h-full object-cover"
              src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/images/${
                defaultData?.image
              }`}
              alt=""
            />
          </div>
        )}
      </div>

      <CustomInputField
        label="Description:"
        type="text-area"
        name="description"
        {...register("description")}
      />
      <CustomInputField
        label="Product Price:"
        type="text"
        name="productPrice"
        {...register("productPrice")}
      />

      <CustomButton type="submit" disabled={isAdding || isEditing}>
        {isAdding || isEditing ? "Processing..." : "Submit"}
      </CustomButton>
      {(addError || editError) && (
        <p className="text-red-500 mt-2">{addError || editError}</p>
      )}
    </form>
  );
};

MerchandiseForm.propTypes = {
  defaultData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    image: PropTypes.string,
    size: PropTypes.string,
    productPrice: PropTypes.string,
    // socialMedia: PropTypes.shape({
    //   facebook: PropTypes.string,
    //   email: PropTypes.string,
    //   linkedin: PropTypes.string,
    // }),
  }),
};

export default MerchandiseForm;

{
  /* <div className="grid grid-cols-3 gap-3">
        {
          ["facebook", "email", "linkedin"].map((socialMedia, index) => (
          <Controller
            key={index}
            name={`socialMedia.${socialMedia}`}
            control={control}
            defaultValue={getValues(`socialMedia.${socialMedia}`) || ""}
            // defaultValue={getValues(`socialMedia?[index].${socialMedia}`) || ""}
            render={({ field }) => {
              return (
                <CustomInputField label={socialMedia} type="text" {...field} />
              );
            }}
          />
        ))}
      </div> */
}
