/* eslint-disable no-unused-vars */
import React from 'react';
import Container from '../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
import PublicationForm from '../../../components/forms/postForms/publicationForm/PublicationForm';

const AddPublication = () => {
  return (
    <Container
      breadcumbMenu="publication"
      breadcumbSubMenu="new publication"
      title="New publication"
      btnTitle="back to publication"
      path="/admin/publications"
    >
      <FormContainer heading="Add publication">
        <PublicationForm />
      </FormContainer>
    </Container>
  );
};

export default AddPublication;
