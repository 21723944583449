import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import ScopeForm from "../../../components/forms/postForms/scopeForm/ScopeForm";
import { useParams } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import DataFetchingError from "../../errorPage/DataFetchingError";
import useFetchDataDetails from "../../../hooks/useFetchDataDetail/useFetchDataDetails";

const EditAboutMe = () => {
  const { id } = useParams();
  const { dataDetails, loading, error, refetchData } = useFetchDataDetails(
    "scope",
    id
  );
  return (
    <Container
      breadcumbMenu="Scope Me"
      breadcumbSubMenu="Edit Scope Me"
      title="Scope Me"
      btnTitle="Back to Scope Me"
      path="/admin/scope"
    >
      <FormContainer heading="edit Scope Me data">
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError error={error} refetchData={refetchData} />
        ) : (
          <ScopeForm defaultData={dataDetails} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditAboutMe;
