import { useForm, Controller } from "react-hook-form";
import CustomInputField from "../../customInputField/CustomInputField";
import CustomButton from "../../button/CustomButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAddData } from "../../../../hooks/useAddData/useAddData";
import { useEditData } from "../../../../hooks/useEdit/useEdit";
import PropTypes from "prop-types";

const DonorForm = ({ defaultData }) => {
  const { register, handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: {
      name: defaultData?.name || "",
      description: defaultData?.description || "",
      category: defaultData?.category || "",
      image: defaultData?.image || "",
      donateAmt: defaultData?.donateAmt || "",
      "socialMedia.facebook": defaultData?.socialMedia?.facebook || "",
      "socialMedia.email": defaultData?.socialMedia?.email || "",
      "socialMedia.linkedin": defaultData?.socialMedia?.linkedin || "",
    },
  });

  const categorData = [
    {
      id: "1",
      category: "individual",
    },
    {
      id: "2",
      category: "institution",
    },
  ];
  const navigate = useNavigate();

  const { addData, isLoading: isAdding, error: addError } = useAddData();
  const { editData, isLoading: isEditing, error: editError } = useEditData();
  const header = {
    "Content-Type": "multipart/form-data", // Add the Content-Type header
  };

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("name", data?.name);
    formData.append("image", data?.image[0]);
    formData.append("description", data?.description);
    formData.append("category", data?.category);
    formData.append("donateAmt", data?.donateAmt);
    formData.append("socialMedia[facebook]", data.socialMedia.facebook);
    formData.append("socialMedia[email]", data.socialMedia.email);
    formData.append("socialMedia[linkedin]", data.socialMedia.linkedin);

    try {
      if (defaultData) {

        await editData(formData, "donors", defaultData._id);
        toast.success("Data updated successfully!");
      } else {
        await addData(formData, "donors", header);
        toast.success("Data added successfully!");
      }
      navigate("/admin/donors");
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Failed to ${defaultData ? "update" : "add"} data`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomInputField
        label="Donor Name:"
        type="text"
        name="name"
        {...register("name")}
      />
      <div className="grid grid-cols-3 gap-3">
        {["facebook", "email", "linkedin"].map((socialMedia, index) => (
          <Controller
            key={index}
            name={`socialMedia.${socialMedia}`}
            control={control}
            defaultValue={getValues(`socialMedia.${socialMedia}`) || ""}
            // defaultValue={getValues(`socialMedia?[index].${socialMedia}`) || ""}
            render={({ field }) => {
              return (
                <CustomInputField label={socialMedia} type="text" {...field} />
              );
            }}
          />
        ))}
      </div>

      <div className="grid grid-cols-2 gap-3 items-center">
        <div className="w-full h-full">
          <label
            htmlFor="category"
            className="capitalize text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Category Name:
          </label>
          <select
            id="category"
            name="category"
            {...register("category")}
            className={`mt-2 capitalize transition duration-300 ease flex w-full border-2 border-slate-700/40 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:border-blue-500`}
          >
            <option value="">select category</option>
            {categorData?.map((item, index) => (
              <option key={index} value={item?.category}>
                {item?.category}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center gap-10">
          <CustomInputField
            label="Featured Image:"
            type="file"
            name="image"
            control={control}
            onChange={(e) => setValue("image", e.target.files)}
          />
        </div>
        {defaultData?.image && (
          <div className="w-40 mb-5">
            <img
              className="w-full h-full object-cover"
              src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
                defaultData?.image
              }`}
              alt=""
            />
          </div>
        )}
      </div>

      <CustomInputField
        label="Description:"
        type="text-area"
        name="description"
        {...register("description")}
      />
      {/* <CustomInputField
        label="Donation Amount:"
        type="text"
        name="donateAmt"
        {...register("donateAmt")}
      /> */}

      <CustomButton type="submit" disabled={isAdding || isEditing}>
        {isAdding || isEditing ? "Processing..." : "Submit"}
      </CustomButton>
      {(addError || editError) && (
        <p className="text-red-500 mt-2">{addError || editError}</p>
      )}
    </form>
  );
};

DonorForm.propTypes = {
  defaultData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    image: PropTypes.string,
    donateAmt: PropTypes.string,
    socialMedia: PropTypes.shape({
      facebook: PropTypes.string,
      email: PropTypes.string,
      linkedin: PropTypes.string,
    }),
    _id:PropTypes.string,
  }),
};

export default DonorForm;
