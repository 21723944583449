import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Heading from "../common/Heading";
import ProjectContribution from "./ProjectContribution";
// import ParticipationForm from "./ParticipationForm";
import Testimonials from "../extra/testimonials";
import DonarPopup from "./Donarpopup";
import { Calendar, CheckSquare2, HandCoins, ChevronRight } from "lucide-react";
import useFetchDataDetails from "../../hooks/useFetchDataDetail/useFetchDataDetails";
import { formatDate } from "../../utility/dateFormates";
import DefaultImage from "../defaultImage/DefaultImage";
import { useFetchData } from "../../hooks/useFetchData/useFetchData";
import DonorCard from "./DonorCard";



const ProjectDetailsPage = () => {
  const { id } = useParams();
  const { data: donationData, loading } = useFetchData({
    endpoint: "donations",
  });
  const { dataDetails, loading: isLoading } = useFetchDataDetails(
    "initiatives",
    id ?? ""
  );

  const { data:articleData } = useFetchData({
    endpoint: 'articles',
  });

  const donationPercent = 85;

  // const donor = donationData
  //   .filter((item) => item?.initiativeId?._id === id)
  //   .map((item) => item?.donorId);

  const [selectedCategory, setSelectedCategory] = useState("individual");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedDonar, setSelectedDonar] = useState(null);

  const closePopup = () => setIsPopupOpen(false);

  const onDonarClick = (donarObjDetail) => {
    setSelectedDonar(donarObjDetail);
    setIsPopupOpen(true);
  };

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  if (loading || isLoading) {
    return null;
  }

  return (
    <>
      <div className="h-[60vh] md:h-[70vh] relative">
        <div className="h-[60vh] md:h-[70vh] overflow-hidden">
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <clipPath id={`clipPath`}>
              <path
                d={
                  !isSmallScreen
                    ? "M0,120 C150,180 300,80 500,120 L500,0 L0,0 Z"
                    : "M0,120 C200,140 180,80 500,120 L500,0 L0,0 Z"
                }
                style={{
                  stroke: "none",
                  fill: "#e1efe3",
                }}
              ></path>
            </clipPath>

            {/* Image */}
            <image
              xlinkHref="/images/ProjectDetailsBg.png"
              clipPath={`url(#clipPath)`}
              style={{ objectPosition: "center" }}
              preserveAspectRatio="xMinYMin slice"
              width={"100%"}
              height={"100%"}
            />
          </svg>
        </div>
        <div className="absolute top-[20%] capitalize left-10 md:left-16 lg:left-24 xl:left-36 text-white">
          {dataDetails.status && (
            <h1
              className="text-xl font-semibold capitalize border-b-[3px] border-primary-btnColor w-fit"
              style={{ lineHeight: "42px" }}
            >
              {dataDetails?.status} Project
            </h1>
          )}
          {dataDetails.title && (
            <p className="py-4 font-bold text-3xl">{dataDetails?.title}</p>
          )}
        </div>
      </div>
      {/* after header section  */}
      {/* overview section  */}

      {dataDetails?.projectOverview && (
        <div className="px-8 md:px-12 lg:px-20 xl:px-28">
          <Heading heading="Project Overview" />
          <div className="py-16 flex flex-col-reverse md:flex-row w-full">
            <div className="w-full md:w-[55%] lg:w-[60%] md:mr-6 px-4 mt-8 md:mt-0">
              <div
                style={{
                  textAlign: "justify",
                  lineHeight: "30px",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                }}
              >
                <p>{dataDetails?.projectOverview?.description}</p>
              </div>
              <div className="flex flex-wrap items-center justify-center">
                {dataDetails?.projectOverview?.startingDate && (
                  <div className="flex flex-col items-center justify-center my-10 xl:my-10 mx-10 xl:mx-16">
                    <Calendar className="text-primary-btnColor w-8 h-8 mb-4" />
                    <p className="text-[#828587] font-bold font-mulish py-4">
                      Project date
                    </p>
                    <p className="font-sourceSerifPro text-3xl font-semibold">
                      {formatDate(dataDetails?.projectOverview?.startingDate)}
                    </p>
                  </div>
                )}
                {dataDetails?.projectOverview?.projectBudget && (
                  <div className="flex flex-col items-center justify-center my-10 xl:my-10 mx:10 xl:mx-16">
                    <CheckSquare2 className="text-primary-btnColor w-10 h-10 mb-4" />
                    <p className="text-[#828587] font-bold font-mulish py-4">
                      Project Budget
                    </p>
                    <p className="font-sourceSerifPro text-3xl font-semibold">
                      Rs {dataDetails?.projectOverview?.projectBudget}
                    </p>
                  </div>
                )}

                {dataDetails?.projectOverview?.donationReceived && (
                  <div className="flex flex-col items-center justify-center my-10 xl:my-8 mx-10 xl:mx-16">
                    <HandCoins className="text-primary-btnColor w-10 h-10 mb-4" />
                    <p className="text-[#828587] font-bold font-mulish py-4">
                      Donation Received{" "}
                    </p>
                    <p className="font-sourceSerifPro text-3xl font-semibold">
                      Rs {dataDetails?.projectOverview?.donationReceived}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full md:w-[45%] lg:w-[40%]">
              <div className="w-full h-[50vh] relative flex flex-col items-center justify-center">
                <img
                  src="/images/common/Upwrapper.png"
                  alt="upwraper"
                  className="absolute top-[-2%] md:top-[-3%] lg:top-[-4%] left-[-4%] md:left-[0%] lg:left-[0%]"
                />
                {dataDetails?.image ? (
                  <img
                    src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
                      dataDetails.image
                    }`}
                    alt="project"
                    className="w-[100%] md:w-[94%] h-[98%] md:h-full"
                  />
                ) : (
                  <DefaultImage />
                )}
                <img
                  src="/images/common/Downwrapper.png"
                  alt="upwraper"
                  className="absolute right-[-3%] md:right-[0%] lg:right-[0%] bottom-[-2%] md:bottom-[-3%] lg:bottom-[-4%]"
                />
              </div>
              <button className="mx-auto text-white font-mulish text-base bg-primary-btnColor transition duration-300 ease-in-out hover:text-black hover:border hover:border-primary-btnColor px-3 py-2 rounded-full hover:bg-white flex items-center mt-8">
                Download Project Report
                <ChevronRight className="w-5 h-5 ml-1" />
              </button>
            </div>
          </div>
        </div>
      )}
      {/* conditional contribution section for ongoing project  */}
      {dataDetails?.status === "Ongoing" && (
        <ProjectContribution
          projectBudget={dataDetails?.projectOverview?.projectBudget}
          donationReceived={dataDetails?.projectOverview?.donationReceived}
          donationPercent={donationPercent}
        />
      )}

      {/* project outcome section  */}
      {dataDetails?.projectOutcomes && (
        <div className="px-8 md:px-12 lg:px-20 xl:px-32">
          <Heading heading="Project Outcomes" />
          <div className="my-10 min-h-[40vh]">
            <div className="w-full md:w-[45%] lg:w-[40%] xl:w-[32%] h-[45vh] xl:h-[40vh] relative mb-6 md:float-left md:mr-8 lg:mr-12">
              <img
                src="/images/common/Upwrapper.png"
                alt="upwraper"
                className="absolute top-[-3%] lg:top-[-4%] left-[-4%] md:left-[-3%]"
              />
              {dataDetails?.projectOutcomes?.outcomeImage ? (
                <img
                  src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
                    dataDetails?.projectOutcomes?.outcomeImage
                  }`}
                  alt="project"
                  className="w-[100%] md:w-[96%] h-[98%] md:h-full"
                />
              ) : (
                <DefaultImage />
              )}
              <img
                src="/images/common/Downwrapper.png"
                alt="upwraper"
                className="absolute right-[-3%] md:right-[0%] lg:right-[0%] bottom-[-2%] md:bottom-[-3%] lg:bottom-[-4%]"
              />
            </div>
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: dataDetails?.projectOutcomes?.description,
              }}
              style={{
                textAlign: "justify",
                lineHeight: "30px",
              }}
            ></div>
          </div>
        </div>
      )}
      {/* project outcome section  */}
      {dataDetails?.objectives && (
        <div className="px-8 md:px-12 lg:px-20 xl:px-32">
          <Heading heading="Project objectives" />
          <div className="my-10 min-h-[40vh]">
            <div className="w-full md:w-[45%] lg:w-[40%] xl:w-[32%] h-[45vh] xl:h-[40vh] relative mb-6 md:float-left md:mr-8 lg:mr-12">
              <img
                src="/images/common/Upwrapper.png"
                alt="upwraper"
                className="absolute top-[-3%] lg:top-[-4%] left-[-4%] md:left-[-3%]"
              />
              {dataDetails?.objectives?.objectivesImage ? (
                <img
                  src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
                    dataDetails?.objectives?.objectivesImage
                  }`}
                  alt="project"
                  className="w-[100%] md:w-[96%] h-[98%] md:h-full"
                />
              ) : (
                <DefaultImage />
              )}
              <img
                src="/images/common/Downwrapper.png"
                alt="upwraper"
                className="absolute right-[-3%] md:right-[0%] lg:right-[0%] bottom-[-2%] md:bottom-[-3%] lg:bottom-[-4%]"
              />
            </div>
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: dataDetails?.objectives?.description,
              }}
              style={{
                textAlign: "justify",
                lineHeight: "30px",
              }}
            ></div>
          </div>
        </div>
      )}

      {/* involvement section  */}
      {dataDetails?.involvement && (
        <div className="px-8 md:px-12 lg:px-20 xl:px-32 my-16 min-h-[52vh]">
          <Heading heading="Involvement" />
          <div className="mt-8">
            <div className="w-full md:w-[45%] lg:w-[40%] xl:w-[35%] h-[45vh] xl:h-[40vh] relative mb-6 md:float-right md:ml-12 md:mt-4">
              <img
                src="/images/common/Upwrapper.png"
                alt="upwraper"
                className="absolute top-[-3%] lg:top-[-4%] left-[-4%] md:left-[-3%]"
              />
              {dataDetails?.involvement?.involvementImage ? (
                <img
                  // src={dataDetails?.involvementImg}
                  src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
                    dataDetails?.involvement?.involvementImage
                  }`}
                  alt="project"
                  className="w-[100%] md:w-[96%] h-[98%] md:h-full"
                />
              ) : (
                <DefaultImage />
              )}
              <img
                src="/images/common/Downwrapper.png"
                alt="upwraper"
                className="absolute right-[-3%] md:right-[0%] lg:right-[0%] bottom-[-2%] md:bottom-[-3%] lg:bottom-[-4%]"
              />
            </div>
            <div
              className=" "
              dangerouslySetInnerHTML={{
                __html: dataDetails?.involvement?.description,
              }}
              style={{
                textAlign: "justify",
                lineHeight: "30px",
              }}
            ></div>
          </div>
        </div>
      )}

      {/* target audience  */}
      {dataDetails?.targetAudience && (
        <div className="px-8 md:px-12 lg:px-20 xl:px-32">
          <Heading heading="Target Audience" />
          <div className="my-10 min-h-[40vh]">
            <div className="w-full md:w-[45%] lg:w-[40%] xl:w-[32%] h-[45vh] xl:h-[40vh] relative mb-6 md:float-left md:mr-8 lg:mr-12">
              <img
                src="/images/common/Upwrapper.png"
                alt="upwraper"
                className="absolute top-[-3%] lg:top-[-4%] left-[-4%] md:left-[-3%]"
              />
              {dataDetails?.targetAudience?.audienceImage ? (
                <img
                  // src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
                  //   dataDetails?.projectOutcomes?.outcomeImage
                  // }`}
                  src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${dataDetails?.targetAudience?.audienceImage}`}
                  alt="project"
                  className="w-[100%] md:w-[96%] h-[98%] md:h-full"
                />
              ) : (
                <DefaultImage />
              )}
              <img
                src="/images/common/Downwrapper.png"
                alt="upwraper"
                className="absolute right-[-3%] md:right-[0%] lg:right-[0%] bottom-[-2%] md:bottom-[-3%] lg:bottom-[-4%]"
              />
            </div>
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html:
                  dataDetails?.targetAudience?.description ||
                  " lorem dummy textlorem dummy textlorem dummy textlorem dummy textlorem dummy textlorem dummy textlorem dummy textlorem dummy text  ",
              }}
              style={{
                textAlign: "justify",
                lineHeight: "30px",
              }}
            ></div>
          </div>
        </div>
      )}

      {/* Donors Switch Section
       */}

      <div className="my-10">
        {dataDetails?.status === "ongoing" && (
          <>
            <div className="w-full h-full overflow-hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 2000 200"
                fill="none"
              >
                <path
                  d="M0 0.000244141C0 0.000244141 500 178.488 1000 68.1447C1500 -42.1987 2000 68.1447 2000 68.1447V1080H0V0.000244141Z"
                  fill="#FFF0E1"
                  fillOpacity="0.5"
                />
                <foreignObject
                  x="0"
                  y="10%"
                  width="100%"
                  height="100%"
                ></foreignObject>
              </svg>
            </div>
            <div
              className={`${
                dataDetails?.status === "Completed"
                  ? "bg-secondary-detailsBackground"
                  : "bg-secondary-detailsBackground"
              } px-8 md:px-20 lg:px-28 xl:px-36 mb-36 pb-36`}
            >
              <Heading heading="List Of Donors" />
              <div className="flex justify-center items-center my-10 mb-16 rounded">
                <button
                  className={`px-8 py-2 ${
                    selectedCategory === "individual"
                      ? "border-b-4 border-primary-btnColor"
                      : "border-b-4 border-gray-300"
                  }`}
                  onClick={() => setSelectedCategory("individual")}
                >
                  Individual
                </button>
                <button
                  className={`px-8 py-2 ${
                    selectedCategory === "institution"
                      ? "border-b-4 border-primary-btnColor"
                      : "border-b-4 border-gray-300"
                  }`}
                  onClick={() => setSelectedCategory("institution")}
                >
                  Institution
                </button>
              </div>

              <div className="grid sm:grid-cols-4 w-full">
                {donationData
                  ?.filter(
                    (data) =>
                      data?.initiativeId?._id === id &&
                      data?.donorId?.category === selectedCategory
                  )
                  .map((donor, index) => (
                    <DonorCard
                      donor={donor}
                      onDonarClick={onDonarClick}
                      key={index}
                    />
                  ))}
                
                {isPopupOpen && (
                  <DonarPopup
                    donarDetails={selectedDonar}
                    closePopup={closePopup}
                  />
                )}
              </div>
            </div>
          </>
        )}

        {dataDetails?.status === "upcoming" ||
          (dataDetails?.status === "ongoing" && (
            <Testimonials articleData={articleData} />
          ))}


        {/* {dataDetails?.status === "upcoming" ||
          (dataDetails?.status === "ongoing" && (
            <ParticipationForm
              status={dataDetails?.status}
              donars={donor}
              projectBudget={dataDetails?.projectOverview?.projectBudget}
              donationReceived={dataDetails?.projectOverview?.donationReceived}
              donationPercent={donationPercent}
            />
          ))} */}
      </div>
    </>
  );
};

export default ProjectDetailsPage;
