// import { formatDate } from "../../utility/dateFormates";
// import PropTypes from "prop-types";
// const InvolvementTeachCard = ({ detail }) => {
//   return (
//     <div className='tech-timeline-container w-full relative flex'>
//       <div className='tech-timeline-item '>
//         <div className=' ml-[5px] mr-3 h-[12px] w-[12px] rounded-full bg-primary-btnColor md:-mt-[9px] lg:-mt-[6.5rem] md:absolute md:left-[30%]'></div>
//         <div className='line h-16 border-l-[3px] absolute left-[90%]'></div>
//       </div>
//       <div className=' tech-timeline-item-content '>
//         <div className=''>
//           <h1 className='text-primary-headingColor text-lg font-semibold capitalize'>
//             {detail?.yourPost}
//           </h1>
//           <p className='font-mulish'>{detail?.location}</p>
//         </div>
//         <div className=''>
//           <p className='font-semibold'>
//             {formatDate(detail?.startingDate ?? "")}
//           </p>
//           <span>to</span>
//           <p>{formatDate(detail?.endDate ?? "")}</p>
//         </div>
//       </div>
//     </div>
//   );
// };
// InvolvementTeachCard.propTypes = {
//   detail: PropTypes.shape({
//     startingDate: PropTypes.string.isRequired,
//     endDate: PropTypes.string,
//     yourPost: PropTypes.string.isRequired,
//     location: PropTypes.string.isRequired,
//     image: PropTypes.string.isRequired,
//   }).isRequired,
//   isRight: PropTypes.bool.isRequired,
// };
// export default InvolvementTeachCard;




























// import PropTypes from "prop-types";
// import { formatDate } from "../../utility/dateFormates";

// const InvolvementTeachCard = ({ detail, isRight }) => {
//   return (
//     <div className={`tech-timeline-container ${isRight ? 'right' : 'left'}`}>

//     <div className={`tech-timeline-item  `}>
//       <div className="tech-timeline-item-content involvement-timeline ">
//         <div className="flex ">
//           <p className="">
//             {formatDate(detail.startingDate)}
//             <span className="ms-2">AD</span>
//           </p>
//           {!detail.endDate ? (
//             <p> onwards</p>
//           ) : (
//             <>
//               <p className="text-center px-2"> to </p>
//               <p>
//                 {formatDate(detail.endDate)}
//                 <span className="ms-2">AD</span>
//               </p>
//             </>
//           )}
//         </div>
//         <div className="h-full w-0.5 bg-primary-subHeading"></div>
//         <div className="flex flex-col justify-center md:w-[100%] px-2 md:px-6 pb-2 md:pb-0">
//           <h1 className="text-primary-subHeading capitalize text-base font-bold pb-2 pt-4 md:pt-0 text-center md:text-start">
//             {detail?.yourPost}
//             </h1>
//           <p className="text-base line-clamp-3 sm:line-clamp-5">
//             {detail.location}
//           </p>
//         </div>
//         <span className="tech-circle" />
//       </div>
//       </div>
//     </div>
      
//   );
// };

// InvolvementTeachCard.propTypes = {
//   detail: PropTypes.shape({
//     startingDate: PropTypes.string.isRequired,
//     endDate: PropTypes.string,
//     yourPost: PropTypes.string.isRequired,
//     location: PropTypes.string.isRequired,
//     image: PropTypes.string.isRequired,
//   }).isRequired,
//   isRight: PropTypes.bool.isRequired,
// };

// export default InvolvementTeachCard;








// // import { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { formatDate } from "../../utility/dateFormates";

// const InvolvementTeachCard = ({ detail }) => {
//   return (
//     <div className="tech-timeline-container w-full">

//     <div className="tech-timeline-item">
//       <div className="tech-timeline-item-content">
//         <div>
//           <p className="flex">
//             {formatDate(detail.startingDate)}
//             <span className="ms-2">AD</span>
//           </p>
//           {!detail.endDate ? (
//             <p> onwards</p>
//           ) : (
//             <>
//               <p className="text-center">to</p>
//               <p>
//                 {formatDate(detail.endDate)}
//                 <span className="ms-2">AD</span>
//               </p>
//             </>
//           )}
//         </div>
//         <div className="h-full w-0.5 bg-primary-subHeading"></div>
//         <div className="flex flex-col justify-center md:w-[90%] px-2 md:px-6 pb-2 md:pb-0">
//           <h1 className="text-primary-subHeading capitalize text-base font-bold pb-2 pt-4 md:pt-0 text-center md:text-start">
//             {detail?.yourPost}
//           </h1>
//           <p className="text-base line-clamp-3 sm:line-clamp-5">
//             {detail.location}
//           </p>
//         </div>
//         <span className="tech-circle" />
//       </div>
//       </div>
//     </div>
      
//   );
// };

// InvolvementTeachCard.propTypes = {
//   detail: PropTypes.shape({
//     startingDate: PropTypes.string.isRequired,
//     endDate: PropTypes.string,
//     yourPost: PropTypes.string.isRequired,
//     location: PropTypes.string.isRequired,
//     image: PropTypes.string.isRequired,
//   }).isRequired,
// };

// export default InvolvementTeachCard;



// import PropTypes from 'prop-types';
// import { formatDate } from "../../utility/dateFormates";

// const InvolvementTeachCard = ({ detail }) => {
//   return (
//     // <div className={`timeline-card  flex px-2 md:px-0 py-3 md:py-0 md:items-center bg-white mt-10 md:h-[30vh] lg:[28vh] w-[50%] md:w-[50%] lg:w-[50%] box-shadow font-mulish justify-center`}>
//   <div className="tech-timeline-item ">

//     <div className="tech-timeline-item-content timeline-card">

//         <div>

//       {/* <div className='px-6 font-mulish font-bold md:text-sm lg:text-base md:w-[10%] lg:w-[30%] flex details-center justify-center gap-2 sm:flex-col py-4 md:py-0'> */}
//         <p className='tech-tag'>
//           {formatDate(detail.startingDate)}
//           <span className='ms-2'>AD</span>
//         </p>
//         {!detail.endDate ? (
//           <p> onwards</p>
//         ) : (
//           <>
//             <p className='text-center'>to</p>
//             <p>
//               {formatDate(detail.endDate)}
//               <span className='ms-2'>AD</span>
//             </p>
//           </>
//         )}
//       </div>
//       <div className='h-full w-0.5 bg-primary-subHeading'></div>
//       <div className='flex flex-col justify-center md:w-[40%] px-2 md:px-6 pb-2 md:pb-0 '>
//         <h1 className='text-primary-subHeading capitalize text-base font-bold pb-2 pt-4 md:pt-0 text-center md:text-start'>
//           {detail?.yourPost}
//         </h1>
//         <p className='text-base line-clamp-3 sm:line-clamp-5'>
//           {detail.location}
//         </p>
//       </div>
//       <span className="tech-circle" />

//       </div>
        
//     </div>
//   );
// };
// InvolvementTeachCard.propTypes = {
//   detail: PropTypes.shape({
//     startingDate: PropTypes.string.isRequired,
//     endDate: PropTypes.string,
//     yourPost: PropTypes.string.isRequired,
//     location: PropTypes.string.isRequired,
//     image: PropTypes.string.isRequired,
//   }).isRequired,
// };
// export default InvolvementTeachCard;









 














// import TimelineItem from "./TimelineItem";
// import timelineData from "../../timelineData";
// // import "../../assets/test.css"

// const InvolvementTeachCard = () =>
//     timelineData.length > 0 && (
//         <div className="tech-timeline-container">
//             {timelineData.map((data, idx) => (
//                 <TimelineItem data={data} key={idx} />
//             ))}
//         </div>
//     );

// export default InvolvementTeachCard;


// const InvolvementTeachCard = () => (
//   <div className="tech-timeline-container">
//     {timelineData.map((details, idx) => (
//       <TimelineItem details={details} key={idx} />
//     ))}
//   </div>
// );

// export default InvolvementTeachCard;







import PropTypes from 'prop-types';
import { formatDate } from "../../utility/dateFormates";

const InvolvementTeachCard = ({ detail }) => {
  return (
    <div className="involvement-timeline w-full relative p-0 justify-center items-center">
      <div className="flex flex-col items-center">
        <div className="relative flex items-center w-full">
          {/* <div className="h-4 w-4 rounded-full bg-primary-btnColor relative left-[calc(50%-0.5rem)]"></div> */}
          {/* <div className="h-1 w-[100%] bg-gray-300 relative top-2 left-0 "></div> */}
        </div>
        <div className="w-[65%] h-full mt-8 flex flex-col md:flex-row items-center md:justify-between font-semibold box-shadow p-10 mb-12 bg-white rounded-md">
          <div className=" border-r-2 border-gray-300 pr-6">
            <h1 className="text-primary-headingColor text-lg font-semibold capitalize">
              {detail?.yourPost}
            </h1>
            <p className="text-gray-600">{detail?.location}</p>
          </div>
          
          <div className="md:w-1/3 flex flex-col items-center mt-4 md:mt-0">
            <p className="font-semibold">{formatDate(detail?.startingDate ?? "")}</p>
            <span>to</span>
            <p>{formatDate(detail?.endDate ?? "")}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-1 relative">
         {/* <div className="h-[12px] w-[12px] rounded-full bg-primary-btnColor absolute -top-[6px]"></div> */}
        {/* <div className="h-1 w-12 bg-gray-300 rotate-90"></div> */}
      </div>
    </div>
  );
};

InvolvementTeachCard.propTypes = {
  detail: PropTypes.shape({
    yourPost: PropTypes.string.isRequired,
    location: PropTypes.string,
    startingDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
};

export default InvolvementTeachCard;


















// import PropTypes from 'prop-types';
// import { formatDate } from "../../utility/dateFormates";

// const InvolvementTeachCard = ({ detail }) => {
//   return (
//     <div className="involvement-timeline w-full relative p-12">
//       <div className="flex justify-between items-center py-6 ">
//         <div className="w-1/3 border-r-2 border-gray-300 pr-4">
//           <h1 className="text-primary-headingColor text-lg font-semibold capitalize mb-2">
//             {detail?.yourPost}
//           </h1>
//           <p className="text-gray-600">{detail?.location}</p>
//         </div>
//         <div className="w-[45%] h-full mt-4 py-4 px-2 flex flex-col items-center font-semibold">
//           <p className="font-semibold">
//             {formatDate(detail?.startingDate ?? "")}
//           </p>
//           <span>to</span>
//           <p>{formatDate(detail?.endDate ?? "")}</p>
//         </div>
//         {/* <div className="w-1/3 flex flex-col items-center">
//           <p className="font-semibold">{formatDate(detail?.startingDate ?? "")}</p>
//           <span>to</span>
//           <p>{formatDate(detail?.endDate ?? "")}</p>
//         </div> */}
//       </div>
//       <div className="flex justify-center items-center mt-4 relative">
//         <div className="h-[12px] w-[12px] rounded-full bg-primary-btnColor absolute -top-[6px]"></div>
//         <div className="h-1 w-1/2 bg-gray-300"></div>
//       </div>
//     </div>
//   );
// };

// InvolvementTeachCard.propTypes = {
//   detail: PropTypes.shape({
//     yourPost: PropTypes.string.isRequired,
//     location: PropTypes.string,
//     startingDate: PropTypes.instanceOf(Date),
//     endDate: PropTypes.instanceOf(Date),
//   }),
// };

// export default InvolvementTeachCard;






// import PropTypes from 'prop-types';
// import { formatDate } from "../../utility/dateFormates";

// const InvolvementTeachCard = ({ detail }) => {
//   return (
//     <div className="involvement-timeline w-full relative p-12">
//       <div className="flex-start hidden md:block lg:flex items-center pt-2 md:pt-0 relative min-h-[14vh]">
//         <div className="border-r-2 border-gray-300 -ml-[5px] mr-3 h-[12px] w-[12px] rounded-full bg-primary-btnColor md:-mt-[9px] lg:-mt-[6.5rem] md:absolute md:left-[30%]"></div>
//         <div className="line h-16 border-l-[3px] absolute left-[30%]"></div>
//       </div>
//       <div className="item flex lg:w-[26rem] mt-2 box-shadow my-4 px-2 lg:px-4 ">
//         <div className="border-r-2 w-[100%] py-4 px-2 space-y-2">
//           <h1 className="text-primary-headingColor text-lg font-semibold capitalize">
//             {detail?.yourPost}
//           </h1>
//           <p className="font-mulish">{detail?.location} </p>
//         </div>
//         <div className="w-[45%] h-full mt-4 py-4 px-2 flex flex-col items-center font-semibold">
//           <p className="font-semibold">
//             {formatDate(detail?.startingDate ?? "")}
//           </p>
//           <span>to</span>
//           <p>{formatDate(detail?.endDate ?? "")}</p>
//         </div>
//       </div>
//       <div className="flex justify-center items-center mt-4 relative">
//         <div className="h-[12px] w-[12px] rounded-full bg-primary-btnColor absolute -top-[6px]"></div>
//         <div className="h-1 w-1/2 bg-gray-300"></div>
//       </div>
//     </div>
//   );
// };
// InvolvementTeachCard.propTypes = {
//   detail: PropTypes.shape({
//     yourPost: PropTypes.string.isRequired,
//     location: PropTypes.string,
//     startingDate: PropTypes.instanceOf(Date),
//     endDate: PropTypes.instanceOf(Date),
//   }),
// };
// export default InvolvementTeachCard;
