import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import ColorForm from "../../../components/forms/postForms/colorForm/ColorForm";

const AddColor = () => {
  return (
    <Container
      breadcumbMenu='Colors'
      breadcumbSubMenu='new Colors'
      title='New Colors'
      btnTitle='back to Colors'
      path='/colors'
    >
      <FormContainer heading='Add Colors Data'>
        <ColorForm />
      </FormContainer>
    </Container>
  );
};

export default AddColor;
