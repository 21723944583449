import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import InvolvementAchievementsForm from "../../../components/forms/postForms/InvolvementAchievementsForm/InvolvementAchievementsForm";
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';

const AddInvolvementAchievements = () => {
  const { data } = useFetchData({
    endpoint: 'involvements',
  });
  return (
    <Container
      breadcumbMenu='involvement-achievements'
      breadcumbSubMenu='new involvement-achievements'
      title='New involvement-achievements'
      btnTitle='back to involvement-achievements'
      path='/admin/involvement-achievements'
    >
      <FormContainer heading='Add involvement-achievements'>
        <InvolvementAchievementsForm  involvementData={data}/>
      </FormContainer>
    </Container>
  );
};

export default AddInvolvementAchievements;
