import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
// import InitiativeForm from "../../../components/forms/postForms/initiativeForm/InitiativeForm";
import ManageUsersForm from "../../../components/forms/postForms/ManageUsersForm/ManageUsersForm";

const AddManageUsers = () => {
  return (
    <Container
      breadcumbMenu='Users'
      breadcumbSubMenu='new Users'
      title='New Users'
      btnTitle='back to Users'
      path='/admin/access/users'
    >
      <FormContainer heading='Add Users'>
        <ManageUsersForm />
      </FormContainer>
    </Container>
  );
};

export default AddManageUsers;
