
const Videos = () => {
  const videos = [
    {
      title: 'Dr. Amrit Yonjan Tamang talking on importance of Multilingual Education in Nepal',
      image: 'https://via.placeholder.com/600x400',
      views: '846 views',
      time: '11 hours ago',
      channel: 'Chorus films Rajan Rai',
      url: 'https://www.youtube.com/watch?v=lZmL8t4qLVQ',
    },
    {
      title: 'Indigenous/Multilingual Education (in Nepal) :: AMRIT YONJAN-TAMANG intvd. by DR. MAROHANG LIMBU',
      image: 'https://via.placeholder.com/300x200',
      views: '5.4k views',
      time: '12 hours ago',
      channel: 'GLOBAL NETWORK',
      url: 'https://www.youtube.com/watch?v=CrJGeN0tx_k',
    },
    {
      title: 'संस्कृत डेथ ल्याङ्ग्वेज हो : Interview With Amrit Yonjan Tamang',
      image: 'https://via.placeholder.com/300x200',
      views: '459 views',
      time: '13 hours ago',
      channel: 'Ujyaalo Network - Digital News',
      url: 'https://www.youtube.com/watch?v=RDtUT68W6x8',
    },
    {
      title: 'Amrit Yonjan Tamang-2079/01/06.Khaniyabas,Dhading',
      image: 'https://via.placeholder.com/300x200',
      views: '130 views',
      time: '14 hours ago',
      channel: 'MILESTONE YOUTUBE CHANNEL',
      url: 'https://www.youtube.com/watch?v=3uD7KE8XLMI',
    },
  ];

  const mainVideo = videos[0];

  return (
    <div className="max-w-screen-xl mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="col-span-2">
          <div className="relative overflow-hidden mb-4">
            <iframe
              width="100%"
              height="500"
              src={`https://www.youtube.com/embed/${mainVideo.url.split('v=')[1]}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={mainVideo.title}
              className="w-full h-[500px] md:h-[calc(100vw*7/16)]"
            ></iframe>
          </div>
          <div className="p-4">
            <h3 className="text-2xl font-bold mb-2">{mainVideo.title}</h3>
            <div className="text-sm text-gray-600">
              <span className="mr-2">{mainVideo.channel}</span>
              <span className="mr-2">{mainVideo.views}</span>
              <span>{mainVideo.time}</span>
            </div>
          </div>
        </div>

        <div>
          <div className="grid grid-cols-1 gap-4">
            {videos.slice(1).map((video, index) => (
              <div key={index} className="rounded-lg overflow-hidden">
                <a href={video.url} target="_blank" rel="noopener noreferrer">
                  <iframe
                    src={`https://www.youtube.com/embed/${video.url.split('v=')[1]}`}
                    className="w-full aspect-w-16 aspect-h-9"
                    title={video.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                  <div className="p-4">
                    <h3 className="text-1xl font-normal mb-2">{video.title}</h3>
                    <div className="text-1xl text-gray-600">
                      <span className="mr-2">{video.channel}</span>
                      <span className="mr-2">{video.views}</span>
                      <span>{video.time}</span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videos;





// const Videos = () => {
//   const videos = [
//     {
//       title: 'Dr. Amrit Yonjan Tamang talking on importance of Multilingual Education in Nepal',
//       image: 'https://via.placeholder.com/600x400',
//       views: '846 views',
//       time: '11 hours ago',
//       channel: 'Chorus films Rajan Rai',
//       url: 'https://www.youtube.com/watch?v=lZmL8t4qLVQ',
//     },
//     {
//       title: 'Indigenous/Multilingual Education (in Nepal) :: AMRIT YONJAN-TAMANG intvd. by DR. MAROHANG LIMBU',
//       image: 'https://via.placeholder.com/300x200',
//       views: '5.4k views',
//       time: '12 hours ago',
//       channel: 'GLOBAL NETWORK',
//       url: 'https://www.youtube.com/watch?v=CrJGeN0tx_k',
//     },
//     {
//       title: 'संस्कृत डेथ ल्याङ्ग्वेज हो : Interview With Amrit Yonjan Tamang',
//       image: 'https://via.placeholder.com/300x200',
//       views: '459 views',
//       time: '13 hours ago',
//       channel: 'Ujyaalo Network - Digital News',
//       url: 'https://www.youtube.com/watch?v=RDtUT68W6x8',
//     },
//     {
//       title: 'Amrit Yonjan Tamang-2079/01/06.Khaniyabas,Dhading',
//       image: 'https://via.placeholder.com/300x200',
//       views: '130 views',
//       time: '14 hours ago',
//       channel: 'MILESTONE YOUTUBE CHANNEL',
//       url: 'https://www.youtube.com/watch?v=3uD7KE8XLMI',
//     },
//   ];

//   const mainVideo = videos[0];

//   return (
//     <div className="max-w-screen-xl mx-auto p-4">
//       <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//         <div className="col-span-2">
//           <div className="relative overflow-hidden mb-4">
//             <iframe
//               width="100%"
//               height="600"
//               src={`https://www.youtube.com/embed/${mainVideo.url.split('v=')[1]}`}
//               frameBorder="0"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//               allowFullScreen
//               title={mainVideo.title}
//               className="aspect-w-16 aspect-h-9"
//             ></iframe>
//           </div>
//           <div className="p-4">
//             <h3 className="text-2xl font-bold mb-2">{mainVideo.title}</h3>
//             <div className="text-sm text-gray-600">
//               <span className="mr-2">{mainVideo.channel}</span>
//               <span className="mr-2">{mainVideo.views}</span>
//               <span>{mainVideo.time}</span>
//             </div>
//           </div>
//         </div>

//         <div>
//           {/* <h2 className="text-xl font-bold mb-4">Recommended Videos</h2> */}
//           <div className="grid grid-cols-1 gap-4">
//             {videos.slice(1).map((video, index) => (
//               <div key={index} className="rounded-lg overflow-hidden">
//                 <a href={video.url} target="_blank" rel="noopener noreferrer">
//                   <iframe
//                     src={`https://www.youtube.com/embed/${video.url.split('v=')[1]}`}
//                     className="w-full aspect-w-16 aspect-h-9"
//                     title={video.title}
//                     frameBorder="0"
//                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                     allowFullScreen
//                   />
//                   <div className="p-4">
//                     <h3 className="text-lg font-normal mb-2">{video.title}</h3>
//                     <div className="text-sm text-gray-600">
//                       <span className="mr-2">{video.channel}</span>
//                       <span className="mr-2">{video.views}</span>
//                       <span>{video.time}</span>
//                     </div>
//                   </div>
//                 </a>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Videos;





























// // import Container from '../container/Container';

// const Videos = () => {
//   const videos = [
//     {
//       title: 'Dr. Amrit Yonjan Tamang talking on importance of Multilingual Education in Nepal',
//       image: 'https://via.placeholder.com/600x400',
//       views: '846 views',
//       time: '11 hours ago',
//       channel: 'Chorus films Rajan Rai',
//       url: 'https://www.youtube.com/watch?v=lZmL8t4qLVQ',
//     },
//     {
//       title: 'Indigenous/Multilingual Education (in Nepal) :: AMRIT YONJAN-TAMANG intvd. by DR. MAROHANG LIMBU',
//       image: 'https://via.placeholder.com/300x200',
//       views: '5.4k views',
//       time: '12 hours ago',
//       channel: 'GLOBAL NETWORK',
//       url: 'https://www.youtube.com/watch?v=CrJGeN0tx_k',
//     },
//     {
//       title: 'संस्कृत डेथ ल्याङ्ग्वेज हो : Interview With Amrit Yonjan Tamang',
//       image: 'https://via.placeholder.com/300x200',
//       views: '459 views',
//       time: '13 hours ago',
//       channel: 'Ujyaalo Network - Digital News',
//       url: 'https://www.youtube.com/watch?v=RDtUT68W6x8',
//     },
//     {
//       title: 'Amrit Yonjan Tamang-2079/01/06.Khaniyabas,Dhading',
//       image: 'https://via.placeholder.com/300x200',
//       views: '130 views',
//       time: '14 hours ago',
//       channel: 'MILESTONE YOUTUBE CHANNEL',
//       url: 'https://www.youtube.com/watch?v=3uD7KE8XLMI',
//     },
//   ];

//   const mainVideo = videos[0];

//   return (
//     // <Container>
//       <div className="max-w-screen-xl mx-auto rounded-lg grid grid-flow-col">
//         <div className="mb-8">
//           <div className="relative overflow-hidden mb-4">
//             <iframe
//               width="100%"
//               height="600"
//               src={`https://www.youtube.com/embed/${mainVideo.url.split('v=')[1]}`}
//               frameBorder="0"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//               allowFullScreen
//               title={mainVideo.title}
//             ></iframe>
//           </div>
//           <div className="p-4">
//             <h3 className="text-2xl font-bold mb-2">{mainVideo.title}</h3>
//             <div className="text-sm text-gray-600">
//               <span className="mr-2">{mainVideo.channel}</span>
//               <span className="mr-2">{mainVideo.views}</span>
//               <span>{mainVideo.time}</span>
//             </div>
//           </div>
//         </div>

//         <div>
//           {/* <h2 className="text-xl font-bold mb-4">Recommended Videos</h2> */}
//           <div className="grid px-8 rounded-lg ">
//             {videos.slice(1).map((video, index) => (
//               <div key={index} className=" rounded-lg overflow-hidden">
//                 <a href={video.url} target="_blank" rel="noopener noreferrer">
//                   <iframe src={`https://www.youtube.com/embed/${video.url.split('v=')[1]}`} className="w-full h-48 object-cover" />
//                   <div className="p-4">
//                     <h3 className="text-lg font-normal mb-2">{video.title}</h3>
//                     <div className="text-sm text-gray-600">
//                       <span className="mr-2">{video.channel}</span>
//                       <span className="mr-2">{video.views}</span>
//                       <span>{video.time}</span>
//                     </div>
//                   </div>
//                 </a>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     // </Container>
//   );
// };

// export default Videos;


























// const Videos = () => {
//   const videos = [
//     {
//       title: 'Amrit Yonjan (Tamang) On Nirantar Bahas with Kumar Yatru Episode - 39',
//       image: 'https://via.placeholder.com/300x200',
//       views: '2.4k views',
//       time: '11 hour ago',
//       channel: 'The news channel',
//       url: 'https://www.youtube.com/watch?v=lZmL8t4qLVQ', // Replace with your video URL
//     },
//     {
//       title: 'Amrit Yonjan (Tamang) On the Nirantar Bahas with Kumar Yatru Episode - 39',
//       image: 'https://via.placeholder.com/300x200',
//       views: '2.4k views',
//       time: '11 hour ago',
//       channel: 'The news channel',
//       url: 'https://www.youtube.com/watch?v=your-video-id', // Replace with your video URL
//     },
//     {
//       title: 'Amrit Yonjan (Tamang) On the Nirantar Bahas with Kumar Yatru Episode - 39',
//       image: 'https://via.placeholder.com/300x200',
//       views: '2.4k views',
//       time: '11 hour ago',
//       channel: 'The news channel',
//       url: 'https://www.youtube.com/watch?v=your-video-id', // Replace with your video URL
//     },
//     {
//       title: 'Amrit Yonjan (Tamang) On the Nirantar Bahas with Kumar Yatru Episode - 39',
//       image: 'https://via.placeholder.com/300x200',
//       views: '2.4k views',
//       time: '11 hour ago',
//       channel: 'The news channel',
//       url: 'https://www.youtube.com/watch?v=your-video-id', // Replace with your video URL
//     },
//   ];

//   return (
//     <div className="videos-container">
//       <h2 className="videos-title">Videos</h2>
//       <div className="videos-grid">
//         {videos.map((video, index) => (
//           <div key={index} className="video-card">
//             <a href={video.url} target="_blank" rel="noopener noreferrer">
//               <img src={video.image} alt={video.title} className="video-thumbnail" />
//               <div className="video-play-icon">
//                 <i className="fas fa-play-circle"></i>
//               </div>
//             </a>
//             <div className="video-info">
//               <h3 className="video-title">{video.title}</h3>
//               <div className="video-details">
//                 <span className="video-channel">{video.channel}</span>
//                 <span className="video-views">{video.views}</span>
//                 <span className="video-time">{video.time}</span>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Videos;


// import PropTypes from 'prop-types';
// // import './VideoItem.css'; // Create this CSS file for styles

// const VideoItem = ({ video }) => {
//     return (
//         <div className="video-item">
//             <img src={`https://img.youtube.com/vi/${video?.id}/maxresdefault.jpg`} alt="Video Thumbnail" />
//             <div className="video-info">
//                 <h4>{video?.title}</h4>
//                 <p className="views">{video?.views} views • {video?.timeAgo}</p>
//                 <p className="likes">{video?.likes} likes</p>
//             </div>
//         </div>
//     );
// };

// VideoItem.propTypes = {
//     video: PropTypes.shape({
//         id: PropTypes.string.isRequired,
//         title: PropTypes.string.isRequired,
//         views: PropTypes.string.isRequired,
//         timeAgo: PropTypes.string.isRequired,
//         likes: PropTypes.string.isRequired,
//     }).isRequired,
// };

// export default VideoItem;
