import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { ArrowRight, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import DateViews from '../dateViews/DateViews';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

const CarouselItem = ({ article }) => (
  <div className="py-6 md:h-[45vh] lg:h-[40vh] xl:h-[40vh] mx-2 lg:mx-4 my-10 mb-12 box-shadow cursor-pointer md:hover:scale-105 transition duration-300 ease-in-out flex flex-col md:flex-row px-6">
    <div className="w-full sm:w-[70%] md:w-[30%] lg:w-[28%] h-full md:h-full">
      <Link to={`/articles/details/${article._id}`}>
        <img
          src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${article.image}`}
          alt="book"
          className="w-full h-full object-cover"
        />
      </Link>
    </div>
    <div className="w-full md:w-[68%] font-mulish md:ml-6 h-1/2 mt-7 md:mt-0 md:h-full">
      <h1 className="text-primary-headingColor line-clamp-1 md:line-clamp-2 overflow-hidden whitespace-wrap mb-2 text-xl font-semibold font-inter">
        <Link to={`/articles/details/${article._id}`}>{article.title}</Link>
      </h1>
      <DateViews data={article} className="my-3" />
      <div className="my-2 line-clamp-3 md:line-clamp-4 overflow-hidden whitespace-wrap">
        <p>{parse(article.description)}</p>
      </div>
      <p className="text-primary-btnColor text-sm font-bold cursor-pointer duration-300 ease-in-out mt-4">
        View Detail
      </p>
    </div>
  </div>
);
CarouselItem.propTypes = {
  article: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

const SliderCard = ({ dataDetails }) => (
  <div className="px-4 md:px-10 lg:px-12 xl:px-20">
    <div className="my-4">
      <AliceCarousel
        disableDotsControls
        responsive={{
          0: { items: 1 },
          568: { items: 2 },
          1024: { items: 3 },
          1366: { items: 4 },
        }}
        mouseTracking
        renderPrevButton={() => (
          <div className="flex items-center justify-end">
            <ArrowLeft className="text-primary-btnColor cursor-pointer" />
          </div>
        )}
        renderNextButton={() => (
          <div className="flex items-center justify-start">
            <ArrowRight className="text-primary-btnColor cursor-pointer" />
          </div>
        )}
      >
        {dataDetails.map((article, index) => (
          <CarouselItem key={index} article={article} />
        ))}
      </AliceCarousel>
    </div>
  </div>
);

SliderCard.propTypes = {
  dataDetails: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};


export default SliderCard;
