/* eslint-disable no-unused-vars */
import React from "react";
import Container from "../../../components/container/Container";
import FormContainer from "../../../components/forms/formContainer/FormContainer";
import AffiliationForm from "../../../components/forms/postForms/affiliationForm/AffiliationForm";
import useFetchDataDetails from "./../../../hooks/useFetchDataDetail/useFetchDataDetails";
import { useParams } from "react-router-dom";
import DataFetchingError from "../../errorPage/DataFetchingError";
import Loader from "../../../components/loader/Loader";
import InvolvementBannersForm from "./../../../components/forms/postForms/InvolvementBannersForm/InvolvementBannersForm";

const EditInvolvementBanners = () => {
  const { id } = useParams();
  //   custom hook for fetching data detaails from api with 2 parameters (endpoint and id)
  const { dataDetails, loading, error } = useFetchDataDetails(
    "involvement-banners",
    id
  );

  return (
    <Container
      breadcumbMenu=" Involvement Banners"
      breadcumbSubMenu="Edit involvement banners"
      title="edit involvement banners"
      btnTitle="back to involvement banners"
      path="/admin/involvement-banners"
    >
      <FormContainer heading="edit involvement banners">
        {loading ? (
          <Loader />
        ) : error ? (
          <DataFetchingError />
        ) : (
          <InvolvementBannersForm defaultData={dataDetails} />
        )}
      </FormContainer>
    </Container>
  );
};

export default EditInvolvementBanners;
