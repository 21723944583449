import { useState } from "react";
import ToolTip from "../tooltip/ToolTip";
import { Trash2, Pencil } from "lucide-react";
import TableContainer from "../../container/TableContainer";
import Modal from "../../models/Model";
import { toast } from "react-toastify";
import useDataDeletion from "../../../hooks/useDataDeletion/usedDataDeletion";
import { Link } from "react-router-dom";
import Pagination from "../pagination/Pagination";
import PropTypes from "prop-types";

const ArticleTable = ({ data, refetchData }) => {
  const apiUrl = `${import.meta.env.VITE_APP_API_URL}/articles`;
  const { deleteData } = useDataDeletion();
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10; // Number of posts per page, adjust as needed

  const openModal = (id) => {
    setDeleteItemId(id);
  };

  const closeModal = () => {
    setDeleteItemId(null);
  };

  const handleDelete = async () => {
    try {
      await deleteData(`${apiUrl}/${deleteItemId}`);
      toast.success("Data deleted successfully!");
      refetchData();
      closeModal();
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Failed to delete data");
    }
  };

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const newData = data.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <TableContainer>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 capitalize font-bold">
          <tr>
            <th scope="col" className="px-4 py-3 text-left text-sm text-gray-700">
              <span>SN</span>
            </th>
            <th scope="col" className="px-6 py-3 text-left text-sm text-gray-700">
              Image
            </th>
            <th scope="col" className="px-6 py-3 text-left text-sm text-gray-700">
              Title
            </th>
            <th scope="col" className="px-6 py-3 text-left text-sm text-gray-700">
              Description
            </th>
            <th scope="col" className="px-6 py-3 text-left text-sm text-gray-700">
              Category
            </th>
            <th scope="col" className="relative px-6 py-3">
              <span>Actions</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {newData.map((article, index) => (
            <tr key={index}>
              <td className="whitespace-wrap font-bold px-4 py-4 text-sm text-gray-700">
                {index + 1}
              </td>
              <td className="whitespace-wrap px-6 py-4">
                <div className="flex items-center">
                  <div className="h-16 w-16 flex-shrink-0">
                    <img
                      className="h-full w-full object-cover"
                      src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${article?.image}`}
                      alt="Image"
                    />
                  </div>
                </div>
              </td>
              <td className="whitespace-wrap px-6 py-4">
                <div className="text-sm font-medium text-black capitalize">
                  {article?.title}
                </div>
              </td>
              <td className="whitespace-wrap px-6 py-4">
                <div className="text-sm font-medium text-black capitalize line-clamp-3">
                  {article?.description}
                </div>
              </td>
              <td className="whitespace-wrap px-6 py-4">
                <div className="text-sm font-semibold capitalize line-clamp-3 overflow-hidden">
                  {article?.category ? (
                    <Link to={`/admin/products/details/${article?.id}`}>
                      {article.category.categoryName}
                    </Link>
                  ) : (
                    "N/A"
                  )}
                </div>
              </td>
              <td className="whitespace-wrap px-6 py-4 flex items-center justify-center gap-3">
                <ToolTip text="edit">
                  <Link to={`/admin/articles/edit/${article._id}`}>
                    <span className="flex items-center gap-2 rounded-full bg-blue-500 p-2 text-white">
                      <Pencil className="h-4 w-4" aria-hidden="true" />
                    </span>
                  </Link>
                </ToolTip>
                <ToolTip text="delete">
                  <span
                    onClick={() => openModal(article._id)}
                    className="flex items-center gap-2 rounded-full bg-orange-500 p-2 text-white cursor-pointer"
                  >
                    <Trash2 className="h-4 w-4" aria-hidden="true" />
                  </span>
                </ToolTip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        className="my-10"
        postsPerPage={postsPerPage}
        totalPosts={data.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      <Modal isOpen={deleteItemId !== null} onClose={closeModal}>
        <button
          onClick={closeModal}
          className="p-4 w-fit bg-indigo-600 text-white font-bold text-sm"
        >
          Cancel
        </button>
        <button
          onClick={handleDelete}
          className="p-4 w-fit bg-orange-600 text-white font-bold text-sm"
        >
          Delete
        </button>
      </Modal>
    </TableContainer>
  );
};

ArticleTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired, // Ensuring data is an array of objects and is required
  refetchData: PropTypes.func.isRequired,
};

export default ArticleTable;
