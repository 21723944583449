import PropTypes from "prop-types"; // Import PropTypes

const DataFetchingError = ({ error, refetchData }) => {
  return (
    <div
      className='w-100 md:w-[70%] lg:w-[50%] mx-auto my-10 p-10 space-y-5 bg-red-100 border border-red-400 text-red-700  rounded relative'
      role='alert'
    >
      {error && (
        <div>
          <strong className='font-bold'>Error:</strong>
          <span className='block sm:inline ms-4'>{error}</span>
        </div>
      )}
      <button
        type='button'
        onClick={() => refetchData()}
        className='w-44 mx-auto p-2 text-sm capitalize bg-indigo-400 text-white'
      >
        Refresh the page
      </button>
    </div>
  );
};

// Add prop type validation
DataFetchingError.propTypes = {
  error: PropTypes.string, // Ensure error is a string
  refetchData: PropTypes.func.isRequired, // Ensure refetchData is a function and is required
};

export default DataFetchingError;
