import { useState } from 'react';
import InitiativeCard from '../card/InitiativeCard';
import PropTypes from 'prop-types';

const OrganizationalInitiatives = ({ initiativesData }) => {
  const [btnClicked, setBtnClicked] = useState(false);

  const filteredInitiatives = initiativesData.filter(
    (data) => data.category === 'organizational'
  );

  const displayedInitiatives = btnClicked
    ? filteredInitiatives
    : filteredInitiatives.slice(0, 2);

  const toggleBtnText = btnClicked ? 'View less' : 'View more';

  return (
    <div className="px-8 md:px-8 lg:px-12 xl:px-32 py-6 lg:py-10">
      <h1 className="text-xl font-semibold border-b-[3px] border-primary-btnColor mb-14 w-fit mx-auto"
        style={{ lineHeight: '42px' }}
      >
        Organizational Initiatives
      </h1>
      <div className="w-[90%] mx-auto">
        {displayedInitiatives.map((item, index) => (
          <InitiativeCard item={item} key={index} />
        ))}
      </div>

      {initiativesData.length > 2 && (
        <div
          onClick={() => setBtnClicked(!btnClicked)}
          className="flex items-center mt-16 justify-center cursor-pointer"
        >
          <p className="capitalize">{toggleBtnText}</p>
        </div>
      )}
    </div>
  );
};
// OrganizationalInitiatives.propTypes = {
//   initiativesData: PropTypes.arrayOf(PropTypes.shape({
//     category: PropTypes.string.isRequired,
//     // Add other required properties here
//   })).isRequired,
// };
OrganizationalInitiatives.propTypes = {
  initiativesData: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      // Add other fields present in your initiative data as needed
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string,
    })
  ).isRequired,
};
export default OrganizationalInitiatives;
