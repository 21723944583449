import Container from '../../../components/container/Container';
import FormContainer from '../../../components/forms/formContainer/FormContainer';
import DonationForm from '../../../components/forms/postForms/donorForm/DonorForm';

const AddDonor = () => {
  return (
    <Container
      breadcumbMenu="Donars"
      breadcumbSubMenu="new Donar"
      title="New donar"
      btnTitle="back to donar"
      path="/admin/donors"
    >
      <FormContainer heading="Add Donars Detail">
        <DonationForm />
      </FormContainer>
    </Container>
  );
};

export default AddDonor;
