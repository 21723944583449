import Container from "../../components/container/Container";
import Loader from "../../components/loader/Loader";
import DataFetchingError from "../errorPage/DataFetchingError";
import { useFetchData } from "../../hooks/useFetchData/useFetchData";
import DataNotFound from "../errorPage/DataNotFound";
import ScopeTable from "../../components/forms/tables/ScopeTable";

const AboutMe = () => {
  const { data, loading, error, refetchData } = useFetchData({
    endpoint: "scope",
  });

  return (
    <Container
      breadcumbMenu="Scope"
      title="Scope"
      btnTitle="Add Scope"
      path="add"
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <DataFetchingError error={error} refetchData={refetchData} />
      ) : data.length > 0 ? (
        <ScopeTable data={data} refetchData={refetchData} />
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default AboutMe;
