import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { CalendarMinus2, Eye } from "lucide-react";
import Heading from "./../common/Heading";
import { formatDate } from "../../utility/dateFormates";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
const LatestArticle = ({ articleData }) => {
  const ArrowLeft = (props) => (
    <div
      {...props}
      className="absolute bottom-4 right-1/2 transform translate-x-2 translate-y-1/2 z-10 cursor-pointer text-1xl text-primary-btnColor mt-8 pt-12"
    >
      <FaArrowLeft />
    </div>
  );

  const ArrowRight = (props) => (
    <div
      {...props}
      className="absolute bottom-4 left-1/2 transform translate-x-6 translate-y-1/2 z-10 cursor-pointer text-1xl text-primary-btnColor mt-8 pt-12"
    >
      <FaArrowRight />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    spaceBetween: 30,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="my-5 px-4 md:px-10 lg:px-12 xl:px-20 pt-8 flex flex-col w-full items-center">
      <div className="p-6">
        <Heading heading="Latest Articles" />
      </div>
      <div className="w-full max-w-screen-lg my-4">
        <Slider {...settings} className="gap-6">
          {articleData.map((item) => (
            <div
              key={item.id}
              className="bg-white p-8 shadow-lg rounded-lg border mx-0"
            >
              <div className="w-full h-56">
                <img
                  src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
                  alt="article"
                  className="w-full h-full object-cover rounded-md"
                />
              </div>
              <div className="mt-4">
                <h1 className="text-primary-headingColor text-xl font-semibold font-inter pb-2">
                  {item.title}
                </h1>
                <div className="flex justify-between items-center mt-2">
                  <div className="flex items-center">
                    <CalendarMinus2 className="w-5 h-5 text-[#828587]" />
                    <p className="ml-1">{formatDate(item.createdAt)}</p>
                  </div>
                  <div className="flex items-center">
                    <Eye className="w-5 h-5 text-[#828587]" />
                    <p className="ml-1">{item.views} views</p>
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                  className="mt-2 text-justify text-gray-700"
                  style={{
                    lineHeight: '1.15',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                  }}
                ></div>
                <p className="text-primary-btnColor text-sm font-bold cursor-pointer duration-300 ease-in-out mt-4">
                  <Link>View Detail</Link> 
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

LatestArticle.propTypes = {
  articleData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      views: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default LatestArticle;





// import Slider from "react-slick";
// // import AliceCarousel from 'react-alice-carousel';
// import 'react-alice-carousel/lib/alice-carousel.css';
// import {
//   // ArrowRight,
//   // ArrowLeft,
//   CalendarMinus2, Eye
// } from 'lucide-react';
// import Heading from './../common/Heading';
// import { formatDate } from '../../utility/dateFormates';
// import PropTypes from 'prop-types';
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";


// const LatestArticle = ({ articleData }) => {
//   const ArrowLeft = (props) => (
//     <div
//       {...props}
//       className="absolute bottom-4 right-1/2 transform translate-x-2 translate-y-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-6 pt-10 pb-4"
//     >
//       <FaArrowLeft />
//     </div>
//   );
  
//   const ArrowRight = (props) => (
//     <div
//       {...props}
//       className="absolute bottom-4 left-1/2 transform translate-x-6 translate-y-1/2 z-10 cursor-pointer text-2xl text-primary-btnColor mt-6 pt-10 pb-4"
//     >
//       <FaArrowRight />
//     </div>
//   );
  
//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 600,
//     slidesToShow: 2,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     nextArrow: <ArrowRight />,
//     prevArrow: <ArrowLeft />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };
//   return (
    
//     <div className="my-15 px-4 md:px-10 lg:px-12 xl:px-20 pt-8">
//       <div className="pb-8">
//         <Heading heading="Latest Articles" />
//       </div>
//       <div className="my-4  ">
//           <Slider {...settings}>
//           {articleData.map((item) => (
//             <div
//               key={item.id}
//               className="shadow-lg rounded-lg h-fit pt-8 py-6 md:h-[fit] lg:h-[fit] xl:h-[fit] mx-2 lg:mx-4 cursor-pointer box-shadow  transition duration-300 ease-in-out flex flex-col md:flex-row px-6"
//               // style={{ marginRight: "20px" }} 
//             >
//               <div className="w-full sm:w-[60%] md:w-[30%] lg:w-[25%] h-[30%] md:h-[30%]">
//                 <img
//                   src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${item.image}`}
//                   alt="artimport { Link } from 'react-router-dom';
// icle"
// //                   className="w-full h-[80%]"
// //                 />
//               </div>
//               <div className="w-full md:w-[65%] font-mulish md:ml-6 h-1/2 mt-7 md:mt-0 md:h-full">
//                 <h1 className="text-primary-headingColor text-xl font-semibold font-inter pb-1 lg:pb-3">
//                   {item.title}
//                 </h1>
//                 <div className="md:flex mt-2 lg:mt-0">
//                   <div className="flex items-center mr-1 lg:mr-3 xl:my-2">
//                     <CalendarMinus2 className="w-5 h-5 text-[#828587]" />
//                     <p className="ml-1">{formatDate(item.createdAt)}</p>
//                   </div>
//                   <div className="flex items-center my-4 md:my-0">
//                     <Eye className="w-5 h-5 text-[#828587]" />
//                     <p className="ml-1">{item.views} views</p>
//                   </div>
//                 </div>
//                 <div
//                   dangerouslySetInnerHTML={{
//                     __html: item.description,
//                   }}
//                   className="my-2"
//                   style={{
//                     textAlign: 'justify',
//                     lineHeight: '28px',
//                     overflow: 'hidden',
//                     display: '-webkit-box',
//                     WebkitBoxOrient: 'vertical',
//                     WebkitLineClamp: 3,
//                   }}
//                 ></div>
//                 <p className="text-primary-btnColor text-sm font-bold cursor-pointer duration-300 ease-in-out mt-4">
//                   View Detail
//                 </p>
//               </div>
//             </div>
//           ))}
//           </Slider>
//       </div>
//     </div>
//   );
// };
// LatestArticle.propTypes = {
//   articleData: PropTypes.arrayOf(PropTypes.shape({
//     id: PropTypes.number.isRequired,
//     title: PropTypes.string.isRequired,
//     image: PropTypes.string.isRequired,
//     createdAt: PropTypes.string.isRequired,
//     views: PropTypes.number.isRequired,
//     description: PropTypes.string.isRequired,
//   })).isRequired,
// };

// export default LatestArticle;
