import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import CustomBtn from '../../customBtn/CustomBtn';
import { formatDate } from '../../../utility/dateFormates';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// interface PublicationContentProps {
//   publicationData: Array<{
//     _id: string;
//     image: string;
//     bookTitle: string;
//     publishedDate?: string;
//     summary: string;
//   }>;
//   item: number;
//   className?: string;
// }

const PublicationContent = ({
  publicationData,
  item,
  className = '',
}) => {
  const navigate = useNavigate();

  const selectedPublication = publicationData[item];

  return (
    <>
      <div
        className={`w-full flex flex-col items-center gap-5 lg:gap-10 ${className}`}
      >
        <div className="w-[10%] sm:w-[20rem] h-full">
          <img
            className="w-full h-full object-contain"
            src={`${import.meta.env.VITE_APP_BASE_URL}/uploads/${
              selectedPublication?.image
            }`}
            alt=""
          />
        </div>
        <div className=" bg-white sm:w-[100%] box-shadow mx-5 p-10 border">
          <div>
            <h1 className="capitalize text-xl font-semibold text-gray-700 mb-3">
              {selectedPublication?.bookTitle}
            </h1>
            {selectedPublication?.publishedDate && (
              <p className="capitalize lg:text-2xl font-semibold">
                Publication data:
                <span className="ms-2">
                  {formatDate(selectedPublication?.publishedDate)}
                </span>
              </p>
            )}
            <div className="flex items-center gap-1 my-2">
              <FaStar className="w-5 h-5 text-yellow-500" />
              <FaStar className="w-5 h-5 text-yellow-500" />
              <FaStar className="w-5 h-5 text-yellow-500" />
              <FaStar className="w-5 h-5 text-yellow-500" />
              <FaStarHalfAlt className="w-5 h-5 text-yellow-500" />
            </div>
          </div>
          <div className="my-5">
            <p className="line-clamp-3 lg:line-clamp-3 overflow-hidden whitespace-wrap">
              {selectedPublication?.summary}
            </p>
          </div>
          <CustomBtn
            onClick={() => {
              navigate(`/publications/${selectedPublication?._id}`);
            }}
          >
            View Detail
          </CustomBtn>
        </div>
      </div>
    </>
  );
};
PublicationContent.propTypes = {
  publicationData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      bookTitle: PropTypes.string.isRequired,
      publishedDate: PropTypes.string,
      summary: PropTypes.string.isRequired,
    })
  ).isRequired,
  item: PropTypes.number.isRequired,
  className: PropTypes.string,
};
export default PublicationContent;
